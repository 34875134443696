import { optionSelectorData } from '../data.js'
import { getOrgId } from './get-org-id.js'

export function recordSelection (targetOrgId) {
  if (targetOrgId === 'all') {
    toggleAll()
  } else if (targetOrgId === 'none') {
    toggleNone()
  } else {
    optionSelectorData.organisationId = targetOrgId
  }
}

function toggleAll () {
  optionSelectorData.organisationId = []
  optionSelectorData.orgsArray.forEach(function (org) {
    const orgId = getOrgId(org)
    if (['all', 'none', 'conso'].includes(orgId)) return
    if (org.type === 'break') return

    optionSelectorData.organisationId.push(orgId)
  })
}

function toggleNone () {
  optionSelectorData.organisationId = null
}
