import { getFileSheet } from './get-file-sheet.js'
import { parseSheet } from './parse-sheet.js'

/**
 *
 * @param {*} file
 * @param {Object} options
 * @param {String} options.sheetName
 * @param {Number} options.dataHeader
 * @returns
 */
export async function getFileData (file, options) {
  // console.log('getFileData', options)
  const worksheet = await getFileSheet(file, options)
  // console.log('worksheet', worksheet)

  const sheetParsingOptions = {
    dataHeader: (options?.dataHeader - 1) || 0
  }
  const dataset = parseSheet(worksheet, sheetParsingOptions)

  return dataset
}
