
// import * as mOptionsPeriodicity from './periodicity/options-periodicity.js'
// import * as mOptionsPeriodsIncluded from './periods/options-value-aggregations.js'
// import * as mOptionsOrganisations from './entities/options-organisations.js'
// import * as mOptionsOthers from './others/options-others.js'
// import * as mOptionsForecast from './forecasting-options/forecasting-options.js'

import htmlOptionSelectorTemplate from './option-selector.html'

import { enableConsole } from '../../../helpers/logging/console.js'
import { setElementHtml, showElement } from '../../helpers/dom.js'

import './option-selector.less'
import { makeMenu } from './make-menu.js'

/**
 * TODO: refine how defaults are selected
 * @param {Object} params
 * @param {String} params.containerId
 * @param {Array} [params.dateseries]
 * @param {Object} [params.dateOptions]
 * @param {Object} [params.dateOptions.default]
 * @param {Array} [params.hide] -- selectors to hide
 * @param {Array} [params.show] -- specific selectors to show
 * @param {Function} params.onChange -- function to execute when a change is done
 * @param {Object} params.customSettings -- eg. statementType
 * @returns
 */
export function activateOptionSelector (params) {
  enableConsole(true)
  // console.log('activateOptionSelector', params)

  if (!setElementHtml(params.containerId, htmlOptionSelectorTemplate)) return

  showElement('optionSelectorContainer')

  makeMenu(params)

  // mOptionsPeriodicity.activate(params)
  // mOptionsPeriodsIncluded.activate(params)

  // // Others
  // mOptionsOthers.activate(params)

  // // Other Views
  // mOptionsForecast.activateForecastOptions(params)

  // // Show confirmation button
  // // TODO

  // updateSelectors(params)
  // return params
}
