export function makeColumns (settings, options) {
  const colDefs = []

  colDefs.push({
    id: 'property',
    valueGetter: function (cellParams) {
      const rowOutputname = cellParams.rowDef.outputName
      return rowOutputname
    },
    width: 200
  })

  settings.forEach(function (setting) {
    if (options) {
      if (options.platform && !(options.platform === setting.platform)) return
      if (options.country && !(options.country === setting.country)) return
    }
    const colDef = {
      id: [setting.country, setting.platform, setting.source].join('.'),
      context: {
        country: setting.country,
        platform: setting.platform,
        source: setting.source
      },
      valueGetter: function (cellParams) {
        // console.log(cellParams)

        const rowValues = cellParams.rowDef.values
        const colContext = cellParams.columnDef.context

        const foundValue = rowValues.find(function (rowValue) {
          if (rowValue.country !== colContext.country) return false
          if (rowValue.platform !== colContext.platform) return false
          if (rowValue.source !== colContext.source) return false

          return true
        })

        // Show the input
        const inputName = foundValue?.field?.inputName

        const computeFormula = foundValue?.field?.computeFormula
        return inputName || computeFormula || null

        // Show the setting of the property
        // return (foundValue?.field) ? Object.keys(foundValue?.field).join(',') : null
      },
      width: 100
    }
    colDefs.push(colDef)
  })

  return colDefs
}
