
// Module
import * as mData from './data.js'
import * as fs2Statements from './statements-v2.js'
import * as fsSettings from './settings.js'
import * as fs2Eliminations from './eliminations.js'

import { optionSelectorData } from '../../modules/option-selector/data.js'
import { enableConsole } from '../../../helpers/logging/console.js'
import { showFinancialForecast } from './forecast/forecast-index.js'
import { showActions } from './actions.js'
import { makeDateSerie } from '../../../helpers/dates/make-date-serie.js'

export function executeShowStatements () {
  enableConsole(true)

  const context = {}
  console.log('executeShowStatements', optionSelectorData)
  if (optionSelectorData.layout.length === 1) {
    // If the columnsLayout has length of 2, it means the organisations are managed at the column level, not the grid context
    if (!optionSelectorData.organisationId) {
      // Default to conso for multiple organisation group
      if (mData.db.data.fs3Data.organisations.length > 1) {
        optionSelectorData.organisationId = 'conso'
      } else {
        optionSelectorData.organisationId = mData.db.data.organisations[0].id
      }
    }
    context.showOrgId = optionSelectorData.organisationId
  }

  if (!optionSelectorData.currencySymbol) {
    optionSelectorData.currencySymbol = mData.db.data.organisations[0].details.baseCurrency
  }

  context.currency = optionSelectorData.currencySymbol
  context.data = mData.db.data

  let gridInstance

  console.log('mData.db.options', mData.db.options)
  if (optionSelectorData.organisationId === 'eliminations' && optionSelectorData.layout[0] === 'accounting') {
    console.log('show elimination entries')
    fs2Eliminations.showEliminations({
      containerElementId: 'statementContainer',
      dateseries: mData.db.data.dateseries,
      organisations: mData.db.data.organisations,
      optionPeriods: optionSelectorData.dates,
      fs3Data: mData.db.data.fs3Data
    })
  } else {
    if (mData.db.options.statementType === 'forecast') {
      showFinancialForecast(context)
    } else {
      gridInstance = showFinancialStatements(context)
    }
  }

  showActions(gridInstance)

  return gridInstance
}

function showFinancialStatements (context) {
  console.log('showFinancialStatements', context)

  const dateseries = makeDateSerie(mData.db.data.fs3Data.dateseries[0])

  return fs2Statements.showFinancials({
    aggregationDB: fsSettings.aggregationDB(),
    dateseries, // : mData.db.data.fs3Data.dateseries,
    // classes: ['scrollHorizontal'],
    optionPeriods: optionSelectorData.dates,
    columnsLayout: optionSelectorData.layout,
    context,
    containerElementId: 'statementContainer'
  })
}
