/**
 *
 * @param {Array{}} parsedBundlesProducts
 * @param {Array{}} parsedProducts
 * @returns {Map}
 */
export function computeBundles (parsedBundlesProducts, parsedProducts) {
  const bundles = new Map()

  // Index the products
  const products = new Map()
  parsedProducts?.forEach(function (product) {
    products.set(product.id, product)
  })

  parsedBundlesProducts?.forEach(function (bundleProduct) {
    const bundle = getBundle(bundles, bundleProduct.bundleId)
    const bundleProductInfo = Object.assign({}, bundleProduct)
    const productData = products.get(bundleProduct.SKUid)

    // Price to consider for the SKU
    bundleProductInfo.SKUprice = productData?.price || 1 // Default to 1 to avoid giving 0% to product
    if (!Number.isFinite(productData?.price) || productData?.price === 0) {
      console.warn('no price for SKU', bundleProduct.SKUid, 'defaulting to 1 for Bundle calculations')
    }

    // Price % within bundle
    bundleProductInfo.SKUpriceQuantity = bundleProductInfo.SKUprice * bundleProduct.bundleSKUquantity
    bundle.products.push(bundleProductInfo)
  })

  // Compute the % of SKU within bundles
  bundles?.forEach(function (bundle) {
    bundle.totalProductPrice = bundle.products?.reduce(function (accumulator, product) {
      return accumulator + product.SKUpriceQuantity
    }, 0)
    bundle.products.forEach(function (product) {
      let productPctPrice = null
      if (!Number.isFinite(bundle.totalProductPrice) || bundle.totalProductPrice === 0) {
        productPctPrice = null
      } else {
        productPctPrice = product.SKUpriceQuantity / bundle.totalProductPrice
      }
      product.productPctPrice = productPctPrice
    })
  })

  return bundles
}

function getBundle (bundles, bundleId) {
  let bundle = bundles.get(bundleId)
  if (!bundle) {
    bundles.set(bundleId, {
      bundleId,
      products: []
    })
    bundle = bundles.get(bundleId)
  }
  return bundle
}
