import { ascending } from 'd3'
import { baseAccountObj } from '../../base-account-obj.js'

export function makeRows (context) {
  console.log('makeRows', context)
  const rowsDataIndexed = {}
  context.data.monthlyValues?.forEach(function (monthlyValue) {
    rowsDataIndexed[monthlyValue.accountId] = rowsDataIndexed[monthlyValue.accountId] || baseAccountObj(context, monthlyValue)

    rowsDataIndexed[monthlyValue.accountId].monthlyValues.push(monthlyValue)
  })

  const rowsData = Object.values(rowsDataIndexed)

  sortRows(rowsData, ['statement', 'code', 'type'])

  let changeIndex = null
  rowsData.forEach(function (row, index) {
    if (index === 0) return
    const currentStatement = row.statement
    const previousStatement = rowsData[(index - 1)].statement
    if (currentStatement !== previousStatement) {
      changeIndex = index
    }
  })
  rowsData.splice(changeIndex, 0, { i: changeIndex })

  return rowsData
}

function sortRows (rowsData, sortingProperties) {
  if (!sortingProperties) return
  if (!Array.isArray(sortingProperties)) return

  // Reverse the order of property because we need to sort for the final order
  sortingProperties.reverse()

  // Apply the sorting
  sortingProperties?.forEach(function (sortingProperty) {
    rowsData.sort(function (r1, r2) {
      return ascending(r1[sortingProperty], r2[sortingProperty])
    })
  })
}
