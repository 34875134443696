
// Page Module
import * as mOrganisations from './organisations.js'
import * as mOrgSyncStatus from './organisations-syncstatus.js'
import { callApi } from '../../helpers/fetch.js'

let availableTenants
let currentToken

/**
 *
 * @returns
 */
export async function showConnectedTenants (params) {
  console.log('showConnectedTenants')
  const DOMelementId = 'tokenOrganisations'
  const DOMelement = document.getElementById(DOMelementId)
  if (!DOMelement) return
  DOMelement.innerHTML = ''

  if (params?.refreshTenantsList) {
    await getConnectedTenants()
  }

  console.log('availableTenants', availableTenants)
  const associatedOrganisations = mOrganisations.associatedOrganisations
  console.log('associatedOrganisations', associatedOrganisations)
  const associatedOrganisationsIds = associatedOrganisations?.map(function (one) {
    console.log('one', one)
    return one._xeroOrganisation.orgXeroId
  })
  console.log('associatedOrganisationsIds', associatedOrganisationsIds)

  availableTenants?.forEach(function (oneAvailableTenant) {
    // Don't show as token tenant if already part of the associated org
    if (associatedOrganisationsIds.includes(oneAvailableTenant.tenantId)) return

    // const tenantHtml = generateOneTenant(oneAvailableTenant)
    const tenantHtml = mOrganisations.generateOneOrganisation({
      accountId: params.accountId,
      xeroOrganisation: oneAvailableTenant.xeroOrg,
      connection: oneAvailableTenant,
      isAssociated: false
    })
    DOMelement.insertAdjacentHTML('beforeend', tenantHtml)

    mOrgSyncStatus.checkOrgSyncStatus(oneAvailableTenant.xeroOrg._id)
  })

  showElements(availableTenants)

  const DOMelementToken = document.getElementById('token')
  if (!DOMelementToken) return
  DOMelementToken.innerText = currentToken
}

/**
 * TODO !! There could be a difference between Xero Token tenants and app organisations
 */
export async function getConnectedTenants () {
  const apiUrl = '/api/xero/showTenants'
  const data = await callApi(apiUrl)
  console.log('getConnectedTenants')
  console.log(data)
  console.log('data.tenants.length', data.tenants?.length)
  availableTenants = data.tenants
  currentToken = data.token
  return data
}

function showElements (availableTenants) {
  const shouldBeHidden = !availableTenants || (availableTenants.length === 0)
  const elements = document.querySelectorAll('[needXeroToken="true"]')
  console.log('elements', elements)

  elements.forEach(function (oneElement) {
    if (shouldBeHidden) {
      oneElement.classList.add('is-hidden')
    } else {
      oneElement.classList.remove('is-hidden')
    }
  })
}
