import { generateRecordWithSettings } from '../generate-record-with-settings/generate-record-with-settings.js'

/**
*
* @param {Map} fieldsMap
* @param {Object} dataRow
* @returns
*/
export function parseDataRow (fieldsMap, dataRow, fieldsMapping, options) {
  // console.log('parseDataRow', fieldsMap, dataRow)
  const parsedRow = {
    _raw: dataRow
  }

  // Analyse the propertyNames
  Object.keys(dataRow).forEach(function (property) {
    // Clean the propertyName
    const cleanedProperty = property.trim()
    getField(fieldsMap, cleanedProperty)
  })

  generateRecordWithSettings(dataRow, parsedRow, fieldsMapping, options)

  return parsedRow
}

/**
 * Access the Map value, or create it: allows to set the base value when first creating the Map entry
 * @param {Map} recordMap
 * @param {String} mapEntryId
 * @returns
 */
function getField (recordMap, mapEntryId) {
  let data
  data = recordMap.get(mapEntryId)
  if (!data) {
    recordMap.set(mapEntryId, {
      inputName: mapEntryId
    })
    data = recordMap.get(mapEntryId)
  }

  return data
}
