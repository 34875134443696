import { callApi } from '../../../helpers/fetch.js'
import { entities } from '../upload/entities.js'

export async function loadFXs () {
  const currenciesSymbols = entities.map(function (entity) {
    return entity.baseCurrency
  })

  // List the pairs
  const currencyPairsSet = new Set()
  currenciesSymbols?.forEach(function (fromCurrencySymbol) {
    currenciesSymbols?.forEach(function (toCurrencySymbol) {
      const pair = fromCurrencySymbol + '.' + toCurrencySymbol

      currencyPairsSet.add(pair)
    })
  })
  const currencyPairs = Array.from(currencyPairsSet)

  const data = await callApi('/fx/pairs/:currencyPairs/:dateMin', {
    currencyPairs,
    dateMin: '2023-08-01'
  })
  console.log('data', data)

  return data?.FXratesDB
}
