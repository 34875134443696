import { generateFilePicker } from '../../../../helpers/menus/index.js'

import { moduleData } from '../data.js'
import { setElementHtml } from '../../../../helpers/dom.js'
import { showDropFeedback } from '../utilities/show-drop-feedback.js'
import { fillString } from '../../../../../helpers/templating/string-template.js'
import { readWithEnforcedSettings } from './read-with-enforced-settings.js'
import { mergePropertiesOnDatasets } from '../merge-properties/merge-properties.js'
import { showActions } from '../ui/show-steps.js'
import { showOrdersSummary } from './orders-summary/show-orders-summary.js'
import { showSettlementSummary } from './settlements-summary/show-settlements-summary.js'

export function activateDropSettlements () {
  generateFilePicker({
    containerId: 'containerDropSettlements',
    messages: {
      ready: 'Drop your file'
      // TODO: allow update of dropper for text?
      // moduleSettings?.settlements?.fileDropZoneText
    },
    onChange: async function (file) {
      await receiveSettlementFile({ file })
      showActions()
    },
    isBoxed: true
  })
}

async function receiveSettlementFile (params) {
  const { file } = params

  const droppedFileResult = await readWithEnforcedSettings(file, { settingsType: 'settlements' })
  const settlements = droppedFileResult.data

  const fileInfo = {
    type: 'settlements',
    name: file.name,
    remarks: fillString(':lines lines', { lines: settlements.length })
  }
  // droppedFileResult?.fieldsReview?.numberErrors

  // Combine with previously dropped dataset(s)
  settlements?.forEach(function (line) {
    moduleData.settlements.push(line)
  })
  console.log('moduleData.settlements', moduleData.settlements)

  // analyseSettlementData(settlements)

  mergePropertiesOnDatasets([moduleData.ordersLines, moduleData.settlements])
  console.log('moduleData', moduleData)

  moduleData.files.push(fileInfo)
  setElementHtml('fileSettlements', showDropFeedback('settlements'))

  showOrdersSummary()
  showSettlementSummary()
}
