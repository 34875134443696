
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import htmlIndex from './index.html'
import { showCurrentTimestamp } from '../utilities/timestamp.js'
import { disableSupportAccess, enableSupportAccess } from '../utilities/support-invite.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { callApi } from '../../../helpers/fetch.js'

/**
 *
 * @returns
 */
export async function showPageListAccounts () {
  console.log('module show')

  // Ensure user is logged in
  if (!mUser.isUserLoggedIn()) {
    mUser.showRestrictedPage()
    return
  }

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showCurrentTimestamp('currentTimestamp')
  showListOfAccounts()
}

async function showListOfAccounts () {
  const containerElement = document.getElementById('accountsList')
  containerElement.innerText = ''

  const appAccounts = await loadListOfAccounts()
  console.log('appAccounts', appAccounts)

  appAccounts.forEach(function (one) {
    const accountElement = document.createElement('div')
    // let infoUrl = '/app/admin/account-info/:accountId/infos'
    const infoUrl = fillString('/app/a/:accountId', { accountId: one._id })

    const infoLink = fillString('<b><a href=":infoUrl" target="_blank">:linkLabel</a></b>', {
      infoUrl,
      linkLabel: one.name
    })

    const creationDate = new Date(one.createdAt).toUTCString()

    const isPublicLabel = (one.isPublic) ? '<span class="tag is-warning is-light"><span class="icon"><i class="fa-solid fa-globe"></i></span><span>Public account</span></span>' : ''

    accountElement.innerHTML = ['<div>&nbsp;</div>', infoLink, creationDate, isPublicLabel].join(' ')

    containerElement.append(accountElement)

    let isSupportEnabled = false

    one._userAccounts.forEach(function (oneUserAccount) {
      const userString = ['<b>' + (oneUserAccount._user?.email || '??') + '</b>', oneUserAccount.rights.join(', ')]
      const supportLabel = oneUserAccount.isSupport ? '<b>Zenflow SUPPORT</b>' : ''
      userString.push(supportLabel)

      if (oneUserAccount.isSupport) isSupportEnabled = oneUserAccount._id

      const userHtml = '<br>&nbsp • ' + userString.join(' ')
      accountElement.insertAdjacentHTML('beforeend', userHtml)
    })

    if (!isSupportEnabled) enableSupportAccess(accountElement, one._id)
    if (isSupportEnabled) disableSupportAccess(accountElement, isSupportEnabled)
  })
}

/**
 */
async function loadListOfAccounts () {
  const apiUrl = '/api/xero/sync/info/accounts'
  const data = await callApi(apiUrl)
  return data
}

/*

Account Name / Created At / isPublic

TODO: Account info
... Org connected
... Users
*/
