import { exportCsv } from '../../../utilities/export-csv.js'
import { makeInstructionsLine } from './make-instruction-line.js'
import { makePaymentLine } from './make-payment-line.js'
import { makeRevenueAdjustmentLines } from './make-revenue-adjustments-lines.js'
import { xeroSalesInvoiceProperties } from './sales-invoices-settings.js'

/**
 * TODO: get from settlement: revenueGross, discount
 * Make a line for revenueNet from orderLines to revenueGross: updating the revenue
 * And a line for discount
 * @param {*} productsSales
 * @returns
 */
export function generateXeroSalesInvoice (salesBySKU, options, ordersSettlementsRows) {
  console.log('generateXeroSalesInvoice', salesBySKU, options)
  const { platform, country } = options

  // Base value
  const invoiceValues = {
    contactName: [platform, 'Customers'].join(' '),
    invoiceDate: document.getElementById('periodEnd').value,
    dueDate: document.getElementById('periodEnd').value,
    invoiceNumber: [platform, country, document.getElementById('periodEnd').value].join(' '),
    accountCode: document.getElementById('sales.xeroAccountCode').value,
    taxType: document.getElementById('salesTax.xeroAccountCode').value
  }

  // Sort by Revenue generated
  const propertyToSort = 'revenueNet'
  salesBySKU.sort(function (p1, p2) {
    return p2[propertyToSort] - p1[propertyToSort]
  })

  const invoiceRows = []
  salesBySKU?.forEach(function (productSales) {
    const invoiceLine = {}

    xeroSalesInvoiceProperties.forEach(function (invoiceProperty) {
      if (invoiceProperty.input) {
        invoiceLine[invoiceProperty.outputProperty] = invoiceValues[invoiceProperty.input]
      } else if (invoiceProperty.inputProperty) {
        invoiceLine[invoiceProperty.outputProperty] = productSales[invoiceProperty.inputProperty]
      } else if (invoiceProperty.compute) {
        invoiceLine[invoiceProperty.outputProperty] = invoiceProperty.compute(productSales)
      }
    })
    invoiceRows.push(invoiceLine)
  })

  // Add adjustments
  const adjustementsLines = makeRevenueAdjustmentLines(salesBySKU, ordersSettlementsRows, invoiceValues)
  invoiceRows.push(...adjustementsLines)

  const paymentLine = makePaymentLine(invoiceRows, invoiceValues)
  invoiceRows.push(paymentLine)

  // Intro line: instruction
  const instructionLine = makeInstructionsLine(invoiceValues)
  invoiceRows.unshift(instructionLine)

  console.log('invoiceRows', invoiceRows)

  exportCsv(invoiceRows, 'Xero Sales Invoice', 'Import in Xero')
  return invoiceRows
}
