import { generateButtons } from '../../../../helpers/menus/index.js'
import { exportCsv } from '../../utilities/export-csv.js'
import { transformDataset } from '../../utilities/transform-dataset/transform-dataset.js'
import { filterDB } from '../data-manipulation/filter-db.js'
import { moduleData } from '../data.js'
import { settlementsOutputs } from '../settings/outputs/settlements-outputs.js'
import { showTable, showTableLoading } from '../table-ui/show-table.js'
import { generateXeroCostInvoice } from '../xero/purchases/generate-xero-cost-invoice.js'
import { generateXeroSalesInvoice } from '../xero/sales/generate-xero-sales-invoice.js'
import { datetypeSelectorSettings } from './activate-datetype-selector.js'
import { computeRollups } from '../rollups/compute-rollups.js'
import { salesBySKUOutputs } from '../settings/outputs/sales-sku-settings.js'
import { salesBySKUOrderOutputs } from '../settings/outputs/sales-sku-order-db-settings.js'
import { hideElement, setElementHtml } from '../../../../helpers/dom.js'
import { computeSettlementOutputs } from './settlements-output/compute-settlement-outputs.js'
import { shopeeSummaryOutput } from '../settings/outputs/shopee-summary-output.js'
import { choicePlatform } from './choose-platform.js'
import { lazadaSummaryOutput } from '../settings/outputs/lazada-summary-output.js'
import { showElementsForView } from './show-elements-for-view.js'
import { tiktokSummaryOutput } from '../settings/outputs/tiktok-summary-output.js'

export const computedData = {}
export const viewOptions = {}

export function activateViews () {
  const instance = generateButtons({
    buttonOptions,
    containerId: 'viewButtons',
    groupLabel: 'View:',
    selected: null,
    onChange: function (selectedOption, instance) {
      showTableLoading()

      showElementsForView(selectedOption)

      console.log('activateViews selected', selectedOption, instance)
      console.log('viewOptions.instance', viewOptions.instance, viewOptions.instance.selectedOption())

      // Read the period dates
      const periodStart = document.getElementById('periodStart').value
      const periodEnd = document.getElementById('periodEnd').value

      let computedData

      // Compute the settlement values: useful to compare values from orderLines
      const ordersSettlementsRows = computeSettlementOutputs(periodStart, periodEnd, settlementsOutputs)
      moduleData.computedData.ordersSettlementsRows = ordersSettlementsRows

      let needsAction = true

      if (selectedOption.value === 'summary') {
        const platformOutputs = {
          shopee: shopeeSummaryOutput,
          lazada: lazadaSummaryOutput,
          tiktok: tiktokSummaryOutput
        }
        const currentPlatformName = choicePlatform.chosenPlatform.value

        const platformSummaryOutput = platformOutputs[currentPlatformName]
        if (!platformSummaryOutput) {
          console.warn('no platformSummaryOutput')
          return
        }
        const ordersSettlementsRows = computeSettlementOutputs(periodStart, periodEnd, platformSummaryOutput)

        computedData = {
          rows: ordersSettlementsRows,
          columns: platformSummaryOutput?.propertiesSettings
        }

        if (ordersSettlementsRows.length === 0) {
          setElementHtml('instructions', 'No data? drop both orders AND settlements. Check the period.')
        }

        // For the Summary: show directly
        showTable(computedData.rows?.slice(0, 10), computedData.columns, { allRows: computedData.rows })
        needsAction = false
        //
      } else if (selectedOption.value === 'orders') {
        computedData = {
          rows: ordersSettlementsRows,
          columns: settlementsOutputs.propertiesSettings
        }

        if (ordersSettlementsRows.length === 0) {
          setElementHtml('instructions', 'No data? drop both orders AND settlements. Check the period.')
        }
      } else {
        if (selectedOption.value === 'sales_SKU') {
          computedData = viewOrderLinesOutput(periodStart, periodEnd, salesBySKUOutputs)
        } else if (selectedOption.value === 'sales_SKU_Order') {
          computedData = viewOrderLinesOutput(periodStart, periodEnd, salesBySKUOrderOutputs)
        }
      }
      moduleData.computedData[selectedOption.value] = computedData

      console.log('computedData', computedData)
      console.log('moduleData', moduleData)
      console.log('selectedOption.value', selectedOption.value)

      if (selectedOption.instructions) {
        setElementHtml('instructions', 'Next: Pick the Action')
      }
      if (!needsAction) {
        hideElement('instructions')
      }
    }
  })
  viewOptions.instance = instance
}

/**
 *
 */
const buttonOptions = [
  {
    label: 'Platform Summary',
    value: 'summary',
    containersToShow: [],
    containersToHide: ['xeroSettings', 'actionsButtonsContainer', 'instructions'],
    exports: {
      csv: function (computedData) {
        console.log('export csv', computedData)
        const fileName = 'Orders'
        const sheetName = 'Orders'
        exportCsv(computedData, fileName, sheetName)
      }
    }
  },
  {
    label: 'Order Settlements (Xero: Purchase Bill)',
    value: 'orders',
    containersToShow: ['xeroSettings', 'actionsButtonsContainer'],
    containersToHide: [],
    instructions: 'Choose the action below',
    exports: {
      csv: function (computedData) {
        console.log('export csv', computedData)
        const fileName = 'Orders'
        const sheetName = 'Orders'
        exportCsv(computedData, fileName, sheetName)
      },
      xero: function (computedDataRows, options) {
        console.log('export to Xero costs', computedData)
        generateXeroCostInvoice(computedDataRows, options)
      }
    }
  },
  {
    label: 'SKU Quantity Sold (Xero: Sales Invoice)',
    value: 'sales_SKU',
    containersToShow: ['xeroSettings', 'actionsButtonsContainer'],
    containersToHide: [],
    instructions: 'Choose the action below',
    exports: {
      csv: function (computedDataRows) {
        console.log('export csv sales_SKU', computedData)
        const fileName = 'Sales by SKU'
        const sheetName = 'sales_SKU'
        exportCsv(computedDataRows, fileName, sheetName)
      },
      xero: function (computedDataRows, options) {
        console.log('export to Xero SKUbyOrder', computedData)
        generateXeroSalesInvoice(computedDataRows, options, moduleData.computedData.ordersSettlementsRows)
      }
    }
  },
  {
    label: 'SKU per Order',
    value: 'sales_SKU_Order',
    containersToShow: ['actionsButtonsContainer'],
    containersToHide: ['xeroSettings'],
    instructions: 'Choose the action below',
    exports: {
      csv: function (computedDataRows) {
        console.log('export csv SKUbyOrder', computedData)
        const fileName = 'Sales by SKU_Order'
        const sheetName = 'sales_SKU_Order'
        exportCsv(computedDataRows, fileName, sheetName)
      },
      xero: function () {
        console.log('no export')
      }
    }
  }
]

/**
 *
 * @param {*} periodStart
 * @param {*} periodEnd
 * @param {*} outputSettings
 * @returns
 */
function viewOrderLinesOutput (periodStart, periodEnd, outputSettings) {
  console.log('viewOrderLinesOutput', outputSettings)

  // Get the dataset
  const datasetArray = moduleData[outputSettings.sourceData]
  console.log('datasetArray', datasetArray)

  const filteredLines = filterDB(datasetArray, { periodStart, periodEnd, propertyToFilter: datetypeSelectorSettings.selectedValue })
  console.log('filteredLines', filteredLines)

  // Group and rollup
  const rollups = computeRollups(filteredLines, outputSettings.groupingProperties, {
    useFlat: true,
    returnValuesArray: true
  })
  console.log('rollups', rollups)

  const rollupsArray = rollups

  // Apply transforms
  const rows = transformDataset(rollupsArray, outputSettings.propertiesSettings)
  console.log('rows', rows)

  const columns = outputSettings.propertiesSettings
  // showTable(rows, columns)

  return {
    rows,
    columns
  }
}
