import { enableConsole } from '../../../helpers/logging/console.js'
import { computeSerieAggregations } from '../../../helpers/values/values.js'

export function applyAggregation (valueSeries, optionSelectorData) {
  enableConsole(null)
  // console.log('applyAggregation', valueSeries.length, valueSeries, optionSelectorData)
  // When just 1 period, no need to aggregate
  const isMonthlyView = !optionSelectorData.periodicity || optionSelectorData.periodicity === 1
  const isMonthlyAggregate = optionSelectorData.viewAggregations.includedPeriods === 1
  const isBalance = optionSelectorData.viewAggregations.method === 'balance' // Balance Sheet: show last value of periods
  // console.log('(', isMonthlyView, '&&', isMonthlyAggregate, ') || ', isBalance)
  if ((isMonthlyView && isMonthlyAggregate) || isBalance) {
    return valueSeries
  }

  const options = {
    calculationPeriods: 1 // We calculate values for every months; even though we only show maybe less (quarterly...)
  }

  options.aggregation = optionSelectorData.viewAggregations
  if (options.aggregation.method === 'period') {
    options.aggregation.method = 'sum' // Force sum for P&L
  }
  options.isFromFirstPeriod = true

  const aggregatedSeries = computeSerieAggregations(valueSeries, options)
  // console.log('aggregatedSeries', aggregatedSeries, options)
  return aggregatedSeries
}
