export function generateForecastingDateseries (dateseries, calculationsSettings, startingPeriodIndex) {
  // console.log('generateForecastingDateseries', dateseries, calculationsSettings, startingPeriodIndex)
  const forecastingDateseries = []

  const startingPeriod = dateseries[startingPeriodIndex]
  const startingYear = startingPeriod.substring(0, 4)
  const startingMonth = startingPeriod.substring(5, 7) - 1

  let loopI = 0
  while (loopI < calculationsSettings.monthsAhead) {
    const loopDate = new Date(Date.UTC(startingYear, startingMonth + loopI, 1))
    // console.log(startingPeriod, loopI, startingYear, startingMonth, loopDate)
    forecastingDateseries.push(loopDate.toJSON().substring(0, 7))
    loopI++
  }
  return forecastingDateseries
}
