
import { enableConsole } from '../logging/console.js'
import * as gridHelpers from './helpers.js'

enableConsole(null)

export function generateContentCellsParams (params) {
  enableConsole(false)
  console.log('generateContentCellsParams', params)
  const { gridSettings, rowDefs, columnDefs } = params

  let rowNumber = 1
  const colNumber = 1

  const cells = []

  // Go through each rows, each columns
  rowDefs.forEach(function (oneRowDef) {
    // console.log('--- parent Row')
    const rowReturnInfo = withinRow({
      gridSettings,
      cells,
      rowNumber,
      colNumber,
      rowDef: oneRowDef,
      columnDefs
    })
    // console.log('rowReturnInfo rowNumber', rowReturnInfo.rowNumber)
    rowNumber = rowReturnInfo.rowNumber + 1
    // colNumber = cell.gridRowEnd + 1
  })

  enableConsole('reset')
  return cells
}

function withinRow (params) {
  // console.log('withinRow', params.rowNumber, params)
  const { cells, gridSettings, rowDef, columnDefs } = params
  let { rowNumber } = params

  let colNumber = params.colNumber
  columnDefs?.forEach(function (oneColumnDef) {
    if (oneColumnDef.layout === 'left') return
    const colReturnInfo = withinCol({
      gridSettings,
      cells,
      rowNumber,
      colNumber,
      rowDef,
      columnDef: oneColumnDef
    })
    // console.log('colReturnInfo.colNumber', colReturnInfo.colNumber)
    colNumber = colReturnInfo.colNumber
  })

  if (rowDef.children && gridHelpers.showChildren(rowDef)) {
    // console.log('rowDef.children')
    rowDef.children.forEach(function (oneChildRowDef) {
      rowNumber++
      withinRow({
        gridSettings,
        cells,
        rowNumber,
        colNumber: params.colNumber,
        rowDef: oneChildRowDef,
        columnDefs
      })
    })
  }

  return {
    rowNumber
  }
}

function withinCol (params) {
  // console.log('withinCol', params.colNumber)
  const { cells, gridSettings, rowDef, columnDef } = params
  let { colNumber } = params

  if (columnDef.children) {
    // console.log('columnDef has children')
    columnDef.children.forEach(function (oneChildColDef) {
      withinCol({
        gridSettings,
        cells,
        rowNumber: params.rowNumber,
        colNumber,
        rowDef,
        columnDef: oneChildColDef
      })
      colNumber++
    })
  } else {
    // console.log('no child')
    // TODO: Review why we included this initially
    // const shouldCellBeIncluded = !(typeof columnDef.headerValueGetter === 'function' && columnDef.headerValueGetter() === null)

    // if (!shouldCellBeIncluded) return

    includeCell({
      gridSettings,
      rowDef,
      columnDef,
      colNumber,
      rowNumber: params.rowNumber,
      cells
    })
    colNumber++
  }

  return {
    colNumber
  }
}

function includeCell (params) {
  const { gridSettings, rowDef, columnDef, colNumber, rowNumber, cells } = params

  // console.log('includeCell', rowDef)

  const styles = ['contentCell']
  // Styles of the row
  if (rowDef.styles) {
    styles.push(...rowDef.styles)
  }

  // Styles of the column
  if (columnDef.styles) {
    styles.push(...columnDef.styles)

    // Not sure when this is useful
    // const columnStylesToIncludeOnRows = ['lastGroupColumn']
    // columnStylesToIncludeOnRows.forEach(function (oneStyle) {
    //   if (!columnDef.styles.includes(oneStyle)) return

    //   styles.push(oneStyle)
    // })
  }

  const cell = {
    gridSettings,
    gridRowStart: rowNumber,
    gridColStart: colNumber,
    gridRowEnd: rowNumber + 1,
    gridColEnd: colNumber + 1,
    styles,
    _cellId: 'center_center_' + cells.length,
    _position: {
      v: 'center',
      h: 'center'
    },
    columnDef, // TODO: rename, consistently with header
    rowDef
  }

  // console.log('CELL', cell.gridRowStart, cell.gridColStart)
  cells.push(cell)
}
