import { hideElement, showElement } from '../../../../helpers/dom.js'

export function showElementsForView (options) {
  options?.containersToShow?.forEach(function (containerId) {
    showElement(containerId)
  })

  options?.containersToHide?.forEach(function (containerId) {
    hideElement(containerId)
  })
}
