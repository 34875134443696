import { updateSelectorValueAggregation } from './periods/update-value-aggregations.js'
import { updateSelectorPeriodicity } from './periodicity/update-periodicity.js'
import { updateSelectorLayout } from './entities/layout/update-layout.js'
import { updateEliminationSelectorLayout } from './entities/eliminations/update-eliminations.js'

export function updateSelectors (params) {
  // Dates and Timeframe
  updateSelectorPeriodicity()
  updateSelectorValueAggregation()

  updateSelectorLayout(params)
  updateEliminationSelectorLayout()
}
