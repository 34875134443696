
/**
 * example Lazada
 * Fee Name
 * Amount(Include Tax)
 * VAT Amount
 *
 * [Fee Name] Amount
 * [Fee Name] Tax
 *
 * {
 *  baseProperty: 'Fee Name'
 *  outputProperty: function (record) {
 *    return [record[baseProperty], 'Amount'].join(' ')
 * },
 *  outputValue: function (record) {
 *    return record['Amount(Include Tax)']
 * }
 * }
 *
 */

import { mapToArray } from '../../../utilities/map-to-array.js'

/**
 *
 * @param {*} records
 * @param {*} propertiesToCombine
 */
export function combineMultilinesItems (records, combinationPropertyName, newProperties, excludedProperties) {
  const updatedRecords = new Map()

  const propertiesCreated = new Set()

  records?.forEach(function (record) {
    const outputRecord = getMappedRecord(updatedRecords, record[combinationPropertyName])

    // The base properties
    Object.keys(record).forEach(function (propertyName) {
      if (excludedProperties.includes(propertyName)) return

      outputRecord[propertyName] = record[propertyName]
    })

    // The new properties
    newProperties.forEach(function (newProperty) {
      const newPropertyName = newProperty.outputProperty(record)
      const newPropertyValue = newProperty.outputValue(record)

      propertiesCreated.add(newPropertyName)

      outputRecord[newPropertyName] = newPropertyValue
    })
  })
  console.log(updatedRecords)
  console.log('propertiesCreated', propertiesCreated)

  const recordsArray = mapToArray(updatedRecords)
  console.log(recordsArray)

  return recordsArray
}

function getMappedRecord (recordsMap, mapEntryId) {
  let data
  data = recordsMap.get(mapEntryId)
  if (!data) {
    recordsMap.set(mapEntryId, {})
    data = recordsMap.get(mapEntryId)
  }

  return data
}
