
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as cssGrid from '../../../../helpers/cssgrid/index.js'
import * as mUser from '../../../modules/user/user.js'
import * as mAccount from '../../../modules/user/account.js'
import * as mHtmlParser from '../../../modules/user/html-parser.js'

// Page Module
import htmlTemplateIndex from './index.html'

import { callApi } from '../../../helpers/fetch.js'

import { makeColDefs } from './make-coldefs.js'
import { ascending } from 'd3'

/**
 *
 * @returns
 */
export async function showPageCrossContacts () {
  console.log('showPageCrossContacts()')

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  const accountData = mAccount.accountData()

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Intra Group Contacts:', accountData.name].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })
  contentElement.innerHTML = htmlPageLayout

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const crossContactsAPIresponse = await loadCrossContacts(accountData)

  showCrossContacts({
    crossContacts: crossContactsAPIresponse.crossContacts
  })
}

async function loadCrossContacts (accountData) {
  console.log('loadCrossContacts', accountData)
  const apiUrl = '/api/xero/:accountId/cross-contacts'
  const data = await callApi(apiUrl, {
    accountId: accountData._id
  })

  return data
}

function makeRowDefs (crossContacts) {
  const sorted = crossContacts.sort(function (r1, r2) {
    const organisation1 = mAccount.getOrganisationData(r1._xeroOrganisation)
    const organisation2 = mAccount.getOrganisationData(r2._xeroOrganisation)

    return ascending(organisation1?.name, organisation2?.name)
  })
  return sorted
}

function showCrossContacts (params) {
  console.log('showCrossContacts', params)
  const { crossContacts } = params

  const colDefs = makeColDefs(crossContacts)

  cssGrid.generateGrid({
    containerElementId: 'grid',
    classes: ['fit-content'],
    colDefs,
    rowDefs: makeRowDefs(crossContacts),
    showActions: true
  })
}
