
import * as mUser from '../modules/user/user.js'

/**
 * Inspired by https://blog.logrocket.com/intercepting-javascript-fetch-api-requests-responses/
 */
export function setFetchResponse () {
  // console.log('setFetchResponse')
  const { fetch: originalFetch } = window

  window.fetch = async (...args) => {
    const [resource, config] = args
    // request interceptor here
    const response = await originalFetch(resource, config)
    // response interceptor here
    // console.log('interceptor', response.ok, response.status, response)

    if (!response.ok && response.status === 401) {
      console.warn('401: Not Authenticated')
      mUser.logoutUser()
      console.warn('logout done')
    }

    if (!response.ok && response.status === 403) {
      console.warn('403: Not Authorised')
      mUser.showRestrictedPage()
    }

    return response
  }
}
