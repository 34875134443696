import { getUploads } from './storage/get-uploads.js'

// We're in the browser, so the variable is for the instance of the user
export const itemsDB = {}

newDB()

function newDB () {
  itemsDB.items = new Map()
  itemsDB.numberFiles = 0
  itemsDB.entities = []
}

export function getItemsDBitems () {
  return Array.from(itemsDB.items.values())
}

export function combineItems () {
  // Reset
  newDB()

  const uploads = getUploads()
  uploads?.forEach(function (upload) {
    includeEntityItems(upload.entityName, upload.dataRows)
  })

  // console.log('combineItems', dataRows)
  return getItemsDBitems()
}

function includeEntityItems (entityName, dataRows) {
  // Add items by name
  dataRows?.forEach(function (dataRow) {
    // const rowAccountId = dataRow.Account
    // itemsDB.items.get(rowAccountId)
    const itemData = getItem(dataRow)

    itemData.fileDataIndex.push(itemsDB.numberFiles)
    itemData.data.push(dataRow)
  })

  itemsDB.entities.push(entityName)
  itemsDB.numberFiles++
}

function getItem (dataRow) {
  const rowAccountId = dataRow.accountName.toLowerCase()

  if (!itemsDB.items.has(rowAccountId)) {
    itemsDB.items.set(rowAccountId, {
      id: rowAccountId,
      account: {},
      data: [],
      fileDataIndex: [] // An item might not have value for all: keep track of which file
    })
  }

  const availableItem = itemsDB.items.get(rowAccountId)

  availableItem.account.code = availableItem.account.code || dataRow.accountCode
  availableItem.account.name = availableItem.account.name || dataRow.accountName

  return availableItem
}
