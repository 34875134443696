// client/pages/receivables/receivables-table.js
// client/modules/user/account.js
import * as mAccount from '../../modules/user/account.js'

import * as cssGrid from '../../../helpers/cssgrid/index.js'
import { mapGroups } from '../../../helpers/data/array-of-objects/mapped-array.js'
import { makeXeroLink } from '../../helpers/xero-links.js'
import { accessObjectPropertyValue } from '../../../helpers/data/objects.js'
import { d3NumberFormat } from '../../../helpers/helpers/numbers.js'
import { contentValueStyling } from '../../helpers/helpers.js'
import { receivablesSettings } from './menu.js'
import { makeDateForPnl } from '../../helpers/financial-dates.js'
import { fillString } from '../../../helpers/templating/string-template.js'

export function showReceivablesTable () {
  console.log('showReceivablesTable')

  const filteredReceivables = receivablesSettings.enrichedReceivables.filter(function (one) {
    return receivablesSettings.selectedStatus.includes(one.status)
  })

  const columnOptions = {}
  columnOptions.orgId = receivablesSettings.orgId
  columnOptions.months = receivablesSettings.months
  columnOptions.enrichedReceivables = filteredReceivables

  cssGrid.generateGrid({
    containerElementId: 'receivablesTable',
    rowDefs: tableRows(filteredReceivables),
    colDefs: tableColumns(columnOptions)
  })
}

function tableRows (enrichedReceivables) {
  const groupingProperties = [
    '_contact.name',
    '_contact.contactXeroId'
  ]

  const rollups = [{
    property: 'totalValue',
    calculation: ['sum', 'localValue']
  }]

  const groupedByContacts = mapGroups(enrichedReceivables, groupingProperties, rollups, { returnArray: true })
  // console.log('groupedByContacts', groupedByContacts)

  const rows = groupedByContacts.map(function (oneContactGroup) {
    return {
      details: oneContactGroup
    }
  })
  console.log('rows', rows)

  rows.push({
    isTotal: true,
    styles: ['sectionTotal']
  })
  return rows
}

function tableColumns (params) {
  console.log('tableColumns', params)
  const { orgId, enrichedReceivables } = params

  // const accountData = mAccount.accountData()
  const organisationData = mAccount.getOrganisationData(orgId)

  const groupingProperties = [
    '_contact.name',
    '_contact.contactXeroId',
    'expectedMonth'
  ]

  const rollups = [{
    property: 'totalValue',
    calculation: ['sum', 'localValue']
  }]

  const groupedReceivables = mapGroups(enrichedReceivables, groupingProperties, rollups, { returnArray: true })
  console.log('groupedReceivables', groupedReceivables)

  const columns = []

  columns.push({
    value: 'Customer',
    valueGetter: function (cellParams) {
      if (cellParams.rowDef.isTotal) {
        return 'Total'
      }
      return cellParams.rowDef?.details?.key?.['_contact.name']
    },
    position: 'left',
    width: 200
  })

  columns.push({
    value: 'Link',
    valueGetter: function (cellParams) {
      if (cellParams.rowDef.isTotal) {
        return ''
      }
      const xeroId = cellParams.rowDef?.details?.key?.['_contact.contactXeroId']
      const url = makeXeroLink('Contact', {
        shortCode: organisationData?.details?.shortCode,
        contactXeroId: xeroId
      })
      return fillString('<a href=":url" target="_blank">:label</a>', {
        url,
        label: 'open in Xero'
      })
    },
    position: 'left',
    width: 90
  })

  columns.push({
    value: 'Invoices',
    valueGetter: function (cellParams) {
      function rowValue (rowDef) {
        const invoices = accessObjectPropertyValue('details.values', rowDef)
        return invoices?.length
      }

      if (cellParams.rowDef.isTotal) {
        let total = 0
        cellParams.gridSettings.rowDefs.forEach(function (rowDef) {
          if (rowDef.isTotal) return
          total += rowValue(rowDef)
        })
        return total
      }

      return rowValue(cellParams.rowDef)
    },
    valueFormatter: function (cellParams) {
      return d3NumberFormat(cellParams._value, ',.0f')
    },
    // position: 'left',
    styles: ['numberColumns'],
    width: 80
  })

  columns.push({
    value: 'Total',
    valueGetter: function (cellParams) {
      function rowValue (rowDef) {
        if (rowDef.isTotal) return 0
        const value = accessObjectPropertyValue('details.totalValue', rowDef)
        return value
      }

      if (cellParams.rowDef.isTotal) {
        let total = 0
        cellParams.gridSettings.rowDefs.forEach(function (rowDef) {
          if (rowDef.isTotal) return
          total += rowValue(rowDef)
        })
        return total
      }

      return rowValue(cellParams.rowDef)
    },
    valueFormatter: function (cellParams) {
      return d3NumberFormat(cellParams._value, ',.0f')
    },
    styles: ['numberColumns'],
    // position: 'left',
    width: 120
  })

  if (params.months) {
    params.months.forEach(function (oneMonth) {
      columns.push({
        id: oneMonth,
        value: oneMonth,
        headerValueGetter: function (cellParams) {
          return makeDateForPnl(cellParams.columnDef.id)
          // return cellParams.columnDef.id
        },
        valueGetter: function (cellParams) {
          const colDef = cellParams.columnDef

          function rowValue (rowDef) {
            if (rowDef.isTotal) return 0
            const targetMonth = colDef.value
            const targetContactName = rowDef?.details.key['_contact.name']

            const targetGroup = groupedReceivables.find(function (oneGroup) {
              if (!(oneGroup.key.expectedMonth === targetMonth)) return false
              if (!(oneGroup.key['_contact.name'] === targetContactName)) return false

              return true
            })
            return targetGroup?.totalValue || 0
          }

          if (cellParams.rowDef.isTotal) {
            let total = 0
            cellParams.gridSettings.rowDefs.forEach(function (rowDef) {
              if (rowDef.isTotal) return
              total += rowValue(rowDef)
            })
            return total
          }

          return rowValue(cellParams.rowDef)
        },
        valueFormatter: function (cellParams) {
          return d3NumberFormat(cellParams._value, ',.0f')
        },
        valueStyling: function (cellParams) {
          return contentValueStyling({ cellParams })
        },
        styles: ['numberColumns'],
        // position: 'left',
        width: 120
      })
    })
  }
  return columns
}
