import { callApi } from '../../../helpers/fetch.js'

export async function loadOrgUsers (orgId) {
  const apiUrl = '/api/xero/sync/info/:orgId/users'
  const data = callApi(apiUrl, {
    orgId
  })
  return data
}

export async function loadOrgTokens (orgId) {
  const apiUrl = '/api/xero/sync/info/:orgId/tokens'
  const data = callApi(apiUrl, {
    orgId
  })
  return data
}
