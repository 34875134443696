import { getSheet } from './get-sheet.js'
import { readXLSXfile } from './read-xslx-file.js'
import { updateSheetRange } from './update-range.js'

export async function getFileSheet (file, options) {
  const workbook = await readXLSXfile(file)
  // console.log('workbook', workbook)
  console.log('sheets available:', workbook.SheetNames)
  if (options?.sheetName && !workbook.SheetNames.includes(options?.sheetName)) {
    console.warn('target sheet does not exist', options?.sheetName, 'in', workbook.SheetNames)
  }

  const expectedSheetName = options?.sheetName || workbook.SheetNames[0]
  // console.log('expectedSheetName', expectedSheetName)
  if (!expectedSheetName) return

  const worksheet = getSheet(workbook, expectedSheetName)
  updateSheetRange(worksheet)
  // console.log('worksheet', worksheet)

  return worksheet
}
