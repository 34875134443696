import * as cssGrid from '../../../../helpers/cssgrid/index.js'
import { hideElement, showElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { loadFXs } from '../utils/load-fx.js'
import { combineItems } from '../utils/manage-items.js'
import { tableColumns } from './table-columns.js'
import { makeTableRows } from './table-rows.js'
import { getUploads } from '../utils/storage/get-uploads.js'
import { enableExport } from './enable-export.js'

const context = {
  data: {}
}

let gridInstance

export async function updateView () {
  console.log('updateView', optionSelectorData)
  showElement('statementLoadingMessage')

  if (!context.FXratesDB) {
    context.FXratesDB = await loadFXs()
  }

  // Params in use
  // context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'
  context.viewDecimals = optionSelectorData.decimals || 0
  // context.dates = optionSelectorData.dates
  console.log('context', context)

  combineItems()
  const tableColDefs = tableColumns({ context })
  const tableRows = makeTableRows()

  gridInstance = cssGrid.generateGrid({
    containerElementId: 'statementContainer',
    colDefs: tableColDefs,
    rowDefs: tableRows,
    context,
    classes: [],
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })
  enableExport(gridInstance)

  hideElement('statementLoadingMessage')

  getUploads()
}
