
/**
 * Add multiple items to an array
 * Useful for large number of items to add
 * Because .push(...items) will fail for 135k+ items: Max stack
 * and because array.concat(items) returns a new array
 * and [...array, ...items] also returns a new array
 * @param {Array} array
 * @param {Array} items
 */
export function arrayAddItems (array, items) {
  if (!Array.isArray(array)) return
  if (!Array.isArray(items)) return

  items.forEach(function (item) {
    array.push(item)
  })
}
