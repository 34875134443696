
/**
 * Cell might be
 * Timeframe: 1 month, X months
 * ValueType: period, balance
 * Sum, Average, First, Last
 * @param {*} cellParams
 * @returns
 */
export function getCellValue (cellParams) {
  const cellStatement = cellParams.rowDef.statement || null
  if (!cellStatement) return null

  const targetMonth = cellParams.columnDef.params?.month

  const targetValueType = valueType[cellStatement] || 'monthAmount'
  let cellValue = getValueOfMonth(cellParams.rowDef.monthlyValues, targetMonth, targetValueType, cellParams.gridSettings.context.data.calculationDecimals)

  // Make the PnL Credit positive
  if (cellStatement === 'pnl') cellValue = -1 * cellValue

  return cellValue
}

const valueType = {
  pnl: 'monthAmount',
  bs: 'balance'
}

function getValueOfMonth (monthlyValues, targetMonth, targetValueType, calculationDecimals) {
  let cellValue = 0
  monthlyValues.forEach(function (oneEntry) {
    if (oneEntry.month !== targetMonth) return
    const entryValue = oneEntry[targetValueType]
    cellValue += entryValue || 0
  })

  const _calculationDecimals = Number.isFinite(calculationDecimals) ? calculationDecimals : 0
  const valueMultiplier = Math.pow(10, _calculationDecimals)

  cellValue = cellValue / valueMultiplier

  return cellValue
}
