import { callApi } from '../../helpers/fetch.js'

export async function loadScheduleData (params) {
  const apiUrl = '/api/xero/a/:accountId/o/:orgId/schedule/:accountXeroId'
  const data = await callApi(apiUrl, {
    accountId: params.accountId,
    orgId: params.orgId,
    accountXeroId: params.chartAccountId
  })
  console.log('loadScheduleData', data)
  return data
}
