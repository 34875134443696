import Sortable, { MultiDrag } from 'sortablejs'
import { lists } from './items-lists.js'
Sortable.mount(new MultiDrag())

const sortables = {}

export function enableDragging () {
  lists.forEach(function (oneList) {
    const itemsContainer = document.getElementById(oneList.elementId)
    sortables[oneList.elementId] = Sortable.create(itemsContainer, {
      group: 'itemsContainers',
      multiDrag: true,
      selectedClass: 'sortable-selected',
      onSort: function (event) {
        console.log(oneList.elementId, 'onSort', event)
        onSomething()
      }
    })
  })
}

function onSomething () {
  console.log('onSomething')
  console.log(sortables)
  Object.keys(sortables).forEach(function (listKey) {
    const sortedItems = sortables[listKey].toArray()
    console.log(listKey, sortedItems)
    storeGrouping(listKey, sortedItems)
  })
}

function storeGrouping (listKey, items) {
  const localStorageKey = ['items', listKey].join('.')
  localStorage.setItem(localStorageKey, JSON.stringify(items))
}
