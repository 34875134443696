import { statementTypeNames } from './helpers.js'
import * as mAccount from '../../modules/user/account.js'
import { activateExport } from '../../helpers/activate-export.js'

let gridInstance
let isListenerAdded = false

export function showActions (_gridInstance) {
  if (!_gridInstance) return
  gridInstance = _gridInstance

  if (isListenerAdded) return
  isListenerAdded = true
  activateExport(onClick)
}

function onClick () {
  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  const exportTime = [
    (new Date()).toJSON().substring(0, 10),
    [(new Date()).getHours(), (new Date()).getMinutes(), (new Date()).getSeconds()].join(':')
  ].join(' ')

  // *** The gridInstance is NOT updated with the page view
  const exportSettings = {
    sheetHeader: [
      [null],
      [null, accountData.name],
      [null, statementTypeNames[gridInstance?.context?.statementType]],
      [null, 'Currency:', gridInstance.context?.currency],
      [null, 'Exported on:', exportTime]
    ],
    filename: [accountData.name, gridInstance.context.statementType, gridInstance.context.currency].join(' - ')
  }

  gridInstance.exportCsv(exportSettings)
}
