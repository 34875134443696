
import * as fsSettings from './settings.js'
/*
  [
    {
      value: String,
      styles: []
    }
  ]

  Title for 'row group'
  then each group rows

*/
export function generateRowDefs (params) {
  // console.log('generateRowDefs', params)
  const { aggregationDB, layoutRows } = params

  const rowDefs = []

  const targetIncludedAccountsProp = fsSettings.layoutRowIncludedAccounts()

  layoutRows.forEach(function (oneLayoutRow, index) {
    // console.log(oneAccountType)
    if (oneLayoutRow[targetIncludedAccountsProp]) {
      const accountsOfType = aggregationDB.filter(function (oneAggregationData) {
        return oneLayoutRow[targetIncludedAccountsProp] === fsSettings.accountType(oneAggregationData.account)
      })
      // console.log('accountsOfType', accountsOfType)

      if (!accountsOfType || accountsOfType.length === 0) return

      const rowGroupDef = generateRowGroupDef({
        layoutRow: oneLayoutRow,
        rows: accountsOfType
      })

      rowDefs.push(rowGroupDef)
    }

    if (oneLayoutRow.calculation) {
      const rowDef = {
        id: oneLayoutRow.id,
        isLayoutRow: true,
        value: oneLayoutRow.name,
        styles: oneLayoutRow.styles,
        params: oneLayoutRow
      }
      rowDefs.push(rowDef)
    }

    if (oneLayoutRow.type === 'break') {
      const rowDef = {
        height: 10, // TODO: not yet implemented
        id: 'row_' + index,
        params: oneLayoutRow,
        value: ' '
      }
      rowDefs.push(rowDef)
    }
  })

  // console.log('rowDefs', rowDefs)
  return rowDefs
}

function generateRowGroupDef (params) {
  // console.log('generateRowGroupDef', params)
  const { layoutRow, rows } = params

  const rowGroup = {
    id: layoutRow.id,
    isLayoutRow: true,
    value: layoutRow.name,
    params: layoutRow,
    children: []
  }

  // We find unique account name, as the name might be in multiple 'organisations' ie. rows of aggregationDB
  const indexedRows = {}

  rows.forEach(function (oneRowData) {
    // TODO: review: if oneRowData.account.details.type should be used instead?
    const rowId = [fsSettings.accountType(oneRowData.account), fsSettings.accountName(oneRowData.account)].join('::')
    indexedRows[rowId] = indexedRows[rowId] || {
      id: fsSettings.accountName(oneRowData.account),
      params: {
        accountIds: [],
        // fs3
        viewSign: layoutRow.viewSign,
        expectedSign: layoutRow.expectedSign,
        oppositeSign: layoutRow.oppositeSign,
        // fs2
        viewMultiplier: layoutRow.viewMultiplier
      },
      styles: ['subRow']
    }

    const accountId = fsSettings.accountId(oneRowData)

    // Unique account
    if (indexedRows[rowId].params.accountIds.includes(accountId)) return

    // Include when not already in
    indexedRows[rowId].params.accountIds.push(accountId)
  })

  Object.keys(indexedRows).forEach(function (oneRowKey) {
    rowGroup.children.push(indexedRows[oneRowKey])
  })
  // console.log('rowGroup', rowGroup)

  // Sort
  rowGroup.children.sort(function (child1, child2) {
    if (child1.id < child2.id) return -1
    if (child1.id > child2.id) return 1
    return 0
  })

  return rowGroup
}
