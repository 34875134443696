import { fillString } from '../../../../helpers/templating/string-template.js'
import { getElement } from '../../../helpers/dom.js'
import { deleteUpload } from '../utils/storage/delete-upload.js'
import { getUploads } from '../utils/storage/get-uploads.js'

import htmlTemplateUpload from './upload.html'

export function showAvailableData () {
  console.log('showAvailableData')
  const uploads = getUploads()
  console.log('uploads', uploads)
  showList(uploads)
}

function showList (uploads) {
  const listContainerElement = getElement('uploadsList', '#app-content .content')
  listContainerElement.classList.add('columns')
  showUploads(uploads, listContainerElement)
  addListeners(listContainerElement)
}

function showUploads (uploads, listContainerElement) {
  // Reset
  listContainerElement.innerHTML = ''

  uploads?.forEach(function (upload) {
    // console.log('upload', upload)
    const element = fillString(htmlTemplateUpload, {
      entityName: upload.entityName,
      fileName: upload.fileName,
      numberEntries: upload.dataRows.length,
      uploadId: upload.id
    })

    listContainerElement.insertAdjacentHTML('beforeend', element)
  })
}

let listenersAdded = false
function addListeners (listContainerElement) {
  if (listenersAdded) return
  listContainerElement.addEventListener('click', function (clickEvent) {
    const clickTarget = clickEvent.target.closest('.uploadAction')
    console.log('clickTarget', clickTarget)
    if (!clickTarget) return

    const action = clickTarget.getAttribute('data-action')
    if (action === 'delete') callDeleteUpload(clickTarget)
  })

  listenersAdded = true
}

function callDeleteUpload (clickTarget) {
  const uploadId = clickTarget.getAttribute('data-uploadId')
  deleteUpload(uploadId)
}
