
function category (trackingCategories, categoryNumber) {
  const keys = Object.keys(trackingCategories)
  const categoryData = trackingCategories[keys?.[categoryNumber - 1]]
  return categoryData
}

export function trackingCategoryName (trackingCategories, categoryNumber) {
  const categoryData = category(trackingCategories, categoryNumber)
  if (!categoryData) return 'Tracking ' + categoryNumber
  return categoryData?.categoryName
}

export function trackingCategoryOptionName (trackingCategories, categoryNumber, optionId) {
  const categoryData = category(trackingCategories, categoryNumber)
  if (!categoryData) return 'Tracking ' + categoryNumber
  const optionName = categoryData.options?.[optionId]
  return optionName || '-'
}

export function outputAccountDetails (loadedTB, accountXeroId, property) {
  const chartAccounts = loadedTB?.chartAccounts
  const account = chartAccounts.find(function (one) {
    return one.accountXeroId === accountXeroId
  })
  return account?.[property] || '-'
}
