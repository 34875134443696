
/**
 * TikTok time csv values are approx "31/10/2023 23:56:24\t"
 * @param {String} value
 */
export function parseDateTimeString (value) {
  if (!value) return

  const year = value.substring(6, 10)
  const month = value.substring(3, 5)
  const day = value.substring(0, 2)

  if (!year || !Number.isFinite(+year)) return
  if (!month || !Number.isFinite(+month)) return
  if (!day || !Number.isFinite(+day)) return

  return [year, month, day].join('-')
}
