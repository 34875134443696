import { fillString } from '../../../../../helpers/templating/string-template.js'
import { readCsvFile } from '../../utilities/read-csv-file.js'
import { moduleData } from '../data.js'

export async function receiveBundles (params) {
  console.log('receiveBundles')
  console.log(params)
  if (!params.file) {
    console.error('no file')
    return
  }

  const records = await readCsvFile(params.file)
  console.log('records', records)

  // const fileId = generateRandomString()

  //
  const parsedBundles = []

  const infos = {
    idIsBigNumb: 0
  }

  records?.forEach(function (record) {
    const parsedBundle = {}
    parsedBundle._raw = record

    const SKUid = record.SKU
    parsedBundle.id = SKUid
    const bundleId = record.Bundle
    const bundleSKUquantity = record['SKU Q']

    parsedBundle.isBundle = true
    parsedBundle.bundleId = bundleId
    parsedBundle.SKUid = SKUid
    parsedBundle.bundleSKUquantity = +bundleSKUquantity || null

    parsedBundles.push(parsedBundle)

    if (
      (typeof bundleId === 'string' && bundleId.includes('E+')) ||
      (typeof SKUid === 'string' && SKUid.includes('E+'))
    ) {
      infos.idIsBigNumb++
    }
  })
  console.log('parsedBundles', parsedBundles)

  const remarks = [fillString(':numberBundles bundles', { numberBundles: parsedBundles?.length })]
  if (infos.idIsBigNumb > 0) {
    remarks.push(['<div class="tag is-warning">', infos.idIsBigNumb, 'with `E+` id?? CHECK FILE FOR IDs', '</div>'].join(''))
  }

  moduleData.files.push({
    type: 'bundles',
    name: params.file.name,
    remarks
  })

  return parsedBundles
}
