import { updateDatasetHighlight } from './highlight-dataset.js'

/**
 * From https://www.chartjs.org/docs/latest/samples/legend/events.html
 * @param {*} event
 * @param {*} item
 * @param {*} legend
 */
export function hoverHighlight (event, legendItem, legend) {
  const targetDatasetIndex = legendItem.datasetIndex
  const chart = legend.chart
  const datasets = chart.data.datasets
  updateDatasetHighlight(datasets, targetDatasetIndex, 0.2)

  chart.update()
}

export function hoverHighlightEnds (event, legendItem, legend) {
  const targetDatasetIndex = legendItem.datasetIndex
  const chart = legend.chart
  const datasets = chart.data.datasets
  updateDatasetHighlight(datasets, targetDatasetIndex, true)

  chart.update()
}
