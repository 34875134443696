import { getStoredItems } from '../group/items-lists.js'
import { getItemsDBitems } from '../utils/manage-items.js'

export function makeTableRows () {
  console.log('makeTableRows')

  return rowsFromSortedItems()
}

/**
 *
 * @returns
 */
function rowsFromSortedItems () {
  console.log('rowsFromSortedItems')
  const listsData = getStoredItems(['itemsPnl', 'itemsBS'])
  console.log('listsData', listsData)

  const itemsDBitems = getItemsDBitems()
  console.log('combineItems itemsDB', itemsDBitems)

  const rows = []

  rows.push({
    type: 'break'
  })

  listsData?.forEach(function (listData) {
    console.log('...listData', listData)
    rows.push({
      type: 'title',
      list: listData,
      styles: ['sectionSubTotal']
    })
    const groupItems = []

    listData.items?.forEach(function (listItem) {
      const targetItem = itemsDBitems.find(function (item) {
        return item.id === listItem.toLowerCase()
      })

      const rowItem = {
        item: targetItem,
        list: listData
      }

      rows.push(rowItem)
      groupItems.push(rowItem)
    })

    rows.push({
      type: 'total',
      list: listData,
      groupItems,
      styles: ['sectionTotal']
    })

    rows.push({
      type: 'break'
    })
  })
  console.log('rows', rows)
  return rows
}

// function rowsAsAllItems () {
//   const rows = Array.from(itemsDB.items.values())
//   console.log('itemsDB', itemsDB)
//   console.log('rows', rows)
//   return rows
// }
