import { lineChart } from '../../../../helpers/charts/index.js'
import { toBoolean } from '../../../../helpers/helpers/string.js'
import { computeCohortPct } from '../utilities/values.js'
import { cohortName, cohortRelativePeriods } from './helpers.js'
import { createContainer } from '../utilities/containers.js'
import { cohortColor, cohortLineWidth } from '../utilities/color.js'
import { containers } from './data.js'
import { checkPeriodsOfInterest } from '../utilities/check-values.js'

export function showCohortRetention (cohorts, property, options) {
  const containerOptions = Object.assign({}, options)
  containerOptions.classes = containerOptions.classes || []
  containerOptions.classes.push('cohort-chart')
  containerOptions.elementType = 'canvas'
  containerOptions.showActionsPlay = true
  const cohortContainerId = createContainer(containerOptions)

  const periodsOfInterest = checkPeriodsOfInterest(cohorts, property)
  // console.log('showCohortRetention', cohorts, property, periodsOfInterest)

  // Find the size of the different cohorts
  const cohortsRetention = cohorts.map(function (cohort, index) {
    let data = Array.from(cohort.info?.[property])

    if (options?.type === '%') {
      data = computeCohortPct(cohort.info?.[property])
    }

    // Check need of period 0
    if (periodsOfInterest?.hasPeriod0 === false) {
      data.splice(1, 1)
    }

    if (toBoolean(options.showInitial) === false) {
      data.shift()
    }

    if (toBoolean(options.showAcquisitionCost) === true) {
      data.unshift(cohort.acquisition?.[property])
    }

    const dataset = {
      cohortId: cohort.key.cohort,
      label: cohortName(cohort.key.cohort, options.cohortPeriods),
      color: cohortColor(cohorts, index),
      borderWidth: cohortLineWidth(cohorts, index),
      data
    }

    if (options.fillNegatives) {
      dataset.fill = {
        target: 'origin',
        // above: false, // Need to color better?
        below: 'rgb(241,70,104, 0.1)'
        // below: 'rgb(186,45,8, 0.1)'
      }
    }
    return dataset
  })

  const relativePeriods = cohortRelativePeriods(cohorts, property, {
    cohortPeriods: options.cohortPeriods
  })
  console.log('relativePeriods', relativePeriods)
  const labels = ['Initial']
  labels.push(...relativePeriods.map(function (one) {
    return one.label
  }))
  // Check need of period 0
  if (periodsOfInterest?.hasPeriod0 === false) {
    labels.splice(1, 1)
  }

  if (toBoolean(options.showInitial) === false) {
    labels.shift()
  }

  if (toBoolean(options.showAcquisitionCost) === true) {
    labels.unshift('Acquisition')
  }

  const chartOptions = {
    chartContainerId: cohortContainerId,
    datasets: cohortsRetention,
    labels,
    yAxisTitle: options.yAxisTitle || 'Retention'
  }

  if (options?.type === '%') {
    chartOptions.valueFormat = '.0%'
  }

  const chart = lineChart(chartOptions)
  containers[cohortContainerId] = {
    chart
  }
}
