import * as mAccount from '../../modules/user/account.js'

import { callApi } from '../../helpers/fetch.js'

export async function loadMonthlyData (context) {
  console.log('loadData', context)

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)
  console.log('accountData.chartOfAccounts', accountData.chartOfAccounts)
  context.chartOfAccounts = accountData.chartOfAccounts
  context.test = true

  const endpointUrl = '/api/finlogic/monthly/:accountId/:orgId/:dateMin/:dateMax/:currencyCode/:groupings'

  const response = await callApi(endpointUrl, {
    accountId: accountData?._id,
    orgId: context.showOrgId,
    dateMin: context?.dates?.min,
    dateMax: context?.dates?.max,
    currencyCode: context?.currencySymbol,
    groupings: makeGroupingUrl(context)
  })
  console.log('response', response)
  return response
}

/**
 * Takes Array of potential groupings and make it xxx-xxx-xxx
 * @param {*} context
 */
function makeGroupingUrl (context) {
  const groupings = context?.groupings

  if (!groupings) return 'defaultgrouping'

  return groupings.join('-')
}
