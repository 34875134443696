
/**
*
* @param {String} value
* @returns {String}
*/
export function parseTiktokId (value) {
  if (!value) return value

  return value.replaceAll('\t', '')
}
