import { roundNumber } from '../../../../../helpers/helpers/numbers.js'

/**
 *
 * @param {Array{}} orderLines
 * @param {Map} productsMap
 * @param {Map} productsMap
 */
export function splitBundlesToProducts (orderLines, productsMap, fieldsMapping) {
  console.log('splitBundlesToProducts', orderLines, productsMap, fieldsMapping)
  const completeOrderLines = []

  orderLines?.forEach(function (orderLine) {
    const productData = productsMap?.get(orderLine.SKU_id)
    if (!productData) {
      const newOrderLine = generateSplitLine(orderLine, fieldsMapping)
      // console.log('newOrderLine', newOrderLine)
      newOrderLine.isDirectProduct = true
      newOrderLine.isUnknownProduct = true
      completeOrderLines.push(newOrderLine)
      return
    }

    if (productData.bundleId && Array.isArray(productData.products)) {
      // Is bundle: generate lines
      const newOrderLines = generateProductLinesFromBundle(orderLine, productData, fieldsMapping)
      completeOrderLines.push(...newOrderLines)

      return
    }

    // Is product: add
    const newOrderLine = generateSplitLine(orderLine, fieldsMapping)
    newOrderLine.isDirectProduct = true

    completeOrderLines.push(newOrderLine)
  })
  console.log('completeOrderLines', completeOrderLines)
  return completeOrderLines
}

/**
 *
 * @param {*} orderLine
 * @param {*} fieldsMapping
 * @param {*} linePct
 * @returns
 */
function generateSplitLine (orderLine, fieldsMapping, linePct) {
  const newLine = Object.assign({}, orderLine)

  fieldsMapping?.forEach(function (fieldMapping) {
    if (fieldMapping?.additional?.splitBundles?.include === false) {
      delete newLine[fieldMapping.outputName]
    }

    if (fieldMapping?.additional?.splitBundles?.split === true) {
      newLine.split = true
      newLine.splitPct = linePct

      if (!Number.isFinite(linePct)) return
      newLine.intialValue = newLine[fieldMapping.outputName]
      newLine[fieldMapping.outputName] = newLine[fieldMapping.outputName] * linePct
      newLine[fieldMapping.outputName] = roundNumber(newLine[fieldMapping.outputName], 2)
    }
  })
  return newLine
}

function generateProductLinesFromBundle (orderLine, productData, fieldsMapping) {
  const bundleOrderLines = []

  productData.products?.forEach(function (product) {
    const newOrderLine = generateSplitLine(orderLine, fieldsMapping, product.productPctPrice)

    newOrderLine._raw = orderLine
    newOrderLine.isFromBundle = true
    newOrderLine.productData = productData
    newOrderLine.SKU_id = product.SKUid
    newOrderLine.quantity = orderLine.quantity * product.bundleSKUquantity

    bundleOrderLines.push(newOrderLine)
  })

  return bundleOrderLines
}
