import { generateButtons } from '../../../../helpers/menus/index.js'
export const computedData = {}
export const viewOptions = {}

const containerId = 'selectDateConsidered'

export const datetypeSelectorSettings = {
  selectedValue: 'settledAt'
}

export function activateDatetypeSelector () {
  const instance = generateButtons({
    buttonOptions,
    containerId,
    groupLabel: 'Date to consider:',
    selected: datetypeSelectorSettings.selectedValue,
    onChange: function (selectedOption, instance) {
      console.log('selected', selectedOption, instance)
      datetypeSelectorSettings.selectedValue = selectedOption.value
    }
  })
  viewOptions.instance = instance
}

const buttonOptions = [
  {
    label: 'Created',
    value: 'createdAt'
  },
  {
    label: 'Paid',
    value: 'paidAt'
  },
  {
    label: 'Settled',
    value: 'settledAt'
  },
  {
    label: 'Settled or Paid',
    value: 'settled.or.paid'
  },
  {
    // This is a special case: not just based on the revenueDate which can be made of order/settlement
    label: 'Paid, Not Settled',
    value: 'paid.notSettled'
  }
]
