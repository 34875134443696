import * as configVar from '../../../config/config.js'
import { optionSelectorData } from '../data.js'
import { ELEMENT_ID } from './options-organisations.js'
import { recordSelection } from './record-selection.js'
import { updateSelectorEntity } from './update-entities.js'

export function listeners (params) {
  const selectorElement = document.getElementById(ELEMENT_ID)
  selectorElement.addEventListener('click', function (clickEvent) {
    const target = clickEvent.target.closest('button')
    if (!target) return
    // console.log('org target', target)

    const targetOrgId = target.getAttribute('d-org-id')
    recordSelection(targetOrgId)
    updateSelectorEntity()

    // Default to showing the dates columns when changing entity
    optionSelectorData.layout = ['dates']

    params.onChange?.()
  })

  // Layout
  const DOMelement = document.getElementById(configVar.contentElementId)
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('.pickLayout')
    if (!clickedElement) return

    const pickLayout = clickedElement.getAttribute('d-layout')
    console.log('pickLayout', pickLayout)

    optionSelectorData.layout = pickLayout.split('.')
    params.onChange?.()
  })
}
