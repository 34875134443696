
export function computeCohortsValues (cohortsData, property, options) {
  cohortsData.forEach(function (oneCohort) {
    applyCohortValues(oneCohort, property, options)
  })
  // console.log('computeCohortsValues', cohortsData)
}

function applyCohortValues (cohortData, property, options) {
  let values = cohortData.info[property]
  if (options?.values?.type === '%') {
    values = computeCohortPct(values)
  }
  cohortData.showValues = values
}

export function computeCohortPct (dataserie) {
  const values = dataserie.map(function (oneValue) {
    return oneValue / dataserie[0]
  })
  return values
}
