import htmlIndexTemplate from './index.html'
import * as mData from './data.js'
import { activateOptionSelector } from '../../modules/option-selector/index.js'
import { makeDateSerie } from '../../../helpers/dates/make-date-serie.js'

export const statementTypeNames = {
  pnl: 'Profit & Loss',
  bs: 'Balance Sheet',
  forecast: 'Forecast',
  'trial-balances': 'Trial Balances'
}

/**
 * Create the HTML page container
 * @param {String} [containerTitle] -- If not defined, use based on mData.db.options.statementType
 * @returns {Element}
 */
export function createContainer (containerTitle) {
  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')

  contentElement.classList.add('height-100', 'is-flex', 'is-flex-direction-column') // Full height, allowing table vertical scrolling
  let htmlIndex = htmlIndexTemplate

  const statementTypeName = containerTitle || statementTypeNames[mData.db.options.statementType]
  htmlIndex = htmlIndex.replaceAll(':statementType', statementTypeName)
  contentElement.innerHTML = htmlIndex

  return contentElement
}

/**
 *
 * @param {Object} accountData
 * @param {String} [pageTypeTitle] -- If not defined, use based on mData.db.options.statementType
 */
export function setPageTitle (accountData, pageTypeTitle) {
  const statementTypeName = pageTypeTitle || statementTypeNames[mData.db.options.statementType]
  document.title = [statementTypeName, ': ', accountData.name].join('')
}

export function setOptionSelector (changeAction) {
  const optionSelectorHide = []
  const optionSelectorShow = []
  if (mData.db.options.statementType === 'forecast') {
    optionSelectorHide.push('dates')
    optionSelectorHide.push('periodicity')

    optionSelectorShow.push('forecasting')
  }
  if (['forecast', 'trial-balances'].includes(mData.db.options.statementType)) {
    optionSelectorHide.push('valueAggregation')
    optionSelectorHide.push('gridLayout')
  }
  if (['trial-balances'].includes(mData.db.options.statementType)) {
    optionSelectorHide.push('currencies')
  }

  const dateseries = makeDateSerie(mData.db.data.fs3Data.dateseries[0])
  // console.log('dateseries', dateseries)
  // console.log('mData.db.data.fs3Data.dateseries', mData.db.data.fs3Data.dateseries)

  const optionSelectorParams = activateOptionSelector({
    containerId: 'optionSelector',
    hide: optionSelectorHide,
    show: optionSelectorShow,
    onChange: changeAction,
    dateseries, // : mData.db.data.fs3Data.dateseries,
    customSettings: {
      statementType: mData.db.options.statementType,
      organisations: mData.db.data.fs3Data.organisations,
      currencies: mData.db.data.currencies
    }
  })
  return optionSelectorParams
}
