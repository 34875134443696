
// Routing
export const appRoutes = '/app'
// export const apiRoutes = '/api'
export const authRoutes = '/auth'

// General variables
export const contentElementId = 'app-content'
export const layoutAuthElementId = 'layoutAuth'

// Fs version
export const fsVersion = 'fs3'
