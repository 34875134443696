import { indexArray } from '../../../helpers/data/array-index.js'
import { loadChartOfAccounts } from '../../pages/charts-of-accounts/load-charts-of-accounts.js'

export async function prepareChartOfAccountsData (accountId) {
  const apiResponse = await loadChartOfAccounts(accountId)

  // Update the array
  const arrayOfAccounts = apiResponse?.chartsOfAccounts?.accounts?.map(function (oneAccount) {
    return {
      _id: oneAccount._id,
      entityId: oneAccount._xeroOrganisation,
      code: oneAccount.details?.code,
      name: oneAccount.details?.name,
      type: oneAccount.details?.type,
      status: oneAccount.details?.status,
      statement: oneAccount.statement
    }
  })

  return {
    indexed: indexArray(arrayOfAccounts, '_id'),
    data: apiResponse
  }
}
