
/**
 * Generates a quite random string of characters [0-9a-z] of a given length
 * @param {Number} [length] defaults to 8
 * @returns
 */
export function generateRandomString (length) {
  let generatedString = ''

  const _length = length || 8

  let loopSafety = 100
  while (generatedString.length < _length && loopSafety > 0) {
    const characters = (Math.random() + 1).toString(36).substring(2)
    generatedString = generatedString + characters

    loopSafety--
  }

  generatedString = generatedString.substring(0, _length)
  return generatedString
}
