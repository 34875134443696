import { launchLogger } from '../../../helpers/logging/pino.logger.js'
import { addAccountDataToRecord, addContactDataToRecord, addTrackingDataToRecord } from './utils.js'

const logger = launchLogger('/finlogic/layouts/enrich-records.js')
logger.silence()
logger.info('loaded')

/**
 * Time consuming function: because of number of records?
 * @param {Object[]} records
 * @param {Object} dbs
 * @param {String[]} propertiesToEnrich
 * @param {Boolean} usePropertiesAsKey
 */
export function enrichRecords (records, dbs, propertiesToEnrich, usePropertiesAsKey = false) {
  logger.debug(['enrichRecords'])
  logger.debug(['records', records.length, records[0]])
  console.time('enrichRecords')

  propertiesToEnrich.forEach(function (propertyToEnrich) {
    enrichWithProperty(records, dbs, propertyToEnrich, usePropertiesAsKey)
  })

  logger.debug(['records', records.length, records[0]])

  console.timeEnd('enrichRecords')
}

function enrichWithProperty (records, dbs, propertyToEnrich, usePropertiesAsKey) {
  if (propertyToEnrich.includes('chartAccountId')) {
    const sourceEnrichmentKey = usePropertiesAsKey ? 'chartAccountId' : sourceEnrichmentKeysForGroups.chartAccountId
    addAccountDataToRecord(records, dbs.chartAccounts, sourceEnrichmentKey)
  }
  if (propertyToEnrich.includes('contactId')) {
    const sourceEnrichmentKey = usePropertiesAsKey ? 'contactId' : sourceEnrichmentKeysForGroups.contactId
    addContactDataToRecord(records, dbs.contacts, sourceEnrichmentKey)
  }
  if (propertyToEnrich.includes('tracking1')) {
    const sourceEnrichmentKey = usePropertiesAsKey ? propertyToEnrich : sourceEnrichmentKeysForGroups.tracking1
    addTrackingDataToRecord(records, dbs.tracking1, sourceEnrichmentKey, 'tracking1')
  }
  if (propertyToEnrich.includes('tracking2')) {
    const sourceEnrichmentKey = usePropertiesAsKey ? propertyToEnrich : sourceEnrichmentKeysForGroups.tracking2
    addTrackingDataToRecord(records, dbs.tracking2, sourceEnrichmentKey, 'tracking2')
  }
}

const sourceEnrichmentKeysForGroups = {
  chartAccountId: 'key.chartAccountId',
  contactId: 'key.contactId',
  tracking1: 'key.tracking1',
  tracking2: 'key.tracking2'
}
