export const moduleData = {
  // Store Orders and Settlements, cleaned
  ordersLines: [], // Array
  settlements: [], // Array

  // Product Catalog
  productsDataset: null,

  // Computed Data: useful for exports
  computedData: {},

  // Files
  files: []
}
