
import { setElementHtml } from '../../../client/helpers/dom.js'
import { generateRandomString } from '../../helpers/random.js'
import htmlTemplate from './export.html'

export function enableActionExport (gridInstance, containerElement) {
  console.log('enableExport', containerElement)
  const actionsContainerElement = containerElement?.querySelector('div.actions')
  console.log('actionsContainerElement', actionsContainerElement)
  if (!actionsContainerElement) return

  const actionContainerId = generateRandomString()

  actionsContainerElement.setAttribute('id', actionContainerId)
  const createdElement = setElementHtml(actionContainerId, htmlTemplate)

  const exportButton = createdElement.querySelector('[gridAction="export"]')
  exportButton.addEventListener('click', function (clickEvent) {
    console.log('clicked')
    const exportSettings = generateExportSettings()

    gridInstance.exportCsv(exportSettings)
  })
}

function generateExportSettings () {
  const exportTime = [
    (new Date()).toJSON().substring(0, 10),
    [(new Date()).getHours(), (new Date()).getMinutes(), (new Date()).getSeconds()].join(':')
  ].join(' ')

  // *** The gridInstance is NOT updated with the page view
  const exportSettings = {
    sheetHeader: [],
    filename: ['export data', exportTime].join(' - ')
  }

  return exportSettings
}
