import { generateGrid } from '../../../../helpers/cssgrid/index.js'
import { accessObjectPropertyValue } from '../../../../helpers/data/objects.js'
import * as timeUI from '../../../../helpers/helpers/time-ui.js'
import { timeDuration } from '../../../../helpers/helpers/time.js'
import { descending } from 'd3'
import { fillString } from '../../../../helpers/templating/string-template.js'

export function showSyncsTable (listOfSyncs) {
  showGrid(listOfSyncs)
}

function showGrid (listOfSyncs) {
  generateGrid({
    containerElementId: 'syncsList',
    colDefs: makeColDefs(),
    // rowDefs: listOfSyncs
    rowDefs: makeRows(listOfSyncs)
  })
}

function makeRows (listOfSyncs) {
  console.log('makeRows', listOfSyncs)
  // const sortingProperty = 'createdAt'
  // const sortingProperty = 'updatedAt'
  const sortingProperty = 'scheduledAt'
  listOfSyncs.sort(function (r1, r2) {
    return descending(accessObjectPropertyValue(sortingProperty, r1), accessObjectPropertyValue(sortingProperty, r2))
  })
  return listOfSyncs
}

function makeColDefs () {
  const columns = []

  columns.push({
    id: 'Entity',
    valueGetter: function (cellParams) {
      const entityName = accessObjectPropertyValue('_xeroOrganisation.details.name', cellParams.rowDef)
      const entityId = accessObjectPropertyValue('_xeroOrganisation._id', cellParams.rowDef)
      if (entityId) {
        const urlToOrgInfo = fillString('/app/admin/org-info/:orgId/infos', {
          orgId: entityId
        })
        return fillString('<a href=":urlToOrgInfo">:entityName</a>', {
          urlToOrgInfo,
          entityName
        })
      } else {
        return entityName
      }
    },
    width: 200,
    styles: ['bold']
  })
  columns.push({
    id: 'Status',
    valueGetter: function (cellParams) {
      return accessObjectPropertyValue('status', cellParams.rowDef)
    },
    width: 100
  })
  columns.push({
    id: 'Origin',
    valueGetter: function (cellParams) {
      return accessObjectPropertyValue('origin', cellParams.rowDef)
    },
    width: 70
  })
  columns.push({
    id: 'createdAt',
    valueGetter: function (cellParams) {
      return accessObjectPropertyValue('createdAt', cellParams.rowDef)?.substring(0, 19)
    },
    width: 150
  })
  columns.push({
    id: 'updatedAt',
    valueGetter: function (cellParams) {
      return accessObjectPropertyValue('updatedAt', cellParams.rowDef)?.substring(0, 19)
    },
    width: 150
  })
  columns.push({
    id: 'scheduledTime',
    valueGetter: function (cellParams) {
      return accessObjectPropertyValue('scheduledTime', cellParams.rowDef)?.substring(0, 19)
    },
    width: 150
  })
  columns.push({
    id: 'startedAt',
    valueGetter: function (cellParams) {
      return accessObjectPropertyValue('startedAt', cellParams.rowDef)?.substring(0, 19) || null
    },
    width: 150
  })
  columns.push({
    id: 'Scheduled',
    valueGetter: function (cellParams) {
      const scheduledTime = accessObjectPropertyValue('scheduledTime', cellParams.rowDef)
      return timeUI.timeAgoHtml(scheduledTime)
    },
    width: 150
  })
  columns.push({
    id: 'startedAt',
    valueGetter: function (cellParams) {
      const startedAt = accessObjectPropertyValue('startedAt', cellParams.rowDef)
      return timeUI.timeAgoHtml(startedAt)
    },
    width: 150
  })
  columns.push({
    id: 'endedAt',
    valueGetter: function (cellParams) {
      const endedAt = accessObjectPropertyValue('endedAt', cellParams.rowDef)
      return timeUI.timeAgoHtml(endedAt)
    },
    width: 150
  })
  columns.push({
    id: 'Time',
    valueGetter: function (cellParams) {
      const startedAt = accessObjectPropertyValue('startedAt', cellParams.rowDef)
      const endedAt = accessObjectPropertyValue('endedAt', cellParams.rowDef)
      return timeDuration(startedAt, endedAt)?.timeText || null
    },
    width: 110
  })
  columns.push({
    id: 'totalApiCalls',
    valueGetter: function (cellParams) {
      const totalApiCalls = accessObjectPropertyValue('totalApiCalls', cellParams.rowDef)
      return totalApiCalls || null
    },
    width: 100
  })
  columns.push({
    id: 'Tokens',
    valueGetter: function (cellParams) {
      const details = accessObjectPropertyValue('details', cellParams.rowDef)
      if (!details) return null

      return details.numberOfTokens
    },
    width: 80
  })
  columns.push({
    id: 'Modules Run',
    valueGetter: function (cellParams) {
      const details = accessObjectPropertyValue('details', cellParams.rowDef)
      if (!details) return null

      return numberModulesRun(details)
    },
    width: 100,
    onClick: function (cellParams) {
      const details = accessObjectPropertyValue('details', cellParams.rowDef)
      const sortedModules = sortModules(details)
      console.log(sortedModules)
    }
  })
  columns.push({
    id: 'Latest Module',
    valueGetter: function (cellParams) {
      const details = accessObjectPropertyValue('details', cellParams.rowDef)
      return showLatestModule(details)?.moduleName || null
    },
    width: 100
  })
  return columns
}

function sortModules (syncDetailsData) {
  if (!syncDetailsData?.module) return null

  const modules = []
  Object.keys(syncDetailsData.module).forEach(function (oneModuleKey) {
    modules.push(Object.assign({ moduleName: oneModuleKey }, syncDetailsData.module[oneModuleKey]))
  })

  modules.sort(function (m1, m2) {
    return descending(m1.startedAt, m2.startedAt)
  })
  return modules
}

function showLatestModule (syncDetailsData) {
  const modules = sortModules(syncDetailsData)

  return modules?.[0]
}

function numberModulesRun (syncDetailsData) {
  if (!syncDetailsData.module) return null

  const totalModules = Object.keys(syncDetailsData.module)?.length

  let failedModules = 0
  Object.keys(syncDetailsData.module).forEach(function (oneModuleKey) {
    const module = syncDetailsData.module[oneModuleKey]
    if (module.isFailed) failedModules++
  })

  if (failedModules === 0) {
    return totalModules
  } else {
    return totalModules + ' (failed:' + failedModules + ')'
  }
}
