import * as XLSX from 'xlsx'
import { activateExport } from '../../../helpers/activate-export.js'

let _gridInstance = null
let isListenerAdded = false

export function enableExport (gridInstance) {
  console.log('enableExport', gridInstance)

  // Store data for module
  _gridInstance = gridInstance

  if (isListenerAdded) return
  isListenerAdded = true
  activateExport(downloadTrialBalanceCsv, _gridInstance)
}

function downloadTrialBalanceCsv (gridInstance) {
  console.log('downloadTrialBalanceCsv', gridInstance)
  const inputDataRows = gridInstance.rowDefs
  const columnsSettings = gridInstance.colDefs

  const rows = inputDataRows.map(function (oneRow) {
    const row = {}

    columnsSettings.forEach(function (columnSettings) {
      const value = columnSettings.valueGetter({
        rowDef: oneRow
      })

      row[columnSettings.id] = value
    })

    return row
  })

  const worksheet = XLSX.utils.json_to_sheet(rows)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Trial balances')

  const writeOptions = {
    compression: true,
    bookType: 'csv'
  }

  XLSX.writeFile(workbook, 'Trial Balances.csv', writeOptions)
}
