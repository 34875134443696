import { generateRecordWithSettings } from '../generate-record-with-settings/generate-record-with-settings.js'

/**
 *
 * @param {*} records
 * @param {*} fieldsSettings
 * @returns
 */
export function transformDataset (records, fieldsSettings) {
  if (!records) return []

  const outputDataset = []

  records?.forEach(function (record) {
    const outputRecord = {}
    generateRecordWithSettings(record, outputRecord, fieldsSettings)

    outputDataset.push(outputRecord)
  })

  return outputDataset
}
