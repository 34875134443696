import { flatRollup, rollup, sum } from 'd3'
import { rollupProperties } from '../settings/rollup-properties.js'

/**
 *
 * @param {Array{}} records
 * @param {Array{}} groupingProperties
 * @param {Object} [options]
 * @param {Boolean} [options.useFlat] true: uses D3 flatRollup(); false (Default) uses rollup() ie Map()
 * @param {Boolean} [options.returnValuesArray] true: removes the rollup keys for the values only; false (Default) returns the D3 results
 * @returns
 */
export function computeRollups (records, groupingProperties, options) {
  // Use the D3 functions wanted
  const rollupFunction = options?.useFlat ? flatRollup : rollup

  const rolledUp = rollupFunction(records,
    function (groupRecords) {
      const rollupValue = computeRollupValue(groupRecords, rollupProperties, groupingProperties)
      // console.log('rollupValue', rollupValue)
      return rollupValue
    },
    ...makeGroupingFunctions(groupingProperties)
  )
  // console.log('rolledUp', rolledUp)

  // Manipulates the output
  if (options?.returnValuesArray) {
    // console.log('returnValuesArray')
    // Returns just the values, not the rollup Keys
    const valuesArray = []
    rolledUp?.forEach(function (group) {
      const rollupValue = group[groupingProperties.length]
      // group.splice(0, groupingProperties.length)
      valuesArray.push(rollupValue)
    })
    return valuesArray
  } else {
    return rolledUp
  }
}

/**
 * March 14, 2024: Manages: first, sum, count
 * @param {*} groupRecords
 */
function computeRollupValue (groupRecords, rollupProperties, groupingProperties) {
  // console.log('computeRollupValue', groupRecords)
  const rollupValue = {}

  // Compute the rollups
  rollupProperties?.forEach(function (rollupProperty) {
    if (rollupProperty.rollup === 'records') {
      rollupValue[rollupProperty.propertyName] = groupRecords
    } else if (rollupProperty.rollup === 'set') {
      rollupValue[rollupProperty.propertyName] = new Set()
      groupRecords.forEach(function (record) {
        rollupValue[rollupProperty.propertyName].add(record[rollupProperty.propertyName])
      })
    } else if (rollupProperty.rollup === 'first') {
      rollupValue[rollupProperty.propertyName] = groupRecords[0][rollupProperty.propertyName]
    } else if (rollupProperty.rollup === 'sum') {
      rollupValue[rollupProperty.propertyName] = sum(groupRecords,
        function (record) { return record[rollupProperty.propertyName] || 0 }
      )
    } else if (rollupProperty.rollup === 'count') {
      rollupValue[rollupProperty.propertyName] = groupRecords.length
    }
  })

  // Include the grouping values
  groupingProperties.forEach(function (groupingProperty) {
    rollupValue[groupingProperty] = groupRecords[0][groupingProperty]
  })

  return rollupValue
}

/**
 *
 * @param {*} groupingProperties
 * @returns
 */
function makeGroupingFunctions (groupingProperties) {
  const functions = []
  groupingProperties?.forEach(function (groupingProperty) {
    const groupingFunction = function (record) { return record[groupingProperty] }
    functions.push(groupingFunction)
  })

  // console.log('functions', functions)
  return functions
}
