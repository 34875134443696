import { ascending } from 'd3'
import { mapArrayProperties } from '../../../helpers/data/array-of-objects/mapped-array.js'
import { cohortSettings } from './data.js'

/**
* Extract the 3 key properties: userId, actionDate, actionValue
*
*/
export function enrichActionsData (records) {
  console.log('enrichActionsData', cohortSettings)
  const properties = [
    {
      name: 'userId',
      accessor: cohortSettings.fields.userId
    }, {
      name: 'actionDate',
      accessor: cohortSettings.fields.actionTimestamp,
      manipulation: function (value) {
        // TODO: manage different format of dates input?
        return new Date(value)
      }
    }, {
      name: 'actionValue',
      accessor: cohortSettings.fields.actionValue,
      manipulation: function (value) {
        return extractValue(value)
      }
    }
  ]
  const enrichedArray = mapArrayProperties(records, properties)
  return enrichedArray
}

export function sortActions (records) {
  records.sort(function (r1, r2) {
    return ascending(r1.actionDate, r2.actionDate)
  })

  return records
}

function extractValue (value) {
  const multiplier = 0.01
  // const multiplier = 1

  const _value = typeof value === 'number' ? value : +value
  return _value * multiplier
}
