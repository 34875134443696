import { makeDateSerie } from '../../../../../helpers/dates/make-date-serie.js'
import { contentValueFormatter, contentValueStyling } from '../../../../helpers/helpers.js'
import { getCellValue } from './get-cell-value.js'

export function makeColumns (context) {
  const columnDefs = []
  columnDefs.push({
    id: 'statement',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.statement || null
    },
    width: 70
  })

  columnDefs.push({
    id: 'Type',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.type || null
    },
    width: 60
  })

  columnDefs.push({
    id: 'ID',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.accountId || null
    },
    width: 100
  })

  columnDefs.push({
    id: 'Code',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.code || null
    },
    width: 40
  })
  columnDefs.push({
    id: 'Name',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.name || null
    },
    width: 190
  })

  // The dates columns
  const datesForColumns = makeDateSerie(context.dates.min, context.dates.max, 'month')

  // For right to left, reverse the array
  // datesForColumns.reverse()

  datesForColumns.forEach(function (oneDate) {
    columnDefs.push({
      id: oneDate,
      valueGetter: function (cellParams) {
        return getCellValue(cellParams)
      },
      valueFormatter: function (cellParams) {
        return contentValueFormatter({ cellParams, decimals: cellParams.gridSettings.context.viewDecimals })
      },
      valueStyling: function (cellParams) {
        return contentValueStyling({ cellParams })
      },
      params: {
        month: oneDate
      },
      styles: ['numberColumns'],
      width: 100
    })
  })

  return columnDefs
}
