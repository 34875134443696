import { optionSelectorData } from '../data.js'
import { methods } from './options-value-aggregations.js'

export function updateSelectorValueAggregation () {
  // console.log('updateSelectorValueAggregation', optionSelectorData)
  // console.log('optionSelectorData.periodicity', optionSelectorData.periodicity)
  // console.log('optionSelectorData.viewAggregations.includedPeriods', optionSelectorData.viewAggregations.includedPeriods)
  // console.log('optionSelectorData.viewAggregations.method', optionSelectorData.viewAggregations.method)

  const basePeriodsMethods = ['period', 'balance']

  const buttonElements = document.querySelectorAll('#valueAggregationButtons > button, #valueAggregationButtons > div')
  buttonElements.forEach(function (oneButton) {
    const buttonIcon = oneButton.querySelector('.icon.iconIfActive')
    if (!buttonIcon) return

    const buttonAggregationMethod = oneButton.getAttribute('d-aggregationMethod')

    // Hide if needed
    if (optionSelectorData.periodicity > 1 && !basePeriodsMethods.includes(buttonAggregationMethod)) {
      // When not using monthly values; only Period Value is available (for now)
      oneButton.classList.add('is-hidden')
    } else {
      oneButton.classList.remove('is-hidden')
    }

    // Active if needed
    const isButtonMatching = buttonAggregationMethod === optionSelectorData.viewAggregations.method // Eg. 'period' for 1 month periods
    // console.log('isButtonMatching', isButtonMatching, buttonAggregationMethod, '===', optionSelectorData.viewAggregations.method)

    const isPeriodicityPeriod = optionSelectorData.viewAggregations.includedPeriods === optionSelectorData.periodicity && (isButtonMatching || basePeriodsMethods.includes(buttonAggregationMethod))
    // console.log('isPeriodicityPeriod', isPeriodicityPeriod, optionSelectorData.viewAggregations.includedPeriods, '===', optionSelectorData.periodicity)
    // console.log('isButtonMatching || isPeriodicityPeriod', isButtonMatching, '||', isPeriodicityPeriod)
    if (isButtonMatching || isPeriodicityPeriod) {
      // For buttons
      if (oneButton.tagName === 'BUTTON') oneButton.classList.add('is-active') // is-active for dropdown means opening it: we don't want that

      // For Dropdown
      if (oneButton.tagName === 'DIV') oneButton.querySelector('button').classList.add('is-active')

      buttonIcon.classList.remove('is-hidden')
    } else {
      oneButton.classList.remove('is-active')
      oneButton.querySelector('button')?.classList.remove('is-active')
      buttonIcon.classList.add('is-hidden')
    }

    updateValueAggregationButton(oneButton)
  })
}

function updateValueAggregationButton (oneButton) {
  function execute () {
    const labelElement = oneButton.querySelector('span.dropdownLabel')
    // console.log('oneButton', oneButton, labelElement)

    const buttonAggregationMethod = oneButton.getAttribute('d-aggregationMethod')

    const targetMethod = methods.find(function (oneMethod) {
      return oneMethod.method === buttonAggregationMethod
    })

    if (buttonAggregationMethod === optionSelectorData.viewAggregations.method) {
      const subMethod = targetMethod.includedPeriods.find(function (oneSubMethod) {
        return oneSubMethod.value === optionSelectorData.viewAggregations.includedPeriods
      })
      labelElement.innerText = targetMethod.label + ': ' + subMethod.label
    } else {
      labelElement.innerText = targetMethod.label
    }
  }

  if (oneButton.tagName === 'DIV') {
    execute()
  }
}
