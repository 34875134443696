// Dependencies
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { contentValueFormatter, contentValueStyling } from '../../../helpers/helpers.js'
import { getOrganisation } from '../../../modules/user/organisations.js'
import { makeXeroLink } from '../../../helpers/xero-links.js'
import { fillString } from '../../../../helpers/templating/string-template.js'

/**
 *
 * @param {*} params
 * @returns {Array} of Object {value, children[], styles, params{date, orgId, [currency], [valueType]} }
 */
export function generateTransactionsColumnDefs (params) {
  console.log('generateTransactionsColumnDefs', params)

  const organisation = getOrganisation()

  const columns = []

  // Left column
  columns.push({
    id: 'Date',
    styles: ['leftColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef.details.journalDate.substring(0, 10)
    },
    layout: 'left',
    width: 100
  })

  columns.push({
    id: 'Source',
    styles: ['leftColumns'],
    valueGetter: function (cellParams) {
      const xeroLink = makeXeroLink(cellParams.rowDef.details.sourceType, {
        shortCode: organisation.details.shortCode,
        sourceId: cellParams.rowDef.details.sourceId
      })

      // const label = [cellParams.rowDef.source.sourceType, cellParams.rowDef.source.status, cellParams.rowDef.source.type, cellParams.rowDef.details.sourceType].join(' ')
      const label = cellParams.rowDef.source.sourceType

      if (!xeroLink) {
        return label
      }

      const link = fillString('<a href=":url" target="_blank">:linkLabel</a>', {
        url: xeroLink,
        linkLabel: label
      })
      return link
    },
    width: 120
  })

  columns.push({
    id: 'Contact',
    styles: ['leftColumns'],
    valueGetter: function (cellParams) {
      const contactUrl = makeXeroLink('Contact', {
        shortCode: organisation.details.shortCode,
        contactXeroId: cellParams.rowDef.source.contactXeroId
      })
      const contactName = cellParams.rowDef.source.contact.name

      const contactLink = fillString('<a href=":url" target="_blank">:contactName</a>', {
        url: contactUrl,
        contactName
      })
      return contactLink
    },
    width: 200
  })

  columns.push({
    id: 'Net',
    styles: ['leftColumns', 'numberColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef.journalLines.netAmount
    },
    valueFormatter: function (cellParams) {
      return contentValueFormatter({
        cellParams,
        decimals: optionSelectorData.decimals
      })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    },
    width: 110
  })

  columns.push({
    id: 'Cumulative',
    styles: ['leftColumns', 'numberColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef.cumulAmount
    },
    valueFormatter: function (cellParams) {
      return contentValueFormatter({
        cellParams,
        decimals: optionSelectorData.decimals
      })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    },
    width: 110
  })

  columns.push({
    id: 'Currency',
    styles: ['leftColumns', 'numberColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef.source.currencyCode
    },
    width: 110
  })

  columns.push({
    id: 'Currency Rate',
    styles: ['leftColumns', 'numberColumns'],
    valueGetter: function (cellParams) {
      if (cellParams.rowDef.source.currencyCode === organisation.details.baseCurrency) return '-'
      // return roundNumber(cellParams.rowDef.source.currencyRate, 5)
      return cellParams.rowDef.source.currencyRate
    },
    valueFormatter: function (cellParams) {
      return contentValueFormatter({
        cellParams,
        decimals: 5
      })
    },
    width: 110
  })

  columns.push({
    id: 'Source Amount',
    styles: ['leftColumns', 'numberColumns'],
    valueGetter: function (cellParams) {
      if (cellParams.rowDef.source.currencyCode === organisation.details.baseCurrency) return '-'

      const sourceAmount = cellParams.rowDef.journalLines.netAmount * cellParams.rowDef.source.currencyRate
      return sourceAmount
    },
    valueFormatter: function (cellParams) {
      // Because the source currency amount are not stored by default in Journals (only available in Source lineItems), we avoid showing the exact number. 1 decimal max
      const maxDecimals = 1
      return contentValueFormatter({
        cellParams,
        decimals: Math.min(maxDecimals, optionSelectorData.decimals)
      })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    },
    width: 110
  })

  return columns
}
