import { parseDateString } from '../../../../utilities/parse-date-string.js'
import { parseNumber } from '../../../utilities/parse-number.js'

/**
 */
export const lazadaOrdersFieldsMapping = [
  {
    inputName: 'orderNumber',
    outputName: 'orderId',
    lineCombination: 'first',
    lineCombinationId: true
  },
  {
    outputName: 'recordType',
    parseFunction: function () {
      return 'orderLine'
    }
  },
  {
    outputName: 'orderPlatform',
    parseFunction: function (record, context) {
      return context?.platform || undefined
    }
  },
  {
    outputName: 'orderCountry',
    parseFunction: function (record, context) {
      return context?.country || undefined
    }
  },
  {
    outputName: 'orderLines',
    lineCombination: 'records'
  },
  {
    inputName: 'sellerSku',
    outputName: 'SKU_id'
  },
  {
    inputName: 'customerName',
    outputName: 'buyerUsername',
    lineCombination: 'first'
  },
  {
    inputName: 'status',
    outputName: 'orderStatus'
  },
  {
    inputName: 'createTime',
    outputName: 'createdAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'DD MMM YYYY HH:mm' })
    },
    lineCombination: 'first'
  },
  {
    inputName: 'deliveredDate',
    outputName: 'deliveredAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'DD MMM YYYY HH:mm' })
    },
    lineCombination: 'first'
  },
  {
    // No quantity in Lazada report??
    outputName: 'quantity',
    computeFormula: '1',
    lineCombination: 'sum'
  },
  {
    inputName: 'unitPrice',
    outputName: 'priceOriginal',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: 'paidPrice',
    outputName: 'priceProduct',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: 'unitPrice',
    outputName: 'revenueGross',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: 'sellerDiscountTotal',
    outputName: 'sellerDiscountTotal',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: 'shippingFee',
    outputName: 'shippingFee',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  }
]
