import { generateGrid } from '../../../../../../helpers/cssgrid/index.js'
import { makeColumns } from './make-columns.js'
import { makeRows } from './make-rows.js'
import { prepareSettingsTable } from './prepare-settings-table.js'

let settingTableFilters = {}

export function showPlatformSettingsTable (options) {
  const table = prepareSettingsTable()

  const rowDefs = makeRows(table.fieldsMap)

  if (options.all) {
    settingTableFilters = {}
  }

  if (options?.country) {
    settingTableFilters.country = options?.country
  }
  if (options?.platform) {
    settingTableFilters.platform = options?.platform
  }
  const colDefs = makeColumns(table.settings, settingTableFilters)

  generateGrid({
    containerElementId: 'commerce',
    colDefs,
    rowDefs,
    showActions: true
  })
}
