import { cohortSettings } from '../data.js'
import { detectDataSource } from '../utilities/detect.js'
import { prepareShopeeData } from './shopee.js'

export function executeDataPreparation (records) {
  const foundSource = detectDataSource(records.columns)
  console.log('foundSource', foundSource)

  if (foundSource.source === 'Shopee') {
    const orderRecords = prepareShopeeData(records)
    cohortSettings.records = orderRecords
  }
}
