import { getInputValue } from '../../utilities/generate-record-with-settings/get-record-input-value.js'
import { parseDataset } from '../../utilities/parse-dataset.js/parse-dataset.js'
import { feedbackOnFile } from './feedback-of-file.js'

export function executeParseDataset (dataset, fieldsMapping, moduleSettings) {
  const parsingOptions = {
    fieldsMapping,
    combineLines: false,
    // combineLines: true,
    context: moduleSettings
  }
  const data = parseDataset(dataset, parsingOptions)

  const fieldsReview = checkExpectedFields(dataset, fieldsMapping)

  feedbackOnFile(data)

  return {
    data,
    fieldsReview
  }
}

/**
 *
 * @param {*} dataset
 * @param {*} fieldsMapping
 */
function checkExpectedFields (dataset, fieldsMapping) {
  const checkedFields = new Map()
  const errors = new Set()
  const successColumns = new Set()

  fieldsMapping?.forEach(function (fieldMapping) {
    const targetPropertyName = fieldMapping.inputName || fieldMapping.propertyTarget

    if (!targetPropertyName) return

    const found = getInputValue(dataset[0], targetPropertyName)
    checkedFields.set(targetPropertyName, found)
    if (found === undefined) {
      errors.add(targetPropertyName)
    } else {
      successColumns.add(targetPropertyName)
    }
  })

  console.log('checkExpectedFields', checkedFields)
  if (errors.size > 0) {
    console.warn('missing potentially expected columns', errors)
  }

  return {
    numberErrors: errors.size,
    numberSuccess: successColumns.size
  }
}
