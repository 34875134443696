import { max, min, sum } from 'd3'
import { formatNumber } from '../../../../../helpers/helpers/numbers.js'

export function feedbackOnFile (dataset) {
  console.log('Lines dropped', dataset.length)
  console.log('createdAt range', findMin(dataset, 'createdAt'), findMax(dataset, 'createdAt'))
  console.log('settledAt range', findMin(dataset, 'settledAt'), findMax(dataset, 'settledAt'))
  console.log('revenueGross total', findTotal(dataset, 'revenueGross'))
  console.log('revenueNet total', findTotal(dataset, 'revenueNet'))
  console.log('settlementAmount total', findTotal(dataset, 'settlementAmount'))
}

function findMin (records, propertyName) {
  return min(records, function (record) { return record[propertyName] })
}
function findMax (records, propertyName) {
  return max(records, function (record) { return record[propertyName] })
}
function findTotal (records, propertyName) {
  const total = sum(records, function (record) { return record[propertyName] })
  return formatNumber(total, 2)
}
