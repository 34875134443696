import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js'
import { launchLogger } from '../logging/pino.logger.js'

dayjs.extend(isSameOrBefore)

const logger = launchLogger('/dates/make-date-serie.js')
logger.silence()
logger.debug(['loaded'])

/**
 * Input date strings (or Date)
 * [From]
 * [To]:
 *    allowFuture? default true // Not implemented
 *    forceToToday or untilTodayAtLeast: default false // Not implemented
 *    allowEndInPast: default true: false to force to today? // Not implemented
 *
  * Output:
 *  outputFormat:
 *    jsDate,
 *    String: eg. YYYY-MM-DD or YYYY-MM,
 *    SOON: ranges: [YYYY-MM-DD, YYYY-MM-DD]

 *
 * Generate monthly series
 * Generate statement periods: monthly, quarterly, financial year
 * @param {String|Date|null} [from] Defaults to today
 * @param {String|Date|null} [to] Defaults to today
 * @param {String|null} [unit=month] Defaults to month
 * @param {Object} [options]
 */
export function makeDateSerie (from, to, unit, options) {
  logger.debug(['makeDateSerie', from, to])

  let _unit = unit
  if (!unit || !Object.keys(settings).includes(unit)) {
    // Default to month
    _unit = 'month'
  }

  const outputFormat = options?.outputFormat || settings[_unit].outputFormat

  const fromDay = dayjs(from)
  const toDay = dayjs(to)

  let runningDate = fromDay.isValid() ? fromDay : dayjs()
  const toDate = toDay.isValid() ? toDay : dayjs()
  logger.debug(['runningDate', runningDate, 'toDate', toDate])

  const dateserie = []

  while (runningDate.isSameOrBefore(toDate)) {
    dateserie.push(makeOutputFormat(runningDate, outputFormat))

    runningDate = nextDate(runningDate, _unit)
  }

  return dateserie
}

/**
 * Accepted units and their default settings
 *  day,
 *  week: from initial day? or from Monday/another day of week only
 *  month: returns YYYY-MM
 *  quarter: converts to 3 months
 *  halfyear: converts to 6 months
 *  year
 *
 */
const settings = {
  day: {
    outputFormat: 'YYYY-MM-DD'
  },
  week: { outputFormat: 'YYYY-MM-DD' },
  month: { outputFormat: 'YYYY-MM' },
  quarter: {
    outputFormat: 'YYYY-MM',
    add: [3, 'month']
  },
  halfyear: {
    outputFormat: 'YYYY-MM',
    add: [12, 'month']
  },
  year: { outputFormat: 'YYYY-MM' }
}

function makeOutputFormat (date, format) {
  let formatted
  if (format === 'jsDate') {
    formatted = date.toDate()
  } else if (typeof format === 'string') {
    formatted = date.format(format)
  }

  return formatted
}

function nextDate (runningDate, unit) {
  const addNumber = settings[unit].add?.[0] || 1
  const addUnit = settings[unit].add?.[1] || unit

  const updatedDate = runningDate.add(addNumber, addUnit)

  return updatedDate
}
