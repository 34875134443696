// Dependencies
import * as mArrays from '../../../helpers/data/arrays.js'
import * as cssGrid from '../../../helpers/cssgrid/index.js'

// Module
import * as mFinancialsHelpers from '../../helpers/helpers.js'

export function showEliminations (params) {
  console.log('showEliminations', params)

  // For debug
  debugDBs(params)
  //

  showEliminationEntries(params)
}

function debugDBs (params) {
  const showDebug = false
  if (!showDebug) return

  const aggregationDB = params.fs3Data.aggregates
  const transactionsDB = params.fs3Data.transactions

  const dbConso = aggregationDB.filter(function (oneAggregationData) {
    return oneAggregationData.org === 'conso'
  })
  console.log('db conso', dbConso)

  const dbEliminations = aggregationDB.filter(function (oneAggregationData) {
    return oneAggregationData.org === 'eliminations'
  })
  console.log('db eliminations', dbEliminations)

  const transactionsEliminations = transactionsDB.filter(function (oneTransaction) {
    return oneTransaction.org === 'eliminations'
  })
  console.log('db transactions eliminations', transactionsEliminations)
}

/**
 *
 * @param {*} params
 */
function columnSettings (params) {
  const settings = []

  settings.push({
    id: 'date',
    value: ' ',
    layout: 'left',
    width: 30,
    valueGetter: function (cellParams) {
      if (!cellParams.rowDef.children) return null
      return cellParams.rowDef.id
    }
  })

  settings.push({
    id: 'account',
    value: ' ',
    layout: 'left',
    width: 200,
    valueGetter: function (cellParams) {
      if (cellParams.rowDef.children) return null

      const targetAccount = params.fs3Data.accounts.find(function (oneAccount) {
        return oneAccount.id === cellParams.rowDef.data?.transaction?.account
      })

      return targetAccount?.name
    }
  })

  settings.push({
    id: 'dateValue',
    value: 'Value on Date',
    children: [{
      id: 'dateValueDebit',
      value: 'Debit',
      width: 100,
      styles: ['numberColumns'],
      valueGetter: function (cellParams) {
        if (cellParams.rowDef.children) return null
        const transactionAmount = cellParams.rowDef?.data?.transaction?.amount
        return returnDebitCredit(transactionAmount, 'debit')
        // if (transactionAmount < 0) return 0
        // return transactionAmount
      },
      valueFormatter: function (cellParams) {
        return mFinancialsHelpers.contentValueFormatter({ cellParams })
      },
      valueStyling: function (cellParams) {
        return mFinancialsHelpers.contentValueStyling({ cellParams })
      }
    },
    {
      id: 'dateValueCredit',
      value: 'Credit',
      width: 100,
      styles: ['numberColumns'],
      valueGetter: function (cellParams) {
        if (cellParams.rowDef.children) return null
        const transactionAmount = cellParams.rowDef?.data?.transaction?.amount
        return returnDebitCredit(transactionAmount, 'credit')
        // if (transactionAmount > 0) return 0
        // return -transactionAmount
      },
      valueFormatter: function (cellParams) {
        return mFinancialsHelpers.contentValueFormatter({ cellParams })
      },
      valueStyling: function (cellParams) {
        return mFinancialsHelpers.contentValueStyling({ cellParams })
      }
    }]
  })

  // Cumul values
  settings.push({
    id: 'dateCumul',
    value: 'Cumul to Date',
    children: [{
      id: 'dateCumulDebit',
      value: 'Debit',
      width: 100,
      styles: ['numberColumns'],
      valueGetter: function (cellParams) {
        if (cellParams.rowDef.children) return null
        if (!cellParams.rowDef.data) return
        const targetDate = cellParams.rowDef._parent.id
        const dateIndex = cellParams.rowDef.data.dateseries?.indexOf(targetDate)
        const valueCumul = cellParams.rowDef.data.aggregation.data.cumuls[dateIndex]
        return returnDebitCredit(valueCumul, 'debit')
      },
      valueFormatter: function (cellParams) {
        return mFinancialsHelpers.contentValueFormatter({ cellParams })
      },
      valueStyling: function (cellParams) {
        return mFinancialsHelpers.contentValueStyling({ cellParams })
      }
    },
    {
      id: 'dateCumulCredit',
      value: 'Credit',
      width: 100,
      styles: ['numberColumns'],
      valueGetter: function (cellParams) {
        if (cellParams.rowDef.children) return null
        if (!cellParams.rowDef.data) return
        const targetDate = cellParams.rowDef._parent.id
        const dateIndex = cellParams.rowDef.data.dateseries?.indexOf(targetDate)
        const valueCumul = cellParams.rowDef.data.aggregation.data.cumuls[dateIndex]
        return returnDebitCredit(valueCumul, 'credit')
      },
      valueFormatter: function (cellParams) {
        return mFinancialsHelpers.contentValueFormatter({ cellParams })
      },
      valueStyling: function (cellParams) {
        return mFinancialsHelpers.contentValueStyling({ cellParams })
      }
    }]
  })
  return settings
}

function returnDebitCredit (amount, type) {
  const signs = {
    debit: 1,
    credit: -1
  }
  if (!signs[type]) return

  return Math.abs((Math.sign(signs[type]) === Math.sign(amount)) * amount)
}

/**
 * Rows: for every date within the selected period
 * @param {*} params
 */
function rowSettings (params) {
  console.log('rowSettings', params)
  const { optionPeriods } = params

  const aggregationDB = params.fs3Data.aggregates
  const transactionsDB = params.fs3Data.transactions

  const transactionsEliminations = transactionsDB.filter(function (oneTransaction) {
    if (!(oneTransaction.org === 'eliminations')) return false

    const isMinDateOk = !optionPeriods.min || (optionPeriods.min <= oneTransaction.date)
    const isMaxDateOk = !optionPeriods.max || (oneTransaction.date <= optionPeriods.max)
    const isDateOk = isMinDateOk && isMaxDateOk
    if (!isDateOk) return false
    return true
  })

  const transactionsByDate = mArrays.groupBy(transactionsEliminations, 'date')
  // console.log('transactionsByDate', transactionsByDate)

  const rows = []

  Object.keys(transactionsByDate)?.forEach(function (oneTransactionDate) {
    // console.log('transactionsByDate', oneTransactionDate, transactionsByDate[oneTransactionDate])

    const row = {
      id: oneTransactionDate,
      showChildren: true
    }

    // Children
    const children = []
    transactionsByDate[oneTransactionDate].forEach(function (oneTransaction) {
      const aggregation = aggregationDB.find(function (oneAggregationData) {
        return (
          oneAggregationData.org === 'eliminations' &&
          oneTransaction.account === oneAggregationData.account
        )
      })

      children.push({
        id: oneTransaction.date + '::' + oneTransaction.account,
        data: {
          transaction: oneTransaction,
          aggregation,
          dateseries: params.fs3Data.dateseries
        }
      })
    })

    row.children = children
    rows.push(row)
  })

  // Chronological order
  rows.reverse()

  return rows
}

/**
 *
 * @param {*} params
 */
function showEliminationEntries (params) {
  const { containerElementId, classes } = params

  cssGrid.generateGrid({
    colDefs: columnSettings(params),
    rowDefs: rowSettings(params),
    containerElementId,
    classes
  })
}
