import { getOrganisationData } from '../../../../modules/user/account.js'
import { baseAccountObj } from '../../base-account-obj.js'

export function makeRows (context) {
  // console.log('makeRows', context)

  const transactionOrigins = new Set()
  const entities = new Set()
  const entitiesNames = new Set()
  context.data?.cashTransactions?.forEach(function (cashTransaction) {
    transactionOrigins.add(cashTransaction.origin)
    entities.add(cashTransaction.entityId)

    const entity = getOrganisationData(cashTransaction.entityId)
    entitiesNames.add(entity.name)
  })
  console.log('transactionOrigins', transactionOrigins)
  console.log('entities', entities)
  console.log('entitiesNames', entitiesNames)

  const excludedOrigins = ['tbDeltaFromTB', 'tbDeltaFromJournal']

  const filtered = context.data?.cashTransactions?.filter(function (cashTransaction) {
    const value = cashTransaction.amount
    const isWithValue = (Number.isFinite(value) && value !== 0)

    const isOriginOk = !excludedOrigins.includes(cashTransaction.origin)
    return isWithValue && isOriginOk
  })
  // console.log('filtered', filtered)

  const rows = filtered?.map(function (cashTransaction) {
    const account = baseAccountObj(context, cashTransaction, { accountIdPropertyName: '_xerodataaccount' })
    // console.log(cashTransaction, account)
    return {
      value: cashTransaction,
      account
    }
  })
  // console.log('rows', rows)

  return rows
}
