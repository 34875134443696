import { ascending } from 'd3'
import { groupByProperties } from '../../../../helpers/data/array-of-objects/group-by-properties.js'
import { makeDateSerie } from '../../../../helpers/dates/make-date-serie.js'
import { isMap } from '../../../../helpers/helpers/check-types.js'
import { getCellValue } from './get-cell-value.js'

/**
 * NEED TO GROUP BY CONTACT:
 * Contact > Accounts
 * D3 group by Contact and Account
 * Then find the rows which have values
 * @param {*} tbLedgerData
 * @returns
 */
export function tableRows (tbLedgerData, context) {
  const rowsMap = groupByProperties(tbLedgerData.ledger.records, ['contact.name', 'account.name'])

  const rowSettings = makeRows(rowsMap, context)
  return rowSettings
}

function makeRows (map, context) {
  const dateserie = makeDateSerie(context.minDate)

  const rowSettings = []
  for (const [mapKey, mapValue] of map) {
    const rowSetting = {
      id: mapKey || 'Unknown'
    }

    if (isMap(mapValue)) {
      rowSetting._showChildren = true
      rowSetting.children = makeChildren(mapKey, mapValue, dateserie, context)

      const maxTotal = rowSetting.children.reduce(function (accumulator, child) {
        return Math.max(accumulator, child.infos.total)
      }, 0)
      rowSetting.maxTotal = maxTotal
    }

    if (rowSetting.children.length > 0) rowSettings.push(rowSetting)
  }
  sortChildren(rowSettings)
  return rowSettings
}

function sortChildren (rowSettings) {
  rowSettings.sort(function (r1, r2) {
    return ascending(r2.maxTotal, r1.maxTotal)
  })
}

function makeChildren (mapKey, mapValue, dateserie, context) {
  const children = []
  for (const [childKey, childValue] of mapValue) {
    const child = makeChild(childKey, childValue, dateserie, context)
    if (child) children.push(child)
  }

  // Sort
  children.sort(function (c1, c2) {
    // return ascending(c1.infos.lastValue, c2.infos.lastValue)
    return ascending(c1.infos.total, c2.infos.total)
  })

  return children
}

function makeChild (mapKey, mapValue, dateserie, context) {
  const childValues = []
  dateserie.forEach(function (oneColumnDate) {
    const cellValue = getCellValue(mapValue, oneColumnDate, {
      targetProperty: context.targetValueProperty,
      significantDecimals: context.data.tbLedgerData.ledger.decimals
    })
    childValues.push(cellValue)
  })
  const cellsWithValue = childValues.reduce(function (accumulator, value) {
    const cellPass = Math.abs(value) > 0
    return accumulator + (cellPass ? 1 : 0)
  }, 0)

  if (cellsWithValue === 0) return null

  const total = childValues.reduce(function (accumulator, value) {
    return accumulator + value
  }, 0)

  const child = {
    id: mapKey,
    data: mapValue,
    infos: {
      cellsWithValue,
      total,
      lastValue: childValues[childValues.length - 1]
    }
  }
  return child
}
