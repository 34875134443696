import { showElement } from '../../../../helpers/dom.js'
import { choiceCountry } from './choose-country.js'
import { choicePlatform } from './choose-platform.js'

export function showDropFiles () {
  if (!choicePlatform.chosenPlatform) return
  if (!choiceCountry.chosenCountry) return

  showElement('step2')
}

export function showActions () {
  showElement('step3')
}
