import { xeroSalesInvoiceProperties } from './sales-invoices-settings.js'

export function makePaymentLine (invoiceRows, invoiceValues) {
  const totalInvoiceAmount = invoiceRows.reduce(function (accumulator, row) {
    const lineAmount = (row['*Quantity'] * row['*UnitAmount']) || 0
    return accumulator + lineAmount
  }, 0)

  const invoiceLine = {}

  xeroSalesInvoiceProperties.forEach(function (invoiceProperty) {
    if (invoiceProperty.input) {
      invoiceLine[invoiceProperty.outputProperty] = invoiceValues[invoiceProperty.input]
    }
    if (invoiceProperty.outputProperty === 'InventoryItemCode') invoiceLine[invoiceProperty.outputProperty] = null

    if (invoiceProperty.outputProperty === '*Description') {
      const instructions = ['Offset Net Revenue against the Balance Sheet account of cash received from platform']
      invoiceLine[invoiceProperty.outputProperty] = instructions.join('\n')
    }
    if (invoiceProperty.outputProperty === '*Quantity') invoiceLine[invoiceProperty.outputProperty] = 1
    if (invoiceProperty.outputProperty === '*UnitAmount') invoiceLine[invoiceProperty.outputProperty] = -totalInvoiceAmount
    if (invoiceProperty.outputProperty === '*AccountCode') invoiceLine[invoiceProperty.outputProperty] = document.getElementById('receivedCash.xeroAccountCode').value
  })
  return invoiceLine
}
