import * as d3 from 'd3'

// App config
import * as configVar from '../../config/config.js'

import * as mUser from '../../modules/user/user.js'

import * as cssGrid from '../../../helpers/cssgrid/index.js'
import { generateDateseries, restrictDateseries } from '../../../helpers/helpers/dateseries.js'
import { optionSelectorData } from '../../modules/option-selector/data.js'
import { generateColumnDefs } from './grid-columns.js'
import { generateRowDefs } from './grid-rows.js'

import htmlIndexTemplate from './schedules.html'
import { activateOptionSelector } from '../../modules/option-selector/index.js'
import { hideElement, setElementHtml } from '../../helpers/dom.js'
import { computeGroups, computeSortedGroups, densifyRecordGroups } from './records.js'
import { loadScheduleData } from './load.js'

let gridInstance
const schedulesDataDB = {}

export async function showSchedule (params) {
  console.log('showSchedule', params)

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  // Ensure the page content element is available
  const DOMelement = setElementHtml(configVar.contentElementId, htmlIndexTemplate)
  if (!DOMelement) return

  schedulesDataDB.schedulesData = await loadScheduleData(params)
  schedulesDataDB.settings = computeDateseries(schedulesDataDB.schedulesData)
  schedulesDataDB.params = params

  executeShowGrid()
  setOptionSelector()
  hideElement('pageLoadingMessage')
}

function setOptionSelector () {
  const optionSelectorHide = []
  optionSelectorHide.push('periodicity')
  optionSelectorHide.push('dates') // The date values bug :(
  // optionSelectorHide.push('valueAggregation')
  optionSelectorHide.push('organisations')
  optionSelectorHide.push('gridLayout')
  optionSelectorHide.push('currencies')
  optionSelectorHide.push('forecasting')

  activateOptionSelector({
    containerId: 'optionSelector',
    hide: optionSelectorHide,
    onChange: executeShowGrid,
    dateseries: schedulesDataDB.settings.dateseriesView,
    aggregationMethods: ['period', 'balance']
  })
}

function executeShowGrid () {
  showScheduleGrid({ schedulesDataDB })
}

function computeDateseries (schedulesData) {
  const dataDateseries = new Set()
  Object.values(schedulesData).forEach(function (one) {
    dataDateseries.add(one._id.date)
  })

  const dateseriesView = Array.from(dataDateseries).sort(function (a, b) {
    return d3.ascending(a, b)
  })
  console.log('dateseriesView', dateseriesView)

  const densified = generateDateseries({
    min: dateseriesView[0],
    max: dateseriesView[dateseriesView.length - 1],
    untilTodayAtLeast: true
  })
  console.log('densified', densified)

  return {
    dateseriesView: densified
  }
}

/**
 *
 * @param {*} params
 * @returns
 */
function showScheduleGrid (params) {
  console.log('showScheduleGrid', params)
  const containerElementId = 'scheduleGrid'

  const columnsLayout = optionSelectorData.layout

  const context = {
    schedulesData: params.schedulesDataDB.schedulesData,
    params: params.schedulesDataDB.params
  }

  const restrictedDateseries = restrictDateseries({
    dateseries: params.schedulesDataDB.settings.dateseriesView,
    optionPeriods: optionSelectorData.dates
  })

  const groupedRecords = computeGroups(params.schedulesDataDB.schedulesData)
  densifyRecordGroups(groupedRecords, {
    min: restrictedDateseries[0],
    max: restrictedDateseries[restrictedDateseries.length - 1]
  })
  const sortedGroups = computeSortedGroups(params.schedulesDataDB.schedulesData, groupedRecords, restrictedDateseries)

  gridInstance = cssGrid.generateGrid({
    colDefs: generateColumnDefs({
      columnsLayout,
      dateseriesView: restrictedDateseries
    }),
    rowDefs: generateRowDefs(sortedGroups),
    containerElementId,
    context,
    // classes,
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  return gridInstance
}
