import { fsVersion } from '../../config/config.js'
import * as mData from './data.js'

export function debug (context) {
  if (fsVersion === 'fs3') return

  // Debug:
  const aggregations = mData.db.data.aggregationDB.filter(function (oneAggregation) {
    if (oneAggregation.currencySymbol === context.currency) return true

    return false
  })
  console.log('aggregations for currency', aggregations)

  // Debug: account
  const debugAccountId = '63919203b7511d1f00c34640'
  const targetAggregations = mData.db.data.aggregationDB.filter(function (oneAggregation) {
    if (oneAggregation.account._id === debugAccountId) return true

    return false
  })
  console.log('aggregations for account', targetAggregations)
  targetAggregations.forEach(function (oneAggregation) {
    console.log('oneAggregation', oneAggregation)
    const transactions = mData.db.data.transactionsDB.filter(function (oneTransaction) {
      const isTargetAccount = (oneTransaction.account._id === debugAccountId)
      if (!isTargetAccount) return false

      const isTargetOrg = (oneTransaction.org.id === oneAggregation.org.id)
      if (!isTargetOrg) return false

      const isTargetCurrency = (oneTransaction.currencySymbol === oneAggregation.currencySymbol)
      if (!isTargetCurrency) return false

      return true
    })
    console.log('transactions', oneAggregation.currencySymbol, oneAggregation.org.id, transactions)
  })
  //
}
