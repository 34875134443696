import { parseDateString } from '../../../../utilities/parse-date-string.js'
import { parseNumber } from '../../../utilities/parse-number.js'

/**
 * Basics: Which property to map the fields from source data to parsed object
 * inputName
 * outputName
 *
 * Value to consider:
 * parseFunction(): function to execute
 * If no function: the value of the inputName property is used
 *
 * How to combine the orders lines
 * lineCombinationId: the field to use as aggregation Key
 *
 * lineCombination:
 * - 'first': record the first value (TODO: detection of mismatch?)
 * - 'sum': number
 * - 'records': array of values
 * - don't combine
 *
 * TODO:
 * quantity?
 * price to consider?
 *
 */

export const shopeeOrdersFieldsMapping = [
  {
    inputName: 'หมายเลขคำสั่งซื้อ',
    outputName: 'orderId',
    lineCombination: 'first',
    lineCombinationId: true
  },
  {
    outputName: 'recordType',
    parseFunction: function () {
      return 'orderLine'
    }
  },
  {
    outputName: 'orderPlatform',
    parseFunction: function (record, context) {
      return context?.platform || undefined
    }
  },
  {
    outputName: 'orderCountry',
    parseFunction: function (record, context) {
      return context?.country || undefined
    }
  },
  {
    outputName: 'orderLines',
    lineCombination: 'records'
  },
  {
    inputName: 'เลขอ้างอิง SKU (SKU Reference No.)',
    outputName: 'SKU_id'
  },
  {
    inputName: 'ชื่อผู้ใช้ (ผู้ซื้อ)',
    outputName: 'buyerUsername',
    lineCombination: 'first'
  },
  {
    inputName: 'สถานะการสั่งซื้อ',
    outputName: 'orderStatus'
  },
  {
    inputName: 'วันที่ทำการสั่งซื้อ',
    outputName: 'createdAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'YYYY-MM-DD HH:mm' })
    },
    lineCombination: 'first'
  },
  {
    inputName: 'เวลาที่ทำการสั่งซื้อสำเร็จ',
    outputName: 'completedAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'YYYY-MM-DD HH:mm' })
    },
    lineCombination: 'first'
  },
  {
    inputName: 'จำนวน',
    outputName: 'quantity',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: 'ราคาตั้งต้น',
    outputName: 'priceOriginal',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: ['ราคาขาย', 'ราคาขายสุทธิ'],
    outputName: 'priceProduct',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: 'จำนวนเงินทั้งหมด',
    outputName: 'revenueGross',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: 'ค่าธรรมเนียม (%)',
    outputName: 'transactionFee',
    parseFunction: function (value) {
      const parsedNumber = parseNumber(value)
      if (Number.isFinite(parsedNumber)) {
        return parsedNumber / 100
      }
      return parsedNumber
    }
  }
]
