import { callApi } from '../../../helpers/fetch.js'

export async function enableSupport (accountId) {
  await createSupportInvite(accountId)

  // Activate invite? Not needed for existing users... and support is an existing user
  // /api/invite/accept/:inviteId
}

async function createSupportInvite (accountId) {
  const data = {
    isSupport: true,
    accountId
  }
  const apiUrl = '/api/invite/create'
  const response = await callApi(apiUrl, null, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  console.log(response)
}

export async function disableUser (inviteId) {
  const apiUrl = '/api/invite/:inviteId'
  const data = await callApi(apiUrl, {
    inviteId
  }, {
    method: 'DELETE'
  })

  console.log(data)
}
