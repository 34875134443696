
/**
 * @param {*} cellParams
 * @returns
 */
export function getCellValue (cellParams) {
  // console.log('getCellValue', cellParams)

  const calculationDecimals = cellParams.gridSettings.context.data.calculationDecimals
  let baseCellValue = cellParams.rowDef.value?.amount
  // console.log('baseCellValue', baseCellValue, calculationDecimals)

  //  Make the values Credit positive
  const multiplierFromDebitPositive = 1
  baseCellValue = multiplierFromDebitPositive * baseCellValue

  const cellValue = applyCalculationDecimals(baseCellValue, calculationDecimals)
  // console.log('cellValue', cellValue)

  return cellValue
}

function applyCalculationDecimals (baseCellValue, calculationDecimals) {
  let cellValue = baseCellValue

  const _calculationDecimals = Number.isFinite(calculationDecimals) ? calculationDecimals : 0
  const valueMultiplier = Math.pow(10, _calculationDecimals)

  cellValue = cellValue / valueMultiplier

  return cellValue
}
