
// App config
import { mapArrayProperties } from '../../../helpers/data/array-of-objects/mapped-array.js'
import { valueSet } from '../../../helpers/data/array-of-objects/value-set.js'
import * as configVar from '../../config/config.js'
import { callApi } from '../../helpers/fetch.js'
import * as mUser from '../../modules/user/user.js'

// Page Module
import htmlIndex from './index.html'
import { receivablesSettings, showMenu } from './menu.js'
import { showReceivablesTable } from './receivables-table.js'

export async function showReceivables (params) {
  console.log('module show')
  const { accountId, orgId } = params

  // If login required
  if (!mUser.isUserLoggedIn()) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Create the page DOM Element:

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const receivablesResponse = await loadReceivables(accountId, orgId)

  console.log(receivablesResponse)

  const receivables = receivablesResponse.receivables

  const enrichedReceivables = enrichReceivables(receivables)
  receivablesSettings.enrichedReceivables = enrichedReceivables
  const months = valueSet(enrichedReceivables, 'expectedMonth', { sort: true })
  console.log('months', months)
  receivablesSettings.months = months

  receivablesSettings.orgId = orgId

  showMenu()
  showReceivablesTable()
}

function enrichReceivables (receivables) {
  const properties = [{
    name: 'expectedMonth',
    accessor: false,
    manipulation: function (record) {
      let date = new Date()
      if (record.potentialPaymentDate) {
        date = new Date(record.potentialPaymentDate)
      } else if (record.dueDate) {
        date = new Date(record.dueDate)
      }
      // console.log('date', date)
      return date.toJSON().substring(0, 7)
    }
  }, {
    name: 'localValue',
    accessor: false,
    manipulation: function (record) {
      return record.amountDue / (record.currencyRate)
    }
  }]
  const enriched = mapArrayProperties(receivables, properties, { keepAllProperties: true })
  console.log(enriched)
  return enriched
}

async function loadReceivables (accountId, orgId) {
  const url = '/api/xero/a/:accountId/receivables/:orgId'
  const data = await callApi(url, { accountId, orgId })
  return data
}
