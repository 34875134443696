
export const xeroPurchaseBillProperties = [
  {
    outputProperty: '*ContactName',
    input: 'contactName'
  },
  {
    outputProperty: '*InvoiceDate',
    input: 'invoiceDate'
  },
  {
    outputProperty: '*DueDate',
    input: 'dueDate'
  },
  {
    outputProperty: '*InvoiceNumber',
    input: 'invoiceNumber'
  },
  {
    outputProperty: '*Description',
    input: 'description'
  },
  {
    outputProperty: '*Quantity',
    input: 'quantity'
  },
  {
    outputProperty: '*UnitAmount',
    input: 'amount'
  },
  {
    outputProperty: '*AccountCode',
    input: 'accountCode'
  },
  {
    outputProperty: '*TaxType',
    input: 'taxType'
  }

  // Currency
  // TrackingName1
  // TrackingOption1
]
