import { indexArray } from '../../../helpers/data/array-index.js'
import { launchLogger } from '../../../helpers/logging/pino.logger.js'
import { indexedAccountTypes } from '../../financials-3/finance/settings-account-types.js'
import { addRecordProperties } from '../layouts/generate/add-properties.js'

const logger = launchLogger('finlogic /layouts/generate/utils')
// logger.silence()
logger.debug(['loaded'])

function addAccountTypeDataToRecord (records) {
  const recordIdToData = 'account.type'
  addRecordProperties(records, indexedAccountTypes, [
    {
      recordIdToData,
      dataAccessProperty: 'expectedSign',
      outputProperty: 'account.expectedSign'
    }, {
      recordIdToData,
      dataAccessProperty: 'viewSign',
      outputProperty: 'account.viewSign'
    }
  ])
}

export function addAccountDataToRecord (records, accounts, recordIdToData) {
  const indexedAccounts = indexArray(accounts, 'id')
  addRecordProperties(records, indexedAccounts, [
    {
      recordIdToData,
      dataAccessProperty: 'id',
      outputProperty: 'account.id'
    }, {
      recordIdToData,
      dataAccessProperty: 'code',
      outputProperty: 'account.code'
    }, {
      recordIdToData,
      dataAccessProperty: 'name',
      outputProperty: 'account.name'
    }, {
      recordIdToData,
      dataAccessProperty: 'type',
      outputProperty: 'account.type'
    }, {
      recordIdToData,
      dataAccessProperty: 'class',
      outputProperty: 'account.class'
    }, {
      recordIdToData,
      dataAccessProperty: 'statement',
      outputProperty: 'account.statement'
    }
  ])

  addAccountTypeDataToRecord(records)
}

export function addContactDataToRecord (records, contacts, recordIdToData) {
  const indexedContacts = indexArray(contacts, 'id')
  addRecordProperties(records, indexedContacts, [
    {
      recordIdToData,
      dataAccessProperty: 'id',
      outputProperty: 'contact.id'
    }, {
      recordIdToData,
      dataAccessProperty: 'name',
      outputProperty: 'contact.name'
    }
  ])
}

export function addTrackingDataToRecord (records, trackingX, recordIdToData, trackingName) {
  logger.debug(['addTrackingDataToRecord', trackingX])
  const indexedTrackings = indexArray(trackingX, 'id')
  // logger.debug(['indexedTrackings', indexedTrackings])
  addRecordProperties(records, indexedTrackings, [
    {
      recordIdToData,
      dataAccessProperty: 'id',
      outputProperty: [trackingName, 'id'].join('.')
    }, {
      recordIdToData,
      dataAccessProperty: 'name',
      outputProperty: [trackingName, 'name'].join('.')
    }
  ])
}
