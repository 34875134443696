import { roundNumber } from '../../../helpers/helpers/numbers.js'
import { optionSelectorData } from '../../modules/option-selector/data.js'
import { propertyForMethod } from './records.js'

const debugMonth = false // '2020-07'

export function executeValueGetter (cellParams) {
  if (cellParams.rowDef.isContactRow) return contactRow(cellParams)

  return totalRow(cellParams)
}

/**
 *
 * @param {*} cellParams
 * @returns
 */
function contactRow (cellParams) {
  // console.log('cellParams', cellParams)
  const month = cellParams.columnDef.id

  const records = cellParams.rowDef.params.records
  // console.log('records', records.length, records)

  const recordValue = monthRecordValue(records, month)

  return recordValue
}

/**
 *
 * @param {*} cellParams
 * @returns
 */
function totalRow (cellParams) {
  const month = cellParams.columnDef.id
  const sortedGroups = cellParams.rowDef.params.sortedGroups

  let total = 0

  for (const oneEntry of sortedGroups.entries()) {
    const records = oneEntry[1]
    const recordValue = monthRecordValue(records, month)

    total += recordValue

    if (month === debugMonth) {
      console.log(recordValue, total)
    }
  }
  return roundNumber(total, 2)
}

/**
 *
 * @param {*} records
 * @param {*} month
 * @returns
 */
function monthRecordValue (records, month) {
  if (month === debugMonth) {
    console.log(debugMonth)
  }
  const match = records?.filter(function (one) {
    const isMonthMatching = one._id.date === month
    if (!isMonthMatching) return false

    return true
  })
  if (month === debugMonth) console.log('match', match.length, match)

  if (match.length > 1) {
    console.warn('!! more than 1?', match)
    return 0
  }

  if (match.length === 0) {
    return 0
  }

  const monthValue = match[0][propertyForMethod[optionSelectorData.viewAggregations.method]]
  return roundNumber(monthValue, 2)
}
