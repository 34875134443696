import { choiceCountry } from '../ui/choose-country.js'
import { choicePlatform } from '../ui/choose-platform.js'
import { analyseSettings } from './analyse/analyse-settings.js'
import { IDshopeeSettings } from './inputs/id-shopee/id-shopee.js'
import { IDtiktokSettings } from './inputs/id-tiktok/tiktok.js'
import { MYLazadaSettings } from './inputs/my-lazada/my-lazada.js'
import { MYshopeeSettings } from './inputs/my-shopee/my-shopee.js'
import { MYtiktokSettings } from './inputs/my-tiktok/tiktok.js'
import { PHLazadaSettings } from './inputs/ph-lazada/ph-lazada.js'
import { PHshopeeSettings } from './inputs/ph-shopee/ph-shopee.js'
import { PHtiktokSettings } from './inputs/ph-tiktok/tiktok.js'
import { SGLazadaSettings } from './inputs/sg-lazada/sg-lazada.js'
import { SGshopeeSettings } from './inputs/sg-shopee/sg-shopee.js'
import { SGtiktokSettings } from './inputs/sg-tiktok/tiktok.js'
import { THLazadaSettings } from './inputs/th-lazada/th-lazada.js'
import { THshopeeSettings } from './inputs/th-shopee/th-shopee.js'
import { THtiktokSettings } from './inputs/th-tiktok/tiktok.js'

export function getSettingsToUse () {
  console.log('getSettingsToUse', choiceCountry?.chosenCountry, choicePlatform?.chosenPlatform)
  const foundSettings = platformsSettings.find(function (one) {
    return (
      one.country === choiceCountry?.chosenCountry?.value &&
      one.platform === choicePlatform?.chosenPlatform?.value
    )
  })
  console.log('foundSettings', foundSettings)
  return foundSettings
}

export const platformsSettings = [
  {
    country: 'ID',
    platform: 'tiktok',
    platformSettings: IDtiktokSettings
  },
  {
    country: 'ID',
    platform: 'shopee',
    platformSettings: IDshopeeSettings
  },
  //
  {
    country: 'TH',
    platform: 'tiktok',
    platformSettings: THtiktokSettings
  },
  {
    country: 'TH',
    platform: 'shopee',
    platformSettings: THshopeeSettings
  },
  {
    country: 'TH',
    platform: 'lazada',
    platformSettings: THLazadaSettings
  },
  //
  {
    country: 'MY',
    platform: 'tiktok',
    platformSettings: MYtiktokSettings
  },
  {
    country: 'MY',
    platform: 'shopee',
    platformSettings: MYshopeeSettings
  },
  {
    country: 'MY',
    platform: 'lazada',
    platformSettings: MYLazadaSettings
  },
  //
  {
    country: 'SG',
    platform: 'tiktok',
    platformSettings: SGtiktokSettings
  },
  {
    country: 'SG',
    platform: 'shopee',
    platformSettings: SGshopeeSettings
  },
  {
    country: 'SG',
    platform: 'lazada',
    platformSettings: SGLazadaSettings
  },
  //
  {
    country: 'PH',
    platform: 'tiktok',
    platformSettings: PHtiktokSettings
  },
  {
    country: 'PH',
    platform: 'shopee',
    platformSettings: PHshopeeSettings
  },
  {
    country: 'PH',
    platform: 'lazada',
    platformSettings: PHLazadaSettings
  }

]

const showSettings = false
function testing () {
  if (!showSettings) return

  analyseSettings()
}

testing()
