
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'

// Page Module
import htmlTemplateIndex from './index.html'
import * as mHtmlParser from '../../modules/user/html-parser.js'

import * as mInviteCreate from './invite-create.js'
import * as mInviteList from './invite-list.js'
import * as mInviteAccept from './invite-accept.js'
import * as mUserList from './user-list.js'
import { hideRenamingForm, renameAccount, showRenamingForm } from './update.js'
import { reloadAccountDetails, showAccountDetails } from './show.js'
import { callApi } from '../../helpers/fetch.js'

/**
 * Expose the sub module function
 * @returns
 */
export async function showInviteAcceptPage (params) {
  console.log('showInviteAcceptPage', arguments)
  return mInviteAccept.show(params)
}

/**
 *
 * @returns
 */
export async function show () {
  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  const accountData = mAccount.accountData()

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })
  contentElement.innerHTML = htmlPageLayout

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showAccountDetails(accountData)
  mUserList.showUserList(accountData)

  document.title = ['Account Info:', accountData.name].join(' ')

  // Invitations:
  if (accountData.myRights?.includes('canManage')) {
    mInviteCreate.showInviteCreation(accountData)
    mInviteList.showInviteList(accountData)
  } else {
    const pageContentTitleElement = document.getElementById('pageContentTitle')
    pageContentTitleElement.innerText = 'Account Info:'
  }

  listeners(DOMelement, accountData)
}

function listeners (DOMelement, accountData) {
  const accountId = accountData.id || accountData._id

  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('#togglePublic')
    if (!clickedElement) return

    const isPublic = clickedElement.getAttribute('d-isPublic')
    togglePublic({ accountId, isPublic })
  })

  // Enable renaming
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('#renameAccount')
    if (!clickedElement) return

    console.log('event to rename')
    showRenamingForm()
  })

  // Cancel renaming
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('#renamingCancel')
    if (!clickedElement) return

    hideRenamingForm()
  })

  // Submit renaming
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('#renamingSubmit')
    if (!clickedElement) return

    renameAccount({ accountId })
  })
}

async function togglePublic (params) {
  console.log('togglePublic', params)
  const { accountId, isPublic } = params

  const apiUrl = '/api/account/:accountId/togglePublic/:isPublic'
  await callApi(apiUrl, {
    accountId,
    isPublic
  }, {
    method: 'PUT'
  })

  reloadAccountDetails(accountId)
}
