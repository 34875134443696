import { fillString } from '../../../helpers/templating/string-template.js'
import { activateMenuCurrencies } from './currencies/options-currencies.js'
import { activateMenuEntities } from './entities/options-organisations.js'
import { activateOptionsDate } from './periods/options-dates.js'

const menuChoices = [
  {
    id: 'dates',
    name: 'Periods',
    isShownByDefault: true,
    launchFunction: activateOptionsDate
  },
  {
    id: 'valueAggregation',
    name: 'Periods'
  },
  {
    id: 'periodicity',
    name: 'Periodicity'
  },
  {
    id: 'forecasting',
    name: 'Forecasting'
  },
  {
    id: 'organisations',
    name: 'Entities',
    isShownByDefault: true,
    launchFunction: activateMenuEntities
  },
  {
    id: 'gridLayout',
    name: 'Layout'
  },
  {
    id: 'currencies',
    name: 'Currencies',
    isShownByDefault: true,
    launchFunction: activateMenuCurrencies
  }
]

/**
 * @param {*} params
 */
export function makeMenu (params) {
  console.log('makeMenu', params)

  const menuContainer = document.getElementById('optionSelectorMenu')
  console.log('menuContainer', menuContainer)

  const menuHtml = []
  menuHtml.push('<div class="menu">')
  menuHtml.push('<p class="menu-label">Options</p>')
  menuHtml.push('<ul class="menu-list">')

  menuChoices.forEach(function (menuChoice) {
    if (!shouldShowMenu(params, menuChoice)) return

    menuChoice.shown = true

    const baseHtml = '<li><a menu-id=":id">:name</a></li>'
    const choiceHtml = fillString(baseHtml, {
      id: menuChoice.id,
      name: menuChoice.name || menuChoices.id
    })

    menuHtml.push(choiceHtml)
  })

  menuHtml.push('</ul>')
  menuHtml.push('</div>')

  menuContainer.innerHTML = menuHtml.join('')

  addListeners(params)
  openDefault(params)
}

function shouldShowMenu (params, menuChoice) {
  if (params?.hide?.includes(menuChoice.id)) return false
  if (params?.show?.includes(menuChoice.id)) return true
  if (menuChoice?.isShownByDefault) return true
}

function openDefault (params) {
  if (params.default) {
    openMenu(params, params.default)
    return
  }

  const menuChoice = menuChoices.find(function (choice) {
    return choice.shown === true
  })
  if (!menuChoice) return

  openMenu(params, menuChoice.id)
}

function openMenu (params, menuId) {
  const menuChoice = findChoice(menuId)

  menuChoice?.launchFunction?.(params)
}

/**
 *
 * @param {*} params
 */
function addListeners (params) {
  const menuContainer = document.getElementById('optionSelectorMenu')
  menuContainer.addEventListener('click', function (clickEvent) {
    const targetElement = clickEvent.target.closest('a')
    if (!targetElement) return

    const menuId = targetElement.getAttribute('menu-id')
    openMenu(params, menuId)
  })
}

function findChoice (menuId) {
  const menuChoice = menuChoices.find(function (choice) {
    return choice.id === menuId
  })
  return menuChoice
}
