import { csvParse } from 'd3'

export async function readCsvFile (file) {
  const reader = new FileReader()

  if (file) {
    reader.readAsText(file)
  }

  return new Promise(function (resolve) {
    function onFileReady () {
      const fileContent = reader.result
      // console.log(fileContent)

      const records = csvParse(fileContent)

      resolve(records)
    }

    reader.addEventListener('load', onFileReady, false)
  })
}
