import { combineMultilinesItems } from '../../../utilities/multilines-item-files/combine-multilines-items.js'

export function prepareLazadaFile (datasetRecords) {
  const newProperties = [
    {
      outputProperty: function (record) {
        const baseProperty = 'Fee Name'
        return [record[baseProperty]].join(' ')
        // return [record[baseProperty], 'Amount'].join(' ')
      },
      outputValue: function (record) {
        return +record['Amount(Include Tax)']
      }
    },
    {
      outputProperty: function (record) {
        const baseProperty = 'Fee Name'
        return [record[baseProperty], 'Tax'].join(' ')
      },
      outputValue: function (record) {
        return +record['VAT Amount']
      }
    }
  ]
  const excludedProperties = [
    'Fee Name',
    'Amount(Include Tax)',
    'VAT Amount'
  ]
  const combinationPropertyName = 'Order Line ID'
  const preparedRecords = combineMultilinesItems(datasetRecords, combinationPropertyName, newProperties, excludedProperties)

  return preparedRecords
}
