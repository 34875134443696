import { optionSelectorData } from '../../data.js'

/**
 *
 */
export function updateEliminationSelectorLayout () {
  const layoutSelectorElement = document.getElementById('optionEliminationsLayoutSelector')
  if (!layoutSelectorElement) return

  if (optionSelectorData.organisationId === 'eliminations') {
    layoutSelectorElement.classList.remove('is-hidden')
  } else {
    layoutSelectorElement.classList.add('is-hidden')
    return
  }

  // Styling
  const buttonElements = layoutSelectorElement.querySelectorAll('button')
  buttonElements.forEach(function (oneButton) {
    oneButton.classList.remove('is-active')
  })

  const selector = 'button[d-layout=":layout"]'.replace(':layout', optionSelectorData.layout.join('.'))
  const selectedButtonElement = layoutSelectorElement.querySelector(selector)
  selectedButtonElement.classList.add('is-active')
}
