
import { _throttle } from '../helpers/util.js'
import { enableConsole } from '../logging/console.js'
enableConsole(null)

export function activateScrolling (gridSettings) {
  enableConsole(false)
  console.log('activateScrolling', gridSettings)
  const { containerElementId, _layoutElements, startingScrollSettings } = gridSettings

  const gridContainerElement = document.getElementById(containerElementId)
  console.log('gridContainerElement', gridContainerElement)

  // Remembering scrolling to allow redraw of grid and replicate it
  _layoutElements.scrollingPositions = _layoutElements.scrollingPositions || initialScrolling(startingScrollSettings)

  _layoutElements.top.center.addEventListener('scroll', function (scrollEvent) {
    scrollLog(scrollEvent)

    _layoutElements.scrollingPositions.left = scrollEvent.target.scrollLeft

    _layoutElements.center.center.scroll({
      left: scrollEvent.target.scrollLeft
      // behaviour: 'smooth'
    })
  })

  _layoutElements.center.left.addEventListener('scroll', function (scrollEvent) {
    scrollLog(scrollEvent)

    _layoutElements.scrollingPositions.top = scrollEvent.target.scrollTop

    _layoutElements.center.center.scroll({
      top: scrollEvent.target.scrollTop
      // behaviour: 'smooth'
    })
  })

  _layoutElements.center.center.addEventListener('scroll', function (scrollEvent) {
    scrollLog(scrollEvent)

    _layoutElements.scrollingPositions.left = scrollEvent.target.scrollLeft
    _layoutElements.scrollingPositions.top = scrollEvent.target.scrollTop

    throttledScrollCenterCenter(_layoutElements, scrollEvent)
    // scrollCenterCenter(_layoutElements, scrollEvent)
  })
  enableConsole('reset')
}

function initialScrolling (startingScrollSettings) {
  return startingScrollSettings || { top: 0, left: 0 }
}

const scrollCenterCenter = function (_layoutElements, scrollEvent) {
  _layoutElements.center.left.scroll({
    top: scrollEvent.target.scrollTop
    // behaviour: 'smooth'
  })

  _layoutElements.top.center.scroll({
    left: scrollEvent.target.scrollLeft
    // behaviour: 'smooth'
  })
}

const throttledScrollCenterCenter = _throttle(scrollCenterCenter, 10, { leading: true })

export function replicateScrolling (gridSettings) {
  // console.log('replicateScrolling')
  const { _layoutElements } = gridSettings

  if (!_layoutElements.scrollingPositions) return

  _layoutElements.center.center.scroll({
    left: _layoutElements.scrollingPositions.left
  })

  _layoutElements.center.center.scroll({
    top: _layoutElements.scrollingPositions.top
  })

  _layoutElements.center.left.scroll({
    top: _layoutElements.scrollingPositions.top
  })

  _layoutElements.top.center.scroll({
    left: _layoutElements.scrollingPositions.left
  })
}

function scrollLog (scrollEvent) {

  // console.log('scrollEvent.target.classList', scrollEvent.target.classList)
  // console.log('scroll Top', scrollEvent, scrollEvent.target.scrollTop)
  // console.log('scroll Left', scrollEvent, scrollEvent.target.scrollleft)
}
