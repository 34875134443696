import { disableUser, enableSupport } from '../enable-support/enable-support.js'

export function enableSupportAccess (accountUsersElement, accountId) {
  const supportAccess = document.createElement('button')
  supportAccess.classList.add('button', 'is-small', 'is-danger', 'is-light')
  supportAccess.innerText = 'Activate Support access'

  supportAccess.addEventListener('click', function (clickEvent) {
    console.log('click', accountId)
    enableSupport(accountId)
  })

  accountUsersElement.append(supportAccess)
}

export function disableSupportAccess (accountUsersElement, inviteId) {
  const supportAccess = document.createElement('button')
  supportAccess.classList.add('button', 'is-small', 'is-primary')
  supportAccess.innerText = 'Disable Support access'

  supportAccess.addEventListener('click', function (clickEvent) {
    console.log('click', inviteId)
    disableUser(inviteId)
  })

  accountUsersElement.append(supportAccess)
}
