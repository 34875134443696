// Page Module
import { setElementHtml } from '../../helpers/dom.js'
import htmlCalendlyContainer from './calendly.html'

export function showCalendly (params) {
  const calendlyContainerElementId = params.containerId

  setElementHtml(calendlyContainerElementId, htmlCalendlyContainer)

  const calendlyContainerId = 'calendlyContainer'
  Calendly.initInlineWidget({
    url: 'https://calendly.com/florian-futureflow/zenflow-onboarding?hide_event_type_details=1',
    parentElement: document.getElementById(calendlyContainerId),
    prefill: {},
    utm: {}
  })
}
