
import { generateRandomString } from '../../../../helpers/helpers/random.js'
import { setElementHtml } from '../../dom.js'
import htmlTemplatZone from './zone.html'

import './style.less'
import { filesizeFriendly } from '../../../../helpers/helpers/numbers.js'
import { fillString } from '../../../../helpers/templating/string-template.js'

/*
Inspired by
https://codepen.io/OptimalLearner/pen/QWMGyZj
https://bulma.io/documentation/form/file/#modifiers
https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
*/

/**
 *
 * @param {Object} options
 * @param {String} options.containerId
 * @param {Function} [options.onChange] Function to execute on change
 */
export function generateFilePicker (options) {
  const filepickerInstance = {
    options,
    file: null,
    onChange: function () {
      if (!options?.onChange) return null
      return options.onChange(filepickerInstance.file)
    },
    update: function (params) {
      if ('file' in params) {
        filepickerInstance.file = params.file
      }
      resetZone(filepickerInstance)
    }
  }
  const buttonContainerId = options.containerId

  // Generate the group container
  const elementZoneId = 'zi_' + generateRandomString(8)
  const elementInputId = 'ii_' + generateRandomString(8)

  let classIsBoxed = ''
  if (options.isBoxed) {
    classIsBoxed = 'is-boxed'
  }

  filepickerInstance.messages = setMessages(options)
  const messages = filepickerInstance.messages

  const element = fillString(htmlTemplatZone, {
    zoneId: elementZoneId,
    inputId: elementInputId,
    textReady: messages.ready,
    isBoxed: classIsBoxed
  })

  const DOMelement = setElementHtml(buttonContainerId, element)
  if (!DOMelement) return

  const zoneElement = document.getElementById(elementZoneId)
  const fileInputElement = document.getElementById(elementInputId)
  const zoneMessageElement = zoneElement.querySelector('.file-name')

  filepickerInstance.elements = {
    zone: zoneElement,
    fileInput: fileInputElement,
    message: zoneMessageElement
  }

  setupFileInput(filepickerInstance)
  setupFileDrop(filepickerInstance)

  return filepickerInstance
}

function setupFileInput (filepickerInstance) {
  const fileInputElement = filepickerInstance.elements.fileInput
  fileInputElement.addEventListener('change', function () {
    const file = fileInputElement.files[0]

    filepickerInstance.file = file
    setFileInfo(filepickerInstance)
    executeChange(filepickerInstance)
  })
}

/**
 *
 * @param {*} zoneElement
 * @param {*} fileInputElement
 * @param {*} messages
 * @param {*} filepickerInstance
 * @returns
 */
function setupFileDrop (filepickerInstance) {
  if (!isDragDropAvailable) {
    console.warn('modern Drag & Drop not available')
    return
  }

  stopDefaultBehaviour(filepickerInstance.elements.zone)

  onDragStop(filepickerInstance)
  onDrag(filepickerInstance)
  onDrop(filepickerInstance)
}

function executeChange (filepickerInstance) {
  if (filepickerInstance.onChange) {
    filepickerInstance.onChange()
  }
}

function onDrop (filepickerInstance) {
  const zoneElement = filepickerInstance.elements.zone
  const fileInputElement = filepickerInstance.elements.fileInput

  zoneElement.addEventListener('drop', function (e) {
    const files = e.dataTransfer.files

    setFilesToInput(files)
    setFileInfo(filepickerInstance)
    executeChange(filepickerInstance)
  })

  function setFilesToInput (files) {
    fileInputElement.files = files
    filepickerInstance.file = files[0]
  }
}

function setFileInfo (filepickerInstance) {
  const zoneMessageElement = filepickerInstance.elements.message

  const file = filepickerInstance.file
  if (!file) {
    resetZone(filepickerInstance)
    return
  }
  const fileSize = filesizeFriendly(file.size)
  zoneMessageElement.innerHTML = [fileSize.text, file.name].join(' ')
}

function onDragStop (filepickerInstance) {
  const zoneElement = filepickerInstance.elements.zone
  const zoneMessageElement = filepickerInstance.elements.message
  const messages = filepickerInstance.messages

  const events = ['dragend', 'dragleave']
  events.forEach(function (event) {
    zoneElement.addEventListener(event, function (e) {
      e.preventDefault()
      e.stopPropagation()

      zoneElement.classList.remove('on-drag')
      zoneMessageElement.innerHTML = messages.ready
    })
  })
}

function onDrag (filepickerInstance) {
  const zoneElement = filepickerInstance.elements.zone
  const zoneMessageElement = zoneElement.querySelector('.file-name')
  const messages = filepickerInstance.messages

  const events = ['dragover', 'dragenter']
  events.forEach(function (event) {
    zoneElement.addEventListener(event, function (e) {
      e.preventDefault()
      e.stopPropagation()

      zoneElement.classList.add('on-drag')
      zoneMessageElement.innerHTML = messages.drag
    })
  })
}

function stopDefaultBehaviour (zoneElement) {
  const events = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
  events.forEach(function (event) {
    zoneElement.addEventListener(event, function (e) {
      e.preventDefault()
      e.stopPropagation()
    })
  })
}

const actionsClasses = ['on-drag']

function setMessages (options) {
  const messages = {
    ready: options.messages?.ready || 'Pick or drop a file',
    drag: 'Drop your file'
  }
  return messages
}

function resetZone (filepickerInstance) {
  const zoneElement = filepickerInstance.elements.zone
  const zoneMessageElement = zoneElement.querySelector('.file-name')

  filepickerInstance.messages = setMessages(filepickerInstance.options)
  const messages = filepickerInstance.messages

  zoneMessageElement.innerHTML = messages.ready
  zoneElement.classList.remove(...actionsClasses)
}

const isDragDropAvailable = (function () {
  const div = document.createElement('div')
  return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
}())
