export const shopeeSummaryOutput = {
  sourceData: 'settlements',
  propertiesSettings: [
    {
      propertyName: 'orderId',
      width: 170
    },
    {
      propertyName: 'createdAt',
      width: 90
    },
    {
      propertyName: 'paidAt',
      width: 90
    },
    {
      propertyName: 'settledAt',
      width: 90
    },
    {
      propertyName: 'originalProductPrice',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'yourSellerProductPromotion',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'refundAmount',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'revenueGross',
      type: 'number',
      width: 120
    },

    {
      propertyName: 'productDiscountRebateFromShopee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'voucher',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'sellerAbsorbedCoinCashback',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'discount',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'revenueNet',
      type: 'number',
      width: 120
    },

    {
      propertyName: 'shippingFeePaidByBuyer',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'shippingRebateFromShopee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'thirdPartyLogisticsDefinedShippingFee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'sellerPaidShippingFeeSalesTax',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'actualShippingFee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'reverseShippingFee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'reverseShippingFeeSalesTax',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'feeReturnsProgramShippingFeeWaivers',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'shippingSubtotal',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'onlineDistributionFees',
      type: 'number',
      width: 120
    },

    {
      propertyName: 'AMSCommissionFee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'commissionFeeInclSalesTax',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'serviceFeeInclSalesTax',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'freeReturnsFeeInclSalesTax',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'transactionFeeInclSalesTax',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'feesAndCharges',
      type: 'number',
      width: 120
    },

    {
      propertyName: 'onlineMarketingAds',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'onlinePlatformFees',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'totalReleasedAmount',
      type: 'number',
      width: 120
    },
    // {
    //   propertyName: 'settlementAmount',
    //   type: 'number',
    //   width: 120
    // },
    {
      propertyName: 'computedSettlementAmount',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'mismatchSettlement',
      type: 'number',
      width: 120
    }
  ]
}
