export const ordersDetailsSummary = {
  sourceData: 'ordersLines',
  propertiesSettings: [
    {
      propertyName: 'orderCountry',
      width: 170
    },
    {
      propertyName: 'orderPlatform',
      width: 170
    },
    {
      propertyName: 'orderId',
      width: 170
    },
    {
      propertyName: 'SKU_id',
      width: 170
    },
    {
      propertyName: 'buyerUsername',
      width: 170
    },
    {
      propertyName: 'createdAt',
      width: 90
    },
    {
      propertyName: 'paidAt',
      width: 90
    },
    {
      propertyName: 'settledAt',
      width: 90
    },
    {
      propertyName: 'quantity',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'revenueNet',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'discount',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'revenueGross',
      type: 'number',
      width: 120
    }
  ]
}
