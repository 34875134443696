import { platformsSettings } from '../settings-to-use.js'

export function prepareSettingsTable () {
  console.log('prepareSettingsTable')
  const fieldsMap = new Map()
  const settings = []

  platformsSettings?.forEach(function (platformSettings) {
    if (!platformSettings.platformSettings) return

    const row1 = analyseSettings(platformSettings.platformSettings.orders, platformSettings.country, platformSettings.platform, 'orders', fieldsMap)
    settings.push(row1)

    const row2 = analyseSettings(platformSettings.platformSettings.settlements, platformSettings.country, platformSettings.platform, 'settlements', fieldsMap)
    settings.push(row2)
  })

  console.log(settings)
  console.log(fieldsMap)
  return {
    fieldsMap,
    settings
  }
}

function analyseSettings (fileSettings, country, platform, source, fieldsMap) {
  const platformSettings = {
    country,
    platform,
    source
  }

  const fieldsMappings = fileSettings.fieldsMapping

  fieldsMappings.forEach(function (field) {
    const outputName = field.outputName || field.propertyName

    // row[outputName] = field

    const outputEntry = getMapEntry(fieldsMap, outputName)
    outputEntry.push({
      country,
      platform,
      source,
      field
    })
  })
  return platformSettings
}

function getMapEntry (mapRecords, entryId) {
  let mapEntry = mapRecords.get(entryId)
  if (mapEntry) return mapEntry

  mapRecords.set(entryId, [])

  mapEntry = mapRecords.get(entryId)
  return mapEntry
}
