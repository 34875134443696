import { makeDateForBS, makeDateForPnl } from '../../helpers/financial-dates.js'
import { optionSelectorData } from '../../modules/option-selector/data.js'

export function getHeaderValue (params) {
  // console.log('getHeaderValue')
  const { cellParams } = params
  const gridSettings = cellParams.gridSettings?.gridSettings || cellParams.gridSettings
  const statementType = gridSettings?.context?.statementType
  const columnDate = cellParams.columnDef.id
  // console.log('statementType', statementType, cellParams)

  if (statementType === 'pnl') {
    return headerDateForPnl(columnDate, optionSelectorData)
  } else {
    return makeDateForBS(columnDate)
  }
}

function headerDateForPnl (columnDate, optionSelectorData) {
  // console.log('headerDateForPnl', columnDate, optionSelectorData)
  const months = optionSelectorData.periodicity || 1
  return makeDateForPnl(columnDate, months)
}
