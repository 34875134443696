
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as cssGrid from '../../../helpers/cssgrid/index.js'
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'
import * as mHtmlParser from '../../modules/user/html-parser.js'

// Page Module
import htmlTemplateIndex from './index.html'
import * as mEliminations from './eliminations.js'
import { loadChartOfAccounts } from './load-charts-of-accounts.js'
import { ascending } from 'd3'

/**
 *
 * @returns
 */
export async function show () {
  console.log('chart of accounts show()')

  const accountData = mAccount.accountData()

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Chart of Accounts:', accountData.name].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })
  contentElement.innerHTML = htmlPageLayout

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const chartOfAccountResponse = await loadChartOfAccounts(accountData._id)
  showChartsOfAccounts({
    chartsOfAccounts: chartOfAccountResponse.chartsOfAccounts,
    eliminations: chartOfAccountResponse.eliminations
  })
}

function showChartsOfAccounts (params) {
  console.log('showChartsOfAccounts', params)
  const { chartsOfAccounts, eliminations } = params
  const colDefs = [
    {
      id: 'accountType',
      headerValueGetter: function () {
        return 'Type'
      },
      valueGetter: function (cellParams) {
        return cellParams.rowDef.data.details.type
      },
      layout: 'left',
      width: 120
    },
    {
      id: 'accountName',
      headerValueGetter: function () {
        return 'Name'
      },
      valueGetter: function (cellParams) {
        return cellParams.rowDef.data.details.name
      },
      layout: 'left',
      width: 300
    }
  ]

  // Organisations columns
  chartsOfAccounts?.organisations?.forEach(function (oneOrg) {
    const columnDef = {
      id: oneOrg.id || oneOrg._id,
      headerValueGetter: function (cellParams) {
        // console.log('headerValueGetter', cellParams)
        // console.log('headerValueGetter this', this)
        // return this.params.org.nam e
        return cellParams?.columnDef?.params?.org?.name
      },
      valueGetter: function (cellParams) {
        // console.log('valueGetter', cellParams)
        const matchingAccount = cellParams.gridSettings.params.dataset.find(function (oneData) {
          // console.log('oneData', oneData)
          // Row types should match
          if (!(oneData.details?.type === cellParams.rowDef.data?.details?.type)) return false
          // console.log('passed Type')

          // Row names should match
          if (!(oneData.details?.name === cellParams.rowDef.data.details?.name)) return false
          // console.log('passed Name')

          // Organisation should be for the column
          const columnOrg = cellParams?.columnDef?.params?.org
          const columnOrgId = columnOrg.id || columnOrg._id
          if (!(oneData._xeroOrganisation === columnOrgId)) return false
          // console.log('passed Org')

          return true
        })
        // console.log('matchingAccount', matchingAccount)
        if (!matchingAccount || matchingAccount.length === 0) return '-'

        // Elimination status
        const isEliminated = mEliminations.isAccountEliminated({
          eliminations,
          chartAccountId: matchingAccount._id
        })

        // console.log('isEliminated', isEliminated)
        let htmlString = ':status [:code] [:isEliminated]'
        htmlString = htmlString.replaceAll(':status', matchingAccount?.details?.status)
        if (!matchingAccount?.details?.code) {
          htmlString = htmlString.replaceAll('[:code]', '')
        }
        htmlString = htmlString.replaceAll(':code', matchingAccount?.details?.code)

        if (!isEliminated) {
          htmlString = htmlString.replaceAll('[:isEliminated]', '')
        }
        htmlString = htmlString.replaceAll(':isEliminated', '*Eliminated*')
        return htmlString
      },
      onClick: async function (cellParams) {
        console.log('onClick', cellParams, cellParams._value, 'org:', cellParams.columnDef.params.org._id)
        cellParams.showCell({ showLoading: true })

        const rowAccountId = generateAccountTableId(cellParams.rowDef.data)

        const targetAccount = cellParams.gridSettings.params.dataset.find(function (oneChartOfAccount) {
          const accountTableId = generateAccountTableId(oneChartOfAccount)
          if (!(rowAccountId === accountTableId)) return false

          if (!(cellParams.columnDef.params.org._id === oneChartOfAccount._xeroOrganisation)) return false

          return true
        })
        // console.log('targetAccount', targetAccount)

        // Elimination status
        const isEliminated = mEliminations.isAccountEliminated({
          eliminations,
          chartAccountId: targetAccount._id
        })

        // Call API to eliminate
        const accountData = mAccount.accountData()
        const eliminationResponse = await mEliminations.toggleEliminatedAccount({
          accountId: accountData._id,
          chartAccountId: targetAccount._id,
          toEliminate: !isEliminated // Call the reverse of current elimination status
        })
        // console.log('eliminationResponse', eliminationResponse)
        if (eliminationResponse.deleted) {
          let eliminationsArrayIndex
          eliminations?.forEach(function (one, index) {
            if (one._xeroAccount === targetAccount._id) {
              eliminationsArrayIndex = index
            }
          })
          if (Number.isFinite(eliminationsArrayIndex)) {
            eliminations?.splice(eliminationsArrayIndex, 1)
          }
        } else {
          eliminations?.push(eliminationResponse)
        }

        cellParams.showCell()
      },
      width: 250,
      params: {
        org: oneOrg
      }
    }
    colDefs.push(columnDef)
  })

  // The rows: list of accounts accross organisation, based on the accountLoopId as unicity
  const uniqueAccounts = new Set()
  const rowDefs = []
  let maxRows = 5000
  chartsOfAccounts?.accounts?.forEach(function (oneChartOfAccount) {
    if (maxRows < 0) return

    const accountLoopId = generateAccountTableId(oneChartOfAccount)

    // Ensure unicity
    if (uniqueAccounts.has(accountLoopId)) return
    uniqueAccounts.add(accountLoopId)

    const rowDef = {
      id: accountLoopId,
      data: {
        details: {
          type: oneChartOfAccount?.details?.type,
          name: oneChartOfAccount?.details?.name
        }
      }
    }
    rowDefs.push(rowDef)

    maxRows--
  })

  sortNames(rowDefs)
  sortTypes(rowDefs)

  cssGrid.generateGrid({
    containerElementId: 'grid',
    classes: ['fit-content'],
    colDefs,
    rowDefs,
    params: {
      dataset: chartsOfAccounts?.accounts
    },
    showActions: true
  })
}

function sortTypes (rowDefs) {
  rowDefs.sort(function (r1, r2) {
    return ascending(r1.data.details?.type, r2.data.details?.type)
  })
  return rowDefs
}

function sortNames (rowDefs) {
  rowDefs.sort(function (r1, r2) {
    return ascending(r1.data.details?.name, r2.data.details?.name)
  })
  return rowDefs
}

function generateAccountTableId (chartOfAccount) {
  // console.log('generateAccountTableId', chartOfAccount)
  return [chartOfAccount.details?.type, chartOfAccount.details?.name].join(':::')
}
