export const settlementsOutputs = {
  sourceData: 'settlements',
  propertiesSettings: [
    {
      propertyName: 'orderId',
      width: 170
    },
    {
      propertyName: 'createdAt',
      width: 90
    },
    {
      propertyName: 'paidAt',
      width: 90
    },
    {
      propertyName: 'settledAt',
      width: 90
    },
    {
      propertyName: 'settlementAmount',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'onlinePlatformFees',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'onlineDistributionFees',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'onlineMarketingAds',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'revenueNet',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'discount',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'revenueGross',
      type: 'number',
      width: 120
    },
    {
      outputName: '_records',
      fillData: 'records',
      export: false
    }
  ]
}
