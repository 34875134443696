import { readCsvFile } from '../../utilities/read-csv-file.js'
import { moduleData } from '../data.js'
import { parseNumber } from '../utilities/parse-number.js'

export async function receiveProducts (params) {
  console.log('receiveProducts')
  if (!params.file) {
    console.error('no file')
    return
  }

  const records = await readCsvFile(params.file)
  console.log('records', records)

  // const fileId = generateRandomString()

  //
  const parsedProducts = []
  const infos = {
    items: 0,
    recognised: 0
  }

  records?.forEach(function (record) {
    const parsedProduct = {}
    parsedProduct._raw = record

    const SKUid = record['SKU ID']
    parsedProduct.id = SKUid

    const SKUprice = record.Price
    parsedProduct.price = parseNumber(SKUprice) || null

    parsedProducts.push(parsedProduct)

    infos.items++
    if (parsedProduct.id) infos.recognised++
  })
  console.log('parsedProducts', parsedProducts)

  moduleData.files.push({
    type: 'products',
    name: params.file.name,
    remarks: [parsedProducts?.length, 'products', ':', infos.recognised, 'with SKU ID'].join(' ')
  })

  return parsedProducts
}
