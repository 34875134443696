
/*
- 'Upload' file(s) or Use Demo
- Fields mapping:
  Which field to use?
    User ID
    Action Date
    Value

- Cohort periods: month, quarters

Additional:
- Currency symbol
*/

import { toBoolean } from '../../../helpers/helpers/string.js'
import { hideElement, showElement } from '../../helpers/dom.js'
import { generateButtons, generateFilePicker, generateSelectLevel } from '../../helpers/menus/index.js'
import { executeDataPreparation } from './connectors/index.js'
import { prepareAcquisitionCosts } from './data-acquisition.js'
import { cohortSettings } from './data.js'
import { analyseCohorts } from './index.js'
import { showLoadingCohorts } from './show.js'
import { demoFileOptions, executeDemo } from './utilities/demos.js'
import { readFile } from './utilities/file.js'

const menuComponents = {
  periods: null,
  fieldUserId: null,
  fieldActionTimestamp: null,
  fieldActionValue: null
}

export function launchMenu () {
  menuDataFile()
  menuOptions()
}

function menuDataFile () {
  // File or Demo
  menuComponents.filepicker = generateFilePicker({
    containerId: 'optionFileActions',
    messages: {
      ready: 'Pick or drop you file (csv)'
    },
    onChange: function (file) {
      menuComponents.demo.update({
        selected: null
      })

      onNewFile({ file })
    },
    isBoxed: true
  })

  menuComponents.demo = generateButtons({
    buttonOptions: demoFileOptions,
    containerId: 'optionDemos',
    groupLabel: 'Or use Demo:',
    selected: false,
    // selected: 'futureflow',
    onChange: async function (selectedOption, instance) {
      menuComponents.filepicker.update({
        file: null
      })
      onNewFile({ selectedOption })
    }
  })
  cohortSettings.demo = menuComponents.demo.selectedOption()

  // Acquisition costs
  menuComponents.filepickerAcquisition = generateFilePicker({
    containerId: 'optionFileAcquisition',
    messages: {
      ready: 'Add csv of Acquisition costs'
    },
    onChange: function (file) {
      // menuComponents.demo.update({
      //   selected: null
      // })

      onNewFileAcquisition({ file })
    },
    isBoxed: true
  })
}

function menuOptions () {
// Cohort settings
  menuComponents.periods = generateButtons({
    buttonOptions,
    containerId: 'optionPeriods',
    groupLabel: 'Cohorts\' periods:',
    selected: 'month',
    onChange: function (selectedOption, instance) {
      console.log('selected', selectedOption, instance)
      cohortSettings.cohortType = selectedOption.value
      analyseCohorts()
    }
  })

  menuComponents.chartRetentionInitial = generateButtons({
    buttonOptions: buttonChartRetentionInitial,
    containerId: 'optionChartRetentionInitial',
    groupLabel: 'Show Initial Period on Retention Charts:',
    selected: true,
    onChange: function (selectedOption, instance) {
      console.log('selected', selectedOption, instance)
      cohortSettings.chartRetentionInitial = toBoolean(selectedOption.value)
      analyseCohorts()
    }
  })

  menuComponents.fieldUserId = generateSelectLevel({
    selectOptions: null,
    containerId: 'optionFieldsUserId',
    groupLabel: 'Field for User IDs:',
    onChange: function (selectedOption) {
      console.log('selectedOption', selectedOption)
      cohortSettings.fields.userId = selectedOption
      console.log('cohortSettings', cohortSettings)
      analyseCohorts()
    }
  })

  menuComponents.fieldActionTimestamp = generateSelectLevel({
    selectOptions: null,
    containerId: 'optionFieldsActionTimestamp',
    groupLabel: 'Field for Date of Action:',
    onChange: function (selectedOption) {
      cohortSettings.fields.actionTimestamp = selectedOption
      analyseCohorts()
    }
  })

  menuComponents.fieldActionValue = generateSelectLevel({
    selectOptions: null,
    containerId: 'optionFieldsActionValue',
    groupLabel: 'Field for Value of Action:',
    onChange: function (selectedOption) {
      cohortSettings.fields.actionValue = selectedOption
      analyseCohorts()
    }
  })
}

export function updateMenu () {
  console.log('updateMenu', cohortSettings.records.columns)
  menuComponents.fieldUserId.update({
    selectOptions: fileColumnsToSelectOptions(),
    selected: cohortSettings.fields.userId
  })

  menuComponents.fieldActionTimestamp.update({
    selectOptions: fileColumnsToSelectOptions(),
    selected: cohortSettings.fields.actionTimestamp
  })

  menuComponents.fieldActionValue.update({
    selectOptions: fileColumnsToSelectOptions(),
    selected: cohortSettings.fields.actionValue
  })
}

function fileColumnsToSelectOptions () {
  const selectOptions = []

  cohortSettings.records?.columns?.forEach(function (oneColumn) {
    selectOptions.push({
      label: oneColumn,
      value: oneColumn
    })
  })
  return selectOptions
}

/*
  Buttons
  Button Dropdown
  HTML select dropdown

  Ability to set a default value
  Update the selector in code: allows refresh
*/
const buttonOptions = [
  {
    label: 'Months',
    value: 'month'
  },
  {
    label: 'Quarters',
    value: 'quarter'
  }
]

const buttonChartRetentionInitial = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

export function showLoadingMenu () {
  showElement('menuOptions')

  showElement('loading')
  hideElement('menuReady')
}

export function showReadyMenu () {
  hideElement('loading')
  showElement('menuReady')
}

async function onNewFile (params) {
  showLoadingCohorts()
  showLoadingMenu()

  if (params.selectedOption) {
    // Demo
    await executeDemo(params.selectedOption)
  } else if (params.file) {
    // File
    const records = await readFile(params.file)
    cohortSettings.records = records
  }

  executeDataPreparation(cohortSettings.records)
  analyseCohorts()
}

async function onNewFileAcquisition (params) {
  console.log('onNewFileAcquisition', params)

  if (params.file) {
    showLoadingCohorts()
    showLoadingMenu()

    const records = await readFile(params.file)
    cohortSettings.recordsAcquisitionCosts = records

    if (!cohortSettings.records) {
      prepareAcquisitionCosts()
      return
    }
    analyseCohorts()
  }
}
