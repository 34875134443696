
// Page Module
import { fillString } from '../../../helpers/templating/string-template.js'
import htmlUserShowOne from './user-list-one.html'
import htmlUserSupport from './user-list-support.html'

export async function showUserList (accountData) {
  console.log('showUserList', accountData)
  const containerElement = document.getElementById('userList')
  containerElement.innerText = ''

  accountData._userAccounts?.forEach(function (oneAccountUser) {
    // Do not show the invitations here
    if (!oneAccountUser._user) return

    const userElement = showOneUser(oneAccountUser)
    containerElement.insertAdjacentHTML('beforeend', userElement)
  })
}

/**
 *
 * @param {*} oneAccountUser
 * @returns
 */
function showOneUser (oneAccountUser) {
  if (oneAccountUser.isSupport) {
    return htmlUserSupport
  }

  return fillString(htmlUserShowOne, {
    userEmail: oneAccountUser._user?.email || '',
    userName: oneAccountUser._user?.name,
    userRights: oneAccountUser.rights.join(', ')
  })
}
