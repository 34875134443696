
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'
import * as mHtmlParser from '../../modules/user/html-parser.js'

// Page Module
import * as tenants from './tenants.js'
import * as mOrganisations from './organisations.js'
import htmlTemplateIndex from './index.html'
import { checkOrgToken } from './organisations-checktoken.js'
import { callApi } from '../../helpers/fetch.js'

/**
 *
 * @returns
 */
export async function show () {
  console.log('Xero show()')

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  const accountData = mAccount.accountData()

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Connect with Xero:', accountData.name].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })
  contentElement.innerHTML = htmlPageLayout

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showAccount(accountData)

  listeners(accountData)

  // Load the Tenants from the user Token, and show the appAccount organisation
  await Promise.all([
    tenants.getConnectedTenants(),
    mOrganisations.showAssociatedOrganisations(accountData._id)
  ])
  // Then show the token tenants: allowing not to double show the Org which are associated
  tenants.showConnectedTenants({ accountId: accountData._id })
}

/**
 *
 */
function showAccount (accountData) {
  console.log('showAccount', accountData)
  const DOMelement = document.getElementById('accountName')
  if (!DOMelement) return
  DOMelement.innerText = accountData.name
}

/**
 *
 */
function listeners (accountData) {
  const DOMelement = document.getElementById(configVar.contentElementId)

  const clickableElementRead = DOMelement.querySelector('#connectXeroOrg')
  clickableElementRead.addEventListener('click', function (clickEvent) {
    console.log('clicked')
    connectXero()
  })

  const clickableElementRevoke = DOMelement.querySelector('#revokeXeroToken')
  clickableElementRevoke.addEventListener('click', function (clickEvent) {
    console.log('revoke clicked')
    revokeXero(accountData)
  })

  const clickableElementRefresh = DOMelement.querySelector('#refreshXeroToken')
  clickableElementRefresh.addEventListener('click', function (clickEvent) {
    console.log('refresh clicked')
    refreshToken(accountData)
  })

  // Disconnection links
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('.disconnectLinks')
    if (!clickedElement) return

    // Show loading state
    clickedElement.classList.add('is-loading')
    clickedElement.setAttribute('disabled', true)

    const orgId = clickedElement.getAttribute('d-org-id')
    disconnectXero({ accountData, orgId })
  })

  // Reconnect Xero
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('.reconnectLinks')
    if (!clickedElement) return

    // Show loading state
    clickedElement.classList.add('is-loading')
    clickedElement.setAttribute('disabled', true)

    connectXero()
  })

  // Sync Data links
  DOMelement.addEventListener('click', async function (clickEvent) {
    const clickedElement = clickEvent.target.closest('.syncLinks')
    if (!clickedElement) return

    // Show loading state
    clickedElement.classList.add('is-loading')
    clickedElement.setAttribute('disabled', true)

    const orgId = clickedElement.getAttribute('d-org-id')
    await syncData({ orgId })

    clickedElement.classList.remove('is-loading')
  })

  // Delete Data links
  DOMelement.addEventListener('click', async function (clickEvent) {
    const clickedElement = clickEvent.target.closest('.deleteLinks')
    if (!clickedElement) return

    // Show loading state
    clickedElement.classList.add('is-loading')
    clickedElement.setAttribute('disabled', true)

    const orgId = clickedElement.getAttribute('d-org-id')
    console.log('orgId', orgId)
    await deleteData({ orgId })
    clickedElement.classList.remove('is-loading')
    clickedElement.innerText = 'Data deleted'
  })

  // Associate Organisation with Account
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('.associateLinks')
    if (!clickedElement) return

    const orgId = clickedElement.getAttribute('d-org-id')
    console.log('associate orgId', orgId)
    associateOrg({ orgId, method: 'POST' })
  })

  // Disassociate Organisation with Account
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('.disassociateLinks')
    if (!clickedElement) return

    const orgId = clickedElement.getAttribute('d-org-id')
    console.log('disassociate orgId', orgId)
    associateOrg({ orgId, method: 'DELETE' })
  })

  // Check Connection
  DOMelement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('.checkConnectionLinks')
    if (!clickedElement) return

    const orgId = clickedElement.getAttribute('d-org-id')
    console.log('checkConnectionLinks', orgId)
    checkOrgToken(orgId)
  })
}

const processingStates = {}

async function connectXero () {
  const apiUrl = '/api/xero/connect'
  const data = await callApi(apiUrl)

  // Redirect to the Xero authentication page
  location.href = data.consentUrl

  // Return would not be reached :p
  return data
}

async function revokeXero (accountData) {
  const apiUrl = '/api/xero/connect/revoke'
  await callApi(apiUrl)

  // !! TODO: seems that it still shows the previously connected tenant
  tenants.showConnectedTenants({ accountId: accountData._id, refreshTenantsList: true })
  return true
}

async function refreshToken (accountData) {
  const apiUrl = '/api/xero/connect/refreshToken'
  await callApi(apiUrl)
  tenants.showConnectedTenants({ accountId: accountData._id, refreshTenantsList: true })
  return true
}

//
async function disconnectXero (params) {
  const { accountData, orgId } = params

  if (processingStates.disconnectXeroOrg) return
  processingStates.disconnectXeroOrg = true

  const data = await loadDisconnectXeroOrg(orgId)
  console.log('disconnected?', data)

  // Refresh page content
  const currentAccount = mAccount.accountData()
  const accountId = currentAccount._id
  await mOrganisations.showAssociatedOrganisations(accountId)
  tenants.showConnectedTenants({ accountId: accountData._id, refreshTenantsList: true })

  processingStates.disconnectXeroOrg = false
  return true
}

export async function loadDisconnectXeroOrg (orgId) {
  const apiUrl = '/api/xero/connect/disconnect/:orgId'
  const data = await callApi(apiUrl, {
    orgId
  })
  return data
}

//
async function syncData (params) {
  const { orgId } = params

  if (processingStates.syncOrgData) return
  processingStates.syncOrgData = true

  const apiUrl = '/api/xero/sync/:orgId'
  const data = await callApi(apiUrl, {
    orgId
  }, { method: 'POST' })

  console.log(data)

  // For data sync: the API returns immediately, while it might take a longer time to sync; hence not allowing to trigger again
  // processingStates.syncOrgData = false
  return true
}

//
async function deleteData (params) {
  const { orgId } = params

  if (processingStates.deleteOrgData) return
  processingStates.deleteOrgData = true

  const apiUrl = '/api/xero/sync/delete/:orgId'
  const data = await callApi(apiUrl, {
    orgId
  }, {
    method: 'DELETE'
  })
  console.log(data)

  processingStates.deleteOrgData = false
  return true
}

/**
 * (dis)associate appAccount and Org
 * @param {*} params
 * @param {String} params.method
 */
async function associateOrg (params) {
  console.log('associateOrg', params)
  const { orgId, method } = params
  const currentAccount = mAccount.accountData()
  const accountId = currentAccount._id
  const apiUrl = '/api/xero/associate/:accountId/with/:orgId'
  await callApi(apiUrl, {
    accountId,
    orgId
  }, {
    method
  })
  await mOrganisations.showAssociatedOrganisations(accountId)
  tenants.showConnectedTenants({ accountId })
  return true
}
