import { fillString } from '../../../../helpers/templating/string-template.js'
import { combineItems } from '../utils/manage-items.js'

import htmlTemplateItem from './item.html'
import { getStoredItems } from './items-lists.js'

export function listItems () {
  const itemSets = {
    all: new Set()
  }

  // Check the items which exists
  const items = combineItems()
  console.log('items', items)
  items?.forEach(function (item) {
    itemSets.all.add(item.account.name)
  })
  console.log('itemSets', itemSets)

  // Show the items already sorted
  const listDatas = getStoredItems()
  listDatas?.forEach(function (listData) {
    const containerElement = document.getElementById(listData.list?.elementId)
    const itemsOfList = listData.items
    itemsOfList?.forEach(function (itemOfList) {
      showItem(itemOfList, containerElement)
      itemSets.all.delete(itemOfList)
    })
  })

  // Add items which might be new
  const itemsContainerElement = document.getElementById('itemsToGroup')
  Array.from(itemSets.all).forEach(function (item) {
    showItem(item, itemsContainerElement)
  })
}

function showItem (accountName, itemsContainerElement) {
  const element = fillString(htmlTemplateItem, {
    accountName
  })

  itemsContainerElement.insertAdjacentHTML('beforeend', element)
}
