
export function listener (selectInstance) {
  const selectElement = selectInstance.DOMelement.querySelector('#' + selectInstance.elementId)
  if (!selectElement) return

  selectElement.addEventListener('change', function (changeEvent) {
    const selected = selectElement.value

    // Record the new selected value
    selectInstance.selectedValue = selected

    if (selectInstance.onChange) {
      selectInstance.onChange()
    }
  })
}
