import { accessObjectPropertyValue } from '../../../../helpers/data/objects.js'
import { setObjectPropertyValue } from '../../../../helpers/data/objects/set-object-property-value.js'
import { launchLogger } from '../../../../helpers/logging/pino.logger.js'

const logger = launchLogger('/compute/add-properties')
logger.silence()
logger.debug(['loaded'])

/**
 *
 * @param {*} records
 * @param {*} indexedData
 * @param {*} properties eg [{
    recordIdToData: 'key.chartAccountId',
    dataAccessProperty: 'name',
    outputProperty: 'account.name'
  }]
 */
export function addRecordProperties (records, indexedData, properties) {
  logger.debug(['addRecordProperties', records?.length, records?.[0], properties])
  // logger.debug(['indexedData', indexedData])
  /*
  [{
    recordIdToData: 'key.chartAccountId',
    dataAccessProperty: 'name',
    outputProperty: 'account.name'
  }]
  */

  records.forEach(function (record) {
    logger.debug(['record', record])
    properties.forEach(function (oneProperty) {
      logger.debug(['oneProperty', oneProperty])

      const recordIdToData = accessObjectPropertyValue(oneProperty.recordIdToData, record)
      logger.debug(['recordIdToData', recordIdToData])
      if (!recordIdToData) return

      const dataRecord = indexedData[recordIdToData]
      logger.debug(['dataRecord', dataRecord])

      const dataAccessProperty = oneProperty.dataAccessProperty
      const outputValue = accessObjectPropertyValue(dataAccessProperty, dataRecord)
      logger.debug(['dataAccessProperty', dataAccessProperty, outputValue])
      const outputProperty = oneProperty.outputProperty
      logger.debug(['outputProperty', outputProperty, outputValue])
      setObjectPropertyValue(record, outputProperty, outputValue)
    })
  })
}
