import dayjs from 'dayjs'

const defaultOutputFormat = 'YYYY-MM-DD'
/**
 *
 * @param {*} value
 * @param {Object} [params]
 * @param {String|Array} [params.inputFormat] dayjs allows an array of potential format
 */
export function parseDateString (value, params = {}) {
  const { inputFormat, outputFormat } = params
  const date = dayjs(value, inputFormat)

  if (!date.isValid()) return value

  const outputFormatToUse = outputFormat || defaultOutputFormat

  return date.format(outputFormatToUse)
}
