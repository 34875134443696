import { getXeroSettings } from '../settings/xero-settings.js'

export function refreshForm () {
  // console.log('refreshForm')
  const settingsForCountry = getXeroSettings()
  // console.log('settingsForCountry', settingsForCountry)
  if (!settingsForCountry) return

  Object.keys(settingsForCountry).forEach(function (oneSettingProperty) {
    const value = settingsForCountry[oneSettingProperty]

    const targetId = [oneSettingProperty, 'xeroAccountCode'].join('.')

    const field = document.getElementById(targetId)
    if (!field) return

    field.value = value
  })
}
