import { fillString } from '../templating/string-template.js'
import * as time from './time.js'

export function timeAgoHtml (startingTime, endingTime) {
  const timeAgo = time.timeDuration(startingTime)
  if (!timeAgo) return ''

  const html = fillString('<span class="timeAgoUI"><span class="timeText">:timeAgo</span><span class="timestamp">:timestamp</span></span>', {
    timeAgo: timeAgo.text,
    timestamp: startingTime
  })

  return html
}
