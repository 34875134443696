import { computeBundles } from '../../utilities/compute-bundles.js'

export function makeProductsDataset (bundles, products) {
  const bundlesDetails = computeBundles(bundles, products)
  const productsData = {
    bundles,
    products,
    bundlesDetails,
    productsMap: makeProductsMap(products, bundlesDetails)
  }
  console.log('productsData', productsData)

  return productsData
}

function makeProductsMap (products, bundlesDetails) {
  const productsMap = new Map()

  products?.forEach(function (product) {
    productsMap.set(product.id, product)
  })

  bundlesDetails?.forEach(function (bundle) {
    productsMap.set(bundle.bundleId, bundle)
  })

  return productsMap
}
