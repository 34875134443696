import { launchLogger } from '../../logging/pino.logger.js'

const logger = launchLogger('/objects/set-object-property-value.js')
logger.silence()
logger.info('loaded')

/**
 * Similar to Lodash _.set()?
 *
 * Adds or replace the value of 1 or several properties (which can be nested properties) or an object
 * @param {Object} object
 * @param {String|Array} properties Array of propertyName: structure of the Object
 * @param {*} value Value to set for the nested property
 */
export function setObjectPropertyValue (object, properties, value) {
  logger.debug(['setObjectPropertyValue', object])
  let pointedObject = object

  const separator = '.'

  let _properties = properties
  if (typeof _properties === 'string') {
    _properties = _properties.split(separator)
  }
  logger.debug(['_properties', _properties])

  _properties.forEach(function (propertyName, index) {
    logger.debug(['propertyName', pointedObject, propertyName])
    // On last propertyName, set the value
    if (index === _properties.length - 1) {
      pointedObject[propertyName] = value
      return
    }

    // Otherwise, create the object structure

    // Create the sub property
    pointedObject[propertyName] = pointedObject[propertyName] || {}

    // Point to the sub property
    pointedObject = pointedObject[propertyName]
  })
}
