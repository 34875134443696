
// App config
import * as configVar from '../../../config/config.js'

import * as cssGrid from '../../../../helpers/cssgrid/index.js'

import { optionSelectorData } from '../../../modules/option-selector/data.js'
import * as mData from '../data.js'
import * as mAccount from '../../../modules/user/account.js'

import { callApi } from '../../../helpers/fetch.js'
import { enforceRestrictedPage } from '../../../modules/user/user.js'

import { hideElement, setElementHtml, showElement } from '../../../helpers/dom.js'
import { createContainer, setPageTitle } from '../helpers.js'
import { showSelectors } from './show-selectors.js'
import { tableRows } from './table-rows.js'
import { tableColumns } from './table-columns.js'
import { enrichRecords } from '../../../../server/finlogic/utils/enrich-records.js'

let gridInstance

/**
 * @param {Object} params
 * @returns
 */

export async function showSchedulesPage (params) {
  console.log('showSchedulesPage')
  console.log('mData', mData)

  if (enforceRestrictedPage({ allowPublic: true })) return

  const DOMelement = setElementHtml(configVar.contentElementId, '')
  if (!DOMelement) return

  DOMelement.classList.add('position-absolute', 'top-to-bottom')

  const contentElement = createContainer('Schedules')
  DOMelement.append(contentElement)

  //

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  setPageTitle(accountData, 'Trial Balances')

  showSelectors(refreshTrialBalanceTable)

  hideElement('statementLoadingMessage')

  refreshTrialBalanceTable()
}

const context = {
  data: {}
}

async function refreshTrialBalanceTable () {
  showElement('statementLoadingMessage')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  if (Array.isArray(context.showOrgId)) {
    console.warn('multiple entities')
    return
  } else if (context.showOrgId === 'conso') {
    console.warn('conso')
    return
  }

  context.minDate = optionSelectorData.dates.min
  context.maxDate = optionSelectorData.dates.max

  context.targetValueProperty = 'amount'

  // Load data
  context.data.tbLedgerData = await loadLedger(context)
  console.log('context', context)

  gridInstance = cssGrid.generateGrid({
    colDefs: tableColumns(context),
    rowDefs: tableRows(context.data.tbLedgerData, context),
    containerElementId: 'statementContainer',
    context,
    classes: [],
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  hideElement('statementLoadingMessage')
}

async function loadLedger (context) {
  console.log('loadLedger', context, mData)

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)
  console.log('optionSelectorData', optionSelectorData)

  const accountId = accountData._id
  const orgId = context.showOrgId

  // Ensure key variables are present
  if (!accountId) return
  if (!orgId) return

  const apiUrl = '/api/xero/a/:accountId/ledger/o/:orgId/:groupings/:currencyCode/:minDate/:maxDate'
  const data = await callApi(apiUrl, {
    accountId,
    orgId,
    groupings: 'accounts_contacts',
    currencyCode: 'XXX',
    minDate: optionSelectorData.dates.min || null,
    maxDate: optionSelectorData.dates.max || null
  })

  const propertiesToEnrich = ['chartAccountId', 'contactId']
  enrichRecords(data.ledger.records, data.dbs, propertiesToEnrich, true)
  return data
}
