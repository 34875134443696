import * as XLSX from 'xlsx'

/**
 *
 * @param {Array{}} data - JSON equivalent
 * @param {String} fileName
 * @param {String} sheetName
 */
export function exportCsv (data, fileName, sheetName) {
  const fullFileName = [fileName, 'csv'].join('.')

  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

  const writeOptions = {
    compression: true,
    bookType: 'csv'
  }

  XLSX.writeFile(workbook, fullFileName, writeOptions)
}
