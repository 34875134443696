import { makeDateSerie } from '../../../../helpers/dates/make-date-serie.js'
import { activateOptionSelector } from '../../../modules/option-selector/index.js'
import * as mAccount from '../../../modules/user/account.js'

export function showSelectors (changeAction) {
  console.log('showSelectors')

  const optionSelectorHide = [
    'periodicity',
    'forecasting',
    'valueAggregation',
    'gridLayout',

    'currencies'
  ]

  const optionSelectorShow = [
    // 'dates',
    // 'currencies'
  ]

  const accountData = mAccount.accountData()
  const dateseries = makeDateSerie(accountData.firstDate)

  const optionSelectorParams = activateOptionSelector({
    containerId: 'optionSelector',
    hide: optionSelectorHide,
    show: optionSelectorShow,
    onChange: changeAction,
    dateseries,
    dateOptions: {
      default: {
        from: 'relative:-2',
        to: 'relative:0'
      }
    },
    customSettings: {
      statementType: 'trial-balances',
      organisations: mapOrganisations(),
      orgOptions: {
        allowConso: false, // Should be true once the page feature is complete
        allowMultiple: false, // Should be true once the page feature is complete
        defaultIndex: 0
      },
      currencies: []
    }
  })
  return optionSelectorParams
}

function mapOrganisations () {
  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  const organisations = accountData?._accountOrganisations?.map(function (accountOrganisation) {
    return accountOrganisation._xeroOrganisation
  })
  return organisations
}
