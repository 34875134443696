import { mapArrayProperties, mapGroups } from '../../../helpers/data/array-of-objects/mapped-array.js'
import { cohortSettings } from './data.js'
import { extractCohortDate } from './utilities/dates.js'

export async function prepareAcquisitionCosts () {
  console.log('cohortSettings.recordsAcquisitionCosts', cohortSettings.recordsAcquisitionCosts)
  if (!cohortSettings.recordsAcquisitionCosts) return

  // Set the field names
  cohortSettings.fieldsAcquisitionCosts.timestamp = cohortSettings.recordsAcquisitionCosts.columns[0]
  cohortSettings.fieldsAcquisitionCosts.value = cohortSettings.recordsAcquisitionCosts.columns[1]

  const enrichedRecords = enrichAcquisitionRecords(cohortSettings.recordsAcquisitionCosts)
  console.log('enrichedRecords', enrichedRecords)

  const cohortsSpend = computeCostPerCohort(enrichedRecords)
  console.log('cohortsSpend', cohortsSpend)

  cohortSettings.cohortsAcquisitionCosts = cohortsSpend
}

function parseNumber (value) {
  if (typeof value === 'number') return value

  let _value = value
  _value = _value.replaceAll(',', '')
  return _value
}

function enrichAcquisitionRecords (records) {
  const properties = [
    {
      name: 'spendDate',
      accessor: cohortSettings.fieldsAcquisitionCosts.timestamp,
      manipulation: function (value) {
        // TODO: manage different format of dates input?
        return new Date(value)
      }
    }, {
      name: 'spendValue',
      accessor: cohortSettings.fieldsAcquisitionCosts.value,
      manipulation: function (value) {
        return -parseNumber(value)
      }
    }
  ]
  const enrichedArray = mapArrayProperties(records, properties)
  return enrichedArray
}

function computeCostPerCohort (records) {
  const properties = [
    {
      name: 'cohort',
      accessor: 'spendDate',
      manipulation: function (value) {
        return extractCohortDate(value, cohortSettings.cohortType)
      }
    }
  ]

  const withCohorts = mapArrayProperties(records, properties, { keepAllProperties: true })
  // console.log('withCohorts', withCohorts)

  const groupingProperties = [
    'cohort'
  ]
  const rollups = [
    {
      property: 'cohortTotalValue',
      calculation: ['sum', 'spendValue']
    }
  ]
  const cohorts = mapGroups(withCohorts, groupingProperties, rollups, { returnArray: true, nameOfValues: 'spends' })
  console.log('cohorts', cohorts?.length)

  return cohorts
}
