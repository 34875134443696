import * as XLSX from 'xlsx'

/**
 *
 * @param {*} worksheet
 * @param {*} sheetParsingOptions
 * @returns
 */
export function parseSheet (worksheet, sheetParsingOptions) {
  // console.log('parseSheet', worksheet, sheetParsingOptions)
  const options = {
    // header:
    // Defaults to records as object with property names
    // header: 1, // Makes Array of Array
    // header: 'A', // Makes a letter-ed columns

    // raw
    // true: raw value
    // false: formatted string
    raw: true

    // range
    // number to start at a certain row
  }

  if (sheetParsingOptions.header) {
    options.header = sheetParsingOptions.header
  }

  if (Number.isFinite(sheetParsingOptions.dataHeader)) {
    options.range = sheetParsingOptions.dataHeader
  }
  const sheetData = XLSX.utils.sheet_to_json(worksheet, options)
  // console.log('sheetData', sheetData)
  return sheetData
}
