
export function getUploads () {
  const uploadsIds = getUploadsList()

  const uploads = []

  uploadsIds.forEach(function (uploadId) {
    console.log('uploadId', uploadId)
    const upload = localStorage.getItem(uploadId)
    const uploadData = JSON.parse(upload)
    uploads.push(uploadData)
    console.log(uploadData)
  })
  return uploads
}

export function getUploadsList () {
  const uploadIdsString = localStorage.getItem('uploadsIds')
  console.log('uploadIdsString', uploadIdsString)
  const uploadsIds = uploadIdsString?.split(',')

  return uploadsIds || []
}
