export function getInputValue (record, inputPropertyName) {
  if (Array.isArray(inputPropertyName)) {
    let foundValue
    inputPropertyName.forEach(function (targetPropertyName) {
      const found = getRecordPropertyValue(record, targetPropertyName)
      if (found?.exists) foundValue = found.value
    })

    return foundValue
  } else {
    const found = getRecordPropertyValue(record, inputPropertyName)
    if (found?.exists) return found.value
  }
}

function getRecordPropertyValue (record, targetPropertyName) {
  if (!targetPropertyName) return undefined

  if (Object.hasOwn(record, targetPropertyName)) {
    return {
      exists: true,
      value: record[targetPropertyName]
    }
  }

  const trimmedPropertyName = targetPropertyName.trim()
  if (Object.hasOwn(record, trimmedPropertyName)) {
    return {
      exists: true,
      value: record[trimmedPropertyName]
    }
  }

  // Try trimming the propertyNames of the record
  let found
  Object.keys(record).forEach(function (oneRecordProperty) {
    if (found) return

    const trimmedPropertyName = oneRecordProperty.trim()
    if (trimmedPropertyName === targetPropertyName) {
      found = {
        exists: true,
        value: record[oneRecordProperty]
      }
    }
  })
  return found
}
