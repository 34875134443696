
/**
 * Extract the account code and name from a unique string
 * @param {*} accountName
 * @returns
 */
export function parseAccountName (accountName) {
  const accountCodePattern = '(^[0-9-/]+)'
  const regex = [accountCodePattern, ' ', '(.+)'].join('')
  const regexInstance = new RegExp(regex)

  const results = regexInstance.exec(accountName)

  const parsed = {
    code: results?.[1] || null,
    name: results?.[2] || accountName
  }

  return parsed
}
