import { group } from 'd3'

const mergingSettings = {
  mergingKey: 'orderId',
  propertiesToMerge: ['settledAt']
}

/**
 *
 * @param {*} datasets
 */
export function mergePropertiesOnDatasets (datasets) {
  console.log('mergePropertiesOnDatasets', datasets)
  if (!Array.isArray(datasets[0])) return
  if (!Array.isArray(datasets[1])) return

  // Index the datasets
  const datasetsMaps = []

  datasets?.forEach(function (dataset) {
    // countRecordsWithValue(dataset, mergingSettings.propertiesToMerge[0])

    const datasetMap = group(dataset,
      function (record) { return record[mergingSettings.mergingKey] }
    )
    datasetsMaps.push(datasetMap)
  })

  // Then go through the datasets, and find the properties to merge
  mergeDataset(datasetsMaps[0], datasetsMaps[1])
  mergeDataset(datasetsMaps[1], datasetsMaps[0])

  // console.log('mergePropertiesOnDatasets done', datasets)
  datasets?.forEach(function (dataset) {
    countRecordsWithValue(dataset, mergingSettings.propertiesToMerge[0])
  })
}

const showDebug = false
/**
 *
 * @param {*} records
 * @param {*} propertyName
 * @returns
 */
function countRecordsWithValue (records, propertyName) {
  if (!showDebug) return

  const count = records?.reduce(function (accumulator, record) {
    let counter = 0
    if (Object.hasOwn(record, propertyName)) counter = 1
    return accumulator + counter
  }, 0)
  console.log(records, 'with', propertyName, count)
  return count
}

/**
 *
 * @param {*} datasetSourceMap
 * @param {*} datasetTargetMap
 */
function mergeDataset (datasetSourceMap, datasetTargetMap) {
  // console.log('mergeDataset', datasetSourceMap, datasetTargetMap)
  datasetSourceMap.forEach(function (mapRecords) {
    mapRecords?.forEach(function (sourceRecord) {
      processSourceRecord(sourceRecord, datasetTargetMap)
    })
  })
}

function processSourceRecord (sourceRecord, datasetTarget) {
  // console.log('processSourceRecord', sourceRecord, datasetTarget)
  // Find datasetTarget record
  const targetRecordArray = datasetTarget.get(sourceRecord[mergingSettings.mergingKey])
  if (!targetRecordArray) return

  // Go through the properties to merge
  mergingSettings.propertiesToMerge.forEach(function (propertyToMerge) {
    const sourceValue = sourceRecord[propertyToMerge]
    // console.log('sourceValue', sourceValue)
    if (sourceValue === undefined) return

    targetRecordArray.forEach(function (targetRecord) {
      // console.log('targetRecord', targetRecord)
      if (Object.hasOwn(targetRecord, propertyToMerge) && targetRecord[propertyToMerge] !== sourceValue) {
        // console.warn('propertyToMerge present already and difference', propertyToMerge, sourceValue, 'vs.', targetRecord[propertyToMerge])
      } else {
        targetRecord[propertyToMerge] = sourceValue
      }
    })
  })
}
