import * as mAccount from '../../../modules/user/account.js'
import { contentValueFormatter, contentValueStyling } from '../../../helpers/helpers.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { accessObjectPropertyValue } from '../../../../helpers/data/objects.js'
// import { outputAccountDetails } from './helpers.js'

export function tableColumns (context) {
  console.log('tableColumns', context)
  // const ledgerData = context.data.tbLedgerData.trialBalances
  const ledgerData = context.data.tbLedgerData.ledger

  const columnDefs = [{
    id: 'Month',
    width: 90,
    valueGetter: function (cellParams) {
      return cellParams.rowDef.month
    }
  }, {
    id: 'Code',
    width: 70,
    valueGetter: function (cellParams) {
      // const targetAccountXeroId = cellParams.rowDef.chartAccountId

      const value = accessObjectPropertyValue('account.code', cellParams.rowDef) || ''
      return value
    }
  }, {
    id: 'Account',
    width: 200,
    valueGetter: function (cellParams) {
      const value = accessObjectPropertyValue('account.name', cellParams.rowDef) || '??'
      return value
    }
  }, {
    id: 'Value',
    width: 110,
    styles: ['numberColumns'],
    valueGetter: function (cellParams) {
      return getRecordValue(cellParams, 'amount', ledgerData.decimals)
    },
    valueFormatter: function (cellParams) {
      return contentValueFormatter({
        cellParams,
        decimals: optionSelectorData.decimals
      })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    }
  }, {
    id: 'Balance',
    width: 110,
    styles: ['numberColumns'],
    valueGetter: function (cellParams) {
      return getRecordValue(cellParams, 'balance', ledgerData.decimals)
    },
    valueFormatter: function (cellParams) {
      return contentValueFormatter({
        cellParams,
        decimals: optionSelectorData.decimals
      })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    }
  }, {
    id: 'Statement',
    width: 50,
    valueGetter: function (cellParams) {
      const value = accessObjectPropertyValue('account.statement', cellParams.rowDef) || ''
      return value
    }
  }]

  // Entity if needed
  if (Array.isArray(context.showOrgId) && context.showOrgId.length > 1) {
    columnDefs.push({
      id: 'Entity',
      width: 150,
      valueGetter: function (cellParams) {
        const entityId = accessObjectPropertyValue('entityId', cellParams.rowDef)
        const orgData = mAccount.getOrganisationData(entityId)
        const entityName = orgData?.name || '??'
        return entityName
      }
    })
  }

  // Trackings if applicable: using tracking1 and tracking2: replaced by the trackingCategoriesNames
  // const trackingsDBs = ['tracking1', 'tracking2']
  // trackingsDBs.forEach(function (trackingDBName) {
  //   if (context.data.tbLedgerData.dbs[trackingDBName]?.length > 0) {
  //     columnDefs.push({
  //       id: trackingDBName,
  //       width: 200,
  //       valueGetter: function (cellParams) {
  //         const targetPropertyPath = [trackingDBName, 'name'].join('.')
  //         return accessObjectPropertyValue(targetPropertyPath, cellParams.rowDef) || '-'
  //       }
  //     })
  //   }
  // })

  context.data.tbLedgerData.dbs.trackingCategoriesNames?.forEach(function (oneCategoryName) {
    columnDefs.push({
      id: oneCategoryName,
      width: 200,
      valueGetter: function (cellParams) {
        let cellValue = '-'
        const trackingsDBs = ['tracking1', 'tracking2']
        trackingsDBs.forEach(function (trackingDBName) {
          const targetPropertyPath = [trackingDBName, 'id'].join('.')
          const trackingValue = accessObjectPropertyValue(targetPropertyPath, cellParams.rowDef)

          const trackingOptions = context.data.tbLedgerData.dbs.trackingsByName.get(oneCategoryName)
          const found = trackingOptions.find(function (trackingOption) {
            return trackingOption.id === trackingValue
          })
          if (found) cellValue = found.name
        })
        return cellValue
      }
    })
  })

  // columnDefs.push({
  //   id: 'Account Type',
  //   width: 100,
  //   valueGetter: function (cellParams) {
  //     const value = accessObjectPropertyValue('account.type', cellParams.rowDef) || '??'
  //     return value
  //   }
  // })

  // columnDefs.push({
  //   id: 'Trackings',
  //   width: 300,
  //   valueGetter: function (cellParams) {
  //     return JSON.stringify({
  //       t1: cellParams.rowDef.tracking1Id,
  //       t2: cellParams.rowDef.tracking2Id
  //     }
  //     )
  //   }
  // })

  // }, {
  //   // Development values: records are not user transactions as it's already aggregated
  //   id: 'Records',
  //   width: 120,
  //   styles: ['numberColumns'],
  //   valueGetter: function (cellParams) {
  //     return cellParams.rowDef.records
  //   },
  //   valueFormatter: function (cellParams) {
  //     return contentValueFormatter({
  //       cellParams,
  //       decimals: 0
  //     })
  //   },
  //   valueStyling: function (cellParams) {
  //     return contentValueStyling({ cellParams })
  //   }

  return columnDefs
}

function getRecordValue (cellParams, valueProperty, significantDecimals) {
  const baseValue = cellParams.rowDef[valueProperty]
  const valueMultiplier = Math.pow(10, significantDecimals) || 1
  return baseValue / valueMultiplier
}
