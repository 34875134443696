
// App config
import * as configVar from '../../../config/config.js'
import { applyRestrictions } from '../../../modules/user/html-parser.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'
import * as helperString from '../../../../helpers/helpers/string.js'
import { showCalendly } from '../../../modules/calendly/calendly.js'

// Page Module
import htmlLoggedIn from './logged-in.html'
import htmlLoggedOut from './logged-out.html'

export async function show () {
  console.log('auth index show()')

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')

  const userProfile = mUser.userData()
  let htmlIndex
  if (!userProfile) htmlIndex = htmlLoggedOut
  if (userProfile) htmlIndex = htmlLoggedIn

  htmlIndex = applyRestrictions(htmlIndex, { userData: mUser.userData() })
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  if (!userProfile) showLoggedOutUser()
  if (userProfile) showLoggedInUser(userProfile)

  showCalendly({
    containerId: 'showCalendlyBooking'
  })
}

function showLoggedInUser (userProfile) {
  console.log('showLoggedInUser', userProfile)

  document.title = ['Your user info'].join(' ')

  const userInfoString = []

  Object.keys(userProfile).forEach(function (oneUserKey) {
    if (typeof userProfile[oneUserKey] === 'string') {
      userInfoString.push('<b>' + oneUserKey + ':</b> ' + userProfile[oneUserKey])
    } else {
      userInfoString.push('<b>' + oneUserKey + ':</b>')
      const subObj = userProfile[oneUserKey]
      Object.keys(subObj).forEach(function (oneSubKey) {
        userInfoString.push(' .<b>' + oneSubKey + ':</b> ' + JSON.stringify(subObj[oneSubKey]))
      })
    }
  })
  console.log(userInfoString)
  const DOMelement = document.getElementById('userInfo')
  DOMelement.innerHTML = userInfoString.join('<br>')

  const loggedInInfoElement = document.getElementById('loggedInInfo')
  loggedInInfoElement.hidden = false

  showSignInButtons(userProfile)
  enablePublicAccountsFeature()
}

function showLoggedOutUser () {
  document.title = ['Sign up and Log in Zenflow'].join(' ')

  // const DOMelement = document.getElementById('userInfo')
  // DOMelement.innerHTML = 'Please log in'

  // const loggedInInfoElement = document.getElementById('loggedInInfo')
  // loggedInInfoElement.hidden = true

  // const loginLinksElement = document.getElementById('loginLinks')
  // loginLinksElement.hidden = false
}

function showSignInButtons (userProfile) {
  if (!userProfile) return

  // const loginLinksElement = document.getElementById('loginLinks')
  // loginLinksElement.hidden = true

  if (userProfile.xeroProfile) {
    const xeroButton = document.querySelector('#loginLinks [d-login-method="xero"]')
    xeroButton?.classList.add('is-hidden')
  }

  if (userProfile.googleProfile) {
    const googleButton = document.querySelector('#loginLinks [d-login-method="google"]')
    googleButton?.classList.add('is-hidden')
  }
}

/**
 * The feature is activated at the browser level only (no DB sync)
 */
function enablePublicAccountsFeature () {
  const featureElement = document.getElementById('publicAccountFeature')
  if (!featureElement) return

  let appStatus = localStorage.getItem('appStatus')
  appStatus = JSON.parse(appStatus)
  if (!appStatus.enablePublicFeature) {
    featureElement.classList.add('is-hidden')
    return
  }

  const featureEnablingElement = document.getElementById('publicAccountFeatureToggle')

  const toggleUserClientPosition = localStorage.getItem('featurePublicAccounts')
  if (helperString.toBoolean(toggleUserClientPosition) === true) {
    featureEnablingElement.checked = true
  }

  featureEnablingElement.addEventListener('change', function () {
    const togglePosition = featureEnablingElement.checked
    localStorage.setItem('featurePublicAccounts', togglePosition)
  })
}
