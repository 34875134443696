
import * as header from './header.js'
import * as rowsLeft from './rows-left.js'
import * as rowsCenter from './rows-center.js'

import * as content from './content.js'
import * as columnDefs from './column-defs.js'
import * as cell from './cell.js'

import * as events from './events.js'
import * as scrolling from './scrolling.js'
import { enableExport } from './export.js'
import { enableConsole } from '../logging/console.js'

import htmlTemplate from './grid-container.html'
import { setElementHtml } from '../../client/helpers/dom.js'
import { enableActionExport } from './export/enable-action-export.js'

import './style-theme/cssgrid.less'
import './style-theme/default-style.less'

/**
 *
 * @param {Object} params
 * @param {String} params.containerElementId - The ID of the DOM element
 * @param {object[]} params.colDefs - Array of colDef{}
 * @param {object[]} params.rowDefs - Array of rowDef{}
 * @param {Array} [params.showActions] - TRUE to show actions (Export to csv); Defaults to FALSE
 * @param {Object} [params.context] Hold whatever user wants
 * @param {String[]} [params.classes] CSS classes to add to the grid
 * @param {Array|Boolean} [params.startingScrollSettings] Init position within the grid
 * @param {any} [params.gridElements]
 * @returns
 */
export function generateGrid (params) {
  enableConsole(false)
  console.log('generateGrid', params)
  const { colDefs, rowDefs } = params
  const gridInstance = params

  const gridElements = createGridElements(gridInstance)
  if (!gridElements.gridElement) return
  gridInstance.gridElements = gridElements

  // Initialise
  const parsedColumnsSettings = columnDefs.parseColumnDefs({ columnDefs: colDefs })
  // console.log('parsedColumnsSettings', parsedColumnsSettings)
  gridInstance._infos = {
    headerRows: parsedColumnsSettings.columnsAnalysis.numberHeaderRows,
    leftColumns: parsedColumnsSettings.leftColumns.length
  }
  const cssTemplateRows = content.generateRowsCssTemplate(rowDefs)

  // Expose function to show the grid: allowing the refresh it
  gridInstance.showGrid = function () {
    _showGrid({
      gridSettings: gridInstance,
      gridElements,
      parsedColumnsSettings,
      rowDefs,
      cssTemplateRows
    })
  }
  gridInstance.showGrid()

  enableConsole('reset')
  return gridInstance
}

/**
 *
 * @param {*} params
 */
function _showGrid (params) {
  const { gridSettings, gridElements, rowDefs, parsedColumnsSettings, cssTemplateRows } = params

  gridSettings._layoutElements = gridSettings._layoutElements || { top: {}, center: {} }
  gridSettings._layoutElements.top.left = {}
  gridSettings._layoutElements.top.center = {}
  gridSettings._layoutElements.center.left = {}
  gridSettings._layoutElements.center.center = {}

  gridSettings._cells = {}
  gridSettings._cellsArray = []

  gridElements.gridElement.innerText = ''

  //
  // Header: Top Left
  // console.log('**** TOP LEFT')
  const hTopLeftCellDefs = header.generateHeaderCellsParams({
    gridSettings,
    columnDefs: parsedColumnsSettings.leftColumns,
    horizontalPosition: 'left'
  })

  const layoutTopLeftElement = document.createElement('div')
  layoutTopLeftElement.classList.add('layout_top_left')
  layoutTopLeftElement.style['grid-template-columns'] = columnDefs.generateRowColumnsWidth(parsedColumnsSettings.leftColumns)
  gridElements.gridElement.appendChild(layoutTopLeftElement)
  gridSettings._layoutElements.top.left = layoutTopLeftElement

  hTopLeftCellDefs.forEach(function (oneCellDef) {
    const cellElement = cell.createCell({ cellParams: oneCellDef })
    layoutTopLeftElement.appendChild(cellElement)

    gridSettings._cells[oneCellDef._cellId] = oneCellDef
    gridSettings._cellsArray.push(oneCellDef)
  })

  //
  // Header: Top Center
  // console.log('**** TOP CENTER')
  const hTopCenterCellDefs = header.generateHeaderCellsParams({
    gridSettings,
    columnDefs: parsedColumnsSettings.centerColumns,
    horizontalPosition: 'center'
  })

  const layoutTopCenterElement = document.createElement('div')
  layoutTopCenterElement.classList.add('layout_top_center', 'scroll')
  layoutTopCenterElement.style['grid-template-columns'] = columnDefs.generateRowColumnsWidth(parsedColumnsSettings.centerColumns)
  gridElements.gridElement.appendChild(layoutTopCenterElement)
  gridSettings._layoutElements.top.center = layoutTopCenterElement

  hTopCenterCellDefs.forEach(function (oneCellDef) {
    const cellElement = cell.createCell({ cellParams: oneCellDef })
    layoutTopCenterElement.appendChild(cellElement)

    gridSettings._cells[oneCellDef._cellId] = oneCellDef
    gridSettings._cellsArray.push(oneCellDef)
  })

  //
  // Content Center Left
  // console.log('**** CENTER LEFT')
  const rowLeftDefs = rowsLeft.generateRowsLeftCellsParams({
    gridSettings,
    rowDefs,
    columnDefs: parsedColumnsSettings.leftColumns
  })

  const layoutCenterLeftElement = document.createElement('div')
  layoutCenterLeftElement.classList.add('layout_center_left', 'scroll', 'scroll-vertical')
  layoutCenterLeftElement.style['grid-template-columns'] = columnDefs.generateRowColumnsWidth(parsedColumnsSettings.leftColumns)
  layoutCenterLeftElement.style['grid-template-rows'] = cssTemplateRows
  gridElements.gridElement.appendChild(layoutCenterLeftElement)
  gridSettings._layoutElements.center.left = layoutCenterLeftElement

  rowLeftDefs.forEach(function (oneCellDef) {
    const cellElement = cell.createCell({ cellParams: oneCellDef })
    layoutCenterLeftElement.appendChild(cellElement)

    gridSettings._cells[oneCellDef._cellId] = oneCellDef
    gridSettings._cellsArray.push(oneCellDef)
  })

  //
  // Content Center Center
  // console.log('**** CENTER CENTER')
  const contentCellDefs = rowsCenter.generateContentCellsParams({
    gridSettings,
    rowDefs,
    columnDefs: parsedColumnsSettings.centerColumns
  })

  const layoutCenterCenterElement = document.createElement('div')
  layoutCenterCenterElement.classList.add('layout_center_center', 'scroll', 'scroll-vertical')
  layoutCenterCenterElement.style['grid-template-columns'] = columnDefs.generateRowColumnsWidth(parsedColumnsSettings.centerColumns)
  layoutCenterCenterElement.style['grid-template-rows'] = cssTemplateRows
  gridElements.gridElement.appendChild(layoutCenterCenterElement)
  gridSettings._layoutElements.center.center = layoutCenterCenterElement

  contentCellDefs.forEach(function (oneCellDef) {
    const cellElement = cell.createCell({ cellParams: oneCellDef })
    layoutCenterCenterElement.appendChild(cellElement)

    gridSettings._cells[oneCellDef._cellId] = oneCellDef
    gridSettings._cellsArray.push(oneCellDef)
  })

  scrolling.activateScrolling(gridSettings)
  scrolling.replicateScrolling(gridSettings)

  enableExport({ gridSettings })
}

/**
 *
 * @param {*} params
 * @returns
 */
function createGridElements (gridInstance) {
  const { containerElementId } = gridInstance

  enableConsole(true)

  console.log('createGridElements', gridInstance)

  const containerParentElement = setElementHtml(containerElementId, htmlTemplate)
  if (!containerParentElement) {
    console.warn('no container element available:', containerElementId)
    return
  }

  const containerElement = containerParentElement.querySelector('div')

  const gridElement = createGridContainer(gridInstance)
  containerElement.appendChild(gridElement)

  if (gridInstance?.showActions) {
    enableActionExport(gridInstance, containerElement)
  }

  // Listeners
  events.addClickListeners({
    gridElement,
    gridSettings: gridInstance
  })

  return {
    containerElement,
    gridElement
  }
}

function createGridContainer (params) {
  const gridElement = document.createElement('div')
  gridElement.classList.add('cssGrid')

  if (params.classes) {
    gridElement.classList.add(...params.classes)
  }

  return gridElement
}
