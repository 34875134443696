// Default
const COHORT_TYPE = 'month'
// const COHORT_TYPE = 'quarter'

// Storage object
export const cohortSettings = {
  cohortType: COHORT_TYPE,
  records: null,
  fields: {
    userId: null,
    actionTimestamp: null,
    actionValue: null
  },
  fieldsAcquisitionCosts: {
    timestamp: null,
    value: null
  }
}
