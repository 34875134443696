import { callApi } from '../../helpers/fetch.js'

export function listener (params) {
  const { orgIds } = params

  const buttonElement = document.getElementById('callForGoogleSheet')
  if (!buttonElement) return
  buttonElement.addEventListener('click', function (clickEvent) {
    showLoadingFeedback(true)
    generateGoogleSheet({ orgIds })
  })

  // initialise
  showLinkToGoogleSheet()

  showLoadingFeedback(false)
}

function showLinkToGoogleSheet (fileUrl) {
  // Show a link to the file
  const linkElementId = 'gsheet'
  const linkContainerElement = document.getElementById(linkElementId)

  if (fileUrl) {
    const linkElement = linkContainerElement.querySelector('a')
    linkElement.setAttribute('href', fileUrl)
    // linkElement.innerHTML = '<a href=":fileUrl" target="_blank">Google sheets</a>'.replace(':fileUrl', fileUrl)
    linkContainerElement.hidden = false
    showLoadingFeedback(false)
  } else {
    linkContainerElement.hidden = true
  }
}

function showLoadingFeedback (show) {
  console.log('showLoadingFeedback', show)
  const feedbackElement = document.getElementById('loadingFeedback')

  if (show) {
    feedbackElement.classList.remove('is-hidden')
  } else {
    feedbackElement.classList.add('is-hidden')
  }
}

async function generateGoogleSheet (params) {
  console.log('generateGoogleSheet', params)
  const { orgIds } = params

  const apiUrl = '/api/xero/:orgIds/financials?gsheet=1'
  const data = await callApi(apiUrl, {
    orgIds
  })

  showLinkToGoogleSheet(data.fileUrl)
  return true
}
