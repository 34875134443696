import { generateRandomString } from '../../../../../helpers/helpers/random.js'
import { getUploadsList } from './get-uploads.js'

export async function storeUpload (file, entityName, dataRows) {
  const fileName = file.name
  const fileSize = file.size

  const id = generateRandomString(8)
  const uploadId = ['up', id].join('.')

  const storage = {
    id: uploadId,
    fileName,
    fileSize,
    entityName,
    dataRows
  }
  const storageString = JSON.stringify(storage)

  localStorage.setItem(uploadId, storageString)
  updateListUpload(uploadId, 'add')
}

/**
 *
 * @param {String} id
 * @param {String} action - add, delete
 */
export function updateListUpload (id, action) {
  const uploadsIds = getUploadsList()

  if (action === 'add') {
    uploadsIds.push(id)
  } else if (action === 'delete') {
    const targetId = uploadsIds.indexOf(id)
    uploadsIds.splice(targetId, 1)
  }

  localStorage.setItem('uploadsIds', uploadsIds)
}
