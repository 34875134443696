export const salesBySKUOrderOutputs = {
  sourceData: 'ordersLines',
  groupingProperties: ['SKU_id', 'orderId'],
  propertiesSettings: [
    {
      propertyName: 'orderId',
      width: 170
    },
    {
      propertyName: 'SKU_id',
      width: 170
    },
    {
      propertyName: 'numberRecords',
      width: 100,
      type: 'number'
    },
    {
      outputName: 'SKU type',
      computeFunction: function (record) {
        const type = []
        if (record.isDirectProduct.has(true)) type.push('productSKU')
        if (record.isUnknownProduct.has(true)) type.push('(Unknown)')
        if (record.isFromBundle.has(true)) type.push('(From Bundle)')
        if (record.isBundle.has(true)) type.push('isBundle')
        // console.log('computeFunction', record, type)
        return type.join(' ')
      },
      width: 180
    },
    {
      outputName: 'quantityFromDirect',
      computeFunction: function (record) {
        const value = record.records.reduce(function (accumulator, sourceRecord) {
          return accumulator + (sourceRecord.isDirectProduct || 0) * sourceRecord.quantity
        }, 0)
        return value
      },
      width: 100,
      type: 'number'
    },
    {
      outputName: 'quantityFromBundles',
      computeFunction: function (record) {
        const value = record.records.reduce(function (accumulator, sourceRecord) {
          return accumulator + (sourceRecord.isFromBundle || 0) * sourceRecord.quantity
        }, 0)
        return value
      },
      width: 100,
      type: 'number'
    },
    {
      propertyName: 'quantity',
      width: 100,
      type: 'number'
    },
    {
      outputName: 'revenueGrossFromDirect',
      computeFunction: function (record) {
        const value = record.records.reduce(function (accumulator, sourceRecord) {
          return accumulator + (sourceRecord.isDirectProduct || 0) * sourceRecord.revenueGross
        }, 0)
        return value
      },
      width: 100,
      type: 'number'
    },
    {
      outputName: 'revenueGrossFromBundles',
      computeFunction: function (record) {
        const value = record.records.reduce(function (accumulator, sourceRecord) {
          return accumulator + (sourceRecord.isFromBundle || 0) * sourceRecord.revenueGross
        }, 0)
        return value
      },
      width: 100,
      type: 'number'
    },
    {
      propertyName: 'revenueGross',
      width: 100,
      type: 'number'
    },
    {
      outputName: 'revenueNetFromDirect',
      computeFunction: function (record) {
        const value = record.records.reduce(function (accumulator, sourceRecord) {
          return accumulator + (sourceRecord.isDirectProduct || 0) * sourceRecord.revenueNet
        }, 0)
        return value
      },
      width: 100,
      type: 'number'
    },
    {
      outputName: 'revenueNetFromBundles',
      computeFunction: function (record) {
        const value = record.records.reduce(function (accumulator, sourceRecord) {
          return accumulator + (sourceRecord.isFromBundle || 0) * sourceRecord.revenueNet
        }, 0)
        return value
      },
      width: 100,
      type: 'number'
    },
    {
      propertyName: 'revenueNet',
      width: 100,
      type: 'number'
    }
  ]
}
