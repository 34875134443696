import { parseDateTimeString } from '../../../../utilities/parse-time.js'
import { parseTiktokId } from '../../../../utilities/parse-tiktokid.js'
import { parseNumberDotSeparated } from '../../../utilities/parse-number-dot-separated.js'
import { parseNumber } from '../../../utilities/parse-number.js'

/**

 */
export const tiktokOrdersFieldsMapping = [
  {
    inputName: 'Order ID',
    outputName: 'orderId',
    lineCombination: 'first',
    lineCombinationId: true,
    parseFunction: parseTiktokId
  },
  {
    outputName: 'recordType',
    parseFunction: function () {
      return 'orderLine'
    }
  },
  {
    outputName: 'orderPlatform',
    parseFunction: function (record, context) {
      return context?.platform || undefined
    }
  },
  {
    outputName: 'orderCountry',
    parseFunction: function (record, context) {
      return context?.country || undefined
    }
  },
  {
    outputName: 'orderLines',
    lineCombination: 'records'
  },
  {
    // The SKU ID as per Seller ID
    inputName: 'Seller SKU',
    outputName: 'SKU_id',
    parseFunction: parseTiktokId
  },
  {
    inputName: 'Buyer Username',
    outputName: 'buyerUsername',
    lineCombination: 'first'
  },
  {
    inputName: 'Order Status',
    outputName: 'orderStatus',
    lineCombination: 'first'
  },
  {
    inputName: 'Created Time',
    outputName: 'createdAt',
    parseFunction: parseDateTimeString,
    lineCombination: 'first'
  },
  {
    inputName: 'Paid Time',
    outputName: 'paidAt',
    parseFunction: parseDateTimeString,
    lineCombination: 'first'
  },
  {
    inputName: 'Shipped Time',
    outputName: 'shippedAt',
    parseFunction: parseDateTimeString
  },
  {
    inputName: 'Delivered Time',
    outputName: 'deliveredAt',
    parseFunction: parseDateTimeString
  },
  {
    inputName: 'Cancelled Time',
    outputName: 'cancelledAt',
    parseFunction: parseDateTimeString
  },
  {
    inputName: 'Quantity',
    outputName: 'quantity',
    parseFunction: parseNumber,
    lineCombination: 'sum'
  },
  {
    inputName: 'Original Shipping Fee',
    outputName: 'originalShippingFee',
    parseFunction: parseNumberDotSeparated,
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        include: false
      }
    }
  },
  {
    inputName: 'Order Amount',
    outputName: 'orderAmount',
    parseFunction: parseNumberDotSeparated,
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        include: false
      }
    }
  },
  {
    inputName: 'Order Refund Amount',
    outputName: 'orderRefundAmount',
    parseFunction: parseNumberDotSeparated,
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        include: false
      }
    }
  },
  {
    inputName: 'SKU Subtotal Before Discount',
    outputName: 'SKUsubtotalBeforeDiscount',
    parseFunction: parseNumberDotSeparated,
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        include: false
      }
    }
  },
  {
    inputName: 'SKU Seller Discount',
    outputName: 'SKUSellerDiscount',
    parseFunction: parseNumberDotSeparated,
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        include: false
      }
    }
  },
  {
    inputName: 'SKU Platform Discount',
    outputName: 'SKUPlatformDiscount',
    parseFunction: parseNumberDotSeparated,
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        include: false
      }
    }
  },
  {
    inputName: 'Shipping Fee Seller Discount',
    outputName: 'shippingFeeSellerDiscount',
    parseFunction: parseNumberDotSeparated,
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        include: false
      }
    }
  },
  {
    inputName: 'Shipping Fee Platform Discount',
    outputName: 'shippingFeePlatformDiscount',
    parseFunction: parseNumberDotSeparated,
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        include: false
      }
    }
  },
  {
    outputName: 'revenueGross',
    computeFormula: 'SKUsubtotalBeforeDiscount - orderRefundAmount',
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        split: true
      }
    }
  },
  {
    outputName: 'discount',
    computeFormula: '- SKUSellerDiscount', // TikTok Order Discount is Positive, we make it negative
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        split: true
      }
    }
  },
  {
    outputName: 'revenueNet',
    computeFormula: 'revenueGross + discount',
    lineCombination: 'sum',
    additional: {
      splitBundles: {
        split: true
      }
    }
  }
]
