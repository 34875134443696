import { enableConsole } from '../logging/console.js'
import { copyValue } from './event-context-menu.js'
import { rowOpenings } from './helpers.js'

export function addClickListeners (params) {
  enableConsole(false)
  console.log('addClickListeners')
  const { gridElement, gridSettings } = params

  gridElement.addEventListener('click', function (clickEvent) {
    const cellElement = clickEvent.target.closest('.cell')
    if (cellElement) {
      cellClicked({
        cellElement,
        gridSettings
      })
    }
  })

  gridElement.addEventListener('dblclick', function (clickEvent) {
    console.log('double click')
    const cellElement = clickEvent.target.closest('.cell')
    if (cellElement) {
      cellDoubleClicked({
        cellElement,
        gridSettings
      })
    }
  })

  gridElement.addEventListener('contextmenu', function (clickEvent) {
    console.log('right click')
    const cellElement = clickEvent.target.closest('.cell')
    if (cellElement) {
      clickEvent.preventDefault()
      copyValue(cellElement)
    }
  })
  enableConsole('reset')
}

function cellClicked (params) {
  const { cellElement, gridSettings } = params

  const cellId = cellElement.getAttribute('_cellId')
  // console.log('clicked', cellId, params)
  if (!cellId) return

  const cellParams = gridSettings._cells[cellId]
  // console.log('cellParams', cellParams)

  // Disable click behaviour when cell is loading
  if (cellParams?._isLoading) return

  if (cellParams?.columnDef?.onClick && typeof cellParams.columnDef.onClick === 'function') {
    return cellParams.columnDef.onClick(cellParams)
  }
}

function cellDoubleClicked (params) {
  const { cellElement, gridSettings } = params

  const cellId = cellElement.getAttribute('_cellId')
  console.log('double clicked', cellId, params)
  if (!cellId) return

  const cellParams = gridSettings._cells[cellId]

  // Disable click behaviour when cell is loading
  if (cellParams?._isLoading) return

  // Manage row opening/closing
  console.log('cellParams', cellParams)
  if (cellParams.rowDef.children && cellParams.rowDef.children.length > 0) {
    console.log('is parent row')

    // Toggle
    cellParams.rowDef._showChildren = !cellParams.rowDef._showChildren

    // Record to allow reapply
    if (cellParams.rowDef._showChildren) {
      rowOpenings.add(cellParams.rowDef.id)
    } else {
      rowOpenings.delete(cellParams.rowDef.id)
    }

    console.log('cellParams.rowDef._showChildren', cellParams.rowDef._showChildren)
    gridSettings.showGrid()
  }

  // Might not actually focus if the grid was redrawn (the Element would not exist anymore)
  cellElement.focus()

  // User defined behaviour
  if (cellParams?.columnDef?.onDblClick && typeof cellParams.columnDef.onDblClick === 'function') {
    return cellParams.columnDef.onDblClick(cellParams)
  }
}
