
// App config
import { fillString } from '../../../../helpers/templating/string-template.js'
import * as configVar from '../../../config/config.js'
import { showElement } from '../../../helpers/dom.js'
import { callApi } from '../../../helpers/fetch.js'
import { makeXeroLink } from '../../../helpers/xero-links.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'
import { loadDisconnectXeroOrg } from '../../xero/index.js'
import { loadCheckToken } from '../../xero/organisations-checktoken.js'
import { disableSupportAccess, enableSupportAccess } from '../utilities/support-invite.js'
import { infosShowAll } from './infos-show-all.js'

// Page Module
import htmlIndex from './infos.html'
import { loadOrgTokens, loadOrgUsers } from './users.js'

/**
 *
 * @returns
 */
export async function showOrgInfos (orgId) {
  console.log('showOrgInfos show()', orgId)

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  // Tokens
  showOrgTokens(orgId)

  // All Data info
  infosShowAll(orgId)

  const organisationAccess = await loadOrgUsers(orgId)
  showInfos(organisationAccess)
}

async function showInfos (organisationAccess) {
  const containerElement = document.getElementById('orgInfos')
  // containerElement.innerText = 'ok'

  showOrgDetails(organisationAccess)

  const byUsersAndAccounts = groupUsersAndAccounts(organisationAccess)
  showOrgAccounts(byUsersAndAccounts)
  showOrgUsers(byUsersAndAccounts)

  showDataInfo(organisationAccess.journalsInfo)

  // Hide Loading
  const loadingElement = containerElement.querySelector('progress')
  loadingElement.classList.add('is-hidden')
}

function showOrgDetails (organisationAccess) {
  console.log('showOrgDetails', organisationAccess)
  const containerElement = document.getElementById('organisationOverallDetails')
  containerElement.classList.remove('is-hidden')

  const targetElements = containerElement.querySelectorAll('[d-data="organisationName"]')
  targetElements.forEach(function (oneElement) {
    oneElement.innerText = organisationAccess.organisation.name
  })

  const targetElements2 = containerElement.querySelectorAll('[d-data="organisationShortcode"]')
  targetElements2.forEach(function (oneElement) {
    oneElement.innerText = organisationAccess.organisation.xeroShortcode
  })

  const targetElementsLinksToXero = containerElement.querySelectorAll('[d-data="organisationLinkToXero"]')
  const linkToXero = makeXeroLink('Home', {
    shortCode: organisationAccess.organisation.xeroShortcode
  })
  targetElementsLinksToXero.forEach(function (oneElement) {
    oneElement.innerHTML = fillString('<a href=":linkToXero" target="_blank">Open in Xero</a>', {
      linkToXero
    })
  })

  //
  const targetElementsLinksToAdminChartAccounts = containerElement.querySelectorAll('[d-data="orgAdminChartAccounts"]')
  const urlToOrgChartAccounts = fillString('/app/admin/org-info/:orgId', {
    orgId: organisationAccess.organisation.id
  })
  const linkToChartAccounts = fillString('<a href=":urlToOrgChartAccounts">Chart of Accounts</a>', {
    urlToOrgChartAccounts
  })
  targetElementsLinksToAdminChartAccounts.forEach(function (oneElement) {
    oneElement.innerHTML = linkToChartAccounts
  })

  //
  const targetElementsLinkToOrgSyncs = containerElement.querySelectorAll('[d-data="orgAdminSyncs"]')
  const urlToOrgSyncs = fillString('/app/admin/syncs/:orgId', {
    orgId: organisationAccess.organisation.id
  })
  const linkToOrgSync = fillString('<a href=":urlToOrgSyncs">View Syncs</a>', {
    urlToOrgSyncs
  })
  targetElementsLinkToOrgSyncs.forEach(function (oneElement) {
    oneElement.innerHTML = linkToOrgSync
    // oneElement.innerT = linkToOrgSync
  })

  // Schedule Data Sync
  const targetElementsLinkToScheduleSync = containerElement.querySelectorAll('[d-data="orgAdminScheduleSync"]')
  const linkToScheduleSync = fillString('<span data-action="scheduleSync" data-orgId=":orgId" class="button is-small">Schedule Sync</span>', {
    orgId: organisationAccess.organisation.id
  })
  targetElementsLinkToScheduleSync.forEach(function (oneElement) {
    oneElement.innerHTML = linkToScheduleSync
  })

  // Stop Scheduled Data Sync
  const targetElementsLinkToStopScheduleSync = containerElement.querySelectorAll('[d-data="orgAdminStopScheduleSync"]')
  const linkToStopScheduleSync = fillString('<span data-action="stopScheduleSync" data-orgId=":orgId" class="button is-small">Cancel Scheduled Sync</span>', {
    orgId: organisationAccess.organisation.id
  })
  targetElementsLinkToStopScheduleSync.forEach(function (oneElement) {
    oneElement.innerHTML = linkToStopScheduleSync
  })

  // Delete Xero Data
  const targetElementsLinkToDeleteXeroData = containerElement.querySelectorAll('[d-data="orgAdminDeleteXeroData"]')
  const linkToDeleteXeroData = fillString('<span data-action="deleteXeroData" data-orgId=":orgId" class="button is-small is-warning">Delete Xero Data</span>', {
    orgId: organisationAccess.organisation.id
  })
  targetElementsLinkToDeleteXeroData.forEach(function (oneElement) {
    oneElement.innerHTML = linkToDeleteXeroData
  })

  // Delete Xero Data: Sources
  const targetElementsLinkToDeleteXeroDataSources = containerElement.querySelectorAll('[d-data="orgAdminDeleteXeroDataSources"]')
  const linkToDeleteXeroDataSources = fillString('<span data-action="deleteXeroDataSources" data-orgId=":orgId" class="button is-small is-warning">Delete Xero Data (Sources only)</span>', {
    orgId: organisationAccess.organisation.id
  })
  targetElementsLinkToDeleteXeroDataSources.forEach(function (oneElement) {
    oneElement.innerHTML = linkToDeleteXeroDataSources
  })

  // Delete Entries Cache Data
  const targetElementsLinkToDeleteCacheData = containerElement.querySelectorAll('[d-data="orgAdminDeleteCache"]')
  const linkToDeleteCache = fillString('<span data-action="deleteCache" data-orgId=":orgId" class="button is-small is-warning">Delete Cache Data</span>', {
    orgId: organisationAccess.organisation.id
  })
  targetElementsLinkToDeleteCacheData.forEach(function (oneElement) {
    oneElement.innerHTML = linkToDeleteCache
  })

  // Generate Entries Cache Data
  const targetElementsLinkToGenerateCacheData = containerElement.querySelectorAll('[d-data="orgAdminRunCacheGeneration"]')
  const linkToGenerateCache = fillString('<span data-action="generateCache" data-orgId=":orgId" class="button is-small is-primary">Generate Cache Data</span>', {
    orgId: organisationAccess.organisation.id
  })
  targetElementsLinkToGenerateCacheData.forEach(function (oneElement) {
    oneElement.innerHTML = linkToGenerateCache
  })

  activateListeners()
}

function activateListeners () {
  async function listenGenerateCacheData (clickEvent) {
    const targetElement = clickEvent.target.closest('[data-action="generateCache"]')
    if (!targetElement) return

    const orgId = targetElement.getAttribute('data-orgId')
    console.log('orgId', orgId)

    await callApi('/api/finlogic/data/generate/:orgId', {
      orgId
    }, { method: 'GET' })
  }

  async function listenDeleteCacheData (clickEvent) {
    const targetElement = clickEvent.target.closest('[data-action="deleteCache"]')
    if (!targetElement) return

    const orgId = targetElement.getAttribute('data-orgId')
    console.log('orgId', orgId)

    await callApi('/api/finlogic/data/generate/:orgId/:options', {
      orgId,
      options: 2
    }, { method: 'DELETE' })
  }

  async function listenDeleteXeroData (clickEvent) {
    const targetElement = clickEvent.target.closest('[data-action="deleteXeroData"]')
    if (!targetElement) return

    const orgId = targetElement.getAttribute('data-orgId')
    console.log('orgId', orgId)

    await callApi('/api/finlogic/data/generate/:orgId/:options', {
      orgId,
      options: 1
    }, { method: 'DELETE' })
  }

  async function listenDeleteXeroDataSources (clickEvent) {
    const targetElement = clickEvent.target.closest('[data-action="deleteXeroDataSources"]')
    if (!targetElement) return

    const orgId = targetElement.getAttribute('data-orgId')
    console.log('orgId', orgId)

    await callApi('/api/finlogic/data/generate/:orgId/:options/:types', {
      orgId,
      options: 1,
      types: ['sources']
    }, { method: 'DELETE' })
  }

  async function listenDeleteSync (clickEvent) {
    const targetElement = clickEvent.target.closest('[data-action="stopScheduleSync"]')
    if (!targetElement) return

    const orgId = targetElement.getAttribute('data-orgId')
    console.log('orgId', orgId)

    await callApi('/api/xero/sync/:orgId', {
      orgId
    }, { method: 'DELETE' })
  }

  async function listenScheduleSync (clickEvent) {
    const targetElement = clickEvent.target.closest('[data-action="scheduleSync"]')
    if (!targetElement) return

    const orgId = targetElement.getAttribute('data-orgId')
    console.log('orgId', orgId)

    await callApi('/api/xero/sync/:orgId', {
      orgId
    }, { method: 'POST' })
  }

  document.addEventListener('click', async function (clickEvent) {
    listenScheduleSync(clickEvent)
    listenDeleteSync(clickEvent)
    listenDeleteXeroData(clickEvent)
    listenDeleteXeroDataSources(clickEvent)
    listenDeleteCacheData(clickEvent)
    listenGenerateCacheData(clickEvent)
  })
}

function groupUsersAndAccounts (organisationAccess) {
  const byUser = {}
  const byAccount = {}

  function storeByUser (oneUserAccount) {
    if (!oneUserAccount._user) return

    byUser[oneUserAccount._user._id] = byUser[oneUserAccount._user._id] || {
      user: oneUserAccount._user,
      accounts: []
    }

    byUser[oneUserAccount._user._id].accounts.push({
      inviteId: oneUserAccount._id,
      isSupport: oneUserAccount.isSupport,
      rights: oneUserAccount.rights,
      account: oneUserAccount._account
    })
  }

  function storeByAccount (oneUserAccount) {
    if (!oneUserAccount._account) return

    byAccount[oneUserAccount._account._id] = byAccount[oneUserAccount._account._id] || {
      account: oneUserAccount._account,
      users: []
    }

    byAccount[oneUserAccount._account._id].users.push({
      inviteId: oneUserAccount._id,
      isSupport: oneUserAccount.isSupport,
      rights: oneUserAccount.rights,
      user: oneUserAccount._user
    })
  }

  organisationAccess.allUserAccounts.forEach(function (oneAccount) {
    oneAccount.forEach(function (oneUserAccount) {
      storeByUser(oneUserAccount)
      storeByAccount(oneUserAccount)
    })
  })

  return { byUser, byAccount }
}

function showOrgAccounts (byUsersAndAccounts) {
  const containerElement = document.getElementById('organisationAccounts')
  containerElement.classList.remove('is-hidden')

  const contentElement = containerElement.querySelector('.content')
  contentElement.innerHTML = ''

  Object.values(byUsersAndAccounts.byAccount).forEach(function (oneAccount) {
    const accountElement = document.createElement('div')
    accountElement.classList.add('content')

    const nameElement = document.createElement('span')
    nameElement.classList.add('bold')
    nameElement.innerText = oneAccount.account.name + '   '
    accountElement.append(nameElement)

    if (oneAccount.account.isPublic) {
      const isPublicTag = document.createElement('span')
      isPublicTag.innerText = 'isPublic'
      isPublicTag.classList.add('tag', 'is-warning')
      accountElement.append(isPublicTag)
    }

    const creationDate = document.createElement('span')
    creationDate.innerText = (new Date(oneAccount.account.createdAt)).toString()
    creationDate.classList.add('tag')
    accountElement.append(creationDate)

    const accountLink = document.createElement('a')
    accountLink.setAttribute('href', '/app/a/:accountId'.replace(':accountId', oneAccount.account._id))
    accountLink.setAttribute('target', '_blank')
    accountLink.classList.add('tag', 'is-primary')
    accountLink.innerText = 'Open zenflow account'
    accountElement.append(accountLink)

    const accountPnLLink = document.createElement('a')
    accountPnLLink.setAttribute('href', '/app/a/:accountId/pnl/all'.replace(':accountId', oneAccount.account._id))
    accountPnLLink.setAttribute('target', '_blank')
    accountPnLLink.classList.add('tag', 'is-primary')
    accountPnLLink.innerText = 'Open P&L'
    accountElement.append(accountPnLLink)

    const accountUsersElement = document.createElement('div')

    let isSupportEnabled = false

    oneAccount.users.forEach(function (oneUser) {
      const userElement = document.createElement('div')
      userElement.innerHTML = '> ' + oneUser.user?.name + ' (' + oneUser.user?.email + ')' + ' [' + oneUser.rights?.join(', ') + ']' + (oneUser.isSupport ? ' <b>SUPPORT</b>' : '')

      if (oneUser.isSupport) isSupportEnabled = oneUser.inviteId
      accountUsersElement.append(userElement)
    })

    if (!isSupportEnabled) enableSupportAccess(accountUsersElement, oneAccount.account._id)
    if (isSupportEnabled) disableSupportAccess(accountUsersElement, isSupportEnabled)

    accountElement.append(accountUsersElement)
    contentElement.append(accountElement)
  })
}

function showOrgUsers (byUsersAndAccounts) {
  const containerElement = document.getElementById('organisationUsers')
  containerElement.classList.remove('is-hidden')

  const contentElement = containerElement.querySelector('.content')
  contentElement.innerHTML = ''

  Object.values(byUsersAndAccounts.byUser).forEach(function (oneUser) {
    const userElement = document.createElement('div')
    userElement.classList.add('content')

    const nameElement = document.createElement('span')
    nameElement.classList.add('bold')
    nameElement.innerText = oneUser.user?.name + ' (' + oneUser.user?.email + ')'
    userElement.append(nameElement)

    const userAccountsElements = document.createElement('div')

    oneUser.accounts.forEach(function (oneAccount) {
      const accountElement = document.createElement('div')
      accountElement.innerHTML = '> ' + oneAccount.account?.name + ' [' + oneAccount.rights?.join(', ') + ']' + (oneAccount.isSupport ? ' <b>SUPPORT</b>' : '')
      userAccountsElements.append(accountElement)
    })
    userElement.append(userAccountsElements)
    contentElement.append(userElement)
  })
}

async function showOrgTokens (orgId) {
  const containerElement = document.getElementById('organisationTokens')
  containerElement.classList.remove('is-hidden')

  const orgTokenInfos = await loadOrgTokens(orgId)
  console.log('orgTokenInfos', orgTokenInfos)

  const contentElement = containerElement.querySelector('.content')
  contentElement.innerHTML = 'Number Tokens: ' + orgTokenInfos.numberOfTokens

  const footerElement = containerElement.querySelector('footer')
  footerElement.classList.remove('is-hidden')

  // Link to Tokens page
  const targetElementsLinksToTokens = containerElement.querySelectorAll('[d-data="orgLinkTokens"]')
  const urlToTokens = fillString('/app/admin/tokens/:orgId', {
    orgId
  })
  const linkToTokens = fillString('<a href=":urlToTokens">See Tokens</a>', {
    urlToTokens
  })

  targetElementsLinksToTokens.forEach(function (oneElement) {
    oneElement.innerHTML = linkToTokens
  })

  tokenListeners(orgId)
}

function tokenListeners (orgId) {
  let checkTokenOngoing = false
  const checkTokenElement = document.getElementById('clickCheckToken')
  checkTokenElement.addEventListener('click', async function (clickEvent) {
    if (checkTokenOngoing) return
    checkTokenOngoing = true
    const statusElement = checkTokenElement.querySelector('.status')
    statusElement.innerText = ' > CHECKING'

    const data = await loadCheckToken(orgId)
    console.log('data', data)
    statusElement.innerText = ' > Works? ' + data.tokenWorks.work + ' [Scope=' + data.tokenWorks.tokenScope + ']'

    checkTokenOngoing = false
  })

  let disconnectOngoing = false
  const disconnectElement = document.getElementById('clickDisconnect')
  disconnectElement.addEventListener('click', async function (clickEvent) {
    if (disconnectOngoing) return
    disconnectOngoing = true
    const statusElement = disconnectElement.querySelector('.status')
    statusElement.innerText = ' > Disconnecting'

    const data = await loadDisconnectXeroOrg(orgId)
    console.log('data', data)
    statusElement.innerText = ' > Done? ' + data.response

    disconnectOngoing = false
  })
}

function showDataInfo (journalsInfo) {
  console.log('journalsInfo', journalsInfo)

  const element = showElement('organisationDataInfo')

  const journalDatesHtml = []
  Object.keys(journalsInfo.dates)?.forEach(function (oneDate) {
    journalDatesHtml.push('<br>' + oneDate + ': ' + journalsInfo.dates[oneDate])
  })

  const journalStoragesDatesHtml = []
  Object.keys(journalsInfo.stored)?.forEach(function (oneDate) {
    journalStoragesDatesHtml.push('<br>' + oneDate + ': ' + journalsInfo.stored[oneDate])
  })

  const contentHtml = fillString(element.innerHTML, {
    numberJournals: journalsInfo.count,
    datesInfo: journalDatesHtml.join(''),
    syncInfo: journalStoragesDatesHtml.join('')
  })

  element.innerHTML = contentHtml
}
