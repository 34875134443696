
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'

import * as cssGrid from '../../../helpers/cssgrid/index.js'

import { apiEndpointCurrencyList, apiEndpointCurrencyAssociate } from '../../../helpers/fx/settings.js'

// Page Module
import htmlIndex from './index.html'
import { callApi } from '../../helpers/fetch.js'

export async function show () {
  console.log('module show')

  if (mUser.enforceRestrictedPage()) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Create the page DOM Element:

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const accountData = mAccount.accountData()
  document.title = ['Currencies:', accountData.name].join(' ')

  showCurrencies()
  listeners()
}

function listeners () {

}

async function showCurrencies () {
  const accountData = mAccount.accountData()

  if (!accountData) return
  if (!accountData._id) return

  const currencyList = await loadCurrencyList()
  console.log('currencyList', currencyList)

  const currencyListElement = document.getElementById('currencyList')
  if (!currencyListElement) return

  const colDefs = [
    {
      id: 'currencySymbol',
      headerValueGetter: function () {
        return 'Code'
      },
      valueGetter: function (cellParams) {
        return cellParams.rowDef.symbol
      },
      layout: 'left',
      width: 60
    },
    {
      id: 'currencyName',
      headerValueGetter: function () {
        return 'Currency'
      },
      valueGetter: function (cellParams) {
        // console.log('valueGetter', cellParams)
        const targetSymbol = cellParams.rowDef.symbol
        const isActive = isCurrencyActive(accountData, targetSymbol)

        const cellValue = [
          isActive ? '✔' : '',
          cellParams.rowDef.name
        ].join(' ')

        return cellValue
      },
      onClick: async function (cellParams) {
        console.log('onClick', cellParams, cellParams._value, 'currency:', cellParams.rowDef.symbol)
        if (!cellParams.rowDef) return // No behaviour on header

        cellParams.showCell({ showLoading: true })

        const targetSymbol = cellParams.rowDef.symbol
        const isActive = isCurrencyActive(accountData, targetSymbol)

        await associate({
          symbol: cellParams.rowDef.symbol,
          change: isActive ? 'off' : 'on'
        })

        cellParams.showCell()
      },
      layout: 'left',
      // styles: ['clickable'], // Not sure why: it only styles the header, not the main rows
      width: 300
    }
  ]

  cssGrid.generateGrid({
    containerElementId: 'currencyList',
    classes: ['fit-content'],
    colDefs,
    rowDefs: currencyList.list,
    params: {
      dataset: currencyList
    }
  })
}

function isCurrencyActive (accountData, targetSymbol) {
  const associatedCurrency = accountData._accountCurrencies?.find(function (oneAccountCurrency) {
    return targetSymbol === oneAccountCurrency._fxcurrency.symbol
  })

  return !!associatedCurrency
}

async function loadCurrencyList () {
  console.log('loadCurrencyList')

  const apiUrl = apiEndpointCurrencyList
  const data = await callApi(apiUrl)
  return data
}

async function associate (params) {
  const accountData = mAccount.accountData()
  const accountId = accountData._id

  const apiUrl = apiEndpointCurrencyAssociate
  const data = await callApi(apiUrl, {
    accountId,
    symbol: params.symbol,
    change: params.change
  }, {
    method: 'PUT'
  })
  accountData._accountCurrencies = data.accountCurrencies
  // console.log('accountData', accountData)
  return data
}
