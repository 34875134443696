
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'

import { apiEndpoint as subscriptionCreateEndpoint } from '../../../helpers/subscription/settings.js'

// Page Module
import htmlIndex from './index.html'
import { callApi } from '../../helpers/fetch.js'

export async function show () {
  console.log('module show')

  if (mUser.enforceRestrictedPage()) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Subscribe to zenflow'].join(' ')

  // Create the page DOM Element:

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  loadChargebee()
  listeners()
  await backFromChargebee()
}

function listeners () {
  const buttonElements = document.querySelectorAll('.openSubscribe')
  if (!buttonElements) return

  buttonElements.forEach(function (oneButtonElement) {
    oneButtonElement.addEventListener('click', function (clickEvent) {
      const targetElement = clickEvent.target.closest('button')

      console.log('targetElement', targetElement)

      const planId = targetElement.getAttribute('d-plan')
      openChargebee(planId)
    })
  })
}

function loadChargebee () {
  console.log('loadChargebee')
  // Load Chargebee script
  const chargebeeScript = document.createElement('script')
  chargebeeScript.src = 'https://js.chargebee.com/v2/chargebee.js'
  document.head.appendChild(chargebeeScript)

  let appStatus = localStorage.getItem('appStatus')
  appStatus = JSON.parse(appStatus)
  if (!appStatus.chargebeeSite) {
    console.error('No chargebeeSite known')
    return
  }

  chargebeeScript.onload = function () {
    window.Chargebee.init({
      site: appStatus.chargebeeSite
    })
    Chargebee.registerAgain()
    console.log('chargebee ready')
  }
}

function openChargebee (planChoice) {
  const planChoiceMapping = {
    basic: 'basic2022',
    annual: 'growing-business-annual',
    monthly: 'growing-business-monthly',
    'annual-legacy': 'growing-business-annual-2019q1',
    'monthly-legacy': 'growing-business-monthly-2019q1',
    test: 'test'
  }

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)
  const userData = mUser.userData()

  console.log('openChargebee', planChoice)
  // https://www.chargebee.com/checkout-portal-docs/dropIn.html#overview
  const cbInstance = Chargebee.getInstance()
  const cart = cbInstance.getCart()
  const customer = {
    first_name: userData?.name || 'name',
    email: userData?.email || 'email',
    company: accountData?.name || 'account Name',
    billing_address: {
      first_name: userData?.name || 'name',
      email: userData?.email || 'email',
      company: accountData?.name || 'account Name'
    }
  }
  // Custom Field
  customer.cf_zenflowaccountid = accountData?.id || accountData?._id

  cart.setCustomer(customer)

  const productToUse = planChoiceMapping[planChoice] || 'test'
  const product = cbInstance.initializeProduct(productToUse)
  const numberOrganisations = 1 // accountData?.xeroOrganisations?.length || 1
  product.setPlanQuantity(numberOrganisations)

  // product.addAddon( {
  //   id: addon,
  //   quantity: 1
  // } )

  cart.replaceProduct(product)
  cart.proceedToCheckout()
}

// Expose openChargebee() to allow testing
// eg. openChargebee('test')
window.openChargebee = openChargebee

/**
 * Redirect urls are set in Chargebee
 * [baseUrl]?subId={{subscription.id}}&custId={{customer.id}}&q={{plan.quantity}}&planId={{plan.id}}
 */
async function backFromChargebee () {
  console.log('backFromChargebee')
  // Read Chargebee redirect
  const callbackFullUrl = new URL(location.href)
  const parametersNeeded = ['custId', 'subId', 'q', 'planId']
  console.log(new URL(location.href))

  // Check that all the params we want are present
  let isAllParamsPresent = true
  parametersNeeded.forEach(function (oneParam) {
    if (!callbackFullUrl.searchParams.has(oneParam)) {
      isAllParamsPresent = false
    }
  })
  if (!isAllParamsPresent) return

  // Call backend
  await recordSubscription(callbackFullUrl)
}

async function recordSubscription (callbackFullUrl) {
  const accountData = mAccount.accountData()

  if (!accountData) return
  if (!accountData._id) return

  const body = {}
  callbackFullUrl.searchParams.forEach(function (value, key) {
    console.log('oneParam', key, value)
    body[key] = value
  })

  const apiUrl = subscriptionCreateEndpoint
  const data = await callApi(apiUrl, {
    accountId: accountData._id
  }, {
    method: 'POST',
    body: JSON.stringify(body)
  })
  return data
}
