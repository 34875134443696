
/**
 * 2024-03-14
 *
 *
 * See client/pages/cohorts/connectors/shopee.js
 */
export const shopeeSettlementsFieldsMapping = [
  {
    inputName: 'No. Pesanan',
    outputName: 'orderId'
  },
  {
    outputName: 'recordType',
    parseFunction: function () {
      return 'settlementLine'
    }
  },
  {
    outputName: 'orderPlatform',
    parseFunction: function (record, context) {
      return context?.platform || undefined
    }
  },
  {
    outputName: 'orderCountry',
    parseFunction: function (record, context) {
      return context?.country || undefined
    }
  },
  {
    inputName: 'Username (Pembeli)',
    outputName: 'buyerUsername'
    // parseFunction
  },
  {
    inputName: 'Waktu Pesanan Dibuat',
    outputName: 'createdAt'
    // Shopee provides a YYYY-MM-DD string
  },
  {
    inputName: 'Tanggal Dana Dilepaskan',
    outputName: 'settledAt'
    // Shopee provides a YYYY-MM-DD string
  },
  //
  // Revenue
  {
    inputName: 'Harga Asli Produk',
    outputName: 'originalProductPrice'
  },
  {
    inputName: '?? Your Seller product promotion???',
    outputName: 'yourSellerProductPromotion'
  },
  {
    inputName: '?? Refund Amount??',
    outputName: 'refundAmount'
  },
  {
    outputName: 'revenueGross',
    computeFormula: 'originalProductPrice + yourSellerProductPromotion + refundAmount',
    computeFormulaDecimals: 2
  },
  {
    inputName: 'Total Penghasilan',
    outputName: 'totalReleasedAmount'
  },
  {
    outputName: 'settlementAmount',
    computeFormula: 'totalReleasedAmount',
    computeFormulaDecimals: 2
  },
  //
  // Discounts
  {
    inputName: '??? Product Discount Rebate from Shopee',
    outputName: 'productDiscountRebateFromShopee'
  },
  {
    inputName: '??? Voucher',
    outputName: 'voucher'
  },
  {
    inputName: '?? Seller Absorbed Coin Cashback',
    outputName: 'sellerAbsorbedCoinCashback'
  },
  {
    outputName: 'discount',
    computeFormula: 'productDiscountRebateFromShopee + voucher + sellerAbsorbedCoinCashback',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'revenueNet',
    computeFormula: 'revenueGross + discount',
    computeFormulaDecimals: 2
  },
  //
  // Distribution
  {
    inputName: '?? Shipping Fee Paid by Buyer',
    outputName: 'shippingFeePaidByBuyer'
  },
  {
    inputName: '?? Shipping Rebate From Shopee',
    outputName: 'shippingRebateFromShopee'
  },
  // Shipping Fee Discount from 3PL ? Visible in Settlement Summary; not in fields
  {
    inputName: '?? Actual Shipping Fee',
    outputName: 'actualShippingFee'
  },
  {
    inputName: '?? Reverse Shipping Fee',
    outputName: 'reverseShippingFee'
  },
  {
    outputName: 'onlineDistributionFees',
    computeFormula: 'shippingFeePaidByBuyer + shippingRebateFromShopee + actualShippingFee + reverseShippingFee',
    computeFormulaDecimals: 2
  },
  //
  // Platform fees
  {
    inputName: 'AMS Commission Fee',
    outputName: 'AMSCommissionFee'
  },
  {
    inputName: 'Biaya Administrasi (termasuk PPN 11%)',
    outputName: 'commissionFeeInclGST'
  },
  {
    inputName: 'Biaya Layanan (termasuk PPN 11%)',
    outputName: 'serviceFeeInclGST'
  },
  {
    inputName: 'Biaya Transaksi',
    outputName: 'transactionFeeInclGst'
  },
  {
    inputName: '?? Product Goods & Services Tax',
    outputName: 'productGoodsServicesTax'
  },
  {
    inputName: '??? Shipping Goods & Services Tax',
    outputName: 'shippingGoodsServicesTax'
  },
  {
    outputName: 'onlinePlatformFees',
    computeFormula: 'AMSCommissionFee + commissionFeeInclGST + serviceFeeInclGST + transactionFeeInclGst + productGoodsServicesTax + shippingGoodsServicesTax',
    computeFormulaDecimals: 2
  }

]
