
// Dependencies
import * as numbers from '../../helpers/helpers/numbers.js'

/**
 *
 * @param {*} params
 * @returns
 */
export function contentValueFormatter (params) {
  const { cellParams, decimals } = params

  if (!Number.isFinite(cellParams._value)) {
    return cellParams._value
  }

  if (cellParams._value === 0) {
    return 0
  }

  const _decimals = Number.isFinite(decimals) ? decimals : 0
  const numberFormatted = numbers.formatNumber(cellParams._value, _decimals)

  return numberFormatted
}

/**
 *
 * @param {*} params
 * @returns
 */
export function contentValueStyling (params) {
  const { cellParams } = params

  const additionalStyles = []

  if (cellParams._value === 0) {
    additionalStyles.push('zero')
  }
  return additionalStyles
}
