
// Shopee 201; Lazada 203; TikTok 213

import { choiceCountry } from '../ui/choose-country.js'
import { choicePlatform } from '../ui/choose-platform.js'

export function getXeroSettings () {
  const base = xeroSettings[choiceCountry?.chosenCountry?.value] || {}
  const salesAccount = salesAccounts[choicePlatform?.chosenPlatform?.value] || '??'

  const settings = Object.assign({}, base)
  settings.sales = salesAccount

  return settings
}

const salesAccounts = {
  shopee: 201,
  lazada: 203,
  tiktok: 213
}

const xeroSettings = {
  ID: {
    salesTax: 'VAT on Sales (11%)',
    discount: 290,
    receivedCash: 688,
    onlinePlatformFees: 318,
    onlineMarketingAds: 312,
    onlineDistributionFees: 316,
    prepayments: 620
  },
  TH: {
    salesTax: 'Tax on Sales (7%)',
    discount: 290,
    receivedCash: 688,
    onlinePlatformFees: 318,
    onlineMarketingAds: 312,
    onlineDistributionFees: 316,
    prepayments: 620
  },
  PH: {
    salesTax: 'VAT on Sales (11%)',
    discount: 290,
    receivedCash: 688,
    onlinePlatformFees: 318,
    onlineMarketingAds: 312,
    onlineDistributionFees: 316,
    prepayments: 620
  },
  MY: {
    salesTax: 'Exempt Sales (0%)',
    discount: 290,
    receivedCash: 688,
    onlinePlatformFees: 318,
    onlineMarketingAds: 312,
    onlineDistributionFees: 316,
    prepayments: 620
  },
  SG: {
    salesTax: 'None',
    discount: 290,
    receivedCash: 688,
    onlinePlatformFees: 318,
    onlineMarketingAds: 312,
    onlineDistributionFees: 316,
    prepayments: 620
  }
}
