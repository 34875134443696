import { mapArrayProperties, mapGroups } from '../../../helpers/data/array-of-objects/mapped-array.js'
import { cohortSettings } from './data.js'
import { extractCohortDate } from './utilities/dates.js'

// Group by date cohort
export function createCohorts (users) {
  const userArray = users

  const properties = [
    {
      name: 'cohort',
      accessor: 'firstAction.actionDate',
      manipulation: function (value) {
        return extractCohortDate(value, cohortSettings.cohortType)
      }
    }
  ]

  const withCohorts = mapArrayProperties(userArray, properties, { keepAllProperties: true })
  // console.log('withCohorts', withCohorts)

  const groupingProperties = [
    'cohort'
  ]
  const cohorts = mapGroups(withCohorts, groupingProperties, null, { returnArray: true, nameOfValues: 'users' })
  console.log('cohorts', cohorts)
  return cohorts
}

export function computeCohortsInfo (cohorts) {
  cohorts?.forEach(function (oneCohort) {
    computeCohortInfo(oneCohort)
  })
}

/**
 * The first transaction feeds the initial cohort values.
 *
 * Further transaction are relative period, even if in the same aggregate period: they would be period 0. Records in the array at index [1]
 *
 * @param {*} cohort
 */
function computeCohortInfo (cohort) {
  // console.log('computeCohortInfo', cohort)

  cohort.info = {
    userIds: [],
    users: [],
    userCumuls: [],
    values: [],
    valuesCumuls: []
  }

  cohort.users.forEach(function (oneUser) {
    oneUser.groupedActions.forEach(function (oneActionGroup) {
      const arrayIndex = oneActionGroup.key.isFirstAction ? 0 : (oneActionGroup.key.aggregateRelativeDate + 1)

      cohort.info.values[arrayIndex] = cohort.info.values[arrayIndex] || 0
      cohort.info.values[arrayIndex] += oneActionGroup.totalValue

      cohort.info.userIds[arrayIndex] = cohort.info.userIds[arrayIndex] || new Set()
      cohort.info.userIds[arrayIndex].add(oneUser.key.userId)

      cohort.info.users[arrayIndex] = cohort.info.users[arrayIndex] || 0
      cohort.info.users[arrayIndex] = cohort.info.userIds[arrayIndex].size
    })
  })

  let cumulSet = new Set()

  cohort.info.userIds.forEach(function (oneSet, index) {
    if (index === 0) {
      cohort.info.userCumuls[index] = cohort.info.users[index]
      return
    }
    cumulSet = new Set([...cumulSet, ...oneSet])
    cohort.info.userCumuls[index] = cumulSet.size
  })

  // Value cumuls
  cohort.info.valuesCumuls = cohort.info.values.map(function (value, index, array) {
    return array.reduce(function (accumulator, value, _index) {
      if (_index > index) return accumulator
      return accumulator + value
    }, 0)
  })
}
