
import * as gridHelpers from './helpers.js'

import { enableConsole } from '../logging/console.js'
enableConsole(null)

/**
 * TODO: Improve flexibility of module
 * rowDefs [{
 *  .id
 *  .styles
 *  .valueGetter: function
 *  .value
 *  .children [rowDef]
 * }]
 * @param {*} params
 * @returns
 */
export function generateRowsLeftCellsParams (params) {
  enableConsole(false)
  console.log('generateRowsLeftCellsParams', params)
  const { rowDefs, columnDefs } = params

  let rowNumber = 1
  const startingColNumber = 1

  const rowsHaveChildren = checkRowsHaveChildren(rowDefs)

  const cells = []

  rowDefs.forEach(function (oneRowDef) {
    // console.log('--- oneRowDef', oneRowDef)
    let colNumber = startingColNumber
    let cellDefReturn
    columnDefs.forEach(function (oneColDef) {
      // console.log('oneColDef', oneColDef)
      if (oneColDef.layout !== 'left') return
      // TODO: for multi levels, we could use a targetLevel=Number?

      // console.log('-- oneRowDef > ', oneColDef)
      cellDefReturn = cellDef({
        rowDef: oneRowDef,
        colDef: oneColDef,
        rowNumber,
        colNumber,
        cells,
        rowsHaveChildren
      })
      colNumber++
    })
    rowNumber += cellDefReturn?.numberAddedRows || 1
  })

  console.log('rowLeftCells', cells)
  enableConsole('reset')
  return cells
}

/**
 * Analyse if any row has children
 * @returns {Boolean}
 */
function checkRowsHaveChildren (rowDefs) {
  let rowsHaveChildren = false

  rowDefs.forEach(function (oneRowDef) {
    if (oneRowDef.children) rowsHaveChildren = true
  })
  return rowsHaveChildren
}

/**
 *
 * @param {*} params
 */
function cellDef (params) {
  // console.log('rows-left cellDef', params)
  const { rowDef, colDef, cells, rowsHaveChildren } = params

  // No need of cell for row child on previous colums
  // TODO: flexible system
  if (params.colNumber === 1 && rowDef._parent) return
  if (params.colNumber === 2 && rowsHaveChildren && !rowDef._parent && !rowDef.children) return

  const styles = ['leftCell']
  if (rowDef.styles) {
    styles.push(...rowDef.styles)
  }

  if (colDef.styles) {
    styles.push(...colDef.styles)
  }

  let numberAddedRows = 0
  let numberRowChildren = 0

  const cell = {
    _cellId: 'center_left_' + cells.length,
    _position: {
      v: 'center',
      h: 'left'
    },
    rowDef,
    columnDef: colDef,
    gridRowStart: params.rowNumber,
    gridRowEnd: params.rowNumber + 1,
    gridColStart: params.colNumber,
    gridColEnd: params.colNumber + 1, // Default, updated below based on children
    styles
  }
  // Push the cell before the children
  if (!(params.colNumber === 2 && rowDef.children)) {
    // console.log('> add cell')
    cells.push(cell)
  }
  numberAddedRows++

  // Look at the children
  let rowNumber = params.rowNumber
  const colNumber = params.colNumber

  if (rowDef.children) {
    // const indentStyle = ['rowGroupLevel', params.colNumber].join('')
    // styles.push(indentStyle)

    if (gridHelpers.showChildren(rowDef)) {
      // params.colNumber++
      // console.log('.. row children:', params.rowDef.children.length)
      rowDef.children.forEach(function (oneChild) {
        oneChild._parent = rowDef
        cellDef({
          rowDef: oneChild,
          colDef,
          rowNumber: rowNumber + 1,
          colNumber,
          // colNumber: colNumber + 1,
          cells
        })
        rowNumber++
        numberAddedRows++
        // cellDefReturn.push(cell)
      })
      // console.log('.end of children')
    }

    numberRowChildren++
  }

  // For layout rows: they will not have children
  if (rowsHaveChildren && !rowDef.children) numberRowChildren++

  // Adjust the parent cell as needed // TODO: multi level (might be more than 2 columns)
  cell.gridColEnd = params.colNumber + numberRowChildren + 1

  return {
    cell,
    numberAddedRows
  }
}
