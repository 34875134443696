
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as cssGrid from '../../../helpers/cssgrid/index.js'
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'
import * as mHtmlParser from '../../modules/user/html-parser.js'
import { makeXeroLink } from '../../helpers/xero-links.js'

// Page Module
import htmlTemplateIndex from './index.html'
import { fillString } from '../../../helpers/templating/string-template.js'
import { ascending } from 'd3'

/**
 * the account shown is whatever was loaded for the page
 * @returns
 */
export async function showPageEntities () {
  console.log('entities show()')

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Entities:', accountData.name].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })
  contentElement.innerHTML = htmlPageLayout

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showEntities(accountData)
}

function prepareRowsData (accountData) {
  console.log('prepareRowsData')
  const rowsData = accountData?._accountOrganisations?.map(function (oneAccountOrg) {
    return oneAccountOrg._xeroOrganisation
  })
  console.log(rowsData)

  return rowsData
}

function prepareColumnsDefs () {
  const colDefs = [
    {
      id: 'Entity',
      // headerValueGetter: function () {
      //   return 'Type'
      // },
      valueGetter: function (cellParams) {
        return cellParams.rowDef.name
      },
      layout: 'left',
      width: 200
    },
    {
      id: 'To Xero',
      // headerValueGetter: function () {
      //   return 'Type'
      // },
      valueGetter: function (cellParams) {
        const url = makeXeroLink('Home', cellParams.rowDef.details)

        return fillString('<a href=":url" target="_blank">Open in Xero</a>', { url })
      },
      layout: 'left',
      width: 120
    },
    {
      id: 'Xero code',
      valueGetter: function (cellParams) {
        return cellParams.rowDef.details?.shortCode
      },
      layout: 'left',
      width: 90
    },
    {
      id: 'Xero-to-Xero',
      valueGetter: function (cellParams) {
        const url = makeXeroLink('XeroToXero', cellParams.rowDef.details)
        const link = fillString('<a href=":url" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>', { url })

        return [link, cellParams.rowDef.details?.aPIKey].join(' ')
      },
      layout: 'left',
      width: 300
    },
    {
      id: 'Currency',
      valueGetter: function (cellParams) {
        return cellParams.rowDef.details?.baseCurrency
      },
      layout: 'left',
      width: 120
    },
    {
      id: 'Locked (except for admin)',
      valueGetter: function (cellParams) {
        // console.log(cellParams)
        const url = makeXeroLink('FinancialSettings', cellParams.rowDef.details)
        const link = fillString('<a href=":url" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>', { url })

        const lockDate = cellParams.rowDef.details?.periodLockDate

        return (lockDate?.substring(0, 10) || 'not locked!') + ' ' + link
      },
      layout: 'left',
      width: 120
    },
    {
      id: 'Locked (even for admin)',
      valueGetter: function (cellParams) {
        // console.log(cellParams)
        const url = makeXeroLink('FinancialSettings', cellParams.rowDef.details)
        const link = fillString('<a href=":url" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>', { url })

        const lockDate = cellParams.rowDef.details?.endOfYearLockDate

        return (lockDate?.substring(0, 10) || 'not locked!') + ' ' + link
      },
      layout: 'left',
      width: 120
    },
    {
      id: 'Financial year',
      valueGetter: function (cellParams) {
        // console.log(cellParams)
        const url = makeXeroLink('FinancialSettings', cellParams.rowDef.details)
        const link = fillString('<a href=":url" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>', { url })

        return [cellParams.rowDef.details?.financialYearEndDay, cellParams.rowDef.details?.financialYearEndMonth].join(' / ') + ' ' + link
      },
      layout: 'left',
      width: 120
    },
    {
      id: 'Chart of Accounts',
      valueGetter: function (cellParams) {
        // console.log(cellParams)
        const url = makeXeroLink('ChartAccounts', cellParams.rowDef.details)
        const link = fillString('<a href=":url" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i> Chart of Accounts</a>', { url })

        return link
      },
      layout: 'left',
      width: 120
    }
  ]

  return colDefs
}

function showEntities (accountData) {
  console.log('showEntities', accountData)
  const rowDefs = prepareRowsData(accountData)
  console.log('rowDefs', rowDefs)
  const colDefs = prepareColumnsDefs()
  console.log('colDefs', colDefs)

  sortRows(rowDefs)

  cssGrid.generateGrid({
    containerElementId: 'grid',
    classes: ['fit-content'],
    colDefs,
    rowDefs,
    showActions: true
  })
}

function sortRows (rowDefs) {
  rowDefs.sort(function (r1, r2) {
    return ascending(r1.name, r2.name)
  })
  return rowDefs
}
