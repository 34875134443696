import { enableConsole } from '../logging/console.js'

/**
 * columDefs: {
 *  rowSpan: allows to group column
 *  children: [{ columDef }]
 * }
 */
export function generateHeaderCellsParams (params) {
  enableConsole(false)
  console.log('generateHeaderCellsParams', params)
  const { gridSettings, columnDefs, horizontalPosition } = params

  const rowNumber = 1
  let colNumber = 1

  const cells = []

  columnDefs.forEach(function (oneColumnDef) {
    // console.log('-- parent colDef', oneColumnDef)
    const cell = cellDef({
      gridSettings,
      cells,
      horizontalPosition,
      rowNumber,
      colNumber,
      colDef: oneColumnDef
    })
    colNumber = cell.gridColEnd
  })
  // console.log('cells', cells)
  enableConsole('reset')
  return cells
}

/**
 *
 * @param {*} params
 * @returns
 */
function cellDef (params) {
  // console.log('header cellDef', params)
  const { gridSettings, cells, horizontalPosition } = params

  // const cellValue = params.colDef._getHeaderValue()

  const styles = ['headerCell']
  if (params.colDef?.styles) {
    styles.push(...params.colDef?.styles)
  }

  const cell = {
    gridSettings,
    gridRowStart: params.rowNumber,
    gridRowEnd: params.rowNumber + 1,
    gridColStart: params.colNumber,
    styles,
    _cellId: ['top', horizontalPosition, cells.length].join('_'),
    _position: {
      v: 'top',
      h: horizontalPosition
    },
    // value: cellValue,
    columnDef: params.colDef
  }
  // Push the cell before the children
  cells.push(cell)

  const rowNumber = params.rowNumber
  let colNumber = params.colNumber

  if (params.colDef.children) {
    // console.log('.. children')
    params.colDef.children.forEach(function (oneChild) {
      cellDef({
        gridSettings,
        cells,
        horizontalPosition,
        rowNumber: rowNumber + 1,
        colNumber,
        colDef: oneChild
      })
      colNumber++
    })
    // For columns, we dont show a dedicated column for the parent group (while for row the rowGroup parent is shown), so we decrement the colNumber:
    colNumber--
  }
  // console.log('.end of children')

  // Adjust the parent cell as needed
  cell.gridColEnd = colNumber + (params.colDef.rowSpan - 1 || 0) + 1

  return cell
}
