
import * as mAccount from '../../modules/user/account.js'
import { enableConsole } from '../../../helpers/logging/console.js'
import { callApi } from '../../helpers/fetch.js'

/**
 *
 * @param {*} orgId
 * @returns
 */
export async function checkOrgToken (orgId) {
  enableConsole(true)
  console.log('checkOrgToken', orgId)

  const accountData = mAccount.accountData()
  if (!accountData.myRights?.includes('canManage')) return

  const data = await loadCheckToken(orgId)
  show(orgId, data)
}

export async function loadCheckToken (orgId) {
  const apiUrl = '/api/xero/sync/check/:orgId'
  const data = await callApi(apiUrl, { orgId })
  console.log('received:', data)
  return data
}

/**
 *
 * @param {String} orgId
 * @param {Object} syncInfo
 * @param {Object} syncInfo.done
 * @param {Object} syncInfo.next
 * @param {Object} syncInfo.ongoing
 */
function show (orgId, syncCheck) {
  console.log('checkOrgToken show:', orgId, syncCheck)

  const accountData = mAccount.accountData()
  if (!accountData.myRights?.includes('canManage')) return

  const orgContainerElement = document.querySelector('.oneXeroOrg[d-org-id="' + orgId + '"]')
  if (!orgContainerElement) return

  const tokenValidityElement = orgContainerElement.querySelector('.reconnectLinks')
  const syncNowElement = orgContainerElement.querySelector('.syncLinks')
  const disconnectElement = orgContainerElement.querySelector('.disconnectLinks')
  if (syncCheck.tokenWorks) {
    tokenValidityElement.classList.add('is-hidden')
    syncNowElement.classList.remove('is-hidden')
    disconnectElement.classList.remove('is-hidden')
  } else {
    tokenValidityElement.classList.remove('is-hidden')
    syncNowElement.classList.add('is-hidden')
    disconnectElement.classList.add('is-hidden')
  }
}
