import { itemsDB } from '../utils/manage-items.js'
import { computeConvertedAmount } from './compute-converted-amount.js'
import { getEntityCurrency } from './get-entity.js'

/**
 *
 * @param {Object} rowData
 * @param {Number} entityIndex
 * @param {Object} context
 * @returns
 */
export function itemValueGetter (rowData, entityIndex, context) {
  const entityName = itemsDB.entities[entityIndex]
  const baseCurrencySymbol = getEntityCurrency(entityName)

  const fileDataIndex = rowData.item?.fileDataIndex.indexOf(entityIndex)
  if (!Number.isFinite(fileDataIndex)) return null

  const amount = rowData.item?.data[fileDataIndex]?.amount
  // return amount || null

  const amountWithMultiplier = amount * (rowData.list?.list?.multiplier || 1)

  return computeConvertedAmount(baseCurrencySymbol, amountWithMultiplier, context)
}

export function groupTotalValue (groupItems, entityIndex, context) {
  let total = 0
  groupItems.forEach(function (rowData) {
    const value = itemValueGetter(rowData, entityIndex, context)
    total = total + (value || 0)
  })
  return total
}
