// Dependencies
import * as mAccount from '../../modules/user/account.js'

import htmlTemplateAccountInfo from './account-info.html'
import * as mHtmlParser from '../../modules/user/html-parser.js'

/**
 *
 * @param {*} accountData
 */
export function showAccountDetails (accountData) {
  const accountDetailsElement = document.getElementById('accountDetails')
  if (!accountDetailsElement) return

  let htmlContent = htmlTemplateAccountInfo
  htmlContent = mHtmlParser.applyAccountData(htmlContent, accountData)
  htmlContent = mHtmlParser.applyRestrictions(htmlContent, { accountData })

  accountDetailsElement.innerHTML = htmlContent
}

export async function reloadAccountDetails (accountId) {
  // Refresh the account info
  await mAccount.load(accountId)
  const accountData = mAccount.accountData()
  showAccountDetails(accountData)
}
