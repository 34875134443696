import { mapArrayProperties, mapGroups } from '../../../helpers/data/array-of-objects/mapped-array.js'
import { cohortSettings } from './data.js'
import { dateNumber, extractCohortDate, periodsDelta } from './utilities/dates.js'

/**
 * Group by User
 *
 * Extract info about firstAction
 *
 */
export function groupByUser (records) {
  const groupingProperties = [
    'userId'
  ]
  const rollups = [
    {
      property: 'firstAction',
      calculation: ['least', 'actionDate']
    },
    {
      property: 'count',
      calculation: ['count', 'userId']
    }
  ]
  const usersMap = mapGroups(records, groupingProperties, rollups, { returnArray: true })
  return usersMap
}

export function analyseUsers (users) {
  users?.forEach(function (userData) {
    analyseUser(userData)
  })
}

function analyseUser (userData) {
  // Enrich the user actions
  const properties = [
    {
      name: 'aggregateDate',
      accessor: 'actionDate',
      manipulation: function (value) {
        return extractCohortDate(value, cohortSettings.cohortType)
      }
    }, {
      name: 'aggregateRelativeDate',
      accessor: 'actionDate',
      manipulation: function (value) {
        if (!userData.firstAction) {
          console.warn('no userData.firstAction', value)
        }
        const periods = periodsDelta(userData.firstAction?.actionDate, value, cohortSettings.cohortType)
        return periods
      }
    }, {
      name: 'isFirstAction',
      accessor: 'actionDate',
      manipulation: function (value) {
        return userData.firstAction.actionDate === value
      }
    }, {
      name: 'dateNumber',
      accessor: 'actionDate',
      manipulation: function (value) {
        return dateNumber(value, cohortSettings.cohortType)
      }
    }
  ]
  const enrichedUserActions = mapArrayProperties(userData.values, properties, { keepAllProperties: true })
  userData.values = enrichedUserActions

  // Group
  const groupingProperties = [
    'aggregateDate',
    'aggregateRelativeDate',
    'isFirstAction'
  ]

  const rollups = [{
    property: 'actions',
    calculation: ['count', 'actionValue']
  }, {
    property: 'totalValue',
    calculation: ['sum', 'actionValue']
  }]
  const userGroupedActions = mapGroups(enrichedUserActions, groupingProperties, rollups, { returnArray: true })
  userData.groupedActions = userGroupedActions
}
