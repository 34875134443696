/**
*
* @param {*} workbook
* @param {Sheet} expectedSheetName
* @returns
*/
export function getSheet (workbook, expectedSheetName) {
  const worksheet = workbook.Sheets[expectedSheetName]
  return worksheet
}
