import { sum } from 'd3'
import { accessObjectPropertyValue } from '../../../../helpers/data/objects.js'

/**
 * TODO: manage multiple dates? ie not monthly only
 * @param {*} rowRecords
 * @param {*} date
 * @param {*} params
 * @returns
 */
export function getCellValue (rowRecords, date, params) {
  const { targetProperty, significantDecimals } = params

  const matchingRecords = rowRecords?.filter(function (record) {
    const recordTargetValue = accessObjectPropertyValue('month', record)
    return date === recordTargetValue
  })

  const baseValue = sum(matchingRecords, function (record) {
    return accessObjectPropertyValue(targetProperty, record)
  })

  const valueMultiplier = Math.pow(10, significantDecimals) || 1
  return baseValue / valueMultiplier
}
