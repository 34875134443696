import { cohortSettings } from './data.js'

export function computeCohortsAcquisitionCostsInfo (cohorts) {
  if (!cohortSettings.cohortsAcquisitionCosts) return
  if (!cohorts) return

  cohorts.forEach(function (oneCohort) {
    computeCohortAcquisitionCosts(oneCohort)
  })
}

function computeCohortAcquisitionCosts (cohort) {
  console.log('computeCohortAcquisitionCosts', cohort)
  // Find the cohort spend
  const targetCohortSpend = cohortSettings.cohortsAcquisitionCosts.find(function (one) {
    return one.key.cohort === cohort.key.cohort
  })
  // if (!targetCohortSpend)

  cohort.acquisition = {
    values: targetCohortSpend?.cohortTotalValue || 0,
    valuesWithAcquisition: targetCohortSpend?.cohortTotalValue || 0,
    ltvCac: -1
  }

  cohort._spend = targetCohortSpend?.cohortTotalValue || 0
  cohort._spendPerUser = cohort._spend / cohort.info.users[0]

  let cumulWithAcquisition = cohort._spend
  let ltvCac = cohort.acquisition.ltvCac

  // Calculation per user: should take initial or remaining users?
  // let cumulWithAcquisitionPerUser = cohort._spendPerUser

  cohort.info.valuesWithAcquisition = []
  cohort.info.ltvCac = []

  for (const [index, value] of cohort.info.values.entries()) {
    // console.log(index, value)
    cumulWithAcquisition += value || 0
    cohort.info.valuesWithAcquisition.push(cumulWithAcquisition)
    // console.log(index, value, cumulWithAcquisition)

    ltvCac = -cohort.info.valuesCumuls[index] / cohort._spend
    cohort.info.ltvCac.push(ltvCac)
    // console.log(index, cohort.info.valuesCumuls[index], cohort._spend, ltvCac)
  }
}
