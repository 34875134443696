import { baseAccountObj } from '../../base-account-obj.js'

export function makeRows (context) {
  console.log('makeRows', context)

  const filtered = context.data?.monthlyValues?.filter(function (monthlyValue) {
    const isWithValue = (Number.isFinite(monthlyValue.monthAmount) && monthlyValue.monthAmount !== 0)
    return isWithValue
  })

  const rows = filtered?.map(function (monthlyValue) {
    return {
      value: monthlyValue,
      account: baseAccountObj(context, monthlyValue)
    }
  })

  // We focus on the pnl currently
  // BS has issue of conversion
  const filteredPnlRows = rows?.filter(function (row) {
    return row.account?.statement === 'pnl'
  })

  return filteredPnlRows
}
