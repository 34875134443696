import { parseAccountName } from '../utils/parse-account-name.js'
import { accessSheetCell } from './utils.js'

export function parseSheet (params) {
  const settingsTemplate = detectFileTemplate(params)

  const { sheetData } = params

  // Headers might be overrode by the template: Allowing to manage empty header (eg. Xero does not write 'account name')
  const headers = settingsTemplate.headers || sheetData[(settingsTemplate.columnHeaders - 1)]

  const rows = loopEachDataRow({
    sheetData,
    headers,
    settingsTemplate
  })
  // console.log(rows)
  return rows
}

function loopEachDataRow (params) {
  console.log('loopEachDataRow', params)
  const { sheetData, settingsTemplate, headers } = params

  // Array of rows containing Data
  // const dataRows = sheetData.toSplice(0, (settingsTemplate.dataStart - 1))
  const dataRows = []
  sheetData.forEach(function (sheetRow, index) {
    if (index < (settingsTemplate.dataStart - 1)) return

    dataRows.push(sheetRow)
  })

  const rows = []
  dataRows.forEach(function (dataRow) {
    if (dataRow.length === 0) return

    const rowValues = {}
    headers.forEach(function (headerValue, index) {
      rowValues[headerValue] = parseCellValue(dataRow[index])
    })

    // Exclude
    if (rowValues.Account.toLowerCase() === 'total') return

    // Base properties we want:
    rowValues.amount = getRowValue(dataRow, headers)
    const accountNameParsed = parseAccountName(rowValues.Account)
    rowValues.accountCode = accountNameParsed.code
    rowValues.accountName = accountNameParsed.name

    if (isRowWithData(rowValues)) {
      rows.push(rowValues)
    }
  })

  return rows
}

function isRowWithData (rowValues) {
  if (!rowValues.amount) return

  let countData = 0
  Object.values(rowValues).forEach(function (oneValue) {
    if (Number.isFinite(oneValue)) {
      countData++
    } else if (typeof oneValue === 'string' && oneValue.length > 0) {
      countData++
    }
  })
  return countData > 0
}

/**
 * TODO: it should try the different possibilities and return the best value
 * @param {*} value
 * @returns
 */
function parseCellValue (value) {
  return parseString(value)
}

function parseString (value) {
  if (typeof value !== 'string') return value

  // Trim
  return value.trim()
}

/**
 * TODO: improve parsing: check numbers stored as strings?
 * @param {*} dataRow
 * @param {*} headers
 * @returns
 */
function getRowValue (dataRow, headers) {
  // console.log('... getRowValue')

  let rowValue = null
  dataRow.forEach(function (cellValue, index) {
    // console.log(index, cellValue, (!Number.isFinite(cellValue)))
    if (!Number.isFinite(cellValue)) return

    const headerName = headers[index]

    if (settingsValues.debitHeaders.includes(headerName)) {
      rowValue = cellValue
    }
    if (settingsValues.crebitHeaders.includes(headerName)) {
      rowValue = -cellValue
    }
  })
  // console.log('rowValue =', rowValue)
  return rowValue
}

function detectFileTemplate (params) {
  console.log('detectFileTemplate', params)
  const { sheetData } = params

  let matchingTemplate = null

  templates.forEach(function (oneTemplate) {
    const cellTest = accessSheetCell(sheetData, oneTemplate.detectCell.row, oneTemplate.detectCell.col)
    console.log(oneTemplate, cellTest)
    if (cellTest === oneTemplate.detectCell.value) {
      matchingTemplate = oneTemplate
    }
  })
  if (!matchingTemplate) console.warn('unknown file format')

  console.log('matchingTemplate', matchingTemplate)
  return matchingTemplate
}

const templates = []
templates.push({
  provider: 'xero',
  columnHeaders: 6,
  dataStart: 7,
  detectCell: {
    row: 6,
    col: 2,
    value: 'Debit'
  },
  headers: ['Account', 'Debit', 'Credit']
})
templates.push({
  provider: 'other',
  columnHeaders: 2,
  dataStart: 3,
  detectCell: {
    row: 2,
    col: 3,
    value: 'Net Debit'
  }
})

const settingsValues = {
  debitHeaders: ['Net Debit', 'Debit'],
  crebitHeaders: ['Net Credit', 'Credit']
}
