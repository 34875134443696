import { flatRollup, sum } from 'd3'
import { getFileData } from '../../../../../helpers/xlsx-reader.js/get-file-data.js'
import { parseDataset } from '../../utilities/parse-dataset.js/parse-dataset.js'
import { readCsvFile } from '../../utilities/read-csv-file.js'
import { choiceCountry } from '../ui/choose-country.js'
import { choicePlatform } from '../ui/choose-platform.js'
import { showTable } from '../table-ui/show-table.js'

export const ordersLinesData = {
  records: [],
  dataTable: null
}

export async function runDataAnalysis (file, options) {
  console.log('readDroppedFile', file, options)
  const sheetParsingOptions = options?.dataFile

  let dataset
  if (options?.dataFile?.type === 'csv' || file.type === 'text/csv') {
    dataset = await readCsvFile(file)
  } else {
    dataset = await getFileData(file, sheetParsingOptions)
  }
  console.log('dataset', dataset)

  const parsingOptions = {
    fieldsMapping: options?.fieldsMapping,
    combineLines: false,
    context: {
      platform: choicePlatform.chosenPlatform.value,
      country: choiceCountry.chosenCountry.value
    }
  }
  const orderLines = parseDataset(dataset, parsingOptions)
  console.log('orderLines', orderLines)

  store(orderLines)

  generateSKUanalysis()
}

function store (newLines) {
  newLines?.forEach(function (line) {
    ordersLinesData.records.push(line)
  })
}

/**
 * Group by Country, Platform
 * SKU
 * Date
 *
 * ... Count Orders
 * ... Sum Quantity
 * ... Sum revenueGross, revenueNet
 * ... Order Value
 * ... Order Items
 *
 */
function generateSKUanalysis () {
  const rolledUp = flatRollup(ordersLinesData.records,
    function (records) {
      return {
        // records,
        orders: records.length,
        quantity: sum(records, function (record) { return record.quantity || 0 }),
        revenueGross: sum(records, function (record) { return record.revenueGross || 0 }),
        revenueNet: sum(records, function (record) { return record.revenueNet || 0 })
      }
    },
    // Grouping functions
    function (record) {
      return record.orderPlatform
    },
    function (record) {
      return record.orderCountry
    },
    function (record) {
      return record.createdAt
    },
    function (record) {
      return record.SKU_id
    }
  )

  console.log('rolledUp', rolledUp)

  prepareExport(rolledUp)
}

function prepareExport (rolledUp) {
  // Turn rolledUp to list of properties
  const rows = []
  rolledUp.forEach(function (group) {
    const row = {
      platform: group[0],
      country: group[1],
      date: group[2],
      SKUid: group[3],
      quantity: group[4].quantity,
      orders: group[4].orders,
      revenueGross: group[4].revenueGross,
      revenueNet: group[4].revenueNet
    }
    rows.push(row)
  })
  ordersLinesData.dataTable = rows

  const columns = [
    { propertyName: 'platform' },
    { propertyName: 'country' },
    { propertyName: 'date' },
    { propertyName: 'SKUid' },
    { propertyName: 'quantity' },
    { propertyName: 'orders' },
    { propertyName: 'revenueGross' },
    { propertyName: 'revenueNet' }
  ]
  showTable(rows, columns)
}
