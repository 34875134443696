import { setElementHtml } from '../../../helpers/dom.js'

// Module
import htmlTemplateSelectorCurrencies from './currencies.html'
import htmlTemplateOneCurrencyButton from './options-currencies-button.html'
import { optionSelectorData } from '../data.js'
import { executeOnChange } from '../onchange.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { updateSelectorCurrency } from './update-currency.js'

/**
 *
 * @param {Object} params
 * @param {Object} [params.customSettings]
 * @param {Object[]} [params.customSettings.currencies]
 */
export function activateMenuCurrencies (params) {
  const dom = setElementHtml('optionSelectorContent', htmlTemplateSelectorCurrencies)
  if (!dom) return

  showSelector(params)
  listeners(params)
}

function listeners (params) {
  const selectorElement = document.getElementById('currencyButtons')
  if (!selectorElement) return

  selectorElement.addEventListener('click', function (clickEvent) {
    // console.log('currency click')
    const target = clickEvent.target.closest('button')
    if (!target) return

    const targetCurrencySymbol = target.getAttribute('d-currency-symbol')
    // console.log(targetCurrencySymbol)
    optionSelectorData.currencySymbol = targetCurrencySymbol

    updateSelectorCurrency()
    executeOnChange(params)
  })
}

/**
 *
 * @returns
 */
function showSelector (params) {
  const htmlSelector = htmlTemplateSelectorCurrencies
  const selectorElement = setElementHtml('optionCurrencySelector', htmlSelector)
  if (!selectorElement) return

  const currencies = params.customSettings?.currencies

  if (!currencies) {
    // console.warn('No currency available??')
    selectorElement.innerHTML = ':( No currency available'
    return
  }

  const buttonsHtml = []
  currencies.forEach(function (oneCurrency) {
    const currencySymbol = oneCurrency.symbol

    const htmlButton = fillString(htmlTemplateOneCurrencyButton, { currencySymbol })

    buttonsHtml.push(htmlButton)
  })
  // Add the XXX base currency
  buttonsHtml.unshift(fillString(htmlTemplateOneCurrencyButton, { currencySymbol: 'XXX' }))

  setElementHtml('currencyButtons', buttonsHtml.join(''))

  updateSelectorCurrency()
}
