
/**
 * Checks if the period 0 is of interest
 * @param {*} cohorts
 * @param {*} property
 */
export function checkPeriodsOfInterest (cohorts, property) {
  // console.log('checkPeriodsOfInterest', cohorts, property)
  const periodChecked = 0
  const periodRef = periodChecked + 1 // Before index 0 is the Initial value

  let valuesOfInterest = 0

  cohorts.forEach(function (cohort) {
    const values = cohort.info[property]
    if (values[periodRef]) valuesOfInterest++
  })
  // console.log('valuesOfInterest', valuesOfInterest)

  return {
    hasPeriod0: valuesOfInterest > 0
  }
}
