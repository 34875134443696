export function baseAccountObj (context, monthlyValue, options) {
  const accountIdPropertyName = options?.accountIdPropertyName || 'accountId'
  if (monthlyValue[accountIdPropertyName] === '__earningsPNL') {
    return {
      statement: 'pnl',
      accountId: monthlyValue[accountIdPropertyName],
      name: 'earnings',
      // type: context.chartOfAccounts?.indexed?.[monthlyValue.accountId]?.type,
      monthlyValues: []
    }
  } else if (monthlyValue[accountIdPropertyName] === '__earningsBS') {
    return {
      statement: 'bs',
      accountId: monthlyValue[accountIdPropertyName],
      name: 'earnings',
      // type: context.chartOfAccounts?.indexed?.[monthlyValue.accountId]?.type,
      monthlyValues: []
    }
  } else {
    return {
      statement: context.chartOfAccounts?.indexed?.[monthlyValue[accountIdPropertyName]]?.statement,
      accountId: monthlyValue[accountIdPropertyName],
      code: context.chartOfAccounts?.indexed?.[monthlyValue[accountIdPropertyName]]?.code,
      name: context.chartOfAccounts?.indexed?.[monthlyValue[accountIdPropertyName]]?.name,
      type: context.chartOfAccounts?.indexed?.[monthlyValue[accountIdPropertyName]]?.type,
      monthlyValues: []
    }
  }
}
