import { optionSelectorData } from '../data.js'

export function updateSelectorPeriodicity () {
  // console.log('updateSelectorPeriodicity')

  const selectorFromElement = document.getElementById('periodicityChoice')
  if (!selectorFromElement) return

  const optionElements = selectorFromElement.querySelectorAll('option')
  optionElements?.forEach(function (oneOptionElement) {
    const isOptionMatchingPeriodicity = +oneOptionElement.value === optionSelectorData.periodicity
    if (isOptionMatchingPeriodicity) {
      oneOptionElement.selected = true
    } else {
      oneOptionElement.selected = false
    }
  })
}
