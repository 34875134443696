// Module
import htmlOneAccount from './one-account.html'
import * as mHtmlParser from '../../../modules/user/html-parser.js'
import { makeUrlToAccountSettings } from '../../account/helpers.js'
import { callApi } from '../../../helpers/fetch.js'
import { fillString } from '../../../../helpers/templating/string-template.js'

export async function showUserAccounts () {
  const apiUrl = '/api/account/list'
  const responseData = await callApi(apiUrl)
  console.log('showUserAccounts response', responseData)

  outputList({
    userAccounts: responseData.userAccounts
  })
}

function outputList (params) {
  const { userAccounts } = params

  const listContainerElement = document.getElementById('userAccounts')
  if (!listContainerElement) return

  listContainerElement.innerText = ''

  userAccounts.forEach(function (oneUserAccount) {
    const appUrlToXeroSettings = '/app/a/:accountId/xero'.replace(':accountId', oneUserAccount._account._id)
    const appUrlToAccount = makeUrlToAccountSettings(oneUserAccount._account._id)

    // Using HTML Template
    let oneAccountHtml = fillString(htmlOneAccount, {
      accountId: oneUserAccount._account._id,
      accountName: oneUserAccount._account.name,
      userRights: (oneUserAccount.rights).join(', '),
      linkToXeroSettings: appUrlToXeroSettings,
      linkToAccountSettings: appUrlToAccount
    })

    oneAccountHtml = mHtmlParser.applyRestrictions(oneAccountHtml, { accountData: oneUserAccount._account })

    listContainerElement.insertAdjacentHTML('beforeend', oneAccountHtml)
  })
}
