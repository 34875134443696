// Dependencies
import * as mArrays from '../../../helpers/data/arrays.js'
import * as mFinancialsHelpers from '../../helpers/helpers.js'
// import * as sfs2ValueGetter from './statements-fs2-value-getter.js'
import * as sfs3ValueGetter from './statements-fs3-value-getter.js'
import { getHeaderValue } from './statement-v2-columns-headervalue.js'
import { optionSelectorData } from '../../modules/option-selector/data.js'
import * as mAccount from '../../modules/user/account.js'
import * as mData from './data.js'
import { appRoutes } from '../../config/config.js'
import { findLastChildren } from '../../../helpers/data/objects.js'
import { fillString } from '../../../helpers/templating/string-template.js'

/**
 *
 * @param {*} params
 * @returns {Array} of Object {value, children[], styles, params{date, orgId, [currency], [valueType]} }
 */
export function generateColumnDefs (params) {
  console.log('generateColumnDefs', params)
  const { dateseriesView, organisations, valueType, columnsLayout } = params

  const columns = []

  // Left column: account names
  columns.push({
    value: ' ',
    id: 'accountType',
    styles: ['leftColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef.value || cellParams.rowDef.id
    },
    layout: 'left',
    width: 20
  })
  columns.push({
    value: ' ',
    id: 'accountName',
    styles: ['leftColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef.value || cellParams.rowDef.id
    },
    layout: 'left',
    width: 200
  })

  const arrays = []
  columnsLayout.forEach(function (oneSetType, index) {
    // Base colDef for any level of header
    const baseColDef = {
      styles: []
    }
    if (index === columnsLayout.length - 1) {
      // Create the valueGetter for the content column
      baseColDef.valueGetter = function (cellParams) {
        // return sfs2ValueGetter.contentValueGetter({ context, aggregationDB, dateseries, cellParams, valueType })
        return sfs3ValueGetter.contentValueGetter({
          cellParams,
          valueType,
          options: {
            optionSelectorData
          }
        })
      }
      baseColDef.valueFormatter = function (cellParams) {
        return mFinancialsHelpers.contentValueFormatter({
          cellParams,
          decimals: optionSelectorData.decimals
        })
      }
      baseColDef.valueStyling = function (cellParams) {
        return mFinancialsHelpers.contentValueStyling({ cellParams })
      }
      baseColDef.onClick = function (cellParams) {
        // Debugging
        showCellInfo(cellParams)
        sfs3ValueGetter.contentValueGetter({
          cellParams,
          valueType,
          options: { showDebug: true }
        })
      }
      baseColDef.onDblClick = function (cellParams) {
        console.log('onDblClick', cellParams)
        if (cellParams.rowDef.isLayoutRow) return

        const targetAccountId = mAccount.accountData().id || mAccount.accountData()._id

        // TODO: evolve to allow multiple
        const targetOrgId = mData.db.data.organisations[0].id

        // TODO: evolve to allow multiple: use the text string?
        const targetAccountXero = mData.db.data.fs3Data.accounts.find(function (oneAccount) {
          return oneAccount.id === cellParams.rowDef.params?.accountIds?.[0]
        })
        // console.log('targetAccountXero', targetAccountXero)
        const targetAccountXeroId = targetAccountXero.accountXeroId

        // console.log('mAccount', mAccount, mAccount.accountData())
        let targetUrl = appRoutes + '/a/:accountId/schedule/:orgId/:chartAccountId'
        targetUrl = fillString(targetUrl, {
          accountId: targetAccountId,
          orgId: targetOrgId,
          chartAccountId: targetAccountXeroId
        })
        console.log('targetUrl', targetUrl)
        location.href = targetUrl
      }

      baseColDef.styles.push('numberColumns')
      baseColDef.width = 110
    }

    if (index === 0 && columnsLayout.length > 1) {
      baseColDef.styles.push('parentColumn')
    }

    if (oneSetType === 'dates') {
      console.log('optionSelectorData', optionSelectorData)
      console.log('dateseriesView', dateseriesView.length, dateseriesView)

      function dateColumn (oneDate) {
        const colDef = {
          id: oneDate,
          headerValueGetter: function (cellParams) {
            return getHeaderValue({ cellParams })
          },
          params: {
            filter: {
              type: 'dateseries'
            }
          }
        }
        dateObjs.push(Object.assign(colDef, baseColDef))
      }

      const dateObjs = []
      const periodicity = optionSelectorData.periodicity || 1

      // Adjust periods to the last month available
      const willPeriodsBeComplete = ((dateseriesView.length - 1) / periodicity)
      // console.log('willPeriodsBeComplete', willPeriodsBeComplete)
      const neededPeriods = (periodicity * Math.floor(willPeriodsBeComplete))
      const periodAdjustmentDelay = dateseriesView.length - neededPeriods - 1
      // console.log(neededPeriods, 'periodAdjustmentDelay', periodAdjustmentDelay)

      dateseriesView.forEach(function (oneDate, index) {
        const adjustedIndex = index - periodAdjustmentDelay
        const modulo = adjustedIndex % periodicity
        // const adjustedDate = dateseriesView[index + periodAdjustmentDelay]
        const useDate = (adjustedIndex >= 0 && modulo === 0)
        // console.log(index, oneDate, modulo, useDate)
        if (!useDate) return

        dateColumn(oneDate)
      })

      arrays.push(dateObjs)
    }

    if (oneSetType === 'orgs') {
      const orgIds = organisations.map(function (oneOrg) {
        const colDef = {
          id: oneOrg.id,
          params: {
            data: oneOrg,
            filter: {
              type: 'org'
            }
          },
          headerValueGetter: function () {
            return this.params.data.name
          }
        }
        return Object.assign(colDef, baseColDef)
      })
      arrays.push(orgIds)
    }
  })

  // Columns in structure needed for grid

  const nestedColumns = mArrays.arraysToNested(arrays)

  // Style
  nestedColumns.forEach(function (oneColumn) {
    if (!oneColumn.children) return

    const lastChildColumns = findLastChildren(oneColumn, { onlyLastOfLast: true })
    lastChildColumns.forEach(function (oneLastChildColumn) {
      // Copy the styles array (using slice()) as the property is a reference only due to baseColDef
      oneLastChildColumn.styles = oneLastChildColumn.styles.slice() || []
      oneLastChildColumn.styles.push('lastGroupColumn')
    })
  })

  const columnDefs = columns.concat(nestedColumns)
  console.log('columnDefs', columnDefs)

  return columnDefs
}

/**
 * FOR DEBUG: show details about the cell
 */
function showCellInfo (cellParams) {
  console.log('showCellInfo', 'cellParams.rowDef.id:', cellParams.rowDef?.id, 'cellParams.rowDef.params.accountIds:', cellParams.rowDef?.params?.accountIds)
  console.log('cellParams', cellParams)

  const dateseries = cellParams.gridSettings.context.data.dateseries

  console.log('-- transactions:')
  const transactionsDB = cellParams.gridSettings.context.data.transactionsDB || cellParams.gridSettings.context.data.fs3Data.transactions
  const transactions = transactionsDB?.filter(function (oneTransaction) {
    const isTargetAccount = cellParams.rowDef.params.accountIds?.includes(oneTransaction.account?.id)
    if (!isTargetAccount) return false

    const isTargetPeriod = oneTransaction.date === cellParams.columnDef.id
    if (!isTargetPeriod) return false

    return true
  })
  console.log(transactions)

  console.log('-- aggregations:')
  const aggregationDB = cellParams.gridSettings.context.data.aggregationDB || cellParams.gridSettings.context.data.fs3Data.aggregates
  const aggregations = aggregationDB.filter(function (oneAggregation) {
    const isTargetAccount = cellParams.rowDef.params.accountIds?.includes((oneAggregation.account.id || oneAggregation.account))
    if (!isTargetAccount) return false

    return true
  })
  console.log(aggregations)

  console.log('-- layouts:', cellParams.gridSettings.context.data.fs3Data.layouts)
  const layoutDB = cellParams.gridSettings.context.data.fs3Data.layouts
  const layouts = layoutDB.filter(function (oneLayout) {
    const isTargetLayout = cellParams.rowDef.params.id?.includes((oneLayout.id))
    if (!isTargetLayout) return false

    return true
  })
  console.log(layouts)

  // All Transactions
  const accountTransactions = transactionsDB?.filter(function (oneTransaction) {
    const isTargetAccount = cellParams.rowDef.params.accountIds?.includes(oneTransaction.account.id)
    if (!isTargetAccount) return false

    return true
  })
  const grouped = mArrays.groupBy(accountTransactions, 'date')
  console.log('grouped', grouped)
  const dates = Object.keys(grouped)
  const sortedDates = dates.sort(function (d1, d2) {
    if (d1 >= d2) return 1
    return -1
  })
  sortedDates.forEach(function (oneDate, index) {
    console.log('')
    console.log(oneDate, 'index', index)
    console.log(grouped[oneDate])

    grouped[oneDate].forEach(function (oneTransaction) {
      console.log(oneTransaction.currencySymbol, oneTransaction.amount, oneTransaction.source, oneTransaction.origin)
    })

    const targetDateIndex = dateseries.indexOf(oneDate)

    aggregations.forEach(function (oneAggregation) {
      console.log('>', oneAggregation.currencySymbol, oneAggregation.data.valuePeriod[targetDateIndex], '>>', oneAggregation.data.valueCumul[targetDateIndex])
    })
  })
  // Date: BaseCurrency | Converted
  // Date: - | Converted
  // Balance

  console.log('--END--')
}
