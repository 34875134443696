
/**
 * !! This will not work for periods smaller than month
 * @param {*} date
 */
export function dateNumber (date, type) {
  const formulas = {
    quarter: {
      year: function () {
        return (date).getFullYear() * 4
      },
      unit: function () {
        return Math.ceil(((date).getMonth() + 1) / 3)
      }
    },
    month: {
      year: function () {
        return (date).getFullYear() * 12
      },
      unit: function () {
        return (date).getMonth() + 1
      }
    }
  }
  return formulas[type].year() + formulas[type].unit()
}

export function periodsDelta (dateStart, dateEnd, periodsType) {
  // console.log('periodsDelta', dateStart, dateEnd, periods)
  const endNumber = dateNumber(dateEnd, periodsType)
  const startNumber = dateNumber(dateStart, periodsType)
  const numberDelta = endNumber - startNumber
  // console.log(startNumber, endNumber, numberDelta)
  return numberDelta
}

/**
 * // TODO: allow multiple calculation: day, week, month, etc...
 * @param {*} value
 * @returns
 */
export function extractCohortDate (value, periodsType) {
  if (periodsType === 'quarter') {
    // const date = new Date(value)
    const year = value.getFullYear()
    const month = value.getMonth() + 1
    return [year, Math.ceil(month / 3)].join('-')
    // Month
    // return [year, month].join('-')
  }

  // Default to month
  const year = value.getFullYear()
  const month = value.getMonth() + 1
  return [year, month].join('-')

  // return value.toJSON().substring(0, 7)
}
