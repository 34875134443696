
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as cssGrid from '../../../helpers/cssgrid/index.js'
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'
import * as mHtmlParser from '../../modules/user/html-parser.js'
import { makeXeroLink } from '../../helpers/xero-links.js'

// Page Module
import htmlTemplateIndex from './index.html'
import { fillString } from '../../../helpers/templating/string-template.js'
import { ascending } from 'd3'

/**
 * the account shown is whatever was loaded for the page
 * @returns
 */
export async function showPageTrackings () {
  console.log('showPageTrackings')

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Trackings:', accountData.name].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })
  contentElement.innerHTML = htmlPageLayout

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showTrackings(accountData)
}

function prepareRowsData (accountData) {
  console.log('prepareRowsData')
  const rowsData = accountData?._accountOrganisations?.map(function (oneAccountOrg) {
    return oneAccountOrg._xeroOrganisation
  })
  console.log(rowsData)

  return rowsData
}

function prepareColumnsDefs (accountData) {
  const colDefs = [
    {
      id: 'Entity',
      // headerValueGetter: function () {
      //   return 'Type'
      // },
      valueGetter: function (cellParams) {
        return cellParams.rowDef.name
      },
      layout: 'left',
      width: 300
    },
    {
      id: 'To Xero',
      // headerValueGetter: function () {
      //   return 'Type'
      // },
      valueGetter: function (cellParams) {
        const url = makeXeroLink('Home', cellParams.rowDef.details)

        return fillString('<a href=":url" target="_blank">Open in Xero</a>', { url })
      },
      layout: 'left',
      width: 120
    }
  ]

  // Add the Tracking Categories
  // showPageTrackings
  const categories = trackingColumns(accountData)
  categories.forEach(function (categoryName) {
    colDefs.push({
      id: categoryName,
      valueGetter: function (cellParams) {
        const orgData = mAccount.getOrganisationData(cellParams.rowDef._id)
        const orgCategory = orgData?.trackingData?.categories?.find(function (category) {
          return categoryName === category.name
        })
        if (!orgCategory) return null

        const linkToXero = makeXeroLink('Trackings', {
          shortCode: orgData.details.shortCode,
          trackingCategoryId: orgCategory.xeroId // categoryID=:categoryId
        })

        const categoryStatus = orgCategory?.status || null
        const cellValue = fillString('<a href=":linkToXero" target="_blank">:categoryStatus</a>', {
          linkToXero,
          categoryStatus
        })
        return cellValue
      },
      width: 120
    })
  })

  return colDefs
}

function trackingColumns (accountData) {
  const categories = new Set()

  accountData?.trackingCategoriesData?.forEach(function (trackingCategoryData) {
    trackingCategoryData.categories?.forEach(function (category) {
      categories.add(category.name)
    })
  })

  const categoryArray = Array.from(categories)
  if (!categoryArray) return []

  categoryArray.sort(function (c1, c2) {
    return ascending(c1, c2)
  })

  return categoryArray
}

function showTrackings (accountData) {
  const rowDefs = prepareRowsData(accountData)
  const colDefs = prepareColumnsDefs(accountData)

  sortRows(rowDefs)

  cssGrid.generateGrid({
    containerElementId: 'grid',
    classes: ['fit-content'],
    colDefs,
    rowDefs,
    showActions: true
  })
}

function sortRows (rowDefs) {
  rowDefs.sort(function (r1, r2) {
    return ascending(r1.name, r2.name)
  })
  return rowDefs
}
