// App config
import * as configVar from '../../config/config.js'
import { setElementHtml } from '../../helpers/dom.js'
import { showLoadingCohorts, showTheCohorts } from './show.js'

import htmlPageTemplate from './index.html'
import { launchMenu, showLoadingMenu, showReadyMenu, updateMenu } from './menu.js'
import { cohortSettings } from './data.js'
import { enrichActionsData, sortActions } from './data-actions.js'
import { analyseUsers, groupByUser } from './data-users.js'
import { computeCohortsInfo, createCohorts } from './data-cohorts.js'
import { executeDemo } from './utilities/demos.js'

import './style.less'
import { sleep } from '../../../helpers/helpers/sleep.js'
import { computeCohortsAcquisitionCostsInfo } from './data-cohorts-acquisition.js'
import { prepareAcquisitionCosts } from './data-acquisition.js'
// import dayjs from 'dayjs'
// import CustomParseFormat from 'dayjs/plugin/customParseFormat/index.js'
// dayjs.extend(CustomParseFormat)

export async function showCohortPage () {
  const containerElement = setElementHtml(configVar.contentElementId, htmlPageTemplate)
  if (!containerElement) return

  launchMenu()
  executeLaunch()
}

function executeLaunch () {
  console.log('cohortSettings', cohortSettings)
  if (!cohortSettings.demo) return

  executeDemo(cohortSettings.demo)
}

/**
 * Uses the data from the module cohortSettings
 */
export async function analyseCohorts () {
  console.log('analyseCohorts')
  showLoadingCohorts()
  showLoadingMenu()
  updateMenu()

  // Force a sleep time to force the browser to refresh the DOM
  await sleep(0)

  await computeAndShow()

  showReadyMenu()
}

async function computeAndShow () {
  const { records, cohortType, chartRetentionInitial } = cohortSettings

  // Prepare actions data
  const enrichedRecords = enrichActionsData(records)
  // console.log('enrichedRecords', enrichedRecords)

  // Sort the records
  sortActions(enrichedRecords)

  // Prepare user data
  const actionsByUser = groupByUser(enrichedRecords)
  // console.log('actionsByUser', actionsByUser)

  analyseUsers(actionsByUser)
  // console.log('users after analyse', actionsByUser)

  // Prepare cohorts
  const cohorts = createCohorts(actionsByUser)
  computeCohortsInfo(cohorts)
  // console.log('cohorts', cohorts)

  // Calculate the acquisition costs
  prepareAcquisitionCosts()
  computeCohortsAcquisitionCostsInfo(cohorts)
  console.log('cohorts', cohorts)

  showTheCohorts(cohorts, {
    cohortPeriods: cohortType,
    chartRetentionInitial
  })
}
