import { getSettingsToUse } from '../settings/settings-to-use.js'
import { readDroppedFile } from './read-dropped-file.js'

export async function readWithEnforcedSettings (file, options) {
  const { settingsType } = options
  const moduleSettings = getSettingsToUse()
  const platformSettings = moduleSettings?.platformSettings
  console.log('moduleSettings', moduleSettings)

  const droppedFileResult = await readDroppedFile(file, {
    moduleSettings,
    fileSettings: platformSettings?.[settingsType]
  })

  return droppedFileResult
}
