
import { fsVersion } from '../../config/config.js'
import * as mData from './data.js'

export function valueType () {
  let baseSettingsPrep
  if (fsVersion === 'fs3') {
    if (mData.db.options.statementType === 'pnl') {
      baseSettingsPrep = 'periods'
    } else {
      baseSettingsPrep = 'cumuls'
    }
  } else {
    if (mData.db.options.statementType === 'pnl') {
      baseSettingsPrep = 'valuePeriod'
    } else {
      baseSettingsPrep = 'valueCumul'
    }
  }
  console.log('baseSettings', baseSettingsPrep)
  return baseSettingsPrep
}

export function layoutRows () {
  let layoutRowsPrep
  if (fsVersion === 'fs3') {
    layoutRowsPrep = mData.db.data.fs3Data.layoutSettings.filter(function (oneLayoutRow) {
      return oneLayoutRow.statement === mData.db.options.statementType
    })
  } else {
    if (mData.db.options.statementType === 'pnl') {
      layoutRowsPrep = mData.db.data.statementLayouts.pnl
    } else {
      layoutRowsPrep = mData.db.data.statementLayouts.bs
    }
  }
  return layoutRowsPrep
}

export function aggregationDB () {
  let a
  if (fsVersion === 'fs3') {
    a = mData.db.data.fs3Data.aggregates
  } else {
    a = mData.db.data.aggregationDB
  }
  return a
}

export function layoutRowIncludedAccounts () {
  if (fsVersion === 'fs3') {
    return 'includedAccounts'
  }

  return 'accounts'
}

export function accountType (account) {
  if (fsVersion === 'fs3') {
    const indexed = {}
    mData.db.data.fs3Data.accounts.forEach(function (oneAccount) {
      indexed[oneAccount.id] = oneAccount
    })
    return indexed[account].type
  }

  return account.type
}

export function accountName (account) {
  if (fsVersion === 'fs3') {
    const indexed = {}
    mData.db.data.fs3Data.accounts.forEach(function (oneAccount) {
      indexed[oneAccount.id] = oneAccount
    })
    return indexed[account].name
  }

  return account.name
}

export function accountId (obj) {
  if (fsVersion === 'fs3') {
    return obj.account || obj.id
  }

  return obj.account.id || obj.account._id
}
