import { updateSelectors } from './update-selector.js'

export function executeOnChange (params) {
  // console.log('executeOnChange', params)

  updateSelectors(params)

  if (!params.onChange) return
  params.onChange()
}
