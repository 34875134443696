
// App config
import * as configVar from '../../../config/config.js'
import * as mAccount from '../../../modules/user/account.js'

import { enforceRestrictedPage } from '../../../modules/user/user.js'

import { hideElement, setElementHtml } from '../../../helpers/dom.js'
import { createContainer, setPageTitle } from '../../financials/helpers.js'
import { updateView } from './update-view.js'
import { showSelectors } from './show-selectors.js'

/**
 * @param {Object} params
 * @returns
 */

export async function showPageEliminationsJournalTransactions (params) {
  console.log('showPageCashJournalTransactions', configVar, configVar.contentElementId)

  if (enforceRestrictedPage({ allowPublic: true })) return

  const DOMelement = setElementHtml(configVar.contentElementId, '')
  if (!DOMelement) return

  DOMelement.classList.add('position-absolute', 'top-to-bottom')

  const contentElement = createContainer('Eliminations Journal Transactions')
  DOMelement.append(contentElement)

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  setPageTitle(accountData, 'Cash Transactions')

  showSelectors(updateView)

  hideElement('statementLoadingMessage')
}
