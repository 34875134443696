import { combineMultilinesItems } from '../../../utilities/multilines-item-files/combine-multilines-items.js'

export function prepareLazadaFile (datasetRecords) {
  const newProperties = [
    {
      outputProperty: function (record) {
        const baseProperty = 'Fee Name'
        return [record[baseProperty]].join(' ')
        // return [record[baseProperty], 'Amount'].join(' ')
      },
      outputValue: function (record) {
        const propertyName = 'Amount'
        return +record[propertyName]
      }
    },
    {
      outputProperty: function (record) {
        const baseProperty = 'Fee Name'
        return [record[baseProperty], 'Tax'].join(' ')
      },
      outputValue: function (record) {
        const propertyName = 'VAT in Amount'
        return +record[propertyName]
      }
    }
  ]

  const excludedProperties = [
    'Fee Name',
    'Amount(Include Tax)',
    'VAT Amount',
    // PH
    'Amount',
    'VAT in Amount',
    'WHT Amount',
    'WHT included in Amount'
  ]

  const combinationPropertyName = 'Order Item No.'

  const preparedRecords = combineMultilinesItems(datasetRecords, combinationPropertyName, newProperties, excludedProperties)

  return preparedRecords
}
