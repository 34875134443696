
import { fillString } from '../templating/string-template.js'
import * as numbers from './numbers.js'

/**
 *
 * @param {*} startingTime
 * @param {*} endingTime
 * @returns
 */
export function timeDuration (startingTime, endingTime) {
  if (!startingTime && !endingTime) return

  const startingDate = startingTime ? new Date(startingTime) : new Date()
  const endingDate = endingTime ? new Date(endingTime) : new Date()
  // console.log('startingTime', startingTime, startingDate)
  // console.log('endingTime', endingTime, endingDate)
  // console.log(startingDate, startingDate.toISOString(), startingDate.toTimeString(), startingDate.valueOf())
  // console.log(endingDate, endingDate.toISOString(), endingDate.toTimeString(), endingDate.valueOf())

  const delta = endingDate - startingDate
  // console.log('delta', delta)

  const relevantUnit = findRelevantUnit(delta)
  // console.log('relevantUnit', relevantUnit)

  const timeDelta = delta / relevantUnit?.cumulativeMultiplier

  const timeText = fillString(':delta :unitName', {
    delta: numbers.formatNumber(timeDelta, 0, { absolute: true }),
    unitName: relevantUnit?.name
  })

  let text
  if (timeDelta < 0) {
    text = 'in ' + timeText
  } else {
    text = timeText + ' ago'
  }

  return {
    timeDelta,
    msDelta: delta,
    unit: relevantUnit?.unit,
    text,
    timeText
  }
}

function findRelevantUnit (valueMs) {
  // console.log('findRelevantUnit', valueMs)
  let cumulativeMultiplier = 1
  let relevantUnit

  timeUnits.forEach(function (oneUnit) {
    // console.log('try', oneUnit)
    cumulativeMultiplier = cumulativeMultiplier * oneUnit.unitMultiplierFromPrevious
    const unitValue = valueMs / cumulativeMultiplier
    // console.log('unitValue', unitValue, '=', valueMs, '/', cumulativeMultiplier)

    if (Math.abs(unitValue) < 1) {
      return
    }

    relevantUnit = oneUnit
    relevantUnit.cumulativeMultiplier = cumulativeMultiplier
  })
  return relevantUnit
}

const timeUnits = [
  {
    name: 'milliseconds',
    unitMultiplierFromPrevious: 1
  },
  {
    name: 'seconds',
    unitMultiplierFromPrevious: 1000
  },
  {
    name: 'minutes',
    unitMultiplierFromPrevious: 60
  },
  {
    name: 'hours',
    unitMultiplierFromPrevious: 60
  },
  {
    name: 'days',
    unitMultiplierFromPrevious: 24
  }
]
