import { d3NumberFormat } from '../../../helpers/helpers/numbers.js'
import { setElementHtml, showLoading } from '../../helpers/dom.js'
import { cohortSettings } from './data.js'
import { showChartCohortsContributions } from './output/cohort-charts-contribution.js'
import { showCohortRetention } from './output/cohort-charts-retention.js'
import { showCohortSizeTrend } from './output/cohort-charts-size.js'
import { showCohortTable } from './output/cohort-table.js'

export function showLoadingCohorts () {
  showLoading('cohorts')
}

export function showTheCohorts (cohorts, options) {
  debugCohorts(cohorts)

  showCohorts(cohorts, options)
}

function debugCohorts (cohorts) {
  const showDebug = false

  if (!showDebug) return
  cohorts.forEach(function (oneCohort) {
    console.log('---')
    console.log(oneCohort.key.cohort)
    console.log(oneCohort.info.users[0], oneCohort.info.users.map(function (oneUserRetention) {
      const retentionPct = oneUserRetention / oneCohort.info.users[0]

      return d3NumberFormat(retentionPct, '.1%')
    }))

    console.log(oneCohort.info.users[0], oneCohort.info.userCumuls.map(function (oneUserRetention) {
      const retentionPct = oneUserRetention / oneCohort.info.users[0]

      return d3NumberFormat(retentionPct, '.1%')
    }))
  })
}

function showCohorts (cohorts, options) {
  const containerId = 'cohorts'
  console.log('showCohorts')

  // Reset
  setElementHtml(containerId, '')
  // return

  showCohortSizeTrend(cohorts, 'users', {
    title: 'Size of Cohorts',
    parentContainer: containerId,
    createContainer: true,
    cohortPeriods: options?.cohortPeriods
  })

  if (cohortSettings.recordsAcquisitionCosts) {
    showCohortRetention(cohorts, 'valuesWithAcquisition', {
      title: 'Net $ Value of Cohorts',
      parentContainer: containerId,
      createContainer: true,
      cohortPeriods: options?.cohortPeriods,
      showInitial: true,
      showAcquisitionCost: true,
      //
      fillNegatives: true,
      yAxisTitle: 'Net cohort value'
    })

    showCohortRetention(cohorts, 'ltvCac', {
      title: 'LTV/CAC of Cohorts',
      parentContainer: containerId,
      createContainer: true,
      cohortPeriods: options?.cohortPeriods,
      showInitial: true,
      showAcquisitionCost: true,
      //
      fillNegatives: true,
      yAxisTitle: 'LTV/CAC'
    })
  }

  showCohortTable(cohorts, 'users', {
    title: 'Cohorts of Users and their Retention',
    parentContainer: containerId,
    createContainer: true,
    size: {
      d3Format: '0,',
      showSpend: true,
      showCAC: true
    },
    values: {
      d3Format: '0,'
    },
    cohortPeriods: options?.cohortPeriods
  })

  showChartCohortsContributions(cohorts, 'users', {
    title: 'Contribution of Cohorts to total Users over time',
    parentContainer: containerId,
    createContainer: true,
    cohortPeriods: options?.cohortPeriods
  })

  showCohortTable(cohorts, 'users', {
    title: 'User Retention % in period',
    parentContainer: containerId,
    createContainer: true,
    size: {
      d3Format: '0,'
    },
    values: {
      d3Format: '.1%',
      type: '%'
      // colorMax: 1
    },
    cohortPeriods: options?.cohortPeriods
  })

  showCohortRetention(cohorts, 'users', {
    title: 'User Cohort Retention',
    parentContainer: containerId,
    createContainer: true,
    cohortPeriods: options?.cohortPeriods,
    // type: '%'
    showInitial: options?.chartRetentionInitial
  })

  showCohortRetention(cohorts, 'users', {
    title: 'User Cohort Retention %',
    parentContainer: containerId,
    createContainer: true,
    cohortPeriods: options?.cohortPeriods,
    type: '%',
    showInitial: options?.chartRetentionInitial
  })

  showCohortRetention(cohorts, 'values', {
    title: 'Cohort $ Retention',
    parentContainer: containerId,
    createContainer: true,
    cohortPeriods: options?.cohortPeriods,
    // type: '%',
    showInitial: options?.chartRetentionInitial
  })

  showCohortRetention(cohorts, 'values', {
    title: 'Cohort $ Retention %',
    parentContainer: containerId,
    createContainer: true,
    cohortPeriods: options?.cohortPeriods,
    type: '%',
    showInitial: options?.chartRetentionInitial
  })

  showCohortTable(cohorts, 'userCumuls', {
    title: 'User Retention: Cumul to period',
    parentContainer: containerId,
    createContainer: true,
    size: {
      d3Format: '0,'
    },
    values: {
      d3Format: '0,'
    },
    cohortPeriods: options?.cohortPeriods
  })

  showCohortTable(cohorts, 'userCumuls', {
    title: 'User Retention: Cumul % to period',
    parentContainer: containerId,
    createContainer: true,
    size: {
      d3Format: '0,'
    },
    values: {
      d3Format: '.1%',
      type: '%'
    },
    cohortPeriods: options?.cohortPeriods
  })

  showCohortTable(cohorts, 'values', {
    title: '$ Retention in period',
    parentContainer: containerId,
    createContainer: true,
    size: {
      d3Format: '$0,'
    },
    values: {
      d3Format: '$0,'
    },
    cohortPeriods: options?.cohortPeriods
  })

  showChartCohortsContributions(cohorts, 'values', {
    title: 'Cohort $ Contributions',
    parentContainer: containerId,
    createContainer: true,
    cohortPeriods: options?.cohortPeriods
  })

  showCohortSizeTrend(cohorts, 'values', {
    title: 'Size of Cohorts Value',
    parentContainer: containerId,
    createContainer: true,
    cohortPeriods: options?.cohortPeriods
  })

  showCohortTable(cohorts, 'values', {
    title: '$ Retention % in period',
    parentContainer: containerId,
    createContainer: true,
    size: {
      d3Format: '$0,'
    },
    values: {
      d3Format: '.1%',
      type: '%'
    },
    cohortPeriods: options?.cohortPeriods
  })

  showCohortTable(cohorts, 'valuesCumuls', {
    title: '$ Cumul to period',
    parentContainer: containerId,
    createContainer: true,
    size: {
      d3Format: '$0,'
    },
    values: {
      d3Format: '$0,'
    },
    cohortPeriods: options?.cohortPeriods
  })

  showCohortTable(cohorts, 'valuesCumuls', {
    title: '$ Cumul % to period',
    parentContainer: containerId,
    createContainer: true,
    size: {
      d3Format: '$0,'
    },
    values: {
      d3Format: '.1%',
      type: '%'
    },
    cohortPeriods: options?.cohortPeriods
  })
}

// Users
// // Numbers
// // Numbers Cumul

// // Retention %
// // Retention % Cumul

// Value
// // Numbers
// // Numbers Cumul

// // Retention %
// // Retention % Cumul

// Value per User

// Formats:
// Tables
// Chart

// Questions:
// Are we attracting more users in cohorts?
// Cohort Size

// Are we improving initial retention
// Period +1 %

// Are we improving longer term retention?
// Retention %

// Are we improving retention of high value users?

// Are the cohorts acting in every periods or once in a while only?
