
// App config
import * as configVar from '../../config/config.js'
import { callApi } from '../../helpers/fetch.js'

// Page Module
import htmlIndex from './index.html'

// Sub modules
// import * as authXero from './xero.js'

export async function show () {
  console.log('module show')

  // If login required
  // if (mUser.enforceRestrictedPage()) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Create the page DOM Element:

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  listeners()
}

function listeners () {
  const DOMelement = document.getElementById(configVar.contentElementId)

  const clickableElementRead = DOMelement.querySelector('#read')
  clickableElementRead.addEventListener('click', function (clickEvent) {
    console.log('clicked')
    readDB()
  })

  const clickableElementWrite = DOMelement.querySelector('#write')
  clickableElementWrite.addEventListener('click', function (clickEvent) {
    console.log('clicked')
    writeDB()
  })
}

async function readDB () {
  const apiUrl = '/api/demo/read'
  const data = await callApi(apiUrl)
  return data
}

async function writeDB () {
  const apiUrl = '/api/demo/write'
  const data = await callApi(apiUrl, null, {
    method: 'POST',
    body: JSON.stringify({})
  })

  console.log(data)
  return data
}
