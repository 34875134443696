// Idea: use https://github.com/d3/d3-format ? careful, it returns strings, not numbers

import { format } from 'd3'

export function d3NumberFormat (number, targetFormat) {
  if (!Number.isFinite(number)) return number

  const d3f = format(targetFormat)
  return d3f(number)
}
/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
 * @param {Number} number
 * @param {Number} decimals
 */
export function formatNumber (number, decimals, options) {
  // console.log('formatNumber', arguments)
  let value = number
  if (options?.absolute) {
    value = Math.abs(value)
  }
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value)
}

export function roundNumber (_number, _decimals) {
  if (!Number.isFinite(_number)) return _number

  const number = _number
  const decimals = _decimals || 0

  const powerDecimals = Math.pow(10, decimals)
  return Math.round(number * powerDecimals) / powerDecimals
}

/**
 * Simplify number to a rounded 1000s version
 * @param {*} _number
 * @param {*} _decimals
 * @returns
 */
export function shortenNumber (_number, _decimals) {
  const shortening = [
    [3, 'k'],
    [6, 'm'],
    [9, 'bn']
  ]

  if (!Number.isFinite(_number)) return _number

  const number = Math.abs(_number)
  const sign = Math.sign(_number)
  const decimals = _decimals || 0

  let tryNumber = number
  let shorteningIndex = 0

  while (shorteningIndex < shortening.length && tryNumber > 1000) {
    tryNumber = number / Math.pow(10, shortening[shorteningIndex][0])

    shorteningIndex++
  }
  shorteningIndex--

  const roundedNumber = roundNumber(tryNumber, decimals)
  const formattedNumber = ((sign < 0) ? '-' : '') + formatNumber(roundedNumber, decimals)

  if (shorteningIndex < 0) {
    return formattedNumber
  }

  const shortened = [formattedNumber, shortening[shorteningIndex][1]].join('')

  return shortened
}

/**
 * * From https://qawithexperts.com/article/javascript/get-file-size-in-mbkb-using-javascript/539
 * @param {*} _size
 */
export function filesizeFriendly (_size) {
  // console.log(_size)
  const fSExt = ['Bytes', 'KB', 'MB', 'GB']
  let i = 0

  // check if file is in GB,MB,KB or Bytes
  while (_size > 900) {
    _size = _size / 1024 // divide file size
    i++
  }
  const decimals = 1
  const multiplier = Math.pow(10, decimals)
  const text = (Math.round(_size * multiplier) / multiplier) + ' ' + fSExt[i]
  return {
    size: _size,
    unit: fSExt[i],
    text
  }
}
