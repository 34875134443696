
export const xeroSalesInvoiceProperties = [
  {
    outputProperty: '*ContactName',
    input: 'contactName'
  },
  {
    outputProperty: '*InvoiceDate',
    input: 'invoiceDate'
  },
  {
    outputProperty: '*DueDate',
    input: 'dueDate'
  },
  {
    outputProperty: '*InvoiceNumber',
    input: 'invoiceNumber'
  },
  {
    outputProperty: 'InventoryItemCode',
    inputProperty: 'SKU_id'
  },
  {
    outputProperty: '*Description',
    compute: function (productSale) {
      const description = ['SKU: ' + productSale.SKU_id]

      if (productSale.quantityFromDirect !== 0) {
        description.push('Direct Sales: ' + productSale.quantityFromDirect)
      }
      if (productSale.quantityFromBundles !== 0) {
        description.push('Sales through Bundles: ' + productSale.quantityFromBundles)
      }
      return description.join('\n')
    }
  },
  {
    outputProperty: '*Quantity',
    inputProperty: 'quantity'
  },
  {
    outputProperty: '*UnitAmount',
    inputProperty: 'averageSellingPriceNet'
  },
  {
    outputProperty: '*AccountCode',
    input: 'accountCode'
  },
  {
    outputProperty: '*TaxType',
    input: 'taxType'
    // VAT on Sales (11%) for Sales
  }

  // Currency
  // TrackingName1
  // TrackingOption1
]
