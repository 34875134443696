import { fillString } from '../../../../helpers/templating/string-template.js'
import { callApi } from '../../../helpers/fetch.js'

export async function checkToken (tokenId) {
  const checkTokenResult = await callApi('/api/xero/sync/checkToken/:tokenId', {
    tokenId
  })
  console.log('checkTokenResult', checkTokenResult)

  const selectorId = fillString('div.button[data-action="checkToken"][data-tokenId=":tokenId"]', {
    tokenId
  })
  const buttonElement = document.querySelector(selectorId)

  if (Array.isArray(checkTokenResult)) {
    buttonElement.innerHTML = 'Ok'
  } else {
    buttonElement.innerHTML = 'Nope'
  }
}
