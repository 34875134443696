// import { combinedFieldsSettings } from '../../combined-fields-settings.js'
import { shopeeOrdersFieldsMapping } from './shopee-orders-fields-mapping.js'
import { shopeeSettlementsFieldsMapping } from './shopee-settlements-fields-mapping.js'

export const MYshopeeSettings = {
  orders: {
    dataFile: {
      sheetName: 'orders',
      downloadTip: 'Download from Shopee > Order > My Orders'
    },
    fileDropZoneText: 'Pick or drop your file (.xlsx)',
    fieldsMapping: shopeeOrdersFieldsMapping
  },
  settlements: {
    dataFile: {
      // File info
      // Username: A2
      // Headers: line 5
      // Data: line 6+
      sheetName: 'Income',
      dataHeader: 6,
      downloadTip: 'Download from Shopee > Finance > Transactions'
    },
    fileDropZoneText: 'Pick or drop your file (.xlsx)',
    fieldsMapping: shopeeSettlementsFieldsMapping
  }
}

/**
 * Settlement file
 * tab=Summary > B6=mamaschoicemalaysia
 */
