import { createGrid } from 'ag-grid-community'

// CSS import Not working
// import 'ag-grid-community/styles/ag-grid.css'

import { group, max, min, rollup } from 'd3'
import { moduleData } from '../../data.js'

import './style.less'
import htmlPageTemplate from './template.html'
import htmlGridContainterTemplate from './grid-container.html'
import { fillString } from '../../../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../../../helpers/dom.js'
import { transformDataset } from '../../../utilities/transform-dataset/transform-dataset.js'
import { ordersDetailsSummary } from '../../settings/outputs/orders-details-summary.js'
import { commerceContainerId } from '../../table-ui/show-table.js'

export function showOrdersSummary () {
  const groupedByOrder = group(moduleData.ordersLines,
    function (record) {
      return record.orderId
    })

  const groupedBySKU = group(moduleData.ordersLines, function (record) {
    return record.SKU_id
  })

  // Group by order with their settledAt value
  const rolledUp = rollup(moduleData.ordersLines,
    function (records) {
      return min(records, record => record.settledAt)
    },
    function (record) {
      return record.orderId
    }
  )
  // console.log('rolledUp', rolledUp)

  // Group orders by settled or not
  const groupedBySettled = group(rolledUp, function (record) {
    const rollupValue = record[1]
    return !(rollupValue === undefined)
  })
  console.log('groupedBySettled', groupedBySettled)

  const html = fillString(htmlPageTemplate, {
    numberOrders: groupedByOrder.size,
    numberSKUs: groupedBySKU.size,
    createdAtMin: min(moduleData.ordersLines, function (record) { return record.createdAt }) || '??',
    createdAtMax: max(moduleData.ordersLines, function (record) { return record.createdAt }) || '??',
    numberSettled: groupedBySettled?.get(true)?.length || '-',
    numberNotSettled: groupedBySettled?.get(false)?.length || '-e',
    settledAtMin: min(moduleData.ordersLines, function (record) { return record.settledAt }) || '-',
    settledAtMax: max(moduleData.ordersLines, function (record) { return record.settledAt }) || '-'
  })

  setElementHtml('summaryOrdersData', html)

  activateView()
}

function activateView () {
  document.getElementById('viewOrdersData').addEventListener('click', function () {
    // console.log('moduleData', moduleData)
    // console.log('ordersDetailsSummary', ordersDetailsSummary)

    showGrid()
  })
}

function showGrid () {
  console.log('showGrid')
  const dataset = moduleData[ordersDetailsSummary.sourceData]
  const propertySettings = ordersDetailsSummary.propertiesSettings

  const rows = transformDataset(dataset, propertySettings)
  // showTable(rows, ordersDetailsSummary.propertiesSettings)
  console.log('rows', rows)

  const columnDefs = []
  ordersDetailsSummary.propertiesSettings.forEach(function (property) {
    const colDef = {
      field: property.propertyName
    }
    columnDefs.push(colDef)
  })
  console.log('columnDefs', columnDefs)

  const gridOptions = {
    rowData: rows,
    columnDefs
  }

  console.log('gridOptions', gridOptions)

  const gridContainerId = commerceContainerId
  const gridElement = document.getElementById(gridContainerId)
  gridElement.innerHTML = htmlGridContainterTemplate
  console.log('gridElement', gridElement)

  const gridContainerElement = document.getElementById('gridContainer')
  const grid = createGrid(gridContainerElement, gridOptions)
  console.log('grid', grid)

  grid.exportDataAsCsv()
}
