
// Module
import htmlTemplateSelectorOrganisations from './options-organisations.html'

import { optionSelectorData } from '../data.js'
import { setElementHtml } from '../../../helpers/dom.js'
import { getOrgId } from './get-org-id.js'
import { makeEntitiesChoices } from './make-entities-choices.js'
import { updateSelectorEntity } from './update-entities.js'
import { recordSelection } from './record-selection.js'
import { listeners } from './listeners.js'

export const ELEMENT_ID = 'optionOrganisationSelector'

export function activateMenuEntities (params) {
  console.log('activateMenuEntities', params, optionSelectorData)
  const orgsArray = showSelector(params)
  optionSelectorData.orgsArray = orgsArray

  listeners(params)
}

function showSelector (params) {
  const dom = setElementHtml('optionSelectorContent', htmlTemplateSelectorOrganisations)
  if (!dom) return

  let htmlSelector = htmlTemplateSelectorOrganisations

  const organisations = params.customSettings?.organisations

  if (organisations?.length === 0) {
    htmlSelector = htmlSelector.replaceAll(':whenNoOrganisation', '')
  } else {
    htmlSelector = htmlSelector.replaceAll(':whenNoOrganisation', 'is-hidden')
  }

  if (!organisations) return

  if (['trial-balances'].includes(params.customSettings?.statementType) || organisations.length <= 1) {
    htmlSelector = htmlSelector.replaceAll(':whenConsolidationAvailable', 'is-hidden')
  } else {
    htmlSelector = htmlSelector.replaceAll(':whenConsolidationAvailable', '')
  }

  setElementHtml(ELEMENT_ID, htmlSelector)
  console.log('optionSelectorData', optionSelectorData)

  const orgsArray = generateOrgArray(organisations, params.customSettings)
  console.log('optionSelectorData', optionSelectorData)

  makeEntitiesChoices('optionsEntities', orgsArray)
  console.log('optionSelectorData', optionSelectorData)

  updateSelectorEntity()
  console.log('optionSelectorData', optionSelectorData)

  return orgsArray
}

function generateOrgArray (organisations, customSettings) {
  console.log('generateOrgArray', organisations, customSettings)
  console.log('optionSelectorData', optionSelectorData)

  const { statementType, orgOptions } = customSettings
  const orgArray = []

  organisations.forEach(function (oneOrganisation) {
    const orgId = getOrgId(oneOrganisation)

    // The conso entity is shown by itself
    if (orgId === 'conso') return

    // Eliminations Trial Balances not yet available
    if (orgId === 'eliminations' && ['trial-balances'].includes(statementType)) return

    orgArray.push(oneOrganisation)
  })

  if (orgOptions?.allowMultiple && organisations.length > 1) {
    orgArray.unshift({
      type: 'break'
    })
    orgArray.unshift({
      id: 'none',
      name: 'Deselect All'
    })
    orgArray.unshift({
      id: 'all',
      name: 'Select All'
    })
  }

  if (orgOptions?.allowConso && organisations.length > 1) {
    orgArray.unshift({
      type: 'break'
    })
    orgArray.unshift({
      id: 'conso',
      name: 'Consolidated Group'
    })
  }

  // Defaults to select an entity
  if (!optionSelectorData.organisationId && Number.isFinite(orgOptions?.defaultIndex)) {
    recordSelection(getOrgId(organisations[orgOptions.defaultIndex]))
  }

  return orgArray
}
