import { generateGrid } from '../../../../helpers/cssgrid/index.js'
import { d3NumberFormat } from '../../../../helpers/helpers/numbers.js'
import { computeColorRange } from '../utilities/color.js'
import { computeCohortsValues } from '../utilities/values.js'

import '../style.less'
import { cohortName } from './helpers.js'
import { colorArrayToRGB } from '../../../../helpers/colors/index.js'
import { createContainer } from '../utilities/containers.js'
import { cohortSettings } from '../data.js'
import { checkPeriodsOfInterest } from '../utilities/check-values.js'

/**
* Generate and show a Cohort Table

* TODO:
* Additional column: size users
* @param {Array} cohorts
* @param {String} property property of the values to use
* @param {Object} options
* @param {String} [options.title]
* @param {String} options.parentContainer Id of the parent
* @param {Boolean} options.createContainer
* @param {Object} options.size Options for the cohort size
* @param {String} options.size.d3Format Formatting as per D3 method

* @param {Object} options.values Options for the cohort values
* @param {String} options.values.d3Format Formatting as per D3 method
* @param {String} options.values.type % to use % instead of values
* @param {String} options.values.colorMax 1 to color to 100% instead of max; Only available for type === %
*/
export function showCohortTable (cohorts, property, options) {
  // console.log('showCohortTable', cohorts)
  const periodsOfInterest = checkPeriodsOfInterest(cohorts, property)
  const containerOptions = Object.assign({}, options)
  containerOptions.classes = containerOptions.classes || []
  containerOptions.classes.push('cohort-table')
  containerOptions.elementType = 'div'

  const cohortContainerId = createContainer(containerOptions)

  // How many rows to show
  const maxCohorts = 12
  const effectiveStartingCohortIndex = Math.max(0, cohorts.length - maxCohorts)
  const rows = cohorts.slice(effectiveStartingCohortIndex)
  computeCohortsValues(rows, property, options)

  const targetColor = [130, 202, 186]
  const valueRange = computeColorRange({
    cohorts: rows
  })

  const columns = [{
    id: 'cohort',
    valueGetter: function (cellParams) {
      return cohortName(cellParams.rowDef.key.cohort, options.cohortPeriods)
    },
    width: 80,
    layout: 'left'
  }, {
    id: 'cohortSize',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.info[property]?.[0]
      // return cellParams.rowDef.showValues?.[0]
    },
    valueFormatter: function (cellParams) {
      if (options?.size?.d3Format) {
        return d3NumberFormat(cellParams._value, options.size.d3Format)
      } else {
        return cellParams._value
      }
    },
    backgroundColor: function (cellParams) {
      // Do not style cohort size of % tables
      if (options.values.type === '%') return

      const pct = cellParams._value / valueRange[1]
      const colorString = colorArrayToRGB([...targetColor, pct])
      // console.log('color:', colorString)
      return colorString
    },
    width: 110,
    styles: ['numberColumns'],
    layout: 'left'
  }]

  if (options.size.showSpend && cohortSettings.recordsAcquisitionCosts) {
    columns.push({
      id: 'cohortAcquisitionSpend',
      valueGetter: function (cellParams) {
        return cellParams.rowDef._spend
      },
      valueFormatter: function (cellParams) {
        return d3NumberFormat(cellParams._value, '$,.0f')
      },
      width: 110,
      styles: ['numberColumns'],
      layout: 'left'
    })
  }

  if (options.size.showCAC && cohortSettings.recordsAcquisitionCosts) {
    columns.push({
      id: 'cohortAcquisitionCAC',
      valueGetter: function (cellParams) {
        return cellParams.rowDef._spendPerUser
      },
      valueFormatter: function (cellParams) {
        return d3NumberFormat(cellParams._value, '$,.1f')
      },
      width: 110,
      styles: ['numberColumns'],
      layout: 'left'
    })
  }

  // How many columns to show
  const maxColumns = 12
  const effectiveColumns = Math.min(maxColumns, cohorts[0].info[property].length)
  // TODO: move to cohortRelativePeriods()

  for (let periodRef = 1; periodRef <= effectiveColumns; periodRef++) {
    // console.log(periodRef, periodsOfInterest)

    const needColumn = !(periodsOfInterest?.hasPeriod0 === false && periodRef === 1)
    if (needColumn) {
      columns.push(periodColumn(periodRef, options, valueRange, targetColor))
    }
  }

  // Cohort Period | size | period0 | period... | periodMax

  generateGrid({
    colDefs: columns,
    rowDefs: rows,
    containerElementId: cohortContainerId,
    classes: ['zenflowGrid']
  })
}

function periodColumn (periodRef, options, valueRange, targetColor) {
  const text = (options.cohortPeriods === 'quarter') ? 'Q' : 'Month'

  // Because the cohorts have an initial value, then value for each following period including period 0 (the same aggregated date than initial action, but not the initial action: eg. within the same quarter)
  const periodRefText = periodRef - 1

  const column = {
    id: [text + ' +', periodRefText].join(''),
    valueGetter: function (cellParams) {
      return cellParams.rowDef.showValues?.[periodRef] || null
    },
    valueFormatter: function (cellParams) {
      if (options?.values?.d3Format) {
        return d3NumberFormat(cellParams._value, options.values.d3Format)
      } else {
        return cellParams._value
      }
    },
    backgroundColor: function (cellParams) {
      let pct
      if (options.values.type === '%' && options.values.colorMax === 1) {
        // Option to color to 100%
        // console.log(cellParams._value, 'in valueRange', valueRange, pct)
        pct = cellParams._value / 1
      } else {
        // Default to color to the max of the range
        pct = cellParams._value / valueRange[1]
      }
      const colorString = ['rgba(', targetColor.join(','), ',', pct, ')'].join('')
      // console.log('color:', colorString)
      return colorString
    },
    width: 90,
    styles: ['numberColumns']
  }
  return column
}
