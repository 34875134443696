
export function toBoolean (string) {
  if (string === 1 || string === '1') {
    return true
  }
  if (string === true || string === 'true') {
    return true
  }

  if (string === 0 || string === '0') {
    return false
  }
  if (string === false || string === 'false') {
    return false
  }

  return null
}
