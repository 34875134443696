import { enableConsole } from '../logging/console.js'
enableConsole(null)

/**
 * Adds properties:
 * - _parent {columnDef} to the level relations
 * - _level {Number}
 * - _hasLevels {Numbers}
 *
 * @param {Object} params
 * @param {Object[]} params.columnDefs TODO: to be renamed columnsSettings
 * @param {String} params.columnDefs[].id
 * @param {Function} params.columnDefs[].headerValueGetter
 * @param {String} [params.columnDefs[].value] - value for header cell when not using headerValueGetter()
//  * @param {Number} [params.columnDefs[].width] - width in pixels of the column
 * @param {Function} params.columnDefs[].valueGetter
 * @param {Number} [params.columnDefs[].rowSpan]
 * @param {String} [params.columnDefs[].layout=content] - left|content Default: content
 * @param {Object[]} [params.columnDefs[].children]
 * @param {Object[]} [params.columnDefs[].params] - Not native to the grid; whatever one wants
 * @returns {columnDefs} columnDefs
*/
export function parseColumnDefs (params) {
  enableConsole(false)
  console.log('parseColumnDefs', params)
  const { columnDefs } = params

  // let numberColumnLevels = 0
  const columnLevel = 0

  const leftColumns = []
  const centerColumns = []
  const columnsAnalysis = {
    numberHeaderRows: 0
    // TODO:
    // numberTotalColumns: {
    //   left: 0,
    //   content: 0
    // }
  }

  columnDefs.forEach(function (oneColumnDef) {
    const columnDef = levelColumnDef({
      columnLevel,
      columnsAnalysis,
      columnDef: oneColumnDef
    })
    if (oneColumnDef.layout === 'left') {
      leftColumns.push(columnDef)
    } else {
      centerColumns.push(columnDef)
    }
  })

  enableConsole('reset')

  return {
    leftColumns,
    centerColumns,
    columnsAnalysis
  }
}

/**
 *
 * @param {*} params
 * @returns
 */
function levelColumnDef (params) {
  const { columnsAnalysis, columnLevel, columnDef } = params
  const parsedColumnDef = {}

  // We include the initial properties, except reserved ones
  Object.keys(columnDef).forEach(function (oneProperty) {
    if (['children'].includes(oneProperty)) return

    parsedColumnDef[oneProperty] = columnDef[oneProperty]
  })

  parsedColumnDef._level = columnLevel
  parsedColumnDef._hasLevels = 0

  // The total number of header rows is the columnLevel (+1 as levels are in base0)
  columnsAnalysis.numberHeaderRows = Math.max(columnsAnalysis.numberHeaderRows, columnLevel + 1)

  // Manage the children
  if (params.columnDef.children) {
    parsedColumnDef.children = []
    parsedColumnDef._hasLevels++

    params.columnDef.children.forEach(function (oneChildColDef) {
      const subColumnDef = levelColumnDef({
        columnsAnalysis,
        columnLevel: columnLevel + 1,
        columnDef: oneChildColDef
      })
      subColumnDef._parent = parsedColumnDef
      parsedColumnDef._hasLevels += subColumnDef._hasLevels

      parsedColumnDef.children.push(subColumnDef)
    })
  }
  return parsedColumnDef
}

/**
 * Generate the grid-template-columns css property for the columns
 * @param {columnDef[]} columnDefs
 * @returns
 */
export function generateRowColumnsWidth (columnDefs) {
  enableConsole(false)
  console.log('generateRowColumnsWidth', columnDefs)
  const gridTemplateColumns = []
  // grid-template-columns

  function includeColumnWidth (params) {
    const { columnDef } = params
    if (columnDef.children) {
      columnDef.children.forEach(function (oneColumnDef) {
        includeColumnWidth({
          columnDef: oneColumnDef
        })
      })
      return
    }
    // console.log('columnDef.width', columnDef.width)
    if (Number.isFinite(columnDef.width)) {
      gridTemplateColumns.push(columnDef.width + 'px')
    } else {
      gridTemplateColumns.push('auto')
    }
  }

  columnDefs?.forEach(function (oneColumnDef) {
    includeColumnWidth({
      columnDef: oneColumnDef
    })
  })

  enableConsole('reset')
  return gridTemplateColumns.join(' ')
}
