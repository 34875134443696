
export function showDatasetOnly (event, legendItem, legend) {
  const targetDatasetIndex = legendItem.datasetIndex
  const chart = legend.chart
  const datasets = chart.data.datasets

  if (chart.isDatasetVisible(targetDatasetIndex)) {
    chart.setDatasetVisibility(targetDatasetIndex, false)
  } else {
    chart.setDatasetVisibility(targetDatasetIndex, true)
  }

  manageDoubleClick(chart, datasets, targetDatasetIndex)

  // Store info
  chart.$$f = chart.$$f || {}
  chart.$$f.legendClick = {
    datasetIndex: targetDatasetIndex,
    timestamp: new Date()
  }
  chart.update()
}

function manageDoubleClick (chart, datasets, targetDatasetIndex) {
  const maxDoubleClickTime = 200

  if (!chart.$$f?.legendClick) return
  if (chart.$$f?.legendClick.datasetIndex !== targetDatasetIndex) return

  const previousClickTimeAgo = new Date() - chart.$$f.legendClick.timestamp
  if (previousClickTimeAgo > maxDoubleClickTime) return

  let visibilityOfOthers = false
  if (chart.$$f.legendDblClick?.datasetIndex === targetDatasetIndex) {
    visibilityOfOthers = true
    if (chart.$$f.legendDblClick?.visibilityOfOthers === true) {
      visibilityOfOthers = false
    }
  }
  updateDatasetsVisibility(chart, datasets, targetDatasetIndex, visibilityOfOthers)

  chart.$$f.legendDblClick = {
    datasetIndex: targetDatasetIndex,
    visibilityOfOthers
  }
}

function updateDatasetsVisibility (chart, datasets, targetDatasetIndex, visibilityOfOthers) {
  datasets.forEach(function (oneDataset, index) {
    if (index === targetDatasetIndex) {
      chart.setDatasetVisibility(index, true)
    } else {
      chart.setDatasetVisibility(index, visibilityOfOthers)
    }
  })
}
