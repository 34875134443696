
export const lists = [
  {
    elementId: 'itemsToGroup'
  },
  {
    elementId: 'itemsPnl',
    title: 'Profit & Loss',
    total: 'Profit',
    multiplier: -1
  },
  {
    elementId: 'itemsBS',
    title: 'Balance Sheet',
    total: 'Balance'
  }
]

/**
 *
 * @param {String|String[]} [targetList] itemsToGroup, itemsPnl, itemsBS
 */
export function getStoredItems (targetList) {
  const storedItems = []

  lists.forEach(function (oneList) {
    const matchingTargetList = targetList === oneList.elementId || targetList?.includes(oneList.elementId)
    if (targetList && !matchingTargetList) return

    const listData = {
      list: oneList,
      items: []
    }
    const localStorageKey = ['items', oneList.elementId].join('.')
    const localStorageData = localStorage.getItem(localStorageKey)
    const itemsOfList = JSON.parse(localStorageData)
    listData.items = itemsOfList

    storedItems.push(listData)
  })

  return storedItems
}
