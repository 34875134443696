
// Dependencies
import * as mAccount from '../../modules/user/account.js'
import * as mHtmlParser from '../../modules/user/html-parser.js'

// Module
import * as mOrgSyncStatus from './organisations-syncstatus.js'
import { checkOrgToken } from './organisations-checktoken.js'
import { callApi } from '../../helpers/fetch.js'
import { conditionalValue, fillString } from '../../../helpers/templating/string-template.js'
import { makeXeroLink } from '../../helpers/xero-links.js'

import htmlTemplateOrganisation from './organisation-one.html'

export let associatedOrganisations
/**
 *
 * @returns
 */
export async function showAssociatedOrganisations (accountId) {
  console.log('showAssociatedOrganisations', accountId)
  const DOMelementId = 'accountOrganisations'
  const DOMelement = document.getElementById(DOMelementId)
  if (!DOMelement) return
  DOMelement.innerHTML = ''

  await getAssociatedOrganisations(accountId)

  associatedOrganisations?.forEach(function (oneAssociatedOrg) {
    const organisationHtml = generateOneOrganisation({
      accountId,
      xeroOrganisation: oneAssociatedOrg._xeroOrganisation,
      isAssociated: true
    })
    DOMelement.insertAdjacentHTML('beforeend', organisationHtml)

    mOrgSyncStatus.checkOrgSyncStatus(oneAssociatedOrg._xeroOrganisation._id)
    checkOrgToken(oneAssociatedOrg._xeroOrganisation._id)
  })
  showNoAssociatedOrganisation(associatedOrganisations)
}

function showNoAssociatedOrganisation (associatedOrganisations) {
  const messageElement = document.getElementById('messageNoOrganisation')
  messageElement.hidden = !(associatedOrganisations.length === 0)
}

/**
 *
 * @param {*} params
 * @returns
 */
export function generateOneOrganisation (params) {
  console.log('outputOneOrganisation', params)
  const { accountId, xeroOrganisation, connection } = params
  if (!xeroOrganisation) return

  const organisationDetails = xeroOrganisation.details

  const linkToXero = makeXeroLink('Home', organisationDetails)
  const orgName = organisationDetails?.name || xeroOrganisation.name || '... Not yet available'
  const orgXeroId = xeroOrganisation.orgXeroId
  const orgId = xeroOrganisation.id || xeroOrganisation._id

  let htmlOrganisation = fillString(htmlTemplateOrganisation, {
    accountId,
    orgName,
    orgId,
    orgXeroId,
    linkToXero,
    xeroConnectionId: connection?.id,
    hideIfAssociated: conditionalValue(params.isAssociated, 'is-hidden'),
    hideIfNotAssociated: conditionalValue(!params.isAssociated, 'is-hidden'),
    hideIfNoConnection: conditionalValue(!connection, 'is-hidden'),
    hideUntilTokenCheck: 'is-hidden'
  })

  const accountData = mAccount.accountData()
  htmlOrganisation = mHtmlParser.applyRestrictions(htmlOrganisation, { accountData })

  return htmlOrganisation
}

/**
 *
 */
async function getAssociatedOrganisations (accountId) {
  const apiUrl = '/api/xero/associate/:accountId/list'
  const data = await callApi(apiUrl, {
    accountId
  })

  console.log('getAssociatedOrganisations')
  console.log(data)
  console.log('data.length', data.length)

  if (data.length >= 0) {
    associatedOrganisations = data
  }
  return data
}
