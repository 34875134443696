
export const entities = []

entities.push({
  name: 'REDHILL Communications Pte. Ltd',
  baseCurrency: 'SGD'
})
entities.push({
  name: 'REDHILL COMMUNICATIONS LLP',
  baseCurrency: 'INR'
})
entities.push({
  name: 'REDHILL Co., Ltd',
  baseCurrency: 'JPY'
})
