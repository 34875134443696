import { callApi } from '../../../helpers/fetch.js'

/**
 * TESTING
 * @param {*} params
 * @returns
 */
export async function loadScheduleDataTransactions (params) {
  console.log('loadScheduleDataTransactions', params)

  const apiUrl = '/api/xero/a/:accountId/o/:orgId/schedule/:accountXeroId/:contactName'
  const responseData = await callApi(apiUrl, {
    accountId: params.accountId,
    orgId: params.orgId,
    accountXeroId: params.chartAccountId,
    contactName: params.contactName
  })
  console.log('loadScheduleDataTransactions', responseData)
  return responseData
}
