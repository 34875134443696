import { enableConsole } from '../../../../helpers/logging/console.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import * as mFinancialsHelpers from '../../../helpers/helpers.js'

export function forecastingGridColumns (params) {
  const columnsSettings = []
  enableConsole(true)

  columnsSettings.push({
    value: ' ',
    id: 'itemName',
    styles: ['leftColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef.value || cellParams.rowDef.id
    },
    layout: 'left',
    width: 200
  })

  // columnsSettings.push({
  //   value: 'Includes?',
  //   id: 'itemInclusion',
  //   styles: ['leftColumns'],
  //   valueGetter: function (cellParams) {
  //     if (['startingCash', 'endingCash'].includes(cellParams.rowDef.id)) {
  //       return ''
  //     }
  //     return '[soon]'
  //   },
  //   layout: 'left',
  //   width: 80
  // })

  columnsSettings.push({
    value: 'Monthly Value',
    id: 'monthlyValue',
    styles: ['leftColumns', 'numberColumns'],
    valueGetter: function (cellParams) {
      if (['startingCash', 'endingCash'].includes(cellParams.rowDef.id)) {
        return ''
      }

      const rowData = cellParams.rowDef?.data
      const forecastingValue = rowData?.forecastingValue || 0
      return applyMultiplier(forecastingValue, cellParams)
    },
    valueFormatter: function (cellParams) {
      return mFinancialsHelpers.contentValueFormatter({
        cellParams,
        decimals: optionSelectorData.decimals
      })
    },
    valueStyling: function (cellParams) {
      return mFinancialsHelpers.contentValueStyling({ cellParams })
    },
    layout: 'left',
    width: 110
  })

  columnsSettings.push(...forecastingColumns(params))

  return columnsSettings
}

function forecastingColumns (params) {
  const columns = []

  const { forecastingDateseries } = params

  forecastingDateseries.forEach(function (oneForecastingDate, index) {
    const columnSettings = {
      headerValueGetter: function () {
        return oneForecastingDate
      },
      valueGetter: function (cellParams) {
        enableConsole(true)
        if (['startingCash', 'endingCash'].includes(cellParams.rowDef.id)) {
          return valueGetterRunningCash(cellParams)
        } else {
          return valueGetterForecastingLine(cellParams)
        }
      },
      valueFormatter: function (cellParams) {
        return mFinancialsHelpers.contentValueFormatter({
          cellParams,
          decimals: optionSelectorData.decimals
        })
      },
      valueStyling: function (cellParams) {
        return mFinancialsHelpers.contentValueStyling({ cellParams })
      },
      id: oneForecastingDate,
      params: {
        forecastingDate: oneForecastingDate,
        forecastingDateseriesIndex: index
      },
      styles: ['numberColumns'],
      width: 110
    }

    columns.push(columnSettings)
  })

  return columns
}

function applyMultiplier (rawValue, cellParams) {
  const rowData = cellParams.rowDef.data
  // enableConsole(true)
  // console.log('applyMultiplier', rawValue, cellParams)

  const viewMultiplier = (rowData.accountType?.viewSign * rowData.accountType?.expectedSign) || 1

  return rawValue * viewMultiplier
}

/**
 *
 * @param {*} cellParams
 * @returns
 */
function valueGetterForecastingLine (cellParams) {
  // console.log('valueGetterForecastingLine', cellParams)
  const rowData = cellParams.rowDef.data
  if (!rowData) return null

  const columnDef = cellParams.columnDef
  const forecastingDateseriesIndex = columnDef.params.forecastingDateseriesIndex

  const forecastingValue = rowData.forecastingValues[forecastingDateseriesIndex]

  return applyMultiplier(forecastingValue, cellParams)
}

/**
 *
 * @param {*} cellParams
 * @returns
 */
function valueGetterRunningCash (cellParams) {
  // console.log('valueGetterRunningCash', cellParams)
  const rowDef = cellParams.rowDef
  if (!rowDef) return null

  const columnDef = cellParams.columnDef
  const forecastingDateseriesIndex = columnDef.params.forecastingDateseriesIndex

  const forecastingRunningCash = rowDef.params.forecastingRunningCash
  if (!forecastingRunningCash) return null

  let runningCashIndex = forecastingDateseriesIndex

  if (rowDef.id === 'endingCash') {
    runningCashIndex++
  }
  // console.log('runningCashIndex', runningCashIndex, forecastingRunningCash[runningCashIndex])

  return forecastingRunningCash[runningCashIndex]
}
