
import { combineLines } from './combine-lines/combine-lines.js'
import { isCombinationNeeded } from './combine-lines/is-combination-needed.js'
import { parseDataRow } from './parse-data-row.js'
import { showDatasetInfo } from './show-dataset-info/show-dataset-info.js'

/**
 * Once passing the sheet data,
 * - find the fields: trim them
 * - parse the data
 *  numbers
 *  percentages
 *
 * Analysis
 *  Map of Fields
 *  Value
 *  Type
 */
/**
 *
 * @param {*} dataset
 * @param {Object} options
 * @param {Object[]} options.fieldsMapping
 * @param {Boolean} [options.combineLines]
 * @returns
 */
export function parseDataset (dataset, options) {
  console.log('parseDataset', options, dataset)

  const fieldsMapping = options?.fieldsMapping

  const fieldsMap = new Map()
  const parsedRows = []

  dataset?.forEach(function (dataRow) {
    const parsedDataRow = parseDataRow(fieldsMap, dataRow, fieldsMapping, options)
    parsedRows.push(parsedDataRow)
  })

  console.log('fieldsMap', fieldsMap)
  showDatasetInfo(fieldsMap, fieldsMapping)

  console.log('parsedRows', parsedRows)

  if (options?.combineLines && isCombinationNeeded(fieldsMapping)) {
    const combinedLines = combineLines(parsedRows, fieldsMapping)
    return combinedLines
  } else {
    return parsedRows
  }
}
