import { getFileData } from '../../../../../helpers/xlsx-reader.js/get-file-data.js'
import { readCsvFile } from '../../utilities/read-csv-file.js'
import { executeParseDataset } from './execute-parse-dataset.js'

export async function readDroppedFile (file, options) {
  console.log('readDroppedFile', file, options)
  const { moduleSettings, fileSettings } = options
  const sheetParsingOptions = fileSettings?.dataFile

  let dataset
  if (options?.dataFile?.type === 'csv' || file.type === 'text/csv') {
    dataset = await readCsvFile(file)
  } else {
    dataset = await getFileData(file, sheetParsingOptions)
  }
  console.log('file dataset (before parsing)', dataset)

  if (options?.fileSettings?.dataPreparation) {
    dataset = options.fileSettings.dataPreparation(dataset)
  }

  const results = executeParseDataset(dataset, fileSettings?.fieldsMapping, moduleSettings)

  return results
}
