import { optionSelectorData } from '../../data.js'

/**
 *
 */
export function updateSelectorLayout (params) {
  const layoutSelectorElement = document.getElementById('optionLayoutSelector')
  if (!layoutSelectorElement) return

  if (params.hide?.includes('gridLayout')) {
    layoutSelectorElement.classList.add('is-hidden')
    return
  }

  if (optionSelectorData.organisationId === 'conso') {
    layoutSelectorElement.classList.remove('is-hidden')
  } else {
    layoutSelectorElement.classList.add('is-hidden')
    return
  }

  // Styling
  const buttonElements = layoutSelectorElement.querySelectorAll('button')
  buttonElements.forEach(function (oneButton) {
    oneButton.classList.remove('is-active')

    const buttonIcon = oneButton.querySelector('.icon')
    buttonIcon?.classList.add('is-hidden')
  })

  const selector = 'button[d-layout=":layout"]'.replace(':layout', optionSelectorData.layout.join('.'))
  const selectedButtonElement = layoutSelectorElement.querySelector(selector)
  selectedButtonElement.classList.add('is-active')
  const iconElement = selectedButtonElement.querySelector('.icon')
  iconElement.classList.remove('is-hidden')
}
