import * as d3 from 'd3'
import { Chart } from 'chart.js/auto'
import { d3NumberFormat } from '../helpers/numbers.js'

export function columnChart (options) {
  console.log('columnChart', options)

  const chartContainerId = options.chartContainerId
  const chartContainerElement = document.getElementById(chartContainerId)
  console.log('chartContainerElement', chartContainerElement)

  /*
    {
      label
      data
      order: allows position of the dataset (useful :)
    }
  */
  const datasets = options.datasets || []

  // datasets.push({
  //   label: 'x',
  //   data: [],
  //   backgroundColor: [],
  //   borderColor: [],
  //   borderWidth: 1
  // })

  const chartConfig = {
    type: 'bar',
    data: {
      labels: options.labels,
      datasets
    },
    options: {
      scales: {
        y: {
          title: {
            display: true,
            text: 'Size'
          },
          // backgroundColor: 'red',
          // border: {
          // }
          grid: {
            // display: false, // Show or not the horizontal lines and their ticks
            // drawOnChartArea: false,
            // drawTicks: true
          },
          // min: 0,
          // max: 1,
          // reverse: true,
          // stacked: true,
          // weight: 0, // position from the chart
          beginAtZero: true
        },
        x: {
          title: 'Cohort',
          grid: {
            display: false // Show or not the vertical lines
          }
        }
      },
      plugins: {
        legend: {
          reverse: true // Reverse the order of the series
          // labels: {
          //   font: {
          //     size: 14
          //   }
          // }
        }
      }
    }
  }

  if (datasets.length === 2) {
    // Right axis: % change
    datasets[1].yAxisID = 'y2'
    datasets[1].order = 1
    datasets[0].order = 2
    chartConfig.options.scales.y2 = {
      position: 'right',
      type: 'linear',
      title: {
        display: true,
        // color: 'red',
        text: 'Change'
      },
      grid: {
        drawOnChartArea: false,
        drawTicks: false
      },
      ticks: {
        padding: 9,
        callback: function (value) {
          return d3NumberFormat(value, '+.0%')
        }
      }
    }

    // yAxis for growth numbers
    const dataserie = datasets[1].data
    limitYaxisExtremes(dataserie, chartConfig.options.scales.y2)

    chartConfig.options.plugins = chartConfig.options.plugins || {}
    chartConfig.options.plugins.tooltip = chartConfig.options.plugins.tooltip || {}
    chartConfig.options.plugins.tooltip.callbacks = {
      label: function (context) {
        const { datasetIndex, dataIndex, dataset } = context

        const value = dataset.data[dataIndex]
        if (datasetIndex === 1) {
          return d3NumberFormat(value, '+.1%')
        } else {
          return d3NumberFormat(value, '0,')
        }
      }
    }
  }

  if (datasets[0].color) {
    datasets[0].backgroundColor = datasets[0].color

    chartConfig.options.scales.y.ticks = chartConfig.options.scales.y.ticks || {}
    chartConfig.options.scales.y.ticks.backdropColor = datasets[0].color
    chartConfig.options.scales.y.ticks.color = datasets[0].color
    chartConfig.options.scales.y.title.color = datasets[0].color
  }

  function redNegatives (context, baseColor) {
    const value = context.raw
    if (value < 0) return '#990000'

    return baseColor
  }

  if (datasets[1].color) {
    datasets[1].backgroundColor = function (context) {
      return redNegatives(context, datasets[1].color)
    }
    datasets[1].borderColor = function (context) {
      return redNegatives(context, datasets[1].color)
    }

    chartConfig.options.scales.y2.ticks = chartConfig.options.scales.y2.ticks || {}
    chartConfig.options.scales.y2.ticks.backdropColor = datasets[1].color
    chartConfig.options.scales.y2.ticks.color = datasets[1].color
    chartConfig.options.scales.y2.title.color = datasets[1].color
  }

  const chart = new Chart(chartContainerElement, chartConfig)
  return chart
}

function limitYaxisExtremes (dataserie, scaleSettings) {
  const maxExtreme = 3
  const quantilePct = 0.8

  const maxValue = d3.max(dataserie)
  const quantileValue = d3.quantile(dataserie, quantilePct)
  const medianValue = d3.median(dataserie)
  // const deviation = d3.deviation(dataserie)
  // console.log('limitYaxisExtremes', dataserie, 'quantileValue', quantileValue, 'maxValue', maxValue, 'medianValue', medianValue, 'deviation', deviation)

  if (maxValue > quantileValue * maxExtreme) {
    const max = Math.min(quantileValue * maxExtreme, medianValue * maxExtreme * maxExtreme)
    scaleSettings.max = max
  }
}
