import { enableConsole } from '../logging/console.js'

export function restrictDateseries (params) {
  // console.log('restrictDateseries', params)
  const { dateseries, optionPeriods } = params

  const restrictedDateseries = []

  dateseries.forEach(function (oneDate) {
    if (optionPeriods.min && optionPeriods.min > oneDate) return
    if (optionPeriods.max && optionPeriods.max < oneDate) return

    restrictedDateseries.push(oneDate)
  })
  // console.log('restrictedDateseries', restrictedDateseries)
  return restrictedDateseries
}

/**
 * MongoDB uses the wording 'densify' to generate series
 * @param {Object} params
 * @param {String} params.min
 * @param {String} params.max
 * @param {Boolean} params.allowFuture
 * @param {Boolean} params.untilTodayAtLeast
 */
export function generateDateseries (params) {
  const { min, max, allowFuture, untilTodayAtLeast } = params
  enableConsole(false)
  console.log(['generateDateseries', params])

  const loopDate = new Date(min) || new Date()
  if (loopDate > new Date()) {
    console.warn(['min loopDate is future', loopDate])
  }

  let maxDate = new Date(max)
  if (maxDate > new Date() && allowFuture === false) {
    maxDate = new Date()
  }
  if (maxDate < new Date() && untilTodayAtLeast) {
    maxDate = new Date()
  }
  console.log(['generateDateseries', 'start from', loopDate, ' to max', maxDate])

  // Ensure proper dates
  if (isNaN(loopDate)) return
  if (isNaN(maxDate)) return

  let loopSafety = 480 // 12months * 40 years
  let keepLooping = true
  let incrementCount = 0
  const dateseries = []

  while (keepLooping && loopSafety > 0) {
    const genDate = new Date(Date.UTC(loopDate.getFullYear(), loopDate.getMonth() + incrementCount, 1))
    console.log(['try date', genDate])

    // End of loop update
    if (genDate > maxDate) keepLooping = false
    loopSafety--
    incrementCount++
    console.log(['to include?', keepLooping])

    if (keepLooping === true) {
      dateseries.push(genDate.toJSON().substring(0, 7))
    }
  }

  enableConsole('reset')
  return dateseries
}
