import { fillString } from '../../helpers/templating/string-template.js'

/**
 * Generator of links to Xero pages
 *
 */

const xeroDeepLinks = {
  Home: 'https://go.xero.com/app/:shortCode/dashboard',
  Banks: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/BankAccounts.aspx',
  XeroToXero: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Settings/Xero2Xero',
  FinancialSettings: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Setup/FinancialSettings.aspx',
  ChartAccounts: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/GeneralLedger/ChartOfAccounts.aspx',
  Trackings: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Setup/Tracking.aspx?:categorySpecific', // categoryID=:categoryId

  BankTransaction: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=:sourceId',
  BankTransfer: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=:bankTransactionId',
  // Previous bug in storing of BankTransaction as BankTransation
  BankTransation: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=:sourceId',

  // Invoices:
  Invoice: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=:sourceId',

  Bill: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/AccountsPayable/Edit.aspx?InvoiceID=:sourceId',

  //
  //
  CreditNote: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/AccountsPayable/ViewCreditNote.aspx?creditNoteID=:sourceId',

  // Payments
  Payment: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=:sourceId',
  //
  Contact: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Contacts/View/:contactXeroId'
}

export function sourceTypeMapped (type) {
  const linkType = journalSourceTypes[type] || type

  return linkType
}

// https://developer.xero.com/documentation/api/accounting/types/#journals
const journalSourceTypes = {
  ACCREC: 'Invoice',
  ACCPAY: 'Bill', //  Accounts Payable Invoice
  ACCRECCREDIT: 'CreditNote', //  Accounts Receivable Credit Note
  ACCPAYCREDIT: 'CreditNote', //  Accounts Payable Credit Note
  ACCRECPAYMENT: 'Payment', //  Payment on an Accounts Receivable Invoice
  ACCPAYPAYMENT: 'Payment', //  Payment on an Accounts Payable Invoice
  ARCREDITPAYMENT: 'Payment', //  Accounts Receivable Credit Note Payment
  APCREDITPAYMENT: 'Payment', //  Accounts Payable Credit Note Payment
  CASHREC: 'BankTransaction', // Receive Money Bank Transaction
  CASHPAID: 'BankTransaction', // Spend Money Bank Transaction
  TRANSFER: 'BankTransfer', // Bank Transfer
  // 'SPEND-TRANSFER': 'BankTransaction', // Bank Transfer
  // 'RECEIVE-TRANSFER': 'BankTransaction', // Bank Transfer
  ARPREPAYMENT: 'BankTransaction', // Accounts Receivable Prepayment
  APPREPAYMENT: 'BankTransaction', // Accounts Payable Prepayment
  AROVERPAYMENT: 'BankTransaction', // Accounts Receivable Overpayment
  APOVERPAYMENT: 'BankTransaction' // Accounts Payable Overpayment
// EXPCLAIM Expense Claim
// EXPPAYMENT: 'Payment', //  Expense Claim Payment
// MANJOURNAL Manual Journal
// PAYSLIP Payslip
// WAGEPAYABLE Payroll Payable
// INTEGRATEDPAYROLLPE Payroll Expense
// INTEGRATEDPAYROLLPT Payroll Payment
// EXTERNALSPENDMONEY Payroll Employee Payment
// INTEGRATEDPAYROLLPTPAYMENT Payroll Tax Payment
// INTEGRATEDPAYROLLCN
}

/**
 *
 * @param {String} type
 * @param {Object} params
 * @returns
 */
export function makeXeroLink (type, params) {
  const typeMapped = sourceTypeMapped(type)
  const sourceXeroUrl = xeroDeepLinks[typeMapped]
  // console.log('makeXeroLink', type, typeMapped, sourceXeroUrl)
  if (!sourceXeroUrl) return ''

  return updateParameters(sourceXeroUrl, params)
}

function updateParameters (baseUrl, params) {
  let url = baseUrl

  const stringValues = {
    shortCode: params?.shortCode, // Always needed
    contactXeroId: params?.contactXeroId, // Specific
    sourceId: params?.sourceId // Specific
  }

  // For Trackings
  if (params?.trackingCategoryId) {
    stringValues.categorySpecific = 'categoryID=' + params.trackingCategoryId
  } else {
    stringValues.categorySpecific = ''
  }

  // For Bank Transfers
  if (params?.sourceType === 'TRANSFER') {
    stringValues.bankTransactionId = params.bankTransactionId
  }

  url = fillString(url, stringValues)

  // console.log('url', url)
  return url
}
