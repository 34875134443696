
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as cssGrid from '../../../../helpers/cssgrid/index.js'
import * as mUser from '../../../modules/user/user.js'
import * as mAccount from '../../../modules/user/account.js'
import * as mHtmlParser from '../../../modules/user/html-parser.js'

// Page Module
import htmlTemplateIndex from './index.html'

import { callApi } from '../../../helpers/fetch.js'

import { makeColDefs } from './make-coldefs.js'
import { ascending } from 'd3'

/**
 *
 * @returns
 */
export async function showPageAllContacts () {
  console.log('showPageAllContacts()')

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  const accountData = mAccount.accountData()

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Group Contacts:', accountData.name].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })
  contentElement.innerHTML = htmlPageLayout

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const allContacts = await loadAllContacts(accountData)

  showAllContacts({
    allContacts
  })
}

async function loadAllContacts (accountData) {
  console.log('loadAllContacts', accountData)
  const apiUrl = '/api/xero/:accountId/contacts'
  const data = await callApi(apiUrl, {
    accountId: accountData._id
  })

  const allContacts = data?.allContacts

  // for info, unique contact names
  const uniqueNames = new Set()
  allContacts?.forEach(function (value) {
    uniqueNames.add(value.name)
  })
  console.log('uniqueNames', uniqueNames.size, uniqueNames)

  return allContacts
}

function makeRowDefs (allContacts) {
  const sorted = allContacts?.sort(function (r1, r2) {
    return ascending(r1.name, r2.name)
  })
  return sorted
}

function showAllContacts (params) {
  console.log('showAllContacts', params)
  const { allContacts } = params

  const colDefs = makeColDefs(allContacts)

  cssGrid.generateGrid({
    containerElementId: 'grid',
    classes: ['fit-content'],
    colDefs,
    rowDefs: makeRowDefs(allContacts),
    showActions: true
  })
}
