
import * as timeUI from '../../../helpers/helpers/time-ui.js'
import { callApi } from '../../helpers/fetch.js'
/**
 *
 * @param {*} orgId
 * @returns
 */
export async function checkOrgSyncStatus (orgId) {
  console.log('checkOrgSyncStatus', orgId)

  const apiUrl = '/api/xero/sync/info/:orgId'
  const data = await callApi(apiUrl, { orgId })

  show(orgId, data.syncInfo)
}

/**
 *
 * @param {String} orgId
 * @param {Object} syncInfo
 * @param {Object} syncInfo.done
 * @param {Object} syncInfo.next
 * @param {Object} syncInfo.ongoing
 */
function show (orgId, syncInfo) {
  console.log('show', orgId, syncInfo)

  const orgContainerElement = document.querySelector('.oneXeroOrg[d-org-id="' + orgId + '"]')
  if (!orgContainerElement) return

  // Status of done
  const syncDoneElement = orgContainerElement.querySelector('.syncDoneStatus')
  if (syncInfo.done?.startedAt) {
    syncDoneElement.innerHTML = timeUI.timeAgoHtml(syncInfo.done?.startedAt)
  } else {
    syncDoneElement.innerHTML = 'Not yet'
  }

  //
  // Status of schedule
  const syncScheduleElement = orgContainerElement.querySelector('.syncScheduleStatus')
  const scheduleStatus = []
  // Next
  if (syncInfo.next.length === 0) {
    scheduleStatus.push(' No further schedule')
  } else {
    scheduleStatus.push(syncInfo.next.map(function (oneScheduleSync) {
      return timeUI.timeAgoHtml(oneScheduleSync.scheduledTime)
    }))
  }

  // Ongoing
  if (syncInfo.ongoing.length > 0) {
    scheduleStatus.push(' & Ongoing: ' + syncInfo.ongoing.map(function (oneScheduleSync) {
      return timeUI.timeAgoHtml(oneScheduleSync.startedAt)
    }))
  }
  syncScheduleElement.innerHTML = scheduleStatus.join('')

  // Sync button
  // const syncNowElement = orgContainerElement.querySelector('.disconnectLinks')
  // syncNowElement.classList.remove('is-hidden')
}
