
// Debug
import { roundNumber } from '../../../helpers/helpers/numbers.js'
import { enableConsole } from '../../../helpers/logging/console.js'
import { applyAggregation } from './statements-fs3-value-getter-aggregation.js'

/**
 *
 * @param {*} params
 * @returns
 */
export function contentValueGetter (params) {
  const { cellParams, options } = params

  // Console log activation: default to false
  const showDebug = options?.showDebug || false
  enableConsole(showDebug)
  console.log('contentValueGetter', params)

  const columnDef = cellParams?.columnDef
  const rowDef = cellParams?.rowDef
  if (!columnDef || !rowDef) return

  if (!rowDef.params) {
    console.warn('no rowDef.params?', rowDef)
    return '?'
  }

  if (rowDef.params.type === 'break') {
    console.log('break row')
    return ''
  }

  // For Info
  if (rowDef.isLayoutRow) console.log('layout row')
  if (rowDef.params.accountIds) console.log('account row')

  const value = executeValueGetter(params)

  enableConsole('reset') // Reenable the console
  return value
}

function applyMultiplier (rawValue, rowDef) {
  let adjustedValue
  if (rawValue === 0) {
    adjustedValue = 0
  } else {
    const viewMultiplier = (rowDef.params?.viewSign * rowDef.params?.expectedSign) || 1
    console.log('viewMultiplier', viewMultiplier)
    adjustedValue = rawValue * viewMultiplier
  }

  return adjustedValue
}

const APPLY_OPPOSITE_SIGN = true

/**
 *
 * @param {*} context
 * @param {*} rowDef
 * @param {*} columnDef
 * @param {*} filteredDB
 * @param {*} valueType
 * @returns
 */
function getCellValueInUse (context, rowDef, columnDef, filteredDB, valueType, options) {
  const dateIndex = findDateseriesIndex(context, columnDef)

  function returnCellValue (valueArray) {
    // enableConsole(true) // Reenable the console
    const aggregatedSeries = applyAggregation(valueArray, options.optionSelectorData)
    // console.log('valueArray', valueArray)
    // console.log('aggregatedSeries', aggregatedSeries)
    const cellValue = aggregatedSeries[dateIndex] || 0
    // console.log('dateIndex', dateIndex, cellValue)
    // enableConsole(false)
    return roundNumber(cellValue, 2)
  }

  // Default value array
  let valueArray = filteredDB[0].data[valueType]

  if (!APPLY_OPPOSITE_SIGN) {
    return returnCellValue(valueArray)
  }

  if (rowDef.isLayoutRow) {
    // Layout does not know both positive/negative opposite value: as it's computed as 1 side only
    return returnCellValue(valueArray)
  }

  // Applying the opposite values
  if (rowDef.params?.oppositeSign) {
    if (rowDef.params?.oppositeSign.show === 'main') {
      valueArray = filteredDB[0].data.oppositeIncl?.[valueType]
    } else {
      valueArray = filteredDB[0].data.opposite?.[valueType]
    }
  }
  return returnCellValue(valueArray)
}

/**
 *
 * @param {*} params
 * @returns
 */
function findDateseriesFilterId (params) {
  const { columnDef } = params

  if (columnDef.params.filter.type === 'dateseries') {
    return columnDef.id
  }

  if (columnDef._parent) {
    return findDateseriesFilterId({ columnDef: columnDef._parent })
  }
}

function findDateseriesIndex (context, columnDef) {
  // Find the dateseries index
  const dateseries = context.data.fs3Data.dateseries
  const dateseriesTargetId = findDateseriesFilterId({ columnDef })
  const dateIndex = dateseries.indexOf(dateseriesTargetId)

  return dateIndex
}

function findOrgFilterColumDef (params) {
  // console.log('findOrgFilterColumDef', params)
  const { columnDef } = params

  if (columnDef.params.filter.type === 'org') {
    return columnDef
  }

  if (columnDef._parent) {
    // console.log('columnDef._parent', columnDef._parent)
    return findOrgFilterColumDef({ columnDef: columnDef._parent })
  }
}

function findTargetOrgId (context, columnDef) {
  if (context?.showOrgId) return context.showOrgId

  const columnDefOrg = findOrgFilterColumDef({ columnDef })
  return columnDefOrg.params.data.id
}

function filterDB (rowDef, aggregationDB, targetAccountIds, targetOrgId, targetCurrencySymbol) {
  function getRowId (oneAggregationData) {
    if (rowDef.isLayoutRow) {
      return oneAggregationData.layoutId
    } else {
      return oneAggregationData.account
    }
  }

  const filteredDB = aggregationDB.filter(function (oneAggregationData) {
    // console.log('oneAggregationData', oneAggregationData)
    const accountId = getRowId(oneAggregationData)
    // console.log('filter', accountId, targetAccountIds)
    // Filter the accounts
    if (!accountId) return false
    if (!targetAccountIds.includes(accountId)) return false
    // console.log('pass a1')

    // Currency
    const isTargetCurrency = (oneAggregationData.currencySymbol === targetCurrencySymbol)
    if (!isTargetCurrency) return false
    // console.log('pass a2')

    // Organisation
    const isTargetOrg = oneAggregationData.org === targetOrgId
    if (!isTargetOrg) return false

    return true
  })
  console.log('filteredDB', filteredDB)

  return filteredDB
}

function executeValueGetter (params) {
  const { cellParams, valueType, options } = params

  const context = cellParams.gridSettings.context
  const columnDef = cellParams?.columnDef
  const rowDef = cellParams?.rowDef
  const targetOrgId = findTargetOrgId(context, columnDef)

  let aggregationDB
  let targetAccountIds
  if (rowDef.isLayoutRow) {
    aggregationDB = context.data.fs3Data.layouts
    targetAccountIds = [rowDef.params.id]
  } else {
    aggregationDB = context.data.fs3Data.aggregates
    targetAccountIds = rowDef.params?.accountIds
  }

  const filteredDB = filterDB(rowDef, aggregationDB, targetAccountIds, targetOrgId, context.currency)

  // Ensure results are expected
  if (filteredDB.length > 1) {
    console.warn('filteredDB > 1', filteredDB, cellParams, targetOrgId)
    return null
  }
  if (filteredDB.length === 0) {
    return 0
  }

  const rawValue = getCellValueInUse(context, rowDef, columnDef, filteredDB, valueType, options)
  const adjustedValue = applyMultiplier(rawValue, rowDef)

  return adjustedValue
}
