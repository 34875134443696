import { transformDataset } from '../../../utilities/transform-dataset/transform-dataset.js'
import { filterDB } from '../../data-manipulation/filter-db.js'
import { moduleData } from '../../data.js'
import { datetypeSelectorSettings } from '../activate-datetype-selector.js'

export function computeSettlementOutputs (periodStart, periodEnd, outputSettings) {
  console.log('computeOrdersSettlements', moduleData, outputSettings)
  const datasetArray = moduleData?.[outputSettings?.sourceData]

  if (!datasetArray) {
    console.warn('no dataset')
    return []
  }

  const filteredOrders = filterDB(datasetArray, { periodStart, periodEnd, propertyToFilter: datetypeSelectorSettings.selectedValue })
  console.log(filteredOrders)

  const rows = transformDataset(filteredOrders, outputSettings.propertiesSettings)
  return rows
}
