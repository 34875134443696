let exportOngoing = false

export function activateExport (callbackFunction, params) {
  const actionsContainerElement = document.getElementById('actions')
  if (!actionsContainerElement) return

  actionsContainerElement.classList.remove('is-hidden')

  const exportCsvButtonElement = document.getElementById('callForCsv')
  if (!exportCsvButtonElement) return

  exportCsvButtonElement.addEventListener('click', async function (clickEvent) {
    if (exportOngoing) return

    exportOngoing = true
    exportCsvButtonElement.classList.add('is-loading')

    await callbackFunction(params)
    exportCsvButtonElement.classList.remove('is-loading')
    exportOngoing = false
  })
}
