import { callApi } from '../../helpers/fetch.js'
import { reloadAccountDetails } from './show.js'

export async function renameAccount (params) {
  const { accountId } = params

  const newNameElement = document.getElementById('renamingAccountNewName')
  // console.log('newNameElement', newNameElement)
  // console.log('newNameElement', newNameElement.value)

  const newName = newNameElement.value

  const data = {
    name: newName
  }

  const apiUrl = '/api/account/:accountId/update'

  await callApi(apiUrl, {
    accountId
  }, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
  reloadAccountDetails(accountId)
}

export function showRenamingForm () {
  const renamingFormElement = document.getElementById('renamingForm')
  if (!renamingFormElement) return

  renamingFormElement.classList.remove('is-hidden')

  const nameInfoElements = document.querySelectorAll('.nameInfo')
  nameInfoElements.forEach(function (oneElement) {
    oneElement.classList.add('is-hidden')
  })
}

export function hideRenamingForm () {
  const renamingFormElement = document.getElementById('renamingForm')
  if (!renamingFormElement) return

  renamingFormElement.classList.add('is-hidden')

  const nameInfoElements = document.querySelectorAll('.nameInfo')
  nameInfoElements.forEach(function (oneElement) {
    oneElement.classList.remove('is-hidden')
  })
}
