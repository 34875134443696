// App config
import { setElementHtml } from '../../../helpers/dom.js'
import * as configVar from '../../../config/config.js'

import htmlIndexTemplate from './index.html'

import { store, render, component } from 'reefjs'

export function showDemoComponent () {
  console.log('showDemoComponent')
  setElementHtml(configVar.contentElementId, htmlIndexTemplate)

  listenReefStore()
  reefDemo()
}

function reefDemo () {
  // Create a reactive data store
  const data = store({
    greeting: 'Hello',
    name: 'World'
  })

  const todos = store(['Swim', 'Climb', 'Jump', 'Play'])

  // Emits a reef:store event
  data.greeting = 'Bonjour'

  // Create a template function
  function template () {
    const { greeting, name } = data
    const intro = `<p>${greeting}, ${name}!</p>`
    const list = `<ul>
      ${todos.map(function (todo) {
        return `<li id="${todo.toLowerCase().replaceAll(' ', '-')}">${todo}</li>`
      }).join('')}
    </ul>`
    return intro + list
  }

  // Create a component
  // Renders into the UI, and updates whenever the data changes
  component('#demoContainer1', template)

  // const { greeting, name } = data
  // render('#demoContainer1', `<p>Hello, ${greeting}, ${name}!</p>`)

  const name = 'world'
  render('#demoContainer2', `<p>Hello, ${name}!</p>`)

  data.greeting = 'Hi'
  data.name = 'Universe'
}

function listenReefStore () {
  document.addEventListener('reef:store', function (event) {
    console.log('storage', event)
  })

  document.addEventListener('reef:render', function (event) {
    console.log('rendered', event)
  })
}
