import { generateButtons } from '../../../../helpers/menus/index.js'
import { exportCsv } from '../../utilities/export-csv.js'
import { moduleData } from '../data.js'
import { ordersLinesData } from '../drop-data/basic-data.js'
import { getXeroSettings } from '../settings/xero-settings.js'
import { showTable } from '../table-ui/show-table.js'
import { viewOptions } from './activate-views.js'
import { choiceCountry } from './choose-country.js'
import { choicePlatform } from './choose-platform.js'

export function activateActions () {
  generateButtons({
    buttonOptions,
    containerId: 'downloadButtons',
    groupLabel: 'Action:',
    selected: null,
    onChange: function (selectedOption, instance) {
      // console.log('Action selected', selectedOption, instance)

      const viewSelectedOption = viewOptions.instance.selectedOption()
      console.log('viewSelectedOption', viewSelectedOption)

      // Special case for demo:
      if (selectedOption.value === 'SKUsales') {
        const fileName = 'Sales by SKU'
        const sheetName = 'Sales by SKU'
        exportCsv(ordersLinesData.dataTable, fileName, sheetName)

        return
      }

      // const dataset = computedData[viewSelectedOption.value]
      // console.log(dataset)
      console.log('moduleData', moduleData)
      const computedData = moduleData.computedData?.[viewSelectedOption.value]
      console.log('computedData', computedData)

      if (selectedOption.value === 'tableSome') {
        // View in page
        showTable(computedData.rows?.slice(0, 10), computedData.columns, { allRows: computedData.rows })

        return
      }
      if (selectedOption.value === 'tableAll') {
        // View in page
        showTable(computedData.rows, computedData.columns)

        return
      }

      // Otherwise: export

      const exportFunction = viewSelectedOption.exports?.[selectedOption.value]

      const options = {
        platform: choicePlatform.chosenPlatform?.label,
        country: choiceCountry.chosenCountry?.label,
        xeroSettings: getXeroSettings()
      }
      exportFunction?.(computedData.rows, options)
    }
  })
}

const buttonOptions = [
  {
    label: 'View in page (sample)',
    value: 'tableSome'
  },
  {
    label: 'View in page (all)',
    value: 'tableAll'
  },
  {
    label: 'CSV',
    value: 'csv'
  },
  {
    label: 'Xero Import',
    value: 'xero'
  }
  // {
  //   label: 'SKU Sales Export (demo flo)',
  //   value: 'SKUsales'
  // }
]
