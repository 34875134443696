import { containers } from '../output/data.js'

export function containerListener (containerId) {
  // console.log('containerId', containerId)
  console.log(containers[containerId])

  const chart = containers[containerId]?.chart
  if (!chart) return

  chart.$$fchart?.play()
}
