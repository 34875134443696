import { fillString } from '../../../../../helpers/templating/string-template.js'
import { contentValueFormatter, contentValueStyling } from '../../../../helpers/helpers.js'
import { makeXeroLink, sourceTypeMapped } from '../../../../helpers/xero-links.js'
import { getOrganisationData } from '../../../../modules/user/account.js'
import { getCellValue } from './get-cell-value.js'

export function makeColumns (context) {
  console.log('makeColumns', context)
  const columnDefs = []

  columnDefs.push({
    id: 'Date',
    valueGetter: function (cellParams) {
      const date = cellParams.rowDef.value?.date || null

      return date?.substring(0, 10) || null
    },
    width: 90
  })
  columnDefs.push({
    id: 'Amount (Debit positive)',
    valueGetter: function (cellParams) {
      const cellValue = getCellValue(cellParams)
      return cellValue || null
    },
    valueFormatter: function (cellParams) {
      // const decimals = cellParams.gridSettings.context.viewDecimals
      const decimals = 2
      return contentValueFormatter({ cellParams, decimals })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    },
    width: 100,
    styles: ['numberColumns']
  })
  columnDefs.push({
    id: 'Currency',
    valueGetter: function (cellParams) {
      if (context.currencySymbol !== 'XXX') return context.currencySymbol

      const entityId = cellParams.rowDef.value?.entityId
      const orgData = getOrganisationData(entityId)
      return orgData?.details?.baseCurrency || null
    },
    width: 100,
    styles: ['numberColumns']
  })
  columnDefs.push({
    id: 'Link to Xero',
    valueGetter: function (cellParams) {
      const transactionType = cellParams.rowDef.value?.others?.sourceType
      const transactionTypeText = sourceTypeMapped(transactionType)
      const sourceId = cellParams.rowDef.value?.others?.sourceId
      if (!sourceId) return '-'

      const entityId = cellParams.rowDef.value?.entityId
      const orgData = getOrganisationData(entityId)
      const xeroLink = makeXeroLink(transactionType, {
        shortCode: orgData?.details?.shortCode,
        sourceId
      })

      const linkHtml = fillString('<a href=":link" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>', {
        link: xeroLink
      })

      return [linkHtml, transactionTypeText].join(' ') || null
    },
    width: 150
  })

  columnDefs.push({
    id: 'Account Name',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.account?.name || null
    },
    width: 190
  })
  columnDefs.push({
    id: 'Code',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.account?.code || '-'
    },
    width: 60
  })

  // columnDefs.push({
  //   id: 'AccountId',
  //   valueGetter: function (cellParams) {
  //     return cellParams.rowDef.account?.accountId || null
  //   },
  //   width: 180
  // })

  columnDefs.push({
    id: 'Contact',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.value?.contact?.name || null
    },
    width: 250
  })

  if (context.data?.cashTransactions?.[0]?.entityId) {
    columnDefs.push({
      id: 'Entity',
      valueGetter: function (cellParams) {
        const entityId = cellParams.rowDef.value?.entityId
        const orgData = getOrganisationData(entityId)
        return orgData.name || null
      },
      width: 150
    })
  }

  console.log('columnDefs', columnDefs)
  return columnDefs
}
