
// App config
import * as configVar from '../../../config/config.js'

import * as mAccount from '../../../modules/user/account.js'

import { enforceRestrictedPage } from '../../../modules/user/user.js'

import { hideElement, setElementHtml } from '../../../helpers/dom.js'
import { createContainer, setPageTitle } from '../../financials/helpers.js'
import { showSelectors } from './show-selectors.js'
import { updateView } from './update-view.js'

export async function showPageManualConsolidation (params) {
  console.log('showPageManualConsolidation')
  // console.log('mData', mData)

  if (enforceRestrictedPage({ allowPublic: true })) return

  const DOMelement = setElementHtml(configVar.contentElementId, '')
  if (!DOMelement) return

  DOMelement.classList.add('position-absolute', 'top-to-bottom')

  const contentElement = createContainer('Manual Consolidation')
  DOMelement.append(contentElement)

  //

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  setPageTitle(accountData, 'Manual Consolidation')

  showSelectors(updateView)

  // Initial
  updateView()

  hideElement('statementLoadingMessage')
}
