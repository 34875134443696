import { exportCsv } from '../../../utilities/export-csv.js'
import { getXeroSettings } from '../../settings/xero-settings.js'
import { getOrderRowsTotalValue } from '../get-orderrows-total-value.js'
import { xeroPurchaseBillProperties } from './purchase-bills-settings.js'

/**
 *
 * @param {*} productsSales
 * @returns
 */
export function generateXeroCostInvoice (ordersRows, options) {
  console.log('generateXeroCostInvoice', ordersRows, options)
  const { platform } = options

  // Base value
  const invoiceValues = {
    contactName: [platform].join(' '),
    invoiceDate: document.getElementById('periodEnd').value,
    dueDate: document.getElementById('periodEnd').value,
    invoiceNumber: [platform, 'Charges', document.getElementById('periodEnd').value].join(' '),
    taxType: 'No Tax'
  }

  const rows = []

  const xeroSettings = getXeroSettings()

  oneCostLine(rows, ordersRows, xeroSettings, 'onlinePlatformFees', invoiceValues)
  oneCostLine(rows, ordersRows, xeroSettings, 'onlineMarketingAds', invoiceValues)
  oneCostLine(rows, ordersRows, xeroSettings, 'onlineDistributionFees', invoiceValues)
  console.log('rows', rows)

  exportCsv(rows, 'Xero Purchase Bill', 'Import in Xero')
  return rows
}

function oneCostLine (rows, ordersRows, xeroSettings, propertyName, invoiceValues) {
  const lineValues = {
    accountCode: xeroSettings[propertyName],
    amount: -getOrderRowsTotalValue(ordersRows, propertyName),
    quantity: 1,
    description: propertyName
  }
  console.log('lineValues', propertyName, lineValues)

  const row = {}

  console.log('xeroPurchaseBillProperties', xeroPurchaseBillProperties)
  xeroPurchaseBillProperties.forEach(function (invoiceProperty) {
    console.log('invoiceProperty', invoiceProperty, Object.hasOwn(lineValues, invoiceProperty), lineValues[invoiceProperty.input], invoiceValues[invoiceProperty.input])
    if (Object.hasOwn(lineValues, invoiceProperty.input)) {
      console.log('use lineValue', lineValues[invoiceProperty.input])
      row[invoiceProperty.outputProperty] = lineValues[invoiceProperty.input]
    } else {
      row[invoiceProperty.outputProperty] = invoiceValues[invoiceProperty.input]
    }
  })
  console.log('row', row)
  rows.push(row)

  return row
}
