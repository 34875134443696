import { callApi } from '../../helpers/fetch.js'

//
export async function loadChartOfAccounts (accountId) {
  console.log('loadChartOfAccounts', accountId)
  const apiUrl = '/api/xero/:accountId/charts-of-accounts'
  const data = await callApi(apiUrl, {
    accountId
  })
  return data
}
