import * as cssGrid from '../../../../helpers/cssgrid/index.js'
import { hideElement, showElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { loadMonthlyData } from '../load-monthly-data.js'
import { makeColumns } from './table/make-columns.js'
import { makeRows } from './table/make-rows.js'

const context = {
  data: {}
}

let gridInstance

export async function updateView () {
  console.log('updateView', optionSelectorData)
  showElement('statementLoadingMessage')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'
  context.viewDecimals = optionSelectorData.decimals || 0
  context.dates = optionSelectorData.dates

  // Load data
  const response = await loadMonthlyData(context)
  console.log('context', context)
  context.data = response

  const tableRows = makeRows(context)
  console.log('tableRows', tableRows)

  const tableColDefs = makeColumns(context)
  console.log('tableColDefs', tableColDefs)

  gridInstance = cssGrid.generateGrid({
    containerElementId: 'statementContainer',
    colDefs: tableColDefs,
    rowDefs: tableRows,
    context,
    classes: [],
    showActions: true,
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  hideElement('statementLoadingMessage')
}
