import { fillString } from '../../../../../helpers/templating/string-template.js'
import { contentValueFormatter, contentValueStyling } from '../../../../helpers/helpers.js'
import { makeXeroLink, sourceTypeMapped } from '../../../../helpers/xero-links.js'
import { optionSelectorData } from '../../../../modules/option-selector/data.js'
import { getOrganisationData } from '../../../../modules/user/account.js'
import { getCellValue } from './get-cell-value.js'

export function makeColumns (context) {
  console.log('makeColumns', context)
  const columnDefs = []

  columnDefs.push({
    id: 'Date',
    valueGetter: function (cellParams) {
      const date = cellParams.rowDef.value?.details?.journalDate || null

      return date?.substring(0, 10) || null
    },
    width: 90
  })
  columnDefs.push({
    id: 'Amount (Debit positive)',
    valueGetter: function (cellParams) {
      const cellValue = getCellValue(cellParams)
      return cellValue || null
    },
    valueFormatter: function (cellParams) {
      // const decimals = cellParams.gridSettings.context.viewDecimals
      const decimals = 2
      return contentValueFormatter({ cellParams, decimals })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    },
    width: 100,
    styles: ['numberColumns']
  })
  columnDefs.push({
    id: 'Currency',
    valueGetter: function (cellParams) {
      if (context.currencySymbol !== 'XXX') return context.currencySymbol

      const entityId = cellParams.rowDef.value?._xeroOrganisation
      const orgData = getOrganisationData(entityId)
      return orgData?.details?.baseCurrency || null
    },
    width: 100,
    styles: ['numberColumns']
  })
  columnDefs.push({
    id: 'Xero',
    valueGetter: function (cellParams) {
      const transactionType = cellParams.rowDef.value?.details?.sourceType
      const sourceId = cellParams.rowDef.value?.details?.sourceId
      if (!sourceId) return '-'

      const entityId = cellParams.rowDef.value?._xeroOrganisation
      const orgData = getOrganisationData(entityId)

      const linkParams = {
        shortCode: orgData?.details?.shortCode,
        sourceId
      }

      if (transactionType === 'TRANSFER') {
        linkParams.sourceType = transactionType

        const impactedAccountId = cellParams.rowDef.value?.journalLines?.accountXeroId
        if (cellParams.rowDef.value?.xeroSource?.fromBankAccountXeroID === impactedAccountId) {
          linkParams.bankTransactionId = cellParams.rowDef.value?.xeroSource.fromBankTransactionID
        }
        if (cellParams.rowDef.value?.xeroSource?.toBankAccountXeroID === impactedAccountId) {
          linkParams.bankTransactionId = cellParams.rowDef.value?.xeroSource.toBankTransactionID
        }
      }
      const xeroLink = makeXeroLink(transactionType, linkParams)

      const linkHtml = fillString('<a href=":link" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>', {
        link: xeroLink
      })

      return linkHtml || null
    },
    width: 50
  })
  columnDefs.push({
    id: 'Type',
    valueGetter: function (cellParams) {
      const transactionType = cellParams.rowDef.value?.details?.sourceType
      const transactionTypeText = sourceTypeMapped(transactionType)

      return transactionTypeText || null
    },
    width: 150
  })

  columnDefs.push({
    id: 'Account Name',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.account?.name || null
    },
    width: 190
  })
  columnDefs.push({
    id: 'Code',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.account?.code || '-'
    },
    width: 60
  })

  // columnDefs.push({
  //   id: 'AccountId',
  //   valueGetter: function (cellParams) {
  //     return cellParams.rowDef.account?.accountId || null
  //   },
  //   width: 180
  // })

  columnDefs.push({
    id: 'Contact',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.value?.contact?.name || null
    },
    width: 250
  })

  // console.log('optionSelectorData.organisationId', optionSelectorData.organisationId)
  if (Array.isArray(optionSelectorData.organisationId)) {
    columnDefs.push({
      id: 'Entity',
      valueGetter: function (cellParams) {
        const entityId = cellParams.rowDef.value?._xeroOrganisation
        const orgData = getOrganisationData(entityId)
        return orgData.name || null
      },
      width: 150
    })
  }

  // columnDefs.push({
  //   id: 'Source Status',
  //   valueGetter: function (cellParams) {
  //     return cellParams.rowDef.value?.xeroSource?.status || null
  //   },
  //   width: 250
  // })

  console.log('columnDefs', columnDefs)
  return columnDefs
}
