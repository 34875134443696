import { roundNumber } from '../../../../../../helpers/helpers/numbers.js'
import { mapToArray } from '../../map-to-array.js'

/**
 * From an Array of Rows, we combine as per a setting Array
 * @param {Array{}} dataRows
 * @param {Array{}} fieldSettings
 */
export function combineLines (dataRows, fieldSettings) {
  console.log('combineLines')
  console.log('dataRows', dataRows)
  console.log('fieldSettings', fieldSettings)

  const combinedMap = new Map()

  dataRows?.forEach(function (dataRow) {
    // console.log('--')
    // console.log(dataRow)

    // Find the rowKey
    const rowKey = findRowKey(dataRow, fieldSettings)
    // console.log('rowKey', rowKey)

    // Add into the Map
    const combinedEntry = getMapEntry(combinedMap, rowKey, dataRow, fieldSettings)
    // console.log('combinedEntry', combinedEntry)
    updateCombinationValues(combinedEntry, dataRow, fieldSettings)
  })

  const records = mapToArray(combinedMap)

  return records
}

/**
 *
 * @param {*} dataRow
 * @param {*} fieldSettings
 * @returns
 */
function findRowKey (dataRow, fieldSettings) {
  // console.log('findRowKey', dataRow)
  let rowKey = null

  fieldSettings.forEach(function (field) {
    // console.log('field', field, field.lineCombinationId, dataRow[field.outputName])

    if (field.lineCombinationId) rowKey = dataRow[field.outputName]
    // console.log('rowKey=', rowKey)
  })
  return rowKey
}

/**
 * Access the Map value, or create it: allows to set the base value when first creating the Map entry
 * @param {Map} recordMap
 * @param {String} mapEntryId
 * @returns
 */
function getMapEntry (recordMap, mapEntryId, inputEntryData, fieldSettings) {
  let data
  data = recordMap.get(mapEntryId)
  if (!data) {
    // Prepare the entry Object
    const recordObject = {}
    initCombinationValues(recordObject, inputEntryData, fieldSettings)

    // Record the entry Object
    recordMap.set(mapEntryId, recordObject)

    // Get the entryObject from the Map
    data = recordMap.get(mapEntryId)
  }

  return data
}

/**
 *
 * @param {*} mapEntry
 * @param {*} inputEntryData
 * @param {*} fieldSettings
 */
function initCombinationValues (mapEntry, inputEntryData, fieldSettings) {
  // console.log('initCombinationValues', mapEntry, inputEntryData)
  fieldSettings.forEach(function (field) {
    if (!field.lineCombination) return

    if (field.lineCombination === 'first') {
      mapEntry[field.outputName] = inputEntryData[field.outputName]
    } else if (field.lineCombination === 'sum') {
      mapEntry[field.outputName] = 0
    } else if (field.lineCombination === 'count') {
      mapEntry[field.outputName] = 0
    } else if (field.lineCombination === 'records') {
      mapEntry[field.outputName] = []
    }
  })
  // console.log('mapEntry', mapEntry)
}

/**
 *
 * @param {*} mapEntry
 * @param {*} inputEntryData
 * @param {*} fieldSettings
 */
function updateCombinationValues (mapEntry, inputEntryData, fieldSettings) {
  fieldSettings.forEach(function (field) {
    if (!field.lineCombination) return

    if (field.lineCombination === 'first') {
      // Nothing to do: only init changes it
      // Idea: detect mismatch
      if (mapEntry[field.outputName] !== inputEntryData[field.outputName]) {
        console.warn('mismatch', mapEntry[field.outputName], ' !== ', inputEntryData[field.outputName])
      }
    } else if (field.lineCombination === 'sum') {
      mapEntry[field.outputName] += (inputEntryData[field.outputName] || 0)
      const targetDecimals = 2
      mapEntry[field.outputName] = roundNumber(mapEntry[field.outputName], targetDecimals)
    } else if (field.lineCombination === 'count') {
      mapEntry[field.outputName]++
    } else if (field.lineCombination === 'records') {
      mapEntry[field.outputName].push(inputEntryData)
    }
  })
}
