import { contentValueFormatter, contentValueStyling } from '../../../helpers/helpers.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { makeDateSerie } from '../../../../helpers/dates/make-date-serie.js'
import { getCellValue } from './get-cell-value.js'
// import { outputAccountDetails } from './helpers.js'

export function tableColumns (context) {
  const dateserie = makeDateSerie(context.minDate, context.maxDate)

  const columnDefs = [{
    id: 'Contact',
    width: 30,
    layout: 'left',
    valueGetter: function (cellParams) {
      // console.log(cellParams)
      return cellParams.rowDef.id
    }
  }, {
    id: 'Account',
    width: 200,
    layout: 'left',
    valueGetter: function (cellParams) {
      // console.log(cellParams)
      return cellParams.rowDef.id
    }
  }]

  dateserie.forEach(function (oneColumnDate) {
    columnDefs.push({
      id: oneColumnDate,
      width: 120,
      layout: 'center',
      styles: ['numberColumns'],
      extras: {
        targetProperty: context.targetValueProperty
      },
      valueGetter: function (cellParams) {
        // console.log('column cellParams', cellParams)
        return getRecordValue(cellParams, context.data.tbLedgerData.ledger.decimals)
        // return 1
      },
      valueFormatter: function (cellParams) {
        return contentValueFormatter({
          cellParams,
          decimals: optionSelectorData.decimals
        })
      },
      valueStyling: function (cellParams) {
        return contentValueStyling({ cellParams })
      }
    })
  })

  return columnDefs
}

function getRecordValue (cellParams, significantDecimals) {
  const columnDef = cellParams.columnDef
  const rowDef = cellParams.rowDef

  const columnDate = columnDef?.id

  if (!rowDef.data) return ''

  return getCellValue(
    rowDef?.data,
    columnDate,
    {
      targetProperty: cellParams.columnDef.extras?.targetProperty,
      significantDecimals
    })
}
