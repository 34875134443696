import { generateGrid } from '../../../../helpers/cssgrid/index.js'
import { accessObjectPropertyValue } from '../../../../helpers/data/objects.js'
import * as timeUI from '../../../../helpers/helpers/time-ui.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { checkToken } from './check-token.js'

export function showTokensTable (xeroTokens) {
  showGrid(xeroTokens)
}

const gridContainerId = 'tokens'

function showGrid (xeroTokens) {
  generateGrid({
    containerElementId: gridContainerId,
    colDefs: makeColDefs(),
    rowDefs: xeroTokens
  })

  enableListeners()
}

function enableListeners () {
  const containerElement = document.getElementById(gridContainerId)
  containerElement.addEventListener('click', function (clickEvent) {
    const clickedElement = clickEvent.target.closest('div.button[data-action="checkToken"]')
    console.log('clickedElement', clickedElement)

    const tokenId = clickedElement.getAttribute('data-tokenId')
    checkToken(tokenId)
  })
}

function makeColDefs () {
  const columns = []

  columns.push({
    id: 'User',
    valueGetter: function (cellParams) {
      const user = accessObjectPropertyValue('_user', cellParams.rowDef)
      return user.email
    },
    width: 200,
    styles: ['bold']
  })
  columns.push({
    id: 'Token refreshed?',
    valueGetter: function (cellParams) {
      const isFailedRefresh = accessObjectPropertyValue('isFailedRefresh', cellParams.rowDef)
      return fillString('<div class="tag is-small is-:state">:status</div>', {
        state: (isFailedRefresh === true) ? 'warning' : 'info',
        status: (isFailedRefresh === true) ? 'failed' : 'ok'
      })
    },
    width: 80
  })
  columns.push({
    id: 'Check Token',
    valueGetter: function (cellParams) {
      const tokenId = accessObjectPropertyValue('_id', cellParams.rowDef)
      return fillString('<div class="button is-small" data-tokenId=":tokenId" data-action="checkToken">Check Token</div>', {
        tokenId
      })
    },
    width: 120
  })
  columns.push({
    id: 'Created',
    valueGetter: function (cellParams) {
      const createdAt = accessObjectPropertyValue('createdAt', cellParams.rowDef)
      return timeUI.timeAgoHtml(createdAt)
    },
    width: 150
  })
  columns.push({
    id: 'Updated',
    valueGetter: function (cellParams) {
      const updatedAt = accessObjectPropertyValue('updatedAt', cellParams.rowDef)
      return timeUI.timeAgoHtml(updatedAt)
    },
    width: 150
  })
  columns.push({
    id: 'Entities',
    valueGetter: function (cellParams) {
      const entities = accessObjectPropertyValue('_xeroOrganisations', cellParams.rowDef)
      const entitiesName = entities.map(function (entity) {
        return entity.name
      })
      return entitiesName.length + ': ' + entitiesName.join(' / ')
    },
    width: 300
  })
  columns.push({
    id: 'Scope',
    valueGetter: function (cellParams) {
      const scope = accessObjectPropertyValue('scope', cellParams.rowDef)
      return scope.split(' ').length + ': ' + scope
    },
    width: 150
  })
  columns.push({
    id: 'Access Token',
    valueGetter: function (cellParams) {
      const accessToken = accessObjectPropertyValue('access_token', cellParams.rowDef)
      return accessToken
    },
    width: 150
  })

  return columns
}

/*
{
  "_id": "638177bc90ccf3dc6d212d4c",
  "_user": {
      "_id": "6381778090ccf3dc6d212cf1",
      "email": "florian@futureflow.io"
  },
  "_xeroOrganisations": [
      {
          "_id": "6459c25ea4414669b46a8f13",
          "orgXeroId": "59dbe100-0d09-4cd6-b96d-3ec240b23e14",
          "name": "VS Studio — Redhill"
      },
      {
          "_id": "6387522790ccf3dc6d233f71",
          "orgXeroId": "6dfeb3bf-7646-4f6b-bba8-f7e3d014df2e",
          "name": "Florian (Personal)"
      },
      {
          "_id": "6380577690ccf3dc6d1ff4dd",
          "orgXeroId": "74fd3391-6022-47fb-a488-cc6925d5297f",
          "name": "Flow Ventures Pte Ltd"
      },
      {
          "_id": "64fc476bea268980a3d961f6",
          "orgXeroId": "b519f484-fbb4-4c78-9a6e-ae3d177d1585",
          "name": "test sep 9"
      }
  ],
  "orgXeroIds": [
      "59dbe100-0d09-4cd6-b96d-3ec240b23e14",
      "6dfeb3bf-7646-4f6b-bba8-f7e3d014df2e",
      "74fd3391-6022-47fb-a488-cc6925d5297f",
      "b519f484-fbb4-4c78-9a6e-ae3d177d1585"
  ],
  "createdAt": "2022-11-26T02:19:40.248Z",
  "updatedAt": "2023-10-06T11:58:46.422Z",
  "scope": "email profile openid accounting.reports.read accounting.settings.read accounting.journals.read accounting.transactions.read accounting.contacts.read offline_access"
}
*/
