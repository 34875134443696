
import { contentValueFormatter, contentValueStyling } from '../../../helpers/helpers.js'
import { itemsDB } from '../utils/manage-items.js'
import { getEntity } from './get-entity.js'
import { groupTotalValue, itemValueGetter } from './item-value-getter.js'

export function tableColumns (params) {
  const { context } = params

  const columnsSettings = []

  columnsSettings.push({
    id: 'Code',
    valueGetter: function (cellParams) {
      const rowDef = cellParams.rowDef
      if (rowDef.type) {
        if (rowDef.type === 'break') return null
        if (['title', 'total'].includes(rowDef.type)) return null
      } else {
        return cellParams.rowDef.item?.account.code || null
      }
    },
    width: 85
  })

  columnsSettings.push({
    id: 'Account',
    valueGetter: function (cellParams) {
      const rowDef = cellParams.rowDef
      if (rowDef.type) {
        if (rowDef.type === 'break') return null
        if (rowDef.type === 'title') {
          console.log('title', rowDef)
          return rowDef.list?.list?.title
        } else if (rowDef.type === 'total') {
          return rowDef.list?.list?.total
        }
      } else {
        return cellParams.rowDef.item?.account.name || null
      }
    },
    width: 500
  })

  // Columns for every entity
  for (let fileIndex = 0; fileIndex < itemsDB.numberFiles; fileIndex++) {
    const entityName = itemsDB.entities[fileIndex]

    columnsSettings.push({
      id: [entityName, ' [', getEntity(entityName).baseCurrency, ']'].join(''),
      valueGetter: function (cellParams) {
        const rowDef = cellParams.rowDef
        if (rowDef.type) {
          if (rowDef.type === 'break') return null
          if (rowDef.type === 'title') return null
          if (rowDef.type === 'total') {
            return groupTotalValue(rowDef.groupItems, fileIndex, context)
          }
        } else {
          return itemValueGetter(rowDef, fileIndex, context)
        }
      },
      valueFormatter: function (cellParams) {
        const decimals = cellParams.gridSettings.context.viewDecimals
        return contentValueFormatter({ cellParams, decimals })
      },
      valueStyling: function (cellParams) {
        return contentValueStyling({ cellParams })
      },
      styles: ['numberColumns'],
      width: 100
    })
  }

  // Total
  if (context.currencySymbol === 'XXX') return columnsSettings

  columnsSettings.push({
    id: 'Consolidated Group',
    valueGetter: function (cellParams) {
      const rowDef = cellParams.rowDef
      if (rowDef.type) {
        if (rowDef.type === 'total') {
          return computeGroupTotals(rowDef.groupItems, context)
        } else {
          return null
        }
      }
      return computeTotal(cellParams.rowDef, context) || null
    },
    valueFormatter: function (cellParams) {
      const decimals = cellParams.gridSettings.context.viewDecimals
      return contentValueFormatter({ cellParams, decimals })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    },
    styles: ['numberColumns', 'bold'],
    width: 100
  })

  return columnsSettings
}

function computeTotal (rowData, context) {
  const total = rowData.item?.data?.reduce(function (accumulator, value, index) {
    const entityIndex = rowData.item?.fileDataIndex?.[index]

    const convertedAmount = itemValueGetter(rowData, entityIndex, context)

    return accumulator + (convertedAmount || 0)
  }, 0)
  return total
}

function computeGroupTotals (groupItems, context) {
  let total = 0
  groupItems?.forEach(function (rowData) {
    const rowTotal = computeTotal(rowData, context)
    total = total + (rowTotal || 0)
  })
  return total
}
