
export function makeRows (fieldsMap) {
  const rows = Array.from(fieldsMap, function (value, key) {
    // Return the value of the Map record
    return {
      outputName: value[0],
      values: value[1]
    }
  })

  console.log('rows', rows)
  return rows
}
