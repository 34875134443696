
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import htmlIndex from './index.html'
import { shortenNumber } from '../../../../helpers/helpers/numbers.js'
import { timeDuration } from '../../../../helpers/helpers/time.js'
import { callApi } from '../../../helpers/fetch.js'
import { showCurrentTimestamp } from '../utilities/timestamp.js'
import { fillString } from '../../../../helpers/templating/string-template.js'

/**
 *
 * @returns
 */
export async function showPageListOrgs () {
  console.log('module show')

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showCurrentTimestamp('currentTimestamp')
  showListOfOrgs()
}

async function showListOfOrgs () {
  const containerElement = document.getElementById('orgsList')

  const orgsAndJournalInfo = await loadListOfOrgs()
  console.log('orgsAndJournalInfo', orgsAndJournalInfo)

  const html = []
  orgsAndJournalInfo.forEach(function (one) {
    const infoUrl = fillString('/app/admin/org-info/:orgId/infos', { orgId: one._id })
    const infoLink = fillString('<a href=":infoUrl" target="_blank">:linkLabel</a>', {
      infoUrl,
      linkLabel: one.orgName
    })

    const syncInfos = one.syncs.map(function (oneSync) {
      const showString = [oneSync.status]

      const duration = timeDuration(oneSync.startedAt, oneSync.endedAt)
      if (duration) {
        showString.push('[' + duration?.timeText + ']')
      }

      if (oneSync.status === 'scheduled') {
        const duration = timeDuration(oneSync.scheduledTime, null)
        showString.push('[' + duration?.text + ']')
      }
      return showString.join(' ')
    })
    html.push([infoLink, 'size:', shortenNumber(one.size, 1), ' | ', one.count, ' entries', one.firstJournal, 'to', one.lastJournal, '|', syncInfos].join(' '))
  })
  containerElement.innerHTML = html.join('<br>')
}

/**
 * (dis)associate appAccount and Org
 * @param {*} params
 * @param {String} params.method
 */
async function loadListOfOrgs () {
  const apiUrl = '/api/xero/sync/info/orgs'
  const data = await callApi(apiUrl)
  return data
}
