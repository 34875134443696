import { contentValueFormatter, contentValueStyling } from '../../../../helpers/helpers.js'
import { getOrganisationData } from '../../../../modules/user/account.js'
import { getCellValue } from './get-cell-value.js'

export function makeColumns (context) {
  console.log('makeColumns', context)
  const columnDefs = []

  if (context.data?.monthlyValues?.[0]?.orgId) {
    columnDefs.push({
      id: 'entity',
      valueGetter: function (cellParams) {
        const entityId = cellParams.rowDef.value?.orgId
        const orgData = getOrganisationData(entityId)
        return orgData.name || null
      },
      width: 150
    })
  }

  columnDefs.push({
    id: 'statement',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.account?.statement || null
    },
    width: 70
  })

  columnDefs.push({
    id: 'Type',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.account?.type || null
    },
    width: 90
  })

  columnDefs.push({
    id: 'AccountId',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.value?.accountId || null
    },
    width: 180
  })

  columnDefs.push({
    id: 'Code',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.account?.code || null
    },
    width: 60
  })
  columnDefs.push({
    id: 'Name',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.account?.name || null
    },
    width: 190
  })
  columnDefs.push({
    id: 'Month',
    valueGetter: function (cellParams) {
      return cellParams.rowDef.value?.month || null
    },
    width: 90
  })
  columnDefs.push({
    id: 'Amount (Credit positive)',
    valueGetter: function (cellParams) {
      const cellValue = getCellValue(cellParams)
      return cellValue || null
    },
    valueFormatter: function (cellParams) {
      return contentValueFormatter({ cellParams, decimals: cellParams.gridSettings.context.viewDecimals })
    },
    valueStyling: function (cellParams) {
      return contentValueStyling({ cellParams })
    },
    width: 100,
    styles: ['numberColumns']
  })
  columnDefs.push({
    id: 'Currency',
    valueGetter: function (cellParams) {
      if (context.currencySymbol !== 'XXX') return context.currencySymbol

      const entityId = cellParams.rowDef.value?.orgId
      const orgData = getOrganisationData(entityId)
      return orgData?.details?.baseCurrency || null
    },
    width: 100,
    styles: ['numberColumns']
  })

  context.groupings?.forEach(function (groupingProperty) {
    if (groupingProperty === 'accounts') return

    columnDefs.push({
      id: groupingProperty,
      valueGetter: function (cellParams) {
        const trackingOptionId = cellParams.rowDef.value?.[groupingProperty]
        if (!trackingOptionId) return '-'

        const entityId = cellParams.rowDef.value.orgId || cellParams.gridSettings?.context?.showOrgId

        const orgData = getOrganisationData(entityId)
        const optionName = orgData?.trackingData?.options[trackingOptionId]?.name
        const categoryIndex = orgData?.trackingData?.options[trackingOptionId]?.categoryNumber - 1
        const category = orgData?.trackingData?.categories[categoryIndex]

        return [category.name, optionName].join('=')
      },
      width: 150
    })
  })

  return columnDefs
}
