
export function generateRowsCssTemplate (rowDefs) {
  let numberShownRows = 0

  function countRows (theseRowDefs) {
    theseRowDefs?.forEach(function (oneRowDef) {
      numberShownRows++

      if (oneRowDef.children) {
        countRows(oneRowDef.children)
      }
    })
  }
  countRows(rowDefs)

  return 'repeat(:numberRows, 2em)'.replace(':numberRows', numberShownRows)
}
