import { contentValueFormatter, contentValueStyling } from '../../../helpers/helpers.js'
import { makeXeroLink } from '../../../helpers/xero-links.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import * as mAccount from '../../../modules/user/account.js'

export function makeColDefs (chartsOfAccounts) {
  // Date
  // Record Entity
  // Contact
  // Account
  // Amount
  // Link
  // Account is eliminated
  const colDefs = [
    {
      id: 'Date',
      valueGetter: function (cellParams) {
        return cellParams.rowDef.details.journalDate?.substring(0, 10)
      },
      layout: 'left',
      width: 80,
      onClick: function (cellParams) {
        console.log(cellParams.rowDef)
      }
    },
    {
      id: 'Entity',
      valueGetter: function (cellParams) {
        const organisation = mAccount.getOrganisationData(cellParams.rowDef._xeroOrganisation)

        return organisation.name
      },
      layout: 'left',
      width: 200
    },
    {
      id: 'Contact',
      valueGetter: function (cellParams) {
        const contactName = cellParams.rowDef?.source?.contact?.name || '??'

        const organisation = mAccount.getOrganisationData(cellParams.rowDef._xeroOrganisation)
        const link = makeXeroLink('Contact', {
          shortCode: organisation?.details.shortCode,
          contactXeroId: cellParams.rowDef?.source?.contactXeroId
        })
        const linkHtml = fillString('<a href=":link" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>', {
          link
        })

        return [linkHtml, contactName].join(' ')
      },
      layout: 'left',
      width: 200
    },
    {
      id: 'Account',
      valueGetter: function (cellParams) {
        const account = chartsOfAccounts.chartsOfAccounts.accounts.find(function (one) {
          return one._id === cellParams.rowDef.journalLines._xeroAccount
        })
        return account?.details?.name
      },
      width: 200
    },
    {
      id: 'Link',
      valueGetter: function (cellParams) {
        const sourceId = cellParams.rowDef.details?.sourceId
        if (!sourceId) return null

        const organisation = mAccount.getOrganisationData(cellParams.rowDef._xeroOrganisation)
        const link = makeXeroLink(cellParams.rowDef.source?.type, {
          shortCode: organisation?.details.shortCode,
          sourceId
        })
        const linkHtml = fillString('<a href=":link" target="_blank">:label<a>', {
          link,
          label: cellParams.rowDef?.source?.invoiceNumber || 'Open in Xero'
        })
        return linkHtml
      },
      width: 130,
      onClick: function (cellParams) {
        const organisation = mAccount.getOrganisationData(cellParams.rowDef._xeroOrganisation)
        const link = makeXeroLink(cellParams.rowDef.source.type, {
          shortCode: organisation?.details.shortCode,
          sourceId: cellParams.rowDef.details.sourceId
        })
        console.log(link)
      }
    },
    {
      id: 'Amount',
      valueGetter: function (cellParams) {
        // Values are debit positive; we make it Credit Positive
        const multiplier = -1
        const amount = cellParams.rowDef.journalLines.netAmount
        return amount * multiplier
      },
      valueFormatter: function (cellParams) {
        return contentValueFormatter({ cellParams })
      },
      valueStyling: function (cellParams) {
        return contentValueStyling({ cellParams })
      },
      styles: ['numberColumns'],
      width: 110
    },
    {
      id: 'Is Eliminated?',
      valueGetter: function (cellParams) {
        const account = chartsOfAccounts?.eliminations?.find(function (one) {
          return one._xeroAccount === cellParams.rowDef.journalLines?._xeroAccount
        })
        if (account) return 'Yes'
        return 'Not Eliminated'
      },
      width: 90
    },
    {
      id: 'Source',
      valueGetter: function (cellParams) {
        return cellParams.rowDef?.source?.type
      },
      width: 120
    },
    {
      id: 'Status',
      valueGetter: function (cellParams) {
        return cellParams.rowDef?.source?.status
      },
      width: 120
    },
    {
      id: 'Contact Xero Key',
      valueGetter: function (cellParams) {
        return cellParams.rowDef?.source?.contact?.xeroNetworkKey
      },
      width: 120
    },
    {
      id: 'Source Info',
      valueGetter: function (cellParams) {
        return [cellParams.rowDef?.source?.date?.substring(0, 10), (cellParams.rowDef?.source?.date?.substring(0, 10) === cellParams.rowDef.details.journalDate?.substring(0, 10)), cellParams.rowDef?.source?.total, cellParams.rowDef?.source?.currencyCode, cellParams.rowDef?.source?.status].join(' ')
      },
      width: 300
    },
    {
      id: 'Link to copy',
      valueGetter: function (cellParams) {
        const sourceId = cellParams.rowDef.details?.sourceId
        if (!sourceId) return null

        const organisation = mAccount.getOrganisationData(cellParams.rowDef._xeroOrganisation)
        const link = makeXeroLink(cellParams.rowDef.source?.type, {
          shortCode: organisation?.details.shortCode,
          sourceId
        })
        const linkHtml = fillString('<input type="text" value=":link" class="input is-small"/>', {
          link,
          label: cellParams.rowDef?.source?.invoiceNumber || 'Open in Xero'
        })
        return linkHtml
      },
      width: 250
    }
  ]

  return colDefs
}
