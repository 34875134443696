// Dependencies
import * as mArrays from '../../../helpers/data/arrays.js'
import { optionSelectorData } from '../../modules/option-selector/data.js'
import { contentValueFormatter, contentValueStyling } from '../../helpers/helpers.js'
import { showTransactions } from './transactions/show-transactions.js'
import { executeValueGetter } from './value-getter.js'
import { makeDateForBS, makeDateForPnl } from '../../helpers/financial-dates.js'
import { findLastChildren } from '../../../helpers/data/objects.js'

/**
 *
 * @param {*} params
 * @returns {Array} of Object {value, children[], styles, params{date, orgId, [currency], [valueType]} }
 */
export function generateColumnDefs (params) {
  console.log('generateColumnDefs', params)
  const { dateseriesView, organisations, columnsLayout } = params

  const columns = []

  // Left column: contact names
  columns.push({
    value: ' ',
    id: 'contactName',
    styles: ['leftColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef.value || cellParams.rowDef.id
    },
    layout: 'left',
    width: 200
  })

  const arrays = []
  columnsLayout.forEach(function (oneSetType, index) {
    // Base colDef for any level of header
    const baseColDef = {
      styles: []
    }
    if (index === columnsLayout.length - 1) {
      // Create the valueGetter for the content column
      baseColDef.valueGetter = function (cellParams) {
        return executeValueGetter(cellParams)
      }
      baseColDef.valueFormatter = function (cellParams) {
        return contentValueFormatter({
          cellParams,
          decimals: optionSelectorData.decimals
        })
      }
      baseColDef.valueStyling = function (cellParams) {
        return contentValueStyling({ cellParams })
      }
      baseColDef.onClick = function (cellParams) {
        console.log('clicked')
      }
      baseColDef.onDblClick = function (cellParams) {
        console.log('onDblClick', cellParams)
        console.log('soon: dive to transactions')

        const gridContext = cellParams.gridSettings.context

        showTransactions({
          accountId: gridContext.params.accountId,
          orgId: gridContext.params.orgId,
          chartAccountId: gridContext.params.chartAccountId,
          contactName: cellParams.rowDef.params.contactName
        })

        // loadScheduleDataTransactions({
        //   accountId: gridContext.params.accountId,
        //   orgId: gridContext.params.orgId,
        //   chartAccountId: gridContext.params.chartAccountId,
        //   contactName: cellParams.rowDef.params.contactName
        // })
      }

      baseColDef.styles.push('numberColumns')
      baseColDef.width = 110
    }

    if (index === 0 && columnsLayout.length > 1) {
      baseColDef.styles.push('parentColumn')
    }

    if (oneSetType === 'dates') {
      console.log('optionSelectorData', optionSelectorData)
      console.log('dateseriesView', dateseriesView)

      const dateObjs = []
      const periodicity = optionSelectorData.periodicity || 1
      dateseriesView.forEach(function (oneDate, index) {
        if (index % periodicity !== 0) return
        const colDef = {
          id: oneDate,
          headerValueGetter: function (cellParams) {
            const date = cellParams.columnDef.id
            if (optionSelectorData.viewAggregations.method === 'period') {
              return makeDateForPnl(date, 1)
            } else if (optionSelectorData.viewAggregations.method === 'balance') {
              return makeDateForBS(date)
            } else {
              return '??'
            }
          },
          params: {
            filter: {
              type: 'dateseries'
            }
          }
        }
        dateObjs.push(Object.assign(colDef, baseColDef))
      })
      arrays.push(dateObjs)
    }

    if (oneSetType === 'orgs') {
      const orgIds = organisations.map(function (oneOrg) {
        const colDef = {
          id: oneOrg.id,
          params: {
            data: oneOrg,
            filter: {
              type: 'org'
            }
          },
          headerValueGetter: function () {
            return this.params.data.name
          }
        }
        return Object.assign(colDef, baseColDef)
      })
      arrays.push(orgIds)
    }
  })

  // Columns in structure needed for grid
  const nestedColumns = mArrays.arraysToNested(arrays)

  // Style
  nestedColumns.forEach(function (oneColumn) {
    if (!oneColumn.children) return

    const lastChildColumns = findLastChildren(oneColumn, { onlyLastOfLast: true })
    lastChildColumns.forEach(function (oneLastChildColumn) {
      // Copy the styles array (using slice()) as the property is a reference only due to baseColDef
      oneLastChildColumn.styles = oneLastChildColumn.styles.slice() || []
      oneLastChildColumn.styles.push('lastGroupColumn')
    })
  })

  const columnDefs = columns.concat(nestedColumns)
  console.log('columnDefs', columnDefs)

  return columnDefs
}
