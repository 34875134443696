import { callApi } from '../../helpers/fetch.js'

export async function toggleEliminatedAccount (params) {
  const apiUrl = '/api/xero/a/:accountId/eliminate/:chartAccountId'
  const data = await callApi(apiUrl, {
    accountId: params.accountId,
    chartAccountId: params.chartAccountId
  }, {
    method: params.toEliminate ? 'POST' : 'DELETE'
  })
  console.log('eliminations done', data)
  return data.eliminationResponse
}

export function isAccountEliminated (params) {
  const { eliminations, chartAccountId } = params
  // console.log('isAccountEliminated', chartAccountId, eliminations)
  const elimination = eliminations?.find(function (oneElimination) {
    return oneElimination?._xeroAccount === chartAccountId
  })

  if (elimination) return true
  return false
}
