import { callApi } from '../../helpers/fetch.js'

const organisations = {}

export function getOrganisation (orgId) {
  if (orgId && organisations[orgId]) {
    return organisations[orgId]
  }

  const organisationIds = Object.keys(organisations)

  if (!orgId && organisationIds.length === 1) {
    return organisations[organisationIds[0]]
  }

  return false
}
/**
 *
 * @param {*} accountId
 * @returns
 */
export async function loadOneOrganisation (orgId) {
  console.log('loadOneOrganisation', orgId)
  if (orgId === 'all') return

  const apiUrl = '/api/xero/:orgId/infos'

  const responseData = await callApi(apiUrl, {
    orgId
  })
  if (!responseData) return

  organisations[responseData?._id] = responseData
  return responseData
}
