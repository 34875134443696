import mongoose, { isValidObjectId } from 'mongoose'

export function isDate (value) {
  if (typeof value !== 'object') return false

  return (value instanceof Date)
}

export function isObject (value) {
  if (typeof value !== 'object') return false
  if (value === null) return false
  if (Array.isArray(value)) return false

  return true
}

export function isMap (value) {
  if (!value) return false
  if (value instanceof Map) return true

  return false
}

export function isValidOId (string) {
  const isValid = isValidObjectId(string)
  // logger.debug(['isValidObjectId', string, isValid])
  if (!isValid) return false

  const convertedToObjectId = new mongoose.Types.ObjectId(string)

  // Cast both to string
  const isValidated = String(convertedToObjectId) === String(string)
  // logger.silly(['isValidated', isValidated])
  return isValidated
}
