
// Debug
import { launchLogger } from '../../logging/pino.logger.js'
const logger = launchLogger('helpers/fx/convert/convert.js')
logger.silence()

/**
 * Function might be called from browser
 */

/**
 * Uses the first available rate
 * @param {Object} params
 * @param {Object} params.pairRatesDB
 * @param {Object} params.pairRatesDB.info
 * @param {Object[]} params.pairRatesDB.rates
 * @param {String} params.date
 * @param {Number} params.amount
 */
export function convert (params) {
  const { pairRatesDB, date, amount } = params
  logger.info(['convert', amount, date, pairRatesDB])

  const conversionResults = {
    initialAmount: amount,
    targetDate: date,
    date: null,
    rate: 0,
    value: 0
  }

  let applicableRate

  // If conversion rate is before any rate: use the first rate
  logger.debug(['pairRatesDB.rates[0].date', pairRatesDB.rates[0].date])
  if (pairRatesDB.rates[0].date >= date) {
    applicableRate = pairRatesDB.rates[0]
  }

  // Find the exact rate
  if (!applicableRate) {
    applicableRate = pairRatesDB.rates.find(function (oneRate) {
      return oneRate.date === date
    })
  }

  if (!applicableRate) {
    applicableRate = findApproximate(pairRatesDB, date)
  }
  // Use the last available rate
  // if (!applicableRate) {
  //   applicableRate = pairRatesDB.rates[pairRatesDB.rates.length - 1]
  // }
  logger.info([date, 'applicableRate', applicableRate])

  conversionResults.date = applicableRate.date
  conversionResults.rate = applicableRate.rate
  conversionResults.value = +(applicableRate.rate * amount).toFixed(4)
  return conversionResults
}

/**
 * This is time consuming, hence only running it when no exact date is available
 * @param {*} pairRatesDB
 * @param {*} date
 * @returns
 */
function findApproximate (pairRatesDB, date) {
  let applicableRate
  // Find the latest rate before target date
  pairRatesDB.rates.forEach(function (oneRate) {
    // logger.silly(['oneRate', oneRate])
    const rateDate = oneRate.date
    // const rateDate = (oneRate.date).substring(0, 7)
    // logger.silly([date, ' <= ', rateDate])
    if (rateDate <= date) {
      applicableRate = oneRate
    }
  })
  logger.debug(['findApproximate', applicableRate])
  return applicableRate
}
