import { group, max, min } from 'd3'
import { moduleData } from '../../data.js'

import htmlPageTemplate from './template.html'
import { fillString } from '../../../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../../../helpers/dom.js'

export function showSettlementSummary () {
  const groupedByOrder = group(moduleData.settlements, function (record) {
    return record.orderId
  })
  console.log('groupedByOrder', groupedByOrder)

  const ordersDetails = group(moduleData.ordersLines, function (record) {
    return record.orderId
  })
  console.log('ordersDetails', ordersDetails)

  const withOrderDetails = {
    yes: [],
    no: []
  }
  groupedByOrder.forEach(function (settledOrder, orderId) {
    const orderDetails = ordersDetails.get(orderId)

    if (orderDetails) withOrderDetails.yes.push(orderDetails)
    if (!orderDetails) withOrderDetails.no.push(orderDetails)
  })
  console.log('withOrderDetails', withOrderDetails)

  const html = fillString(htmlPageTemplate, {
    numberOrders: groupedByOrder.size,

    createdAtMin: min(moduleData.settlements, function (record) { return record.createdAt }) || '-',
    createdAtMax: max(moduleData.settlements, function (record) { return record.createdAt }) || '-',

    settledAtMin: min(moduleData.settlements, function (record) { return record.settledAt }) || '-',
    settledAtMax: max(moduleData.settlements, function (record) { return record.settledAt }) || '-',

    ordersWithDetails: withOrderDetails.yes.length,
    ordersWithoutDetails: withOrderDetails.no.length
  })

  setElementHtml('summarySettlementsData', html)
}
