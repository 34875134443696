// import { combinedFieldsSettings } from '../../combined-fields-settings.js'
import { lazadaOrdersFieldsMapping } from './lazada-orders-fields-mapping.js'
import { prepareLazadaFile } from './lazada-preparation.js'
import { lazadaSettlementsFieldsMapping } from './lazada-settlements-fields-mapping.js'

export const THLazadaSettings = {
  orders: {
    dataFile: {
      // Only 1 sheet in the file
      // sheetName: 'orders',
      downloadTip: 'Download from Lazada'
    },
    fileDropZoneText: 'Pick or drop your file (.xlsx)',
    fieldsMapping: lazadaOrdersFieldsMapping
  },
  settlements: {
    dataFile: {
      sheetName: 'Income Overview',
      dataHeader: 1,
      downloadTip: 'Download from Lazada (path?)'
    },
    fileDropZoneText: 'Pick or drop your file (.xlsx)',
    fieldsMapping: lazadaSettlementsFieldsMapping,
    dataPreparation: prepareLazadaFile,
    detection: {
      checkCells: [
        {
          cell: 'A1',
          value: 'Statement Period'
        },
        {
          cell: 'B1',
          value: 'Statement Number'
          // Pattern: SG...
          // vs. Malaysia: MY...
        },
        {
          cell: 'F1',
          value: 'VAT Amount?'
        }
      ],
      extractValues: ['A2']
    }
  }
}
