
// App config
import * as configVar from '../../../config/config.js'

import * as mAccount from '../../../modules/user/account.js'

import { enforceRestrictedPage } from '../../../modules/user/user.js'

import { hideElement, setElementHtml } from '../../../helpers/dom.js'
import { createContainer, setPageTitle } from '../helpers.js'
import { showSelectors } from './show-selectors.js'
import { updateView } from './update-view.js'

/**
 * /a/:accountId/trial-balances?
 * orgIds:
 * dateMin:
 * dateMax:
 *
 *
 * @param {Object} params
 * @returns
 */

export async function showPageTrialBalances (params) {
  console.log('showPageTrialBalances')
  // console.log('mData', mData)

  if (enforceRestrictedPage({ allowPublic: true })) return

  const DOMelement = setElementHtml(configVar.contentElementId, '')
  if (!DOMelement) return

  DOMelement.classList.add('position-absolute', 'top-to-bottom')

  const contentElement = createContainer('Trial Balances')
  DOMelement.append(contentElement)

  //

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  setPageTitle(accountData, 'Trial Balances')

  showSelectors(updateView)

  hideElement('statementLoadingMessage')

  // Initial load
  updateView()
}
