
import * as mAccount from '../../../modules/user/account.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { callApi } from '../../../helpers/fetch.js'
import { enrichRecords } from '../../../../server/finlogic/utils/enrich-records.js'
import { recordsAddProperty } from '../../../../helpers/data/array-of-objects/records-add-property.js'
import { arrayAddItems } from '../../../../helpers/data/array-add-items.js'

/**
 *
 * @param {*} context
 * @returns
 */
export async function loadData (context) {
  const accountData = mAccount.accountData()
  // console.log('accountData', accountData)
  // console.log('optionSelectorData', optionSelectorData)

  const accountId = accountData._id
  const orgId = context.showOrgId

  // Ensure key variables are present
  if (!accountId) return
  if (!orgId) return

  const calls = []
  const orgIds = Array.isArray(orgId) ? orgId : [orgId]
  orgIds.forEach(function (oneOrgId) {
    const orgData = mAccount.getOrganisationData(oneOrgId)
    const targetCurrency = orgData?.details?.baseCurrency === context.currencySymbol ? 'XXX' : context.currencySymbol
    calls.push(loadOrgData(accountId, oneOrgId, targetCurrency))
  })

  const receivedData = await Promise.all(calls)

  const data = combineData(receivedData)

  return data
}

async function loadOrgData (accountId, orgId, currencyCode) {
  const apiUrl = '/api/xero/a/:accountId/ledger/o/:orgId/accounts_trackings/:currencyCode/:minDate/:maxDate'

  const data = await callApi(apiUrl, {
    accountId,
    orgId,
    currencyCode,
    minDate: optionSelectorData.dates.min || null,
    maxDate: optionSelectorData.dates.max || null
  })

  // const records = data.trialBalances.records
  const records = data.ledger.records

  const propertiesToEnrich = ['chartAccountId', 'tracking1Id', 'tracking2Id']
  enrichRecords(records, data.dbs, propertiesToEnrich, true)

  // Add the entityId of each records
  recordsAddProperty(records, 'entityId', orgId)
  return data
}

function combineData (receivedData) {
  const combined = {
    dbs: {
      chartAccounts: [],
      contacts: [],
      tracking1: [],
      tracking2: [],
      trackingCategories: {},
      trackingCategoriesNames: new Set(),
      trackingsByName: new Map()
    },
    ledger: {
      decimals: null,
      records: []
    }
  }

  receivedData.forEach(function (oneOrgData) {
    if (combined.ledger.decimals && combined.ledger.decimals !== oneOrgData.ledger.decimals) {
      console.warn('calculating decimals might be different!', oneOrgData)
    }
    combined.ledger.decimals = oneOrgData.ledger.decimals

    arrayAddItems(combined.ledger.records, oneOrgData.ledger.records)

    Object.keys(oneOrgData.dbs).forEach(function (dbKey) {
      arrayAddItems(combined.dbs[dbKey], oneOrgData.dbs[dbKey])
    })

    // Tracking Categories
    combined.dbs.trackingCategories[oneOrgData.entityId] = oneOrgData.dbs.trackings

    oneOrgData.dbs.trackings?.forEach(function (tracking, trackingIndex) {
      combined.dbs.trackingCategoriesNames.add(tracking.name)

      const trackingKey = ['tracking', trackingIndex + 1].join('')
      const currentValue = combined.dbs.trackingsByName.get(tracking.name) || []
      currentValue.push(...oneOrgData.dbs[trackingKey])
      combined.dbs.trackingsByName.set(tracking.name, currentValue)
    })
  })

  return combined
}
