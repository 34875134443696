import { formatNumber } from 'chart.js/helpers'
import { fillString } from '../../../../../../helpers/templating/string-template.js'
import { getOrderRowsTotalValue } from '../get-orderrows-total-value.js'
import { xeroSalesInvoiceProperties } from './sales-invoices-settings.js'

export function makeRevenueAdjustmentLines (salesBySKU, ordersSettlementsRows, invoiceValues) {
  const adjustements = []

  const ordersLinesRevenueNet = getOrderRowsTotalValue(salesBySKU, 'revenueNet')
  const settledRevenueNet = getOrderRowsTotalValue(ordersSettlementsRows, 'revenueNet')
  const settledRevenueGross = getOrderRowsTotalValue(ordersSettlementsRows, 'revenueGross')
  const settledDiscount = getOrderRowsTotalValue(ordersSettlementsRows, 'discount')

  console.log('ordersLinesRevenueNet', ordersLinesRevenueNet)
  console.log('settledRevenueNet', settledRevenueNet)
  console.log('settledRevenueGross', settledRevenueGross)
  console.log('settledDiscount', settledDiscount)

  // 1. From Items Net Revenue (ie line of items added beforehand) to Settled Net Revenue
  const lineFromOrderNetToSettled = makeAdjustmentLine(invoiceValues, {
    description: fillString('From SKU sales Net Revenue (the Items Sales above): :ordersLinesRevenueNet \n to Settled Net Revenue: :settledRevenueNet',
      {
        settledRevenueNet: formatNumber(settledRevenueNet, 2),
        ordersLinesRevenueNet: formatNumber(ordersLinesRevenueNet, 2)
      }
    ),
    amount: settledRevenueNet - ordersLinesRevenueNet,
    accountCode: document.getElementById('sales.xeroAccountCode').value
  })
  adjustements.push(lineFromOrderNetToSettled)

  // 2. From Settled Net Revenue to Gross Revenue
  const lineSettledNetToGross = makeAdjustmentLine(invoiceValues, {
    description: fillString('From Settled Net Revenue: :settledRevenueNet \n to Settled Gross Revenue: :settledRevenueGross',
      {
        settledRevenueNet: formatNumber(settledRevenueNet, 2),
        settledRevenueGross: formatNumber(settledRevenueGross, 2)
      }
    ),
    amount: settledRevenueGross - settledRevenueNet,
    accountCode: document.getElementById('sales.xeroAccountCode').value
  })
  adjustements.push(lineSettledNetToGross)

  const discountLine = makeAdjustmentLine(invoiceValues, {
    description: 'Discount from Gross to Net Revenue',
    amount: settledDiscount,
    accountCode: document.getElementById('discount.xeroAccountCode').value
  })
  adjustements.push(discountLine)
  return adjustements
}

/**
 *
 * @param {*} invoiceValues
 * @param {*} options
 * @returns
 */
function makeAdjustmentLine (invoiceValues, options) {
  const { description, amount, accountCode } = options
  const invoiceLine = {}

  xeroSalesInvoiceProperties.forEach(function (invoiceProperty) {
    if (invoiceProperty.input) {
      invoiceLine[invoiceProperty.outputProperty] = invoiceValues[invoiceProperty.input]
    }
    if (invoiceProperty.outputProperty === 'InventoryItemCode') invoiceLine[invoiceProperty.outputProperty] = null

    if (invoiceProperty.outputProperty === '*Description') invoiceLine[invoiceProperty.outputProperty] = description
    if (invoiceProperty.outputProperty === '*Quantity') invoiceLine[invoiceProperty.outputProperty] = 1
    if (invoiceProperty.outputProperty === '*UnitAmount') invoiceLine[invoiceProperty.outputProperty] = amount
    if (invoiceProperty.outputProperty === '*AccountCode') invoiceLine[invoiceProperty.outputProperty] = accountCode
  })

  return invoiceLine
}
