import { flatGroup, group } from 'd3'
import { accessObjectPropertyValue } from '../objects.js'
import { launchLogger } from '../../logging/pino.logger.js'

const logger = launchLogger('/array-of-objects/group-by-properties.js')
logger.silence()

/**
 * Creates a JS Map, group by nested propertyPaths
 * @param {Object[]} records
 * @param {String[]} propertyPaths
 * @returns
 */
export function groupByProperties (records, propertyPaths, returnFlatMap) {
  const groupingFunctions = getGroupingFunctions(propertyPaths)

  if (!Array.isArray(records)) {
    return (returnFlatMap ? [] : new Map())
  }

  let map
  if (returnFlatMap) {
    map = flatGroup(records, ...groupingFunctions)
  } else {
    map = group(records, ...groupingFunctions)
  }
  // logger.debug(['map', map])
  return map
}

export function getGroupingFunctions (propertyPaths) {
// Find the different keys in use
  const groupingFunction = function (propertyPath) {
    return function (record) { return accessObjectPropertyValue(propertyPath, record) }
  }

  const groupingFunctions = []
  propertyPaths.forEach(function (propertyPath) {
    groupingFunctions.push(groupingFunction(propertyPath))
  })
  logger.debug(['groupingFunctions', groupingFunctions])

  return groupingFunctions
}
