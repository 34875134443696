import { generateRandomString } from '../../../../helpers/helpers/random.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../dom.js'
import { listener } from './actions.js'

import htmlTemplateSelectLevel from './select-level.html'
import htmlTemplateSelect from './select.html'

/**
 *
 * @param {Object} options
 * @param {String} options.containerId
 * @param {String} options.groupLabel
 */
export function generateSelectLevel (options) {
  // console.log('generateSelectLevel', options)

  const buttonContainerId = options.containerId
  const groupLabel = options.groupLabel

  // Generate the group container
  const elementId = 'si_' + generateRandomString(8)
  const selectContainerId = 'select_container_' + generateRandomString(8)

  const element = fillString(htmlTemplateSelectLevel, {
    selectGroupElementId: elementId,
    selectContainerId,
    selectLabel: groupLabel
  })

  const DOMelement = setElementHtml(buttonContainerId, element)
  if (!DOMelement) return

  return generateSelect({
    selectOptions: options.selectOptions,
    containerId: selectContainerId,
    onChange: options.onChange
  })
}

/**
 *
 * @param {*} options
 */
export function generateSelect (options) {
  // console.log('generateSelect', options)
  const selectInstance = {
    selectOptions: options.selectOptions,
    selectedValue: null,
    onChange: function () {
      return options.onChange(selectInstance.selectedValue, selectInstance)
    },
    update: function (params) {
      updateSelect(selectInstance, params)
    }
  }

  // Generate the container
  const elementId = 'select_' + generateRandomString(8)

  const element = fillString(htmlTemplateSelect, { elementId })

  selectInstance.elementId = elementId

  const DOMelement = setElementHtml(options.containerId, element)
  selectInstance.DOMelement = DOMelement

  // Add the options
  generateOptions(selectInstance)

  // Add listener
  listener(selectInstance)

  // Update to selected value

  return selectInstance
}

/**
 *
 * @param {*} selectInstance
 */
function generateOptions (selectInstance) {
  if (!selectInstance.selectOptions) {
    setElementHtml(selectInstance.elementId, 'No options')
  }

  const optionsHtmlArray = []
  selectInstance.selectOptions?.forEach(function (oneOption) {
    const option = Object.assign({}, oneOption)

    const isSelected = (selectInstance.selectedValue && selectInstance.selectedValue === oneOption.value)
    option.isSelected = isSelected
    const html = generateOptionHtml(option)
    optionsHtmlArray.push(html)
  })

  const optionsHtml = optionsHtmlArray.join('')
  setElementHtml(selectInstance.elementId, optionsHtml)
}

function generateOptionHtml (options) {
  const isSelectedValue = options.isSelected ? 'selected' : ''

  let optionHtml = '<option value=":value" :isSelected>:label</option>'
  optionHtml = fillString(optionHtml, {
    value: options.value,
    label: options.label,
    isSelected: isSelectedValue
  })

  return optionHtml
}

function updateSelect (selectInstance, params) {
  if (params.selected) {
    selectInstance.selectedValue = params.selected
  }

  if (params.selectOptions) {
    selectInstance.selectOptions = params.selectOptions
    generateOptions(selectInstance)
  }
}
