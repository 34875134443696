
import { setElementHtml } from '../../../../helpers/dom.js'
import htmlModule from './index.html'

const containerId = 'salesRecordingSettings'

export function showSalesRecording () {
  setElementHtml(containerId, htmlModule)
  // console.log(DOMelement)
}
