import * as cssGrid from '../../../../helpers/cssgrid/index.js'
import { hideElement, showElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { enableExport } from './export.js'
import { loadData } from './load-data.js'
import { tableColumns } from './table-columns.js'
import { tableRows } from './table-rows.js'

const context = {
  data: {}
}

let gridInstance

export async function updateView () {
  console.log('updateView')
  showElement('statementLoadingMessage')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'

  // Load data
  context.data.tbLedgerData = await loadData(context)

  gridInstance = cssGrid.generateGrid({
    containerElementId: 'statementContainer',
    colDefs: tableColumns(context),
    rowDefs: tableRows(context.data.tbLedgerData),
    context,
    classes: [],
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  enableExport(gridInstance)
  hideElement('statementLoadingMessage')
}
