import { colorArrayToRGB, parseRGB } from '../../colors/index.js'

const properties = ['borderColor']
// const properties = ['backgroundColor', 'borderColor']

export function updateDatasetHighlight (datasets, targetDatasetIndex, hoverOut) {
  // console.log('updateDatasetColors', targetDatasetIndex, datasets, hoverOut)
  datasets?.forEach(function (dataset, index) {
    const isTargetDataset = (index === targetDatasetIndex)
    // console.log('dataset', index, isTargetDataset)
    // Keep track of base value at first
    const borderProperty = 'borderWidth'
    const defaultValue = 3 // chartJS default
    const propertyNameHistory = propertyHistoryName(borderProperty)
    // console.log(borderProperty, dataset[borderProperty], propertyNameHistory, dataset[propertyNameHistory])
    if (!dataset[propertyNameHistory]) dataset[propertyNameHistory] = dataset[borderProperty] || defaultValue

    if (hoverOut === true || !isTargetDataset) {
      dataset[borderProperty] = dataset[propertyNameHistory]
      // console.log(borderProperty, 'revert', dataset[borderProperty])
    } else {
      // Matching dataset: show fully
      dataset[borderProperty] = 6
      // console.log(borderProperty, 'show', dataset[borderProperty])
    }

    // Other properties
    properties.forEach(function (oneProperty) {
      const propertyNameHistory = propertyHistoryName(oneProperty)
      const propertyValue = dataset[oneProperty]

      // Keep track of base value at first
      if (!dataset[propertyNameHistory]) dataset[propertyNameHistory] = dataset[oneProperty]

      if (hoverOut === true) {
        dataset[oneProperty] = dataset[propertyNameHistory]
        // console.log(oneProperty, dataset[oneProperty], hoverOut)
        return
      }

      const colorArray = parseRGB(propertyValue)
      if (isTargetDataset) {
        // Matching dataset: show fully
        colorArray[3] = 1
      } else {
        colorArray[3] = hoverOut
      }
      dataset[oneProperty] = colorArrayToRGB(colorArray)
      // console.log(oneProperty, dataset[oneProperty], hoverOut)
    })
  })
}

function propertyHistoryName (propertyName) {
  return ['_keep_', propertyName].join('')
}
