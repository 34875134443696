
/**
 * This leverages Bulma Css classes
 */

/**
 *
 * @param {String} elementId
 * @returns {Element|null} DOM Element or null
 */
export function hideElement (elementId) {
  const element = document.getElementById(elementId)
  if (!element) return null

  element.classList.add('is-hidden')
  return element
}

/**
 *
 * @param {String} elementId
 * @returns {Element|null} DOM Element or null
 */
export function showElement (elementId) {
  const element = document.getElementById(elementId)
  if (!element) return null

  element.classList.remove('is-hidden')
  return element
}

/**
 * To be replaced with dom/setContent()
 * @param {String} elementId
 * @param {String} html
 * @returns {Element|null} Returns the DOM Element from elementIdor null
 */
export function setElementHtml (elementId, html) {
  const element = document.getElementById(elementId)
  if (!element) return null

  element.innerHTML = html
  return element
}

/**
 * Use Bulma loading
 * @param {*} elementId
 * @returns {Element|null} DOM Element or null
 */
export function showLoading (elementId) {
  const loadingElementHtml = '<progress class="progress is-small is-info" max="100">Loading</progress>'
  const element = setElementHtml(elementId, loadingElementHtml)
  return element
}

/**
 * Returns the Element, creates it if needed
 * @param {*} id
 */
export function getElement (id, parentSelector) {
  let targetElement = null

  targetElement = document.getElementById(id)
  if (targetElement) return targetElement

  // Otherwise creates it
  targetElement = document.createElement('div')
  targetElement.setAttribute('id', id)

  const pageContainerElement = document.querySelector(parentSelector)
  pageContainerElement?.append(targetElement)

  return targetElement
}
