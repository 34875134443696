
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as cssGrid from '../../../../helpers/cssgrid/index.js'
import * as mUser from '../../../modules/user/user.js'
import * as mAccount from '../../../modules/user/account.js'
import * as mHtmlParser from '../../../modules/user/html-parser.js'

// Page Module
import htmlTemplateIndex from './index.html'

import { callApi } from '../../../helpers/fetch.js'

import { loadChartOfAccounts } from '../../charts-of-accounts/load-charts-of-accounts.js'
import { makeColDefs } from './make-coldefs.js'
import { ascending } from 'd3'

/**
 *
 * @returns
 */
export async function showPageEliminationSuggestions () {
  console.log('showPageEliminationSuggestions()')

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  const accountData = mAccount.accountData()

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Eliminations Suggestions:', accountData.name].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })
  contentElement.innerHTML = htmlPageLayout

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const dataLoading = []
  dataLoading.push(loadEliminationsSuggestions(accountData))
  dataLoading.push(loadChartOfAccounts(accountData._id))

  const data = await Promise.all(dataLoading)
  const eliminationSuggestions = data[0]
  const chartsOfAccounts = data[1]

  // const eliminationSuggestions = await loadEliminationsSuggestions(accountData)
  // loadChartOfAccounts(accountData)
  showEliminationsSuggestions({
    eliminationSuggestions,
    chartsOfAccounts
  })
}

async function loadEliminationsSuggestions (accountData) {
  console.log('loadChartOfAccounts', accountData)
  const apiUrl = '/api/xero/:accountId/eliminations-suggestions'
  const data = await callApi(apiUrl, {
    accountId: accountData._id
  })

  return data
}

function makeRowDefs (eliminationSuggestions) {
  const sorted = eliminationSuggestions.sort(function (r1, r2) {
    return ascending(r1.details?.journalDate, r2.details?.journalDate)
  })
  return sorted
}

function showEliminationsSuggestions (params) {
  console.log('showEliminationsSuggestions', params)
  const { eliminationSuggestions, chartsOfAccounts } = params

  const colDefs = makeColDefs(chartsOfAccounts)

  cssGrid.generateGrid({
    containerElementId: 'grid',
    classes: ['fit-content'],
    colDefs,
    rowDefs: makeRowDefs(eliminationSuggestions),
    showActions: true
  })
}
