
export function cohortName (cohortId, cohortPeriods) {
  const cohort = cohortId.split('-')
  if (cohortPeriods === 'quarter') {
    return cohort[0] + ' Q' + cohort[1]
  }

  return cohort[0] + '-' + (cohort[1]).padStart(2, 0)
}

export function cohortRelativePeriods (cohorts, property, options) {
  // How many columns to show
  const defaultMaxColumns = 12
  const maxColumns = options.maxRelativePeriods || defaultMaxColumns
  const effectiveColumns = Math.min(maxColumns, cohorts[0].info[property].length)

  const relativePeriods = []

  for (let periodRef = 1; periodRef <= effectiveColumns; periodRef++) {
    const text = (options.cohortPeriods === 'quarter') ? 'Q' : 'Month'

    // Because the cohorts have an initial value, then value for each following period including period 0 (the same aggregated date than initial action, but not the initial action: eg. within the same quarter)
    const periodRefText = periodRef - 1

    relativePeriods.push({
      label: [text + ' +', periodRefText].join(''),
      periodRef
    })
  }

  return relativePeriods
}
