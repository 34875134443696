import * as cssGrid from '../../../../../helpers/cssgrid/index.js'
import { formatNumber } from '../../../../../helpers/helpers/numbers.js'
import { showLoading } from '../../../../helpers/dom.js'
import { contentValueFormatter, contentValueStyling } from '../../../../helpers/helpers.js'
import { moduleData } from '../data.js'

export const commerceContainerId = 'commerce'

export function showTableLoading () {
  showLoading(commerceContainerId)
}

/**
 * Headers
 * Totals
 * Individual Rows
 * @param {*} rows
 * @param {*} columns
 * @returns
 */
export function showTable (rows, columns, context) {
  console.log('showTable', rows, columns)

  const gridInstance = cssGrid.generateGrid({
    colDefs: makeColumns(columns),
    rowDefs: makeRows(rows),
    containerElementId: commerceContainerId,
    showActions: true,
    context,
    classes: []
    // startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  return gridInstance
}

function makeColumns (columns) {
  const colDefs = []

  if (!columns) return []

  columns.forEach(function (column) {
    if (column.export === false) return

    const colDef = {
      id: column.outputName || column.propertyName,
      valueGetter: function (cellParams) {
        // console.log(cellParams)
        const rowDef = cellParams.rowDef
        if (rowDef.rowType === 'total') {
          return computeTotal(cellParams)
        } else {
          const data = rowDef?.data
          const columnProperty = cellParams.columnDef.id
          return data[columnProperty] || null
        }
      },
      onClick: function (cellParams) {
        const orderId = cellParams.rowDef.data?.orderId
        console.log('row', cellParams.rowDef.data)
        console.log('moduleData', moduleData)

        // Find the related data
        const orderLines = moduleData.ordersLines.filter(function (record) {
          return record.orderId === orderId
        })
        console.log('orderLines', orderLines)

        const settlements = moduleData.settlements.filter(function (record) {
          return record.orderId === orderId
        })
        console.log('settlements', settlements)
      },
      params: {
        column
      },
      width: column.width || 170
    }

    if (column.type === 'number') {
      colDef.styles = ['numberColumns']
      colDef.valueFormatter = function (cellParams) {
        return contentValueFormatter({ cellParams, decimals: 2 })
      }
      colDef.valueStyling = function (cellParams) {
        return contentValueStyling({ cellParams })
      }
    }

    colDefs.push(colDef)
  })
  console.log('colDefs', colDefs)
  return colDefs
}

/**
 *
 * @param {*} rows
 * @returns
 */
function makeRows (rows) {
  const rowDefs = []

  // Total Row
  const rowTotal = {
    rowType: 'total',
    styles: ['bold']
  }
  rowDefs.push(rowTotal)

  // Data rows
  rows?.forEach(function (rowData, index) {
    const row = {
      data: rowData
    }
    rowDefs.push(row)
  })

  return rowDefs
}

/**
 *
 * @param {*} cellParams
 * @returns
 */
function computeTotal (cellParams) {
  // console.log('computeTotal', cellParams)
  if (!(cellParams.columnDef.params.column.type === 'number')) return null

  const columnProperty = cellParams.columnDef.id

  const consideredRows = cellParams.gridSettings?.context?.allRows || cellParams.gridSettings.rowDefs
  // console.log('consideredRows', consideredRows)

  const total = consideredRows?.reduce(function (accumulator, rowDef) {
    const rowData = rowDef.data || rowDef
    return accumulator + (rowData?.[columnProperty] || 0)
  }, 0)

  console.log('columnProperty', columnProperty, formatNumber(total, 2))
  return total
}
