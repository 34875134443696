import { ascending } from 'd3'
import { getOrganisationData } from '../../../../modules/user/account.js'

export function makeRows (context) {
  // console.log('makeRows', context)

  const sourceStatus = new Set()
  const entities = new Set()
  const entitiesNames = new Set()
  context.data?.cashTransactions?.forEach(function (cashTransaction) {
    const entityId = cashTransaction._xeroOrganisation
    entities.add(entityId)

    const entity = getOrganisationData(entityId)
    entitiesNames.add(entity.name)

    sourceStatus.add(cashTransaction.xeroSource?.status)
  })
  console.log('entities', entities)
  console.log('entitiesNames', entitiesNames)
  console.log('sourceStatus', sourceStatus)

  const filtered = context.data?.cashTransactions?.filter(function (cashTransaction) {
    const value = cashTransaction.journalLines.netAmount
    const isWithValue = (Number.isFinite(value) && value !== 0)

    return isWithValue
  })
  // console.log('filtered', filtered)

  const rows = filtered?.map(function (cashTransaction) {
    const accountId = cashTransaction.journalLines?._xeroAccount
    const account = getAccountObj(context, accountId)
    return {
      value: cashTransaction,
      account
    }
  })
  console.log('rows', rows)

  rows?.sort(function (r1, r2) {
    return ascending(r1.value?.details?.journalDate, r2.value?.details?.journalDate)
  })

  return rows
}

function getAccountObj (context, accountId) {
  return {
    statement: context.chartOfAccounts?.indexed?.[accountId]?.statement,
    accountId,
    code: context.chartOfAccounts?.indexed?.[accountId]?.code,
    name: context.chartOfAccounts?.indexed?.[accountId]?.name,
    type: context.chartOfAccounts?.indexed?.[accountId]?.type,
    monthlyValues: []
  }
}
