import { parseDateString } from '../../../../utilities/parse-date-string.js'
import { parseNumber } from '../../../utilities/parse-number.js'

/**
 */
export const shopeeOrdersFieldsMapping = [
  {
    inputName: 'Order ID',
    outputName: 'orderId'
  },
  {
    outputName: 'recordType',
    parseFunction: function () {
      return 'orderLine'
    }
  },
  {
    outputName: 'orderPlatform',
    parseFunction: function (record, context) {
      return context?.platform || undefined
    }
  },
  {
    outputName: 'orderCountry',
    parseFunction: function (record, context) {
      return context?.country || undefined
    }
  },
  {
    outputName: 'orderLines',
    lineCombination: 'records'
  },
  {
    inputName: 'SKU Reference No.',
    outputName: 'SKU_id'
  },
  {
    inputName: 'Username (Buyer)',
    outputName: 'buyerUsername'
  },
  {
    inputName: 'Order Status',
    outputName: 'orderStatus'
  },
  {
    inputName: 'Order Creation Date',
    outputName: 'createdAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'YYYY-MM-DD HH:mm' })
    }
  },
  {
    inputName: 'Ship Time',
    outputName: 'shippedAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'YYYY-MM-DD HH:mm' })
    }
  },
  {
    inputName: 'Order Paid Time',
    outputName: 'paidAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'YYYY-MM-DD HH:mm' })
    }
  },
  {
    inputName: 'Order Complete Time',
    outputName: 'completedAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'YYYY-MM-DD HH:mm' })
    }
  },
  {
    inputName: 'Quantity',
    outputName: 'quantity',
    parseFunction: parseNumber
  },
  {
    inputName: 'Original Price',
    outputName: 'priceOriginal',
    parseFunction: parseNumber
  },
  {
    inputName: 'Product Subtotal',
    outputName: 'priceProduct',
    parseFunction: parseNumber
  },
  {
    outputName: 'revenueGross',
    computeFormula: 'priceProduct',
    computeFormulaDecimals: 2
  },
  {
    inputName: 'Seller Voucher',
    outputName: 'discount',
    parseFunction: parseNumber
  },
  {
    outputName: 'revenueNet',
    computeFormula: 'revenueGross - discount',
    computeFormulaDecimals: 2
  },
  {
    // The order total GrossRevenue
    inputName: 'Total Amount',
    outputName: 'totalAmount',
    parseFunction: parseNumber
  },
  {
    // The order total NetRevenue
    inputName: 'Grand Total',
    outputName: 'grandTotal',
    parseFunction: parseNumber
  }
]
