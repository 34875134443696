import * as cssGrid from '../../../../helpers/cssgrid/index.js'
import { hideElement, showElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { loadData } from './load-data.js'
import { makeColumns } from './table/make-columns.js'
import { makeRows } from './table/make-rows.js'
import * as mAccount from '../../../modules/user/account.js'

const context = {
  data: {}
}

let gridInstance

export async function updateView () {
  console.log('updateView', optionSelectorData)
  showElement('statementLoadingMessage')
  hideElement('statementContainer')

  // Params in use
  context.orgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'
  context.viewDecimals = optionSelectorData.decimals || 0
  context.dates = optionSelectorData.dates

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)
  context.chartOfAccounts = accountData.chartOfAccounts

  // Load data
  const response = await loadData(context)
  console.log('response', response)
  context.data = response
  console.log('context', context)

  const tableRows = makeRows(context)
  console.log('tableRows', tableRows)

  const tableColDefs = makeColumns(context)
  console.log('tableColDefs', tableColDefs)

  gridInstance = cssGrid.generateGrid({
    containerElementId: 'statementContainer',
    colDefs: tableColDefs,
    rowDefs: tableRows,
    context,
    classes: [],
    showActions: true,
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  hideElement('statementLoadingMessage')
  showElement('statementContainer')
}
