import { generateRandomString } from '../../../../helpers/helpers/random.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../dom.js'
import { findSelectedOption, listener, updateButtons } from './actions.js'
import htmlTemplateButtonLevel from './button-level.html'
import htmlTemplateButton from './button.html'

/*
  menuOptions
  onChange
*/

/**
 *
 * @param {Object} options
 * @param {String} options.containerId
 * @param {String} options.groupLabel
 * @param {Array} options.buttonOptions {label, value}
 * @param {String} options.selected value of the options selected
 * @param {Function} [options.onChange] Function to execute on change
 */
export function generateButtons (options) {
  // console.log('generateButtons', options)

  // Create an object holding info about this button instance
  const buttonsInstance = {
    buttons: [],
    selectedId: null,
    selectedOption: function () {
      const selectedOption = findSelectedOption(buttonsInstance)
      if (!selectedOption) return null

      return selectedOption.options
    },
    onChange: function () {
      const selectedButton = findSelectedOption(buttonsInstance)
      if (!options?.onChange) return null

      return options.onChange(selectedButton?.options, buttonsInstance)
    },
    update: function (params) {
      if ('selected' in params) {
        buttonsInstance.selectedId = params.selected
      }

      updateButtons({ buttonsInstance })
    }
  }

  const buttonContainerId = options.containerId
  const groupLabel = options.groupLabel

  // Generate the container
  const elementId = 'bi_' + generateRandomString(8)
  buttonsInstance.elementId = elementId

  const element = fillString(htmlTemplateButtonLevel, {
    elementId,
    buttonGroupLabel: groupLabel
  })

  const DOMelement = setElementHtml(buttonContainerId, element)
  buttonsInstance.DOMelement = DOMelement

  // Generate the buttons
  options.buttonOptions.forEach(function (oneButtonOptions) {
    addButton({
      containerElement: DOMelement,
      buttonOptions: oneButtonOptions,
      buttonsInstance,
      isSelected: (options.selected && oneButtonOptions.value === options.selected)
    })
  })

  listener({
    containerElement: DOMelement,
    buttonsInstance
  })

  updateButtons({
    buttonsInstance
  })

  return buttonsInstance
}

function addButton (options) {
  // console.log('addButton', options)

  const elementId = 'b_' + generateRandomString(8)
  options.buttonsInstance.buttons.push({
    id: elementId,
    options: options.buttonOptions
  })

  const element = fillString(htmlTemplateButton, {
    elementId,
    label: options.buttonOptions.label
  })

  const DOMelement = options.containerElement
  if (!DOMelement) return

  const buttonsContainer = DOMelement.querySelector('.buttons')
  buttonsContainer.insertAdjacentHTML('beforeend', element)

  if (options.isSelected) {
    options.buttonsInstance.selectedId = elementId
  }
}
