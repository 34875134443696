
// Module
import { callApi } from '../../../helpers/fetch.js'
import htmlTemplateCreateForm from './account-create.html'
import * as list from './list.js'

export function showCreateAccountForm () {
  const containerId = 'formCreateAccount'

  const containerElement = document.getElementById(containerId)
  containerElement.innerHTML = htmlTemplateCreateForm

  listener()
}

function listener () {
  console.log('listener')
  const creationFormElement = document.getElementById('formAccountCreation')
  creationFormElement.addEventListener('submit', function (submitEvent) {
    console.log('form submitEvent', submitEvent)
    submitEvent.preventDefault()

    createUserAccount()
  })
}

async function createUserAccount () {
  const creationFormElement = document.getElementById('formAccountCreation')

  const nameElement = creationFormElement.querySelector('#accountName')
  console.log('nameElement', nameElement.value)
  const postBody = {
    name: nameElement.value
  }

  console.log('createUserAccount')
  const apiUrl = '/api/account/create'
  const responseData = await callApi(apiUrl, null, {
    method: 'POST',
    body: JSON.stringify(postBody)
  })
  console.log('showUserAccounts response', responseData)
  list.showUserAccounts()
  return responseData
}
