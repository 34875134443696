import { optionSelectorData } from '../data.js'

/**
 *
 * @returns
 */
export function updateSelectorCurrency () {
  const layoutSelectorElement = document.getElementById('currencyButtons')
  if (!layoutSelectorElement) return

  // Remove is-active from all buttons
  const buttonElements = layoutSelectorElement.querySelectorAll('button')
  buttonElements.forEach(function (oneButton) {
    oneButton.classList.remove('is-active')

    const buttonIcon = oneButton.querySelector('.icon')
    buttonIcon.classList.add('is-hidden')
  })

  // Add is-active to the appropriate button
  const selector = 'button[d-currency-symbol=":currencySymbol"]'.replace(':currencySymbol', optionSelectorData.currencySymbol)
  const selectedButtonElement = layoutSelectorElement.querySelector(selector)

  if (!selectedButtonElement) return
  selectedButtonElement.classList.add('is-active')
  const iconElement = selectedButtonElement.querySelector('.icon')
  iconElement.classList.remove('is-hidden')
}
