// import { count, least, sum } from 'd3'
import * as d3 from 'd3'
import { accessObjectPropertyValue } from '../objects.js'

/**
 * Returns a new array, with selected/manipulated `properties` from itself
 * @param {Array} records
 * @param {Object[]} properties
 * @param {String} properties.name
 * @param {String|Boolean} properties.accessor property or nested.property or [nested, property]; or false to access the record object itself
 * @param {Function} [properties.manipulation] function to manipulate the accessed value
 * @param {Object} [options]
 * @param {Boolean} options.keepAllProperties - True to get a reference to the original object !! Might lead to data changes in original object later on
 * @returns {Array}
 */
export function mapArrayProperties (records, properties, options) {
  if (!records) return []

  const mapped = records.map(function (one) {
    let obj = {}
    if (options?.keepAllProperties) {
      obj = Object.assign({}, one)
    }

    properties.forEach(function (oneProperty) {
      let extractedValue
      let value
      if (oneProperty.accessor === false) {
        extractedValue = one
      } else {
        extractedValue = accessObjectPropertyValue(oneProperty.accessor, one)
        value = extractedValue
      }
      if (oneProperty.manipulation) {
        value = oneProperty.manipulation(extractedValue)
      }
      obj[oneProperty.name] = value
    })
    return obj
  })

  return mapped
}

/**
 * April 2023
 * Return a Map, grouped by properties
 *  rollups: [
 *    property: name
 *    calculation: function(groupRecords) {}
 *  ]
 * TODO: rename property to outputProperty for clarity
 * TODO: allow outputProperty nested, and setp properly
 *
 * TODO ? windowFields: [
 *     property:
 *     partitionBy: function (allRecords) {}
 *     sortBy:
 *  ]
 *  records.reduce(function (accumulator, record) {}, initialValue)
 * @param {Array} records
 * @param {String[]} groupingProperties - property names (single levels)
 * @param {Object[]} [rollups]
 * @param {String} rollups[].property - output property
 * @param {String[]|Function} rollups[].calculation - Array of shortcuts or function(records)
 * @param {Object} [options]
 * @param {Boolean} [options.returnArray] - Default false; TRUE to make Array instead of Map
 * @param {String} [options.nameOfValues] what should be the property name holding the values
 * @returns {null|Map|Array} Map or Array
 */
export function mapGroups (records, groupingProperties, rollups, options) {
  if (!records) return null

  const nameOfValues = options?.nameOfValues || 'values'

  const map = new Map()

  function makeKey (key) {
    // TODO: evolve to more efficient key? // generateIndexKey()
    if (typeof key === 'string') return key
    return JSON.stringify(key)
  }

  function getGroup (_key) {
    const key = makeKey(_key)
    const currentGroup = map.get(key)
    if (!currentGroup) {
      map.set(key, {
        key: _key,
        [nameOfValues]: []
      })
      return getGroup(key)
    }
    return currentGroup
  }

  function applyRollups () {
    if (!rollups) return
    const availableRollups = ['sum', 'count', 'least']

    map.forEach(function (oneGroup) {
      const records = oneGroup[nameOfValues]

      rollups.forEach(function (oneRollup) {
        if (Array.isArray(oneRollup.calculation) && availableRollups.includes(oneRollup.calculation[0])) {
          const targetFunction = d3[oneRollup.calculation[0]]
          oneGroup[oneRollup.property] = targetFunction(records, function (value) {
            return value[oneRollup.calculation[1]]
          })
        } else if (typeof oneRollup.calculation === 'function') {
          oneGroup[oneRollup.property] = oneRollup.calculation(records)
        } else {
          console.warn('incorrect rollup function:', oneRollup, 'expect function:', availableRollups)
        }
      })
    })
  }

  records.forEach(function (oneRecord) {
    const groupingKey = {}
    groupingProperties.forEach(function (oneProperty) {
      groupingKey[oneProperty] = accessObjectPropertyValue(oneProperty, oneRecord)
    })
    const currentGroup = getGroup(groupingKey)
    currentGroup[nameOfValues].push(oneRecord)
  })

  applyRollups()

  // console.log('mapGroups', records.length, map.size)
  if (options?.returnArray === true) {
    // Return the Array
    return Array.from(map).map(function (one) { return one[1] })
  } else {
    // Return the Map
    return map
  }
}
