import { xeroSalesInvoiceProperties } from './sales-invoices-settings.js'

export function makeInstructionsLine (invoiceValues) {
  const invoiceLine = {}

  xeroSalesInvoiceProperties.forEach(function (invoiceProperty) {
    if (invoiceProperty.input) {
      invoiceLine[invoiceProperty.outputProperty] = invoiceValues[invoiceProperty.input]
    }
    if (invoiceProperty.outputProperty === 'InventoryItemCode') invoiceLine[invoiceProperty.outputProperty] = null

    if (invoiceProperty.outputProperty === '*Description') {
      const instructions = []
      instructions.push('ONCE IN DRAFT:')
      instructions.push('Include supporting documents:')
      instructions.push('1. Platform extracted files:')
      instructions.push('- Orders file')
      instructions.push('- Settlement file')
      instructions.push('')
      instructions.push('2. Computed data: Sales by SKU+Orders')
      instructions.push('')
      instructions.push('THEN DELETE THIS LINE')

      invoiceLine[invoiceProperty.outputProperty] = instructions.join('\n')
    }
    if (invoiceProperty.outputProperty === '*Quantity') invoiceLine[invoiceProperty.outputProperty] = null
    if (invoiceProperty.outputProperty === '*UnitAmount') invoiceLine[invoiceProperty.outputProperty] = null
    if (invoiceProperty.outputProperty === '*AccountCode') invoiceLine[invoiceProperty.outputProperty] = null
  })
  return invoiceLine
}
