import { generateButtons } from '../../helpers/menus/index.js'
import { showReceivablesTable } from './receivables-table.js'

const statusOptions = [
  {
    label: 'Drafts',
    value: 'DRAFT'
  },
  {
    label: 'Awaiting Approval',
    value: 'SUBMITTED'
  },
  {
    label: 'Awaiting Payment',
    value: 'AUTHORISED'
  }
]

export const receivablesSettings = {
  selectedStatus: ['AUTHORISED']
}

export function showMenu () {
  const selectedOption = receivablesSettings.selectedStatus[0]

  generateButtons({
    buttonOptions: statusOptions,
    containerId: 'receivablesMenu',
    groupLabel: 'Invoices Status:',
    selected: selectedOption,
    onChange: async function (selectedOption, instance) {
      console.log('picked', selectedOption)

      receivablesSettings.selectedStatus.pop()
      receivablesSettings.selectedStatus.push(selectedOption.value)

      showReceivablesTable()
    }
  })
}
