import { csv } from 'd3'
import { cohortSettings } from '../data.js'
import { prepareAcquisitionCosts } from '../data-acquisition.js'

const demoFiles = [
  {
    propertySource: 'Shopee',
    url: '/localonly/shopee.csv',

    // For button menu:
    label: 'Shopee (Big)',
    value: 'shopeebig'
  },
  {
    propertySource: 'Shopee',
    url: '/localonly/shopeemedium.csv',

    // For button menu:
    label: 'Shopee (Medium)',
    value: 'shopeemedium'
  },
  {
    propertySource: 'Shopee',
    url: '/localonly/shopee2.csv',

    // For button menu:
    label: 'Shopee (Small)',
    value: 'shopeesmall'
  },
  {
    propertySource: 'Future Flow Demo',
    url: '/cohorts/data1.csv',

    // For button menu:
    label: 'Future Flow Demo',
    value: 'futureflow'
  },
  {
    propertySource: 'Future Flow Demo 2',
    url: '/cohorts/data2.csv',
    urlAcquisitionCosts: '/cohorts/data2spend.csv',

    // For button menu:
    label: 'Future Flow Demo with Acquisition Costs',
    value: 'futureflowWithAcquisitionCosts'
  },
  {
    propertySource: 'SaaS demo',
    url: '/cohorts/saas1.csv',
    urlAcquisitionCosts: '/cohorts/saas1spend.csv',

    // For button menu:
    label: 'SaaS demo',
    value: 'saas-demo1'
  }
]

export const demoFileOptions = demoFiles.filter(function (one) {
  // On local, show all demos
  if (location.href.includes('localhost')) return true

  // Otherwise, hide the localonly
  if (one.url.includes('localonly')) return false

  return true
})

export async function executeDemo (selectedOption) {
  const records = await loadCsv(selectedOption.url)
  cohortSettings.records = records

  if (!selectedOption.urlAcquisitionCosts) return
  const recordsAcquisitionCosts = await csv(selectedOption.urlAcquisitionCosts)
  cohortSettings.recordsAcquisitionCosts = recordsAcquisitionCosts
  prepareAcquisitionCosts()
}

// Parse csv -> SheetJS? D3?
// Extract properties available?
async function loadCsv (fileUrl) {
  console.group('loadCsv')
  console.time('loadCsv')
  const data = await csv(fileUrl)
  console.log('loaded data', data.length)
  console.log('first entry', data[0])
  console.log('columns', data.columns)
  console.timeEnd('loadCsv')
  console.groupEnd()
  return data
}
