
export function makeDateForBS (dateYYYYMM) {
  const calendarMonthNumber = +(dateYYYYMM.substring(5, 7)) - 1
  const date = new Date(Date.UTC(dateYYYYMM.substring(0, 4), calendarMonthNumber + 1, 0))
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  const formatted = new Intl.DateTimeFormat('en-US', options).format(date)
  return formatted
}

/**
 *
 * @param {String} dateYYYYMM
 * @param {Number} [months] defaults to 1
 * @returns
 */
export function makeDateForPnl (dateYYYYMM, months) {
  function formatDate (date) {
    // console.log('headerDateForPnl', columnDate, date)
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
    // const formatted = new Intl.DateTimeFormat('en-US', options).format(date)
    const formatToParts = new Intl.DateTimeFormat('en-US', options).formatToParts(date)
    // console.log(formatted, formatToParts)
    const dateParts = [0, 0, 0] // to be YYYY MMM DD
    formatToParts.forEach(function (onePart) {
      if (onePart.type === 'year') dateParts[0] = onePart.value
      if (onePart.type === 'month') dateParts[1] = onePart.value
      if (onePart.type === 'day') dateParts[2] = onePart.value
    })
    return [dateParts[1], dateParts[0]].join(' ')
  }

  const calendarMonthNumber = +(dateYYYYMM.substring(5, 7)) - 1
  const dateEnd = new Date(Date.UTC(dateYYYYMM.substring(0, 4), calendarMonthNumber, 1))

  const dateString = [formatDate(dateEnd)]

  if (months > 1) {
    const dateStart = new Date(Date.UTC(dateEnd.getFullYear(), dateEnd.getMonth() - months + 1, dateEnd.getDate()))

    dateString.unshift(formatDate(dateStart))
  }
  return dateString.join(' to ')
}
