
export function showChildren (rowDef) {
  // return true
  if (rowOpenings.has(rowDef.id)) return true

  if (typeof rowDef._showChildren === 'undefined') rowDef._showChildren = rowDef.showChildren || false
  return rowDef._showChildren
}

// Store row Ids which should be opened
export const rowOpenings = new Set()
