import { Chart } from 'chart.js/auto'
import { d3NumberFormat } from '../helpers/numbers.js'
import { hoverHighlight, hoverHighlightEnds } from './helpers/highlight-on-hover.js'
import { showDatasetOnly } from './helpers/toggle-dataset.js'
import { playChart } from './helpers/play.js'

export function lineChart (options) {
  console.log('lineChart', options)

  const chartContainerId = options.chartContainerId
  const chartContainerElement = document.getElementById(chartContainerId)
  console.log('chartContainerElement', chartContainerElement)

  /*
    {
      label
      data
      order: allows position of the dataset (useful :)
    }
  */
  const datasets = options.datasets || []

  // Apply colors
  datasets.forEach(function (dataset) {
    if (!dataset.color) return

    dataset.backgroundColor = dataset.color
    dataset.borderColor = dataset.color
  })

  // datasets.push({
  //   label: 'x',
  //   data: [],
  //   backgroundColor: [],
  //   borderColor: [],
  //   borderWidth: 1
  // })

  const chartConfig = {
    type: 'line',
    data: {
      labels: options.labels,
      datasets
    },
    options: {
      scales: {
        y: {
          title: {
            display: true,
            text: options.yAxisTitle || null // 'Retention'
          },
          // backgroundColor: 'red',
          // border: {
          // }
          grid: {
            // display: false, // Show or not the horizontal lines and their ticks
            // drawOnChartArea: false,
            // drawTicks: true
          },
          // min: 0,
          // max: 1,
          // reverse: true,
          // stacked: true,
          // weight: 0, // position from the chart
          beginAtZero: true
        },
        x: {
          title: 'Relative periods',
          grid: {
            display: false // Show or not the vertical lines
          }
        }
      },
      plugins: {
        legend: {
          onClick: showDatasetOnly,
          onHover: hoverHighlight,
          onLeave: hoverHighlightEnds
          // reverse: true // Reverse the order of the series
          // labels: {
          //   font: {
          //     size: 14
          //   }
          // }
        }
      }
    }
  }

  if (options.valueFormat) {
    chartConfig.options.scales.y.ticks = chartConfig.options.scales.y.ticks || {}
    chartConfig.options.scales.y.ticks.callback = function (value) {
      return d3NumberFormat(value, options.valueFormat)
    }
    chartConfig.options.plugins = chartConfig.options.plugins || {}
    chartConfig.options.plugins.tooltip = chartConfig.options.plugins.tooltip || {}
    chartConfig.options.plugins.tooltip.callbacks = {
      label: function (context) {
        const { dataIndex, dataset } = context

        const value = dataset.data[dataIndex]
        return d3NumberFormat(value, options.valueFormat)
      }
    }
  }

  if (options.stack) {
    chartConfig.options.scales.y.stacked = true
  }

  const chart = new Chart(chartContainerElement, chartConfig)
  chart.$$fchart = {
    play: function () {
      playChart(chart)
    }
  }
  return chart
}
