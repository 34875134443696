import dayjs from 'dayjs'

const DEFAULT_MONTH_AGO = 1

export function setPeriods () {
  const defaultPeriod = {
    start: dayjs().add(-DEFAULT_MONTH_AGO, 'month').startOf('month'),
    end: dayjs().add(-DEFAULT_MONTH_AGO, 'month').endOf('month')
  }

  document.getElementById('periodStart').value = defaultPeriod.start.format('YYYY-MM-DD')
  document.getElementById('periodEnd').value = defaultPeriod.end.format('YYYY-MM-DD')
}
