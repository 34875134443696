import { tiktokOrdersFieldsMapping } from './tiktok-orders-fields-mapping.js'
import { tiktokSettlementsFieldsMapping } from './tiktok-settlements-fields-mapping.js'

export const PHtiktokSettings = {
  orders: {
    dataFile: {
      type: 'csv'
    },
    fileDropZoneText: 'Pick or drop your file (.csv)',
    fieldsMapping: tiktokOrdersFieldsMapping
  },
  settlements: {
    dataFile: {
      sheetName: 'Order details',
      downloadTip: 'eg from https://seller-ph.tokopedia.com/finance/settled'
    },
    fileDropZoneText: 'Pick or drop your file (.xlsx)',
    fieldsMapping: tiktokSettlementsFieldsMapping
  }
}
