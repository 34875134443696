import { entities } from '../upload/entities.js'

export function getEntityCurrency (entityName) {
  const matchingEntity = getEntity(entityName)
  return matchingEntity.baseCurrency
}

export function getEntity (entityName) {
  const matchingEntity = entities.find(function (entity) {
    return entity.name === entityName
  })
  return matchingEntity
}
