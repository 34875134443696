
/**
 * See client/pages/cohorts/connectors/shopee.js
 */
export const shopeeSettlementsFieldsMapping = [
  {
    inputName: 'Order ID',
    outputName: 'orderId'
  },
  {
    outputName: 'recordType',
    parseFunction: function () {
      return 'settlementLine'
    }
  },
  {
    outputName: 'orderPlatform',
    parseFunction: function (record, context) {
      return context?.platform || undefined
    }
  },
  {
    outputName: 'orderCountry',
    parseFunction: function (record, context) {
      return context?.country || undefined
    }
  },
  {
    inputName: 'Username (Buyer)',
    outputName: 'buyerUsername'
    // parseFunction
  },
  {
    inputName: 'Order Creation Date',
    outputName: 'createdAt'
    // Shopee provides a YYYY-MM-DD string
  },
  {
    inputName: 'Payout Completed Date',
    outputName: 'settledAt'
    // Shopee provides a YYYY-MM-DD string
  },
  //
  // Revenue
  {
    inputName: 'Original product price',
    outputName: 'originalProductPrice'
  },
  {
    inputName: 'Your Seller product promotion',
    outputName: 'yourSellerProductPromotion'
  },
  {
    inputName: 'Refund Amount',
    outputName: 'refundAmount'
  },
  {
    outputName: 'revenueGross',
    computeFormula: 'originalProductPrice + yourSellerProductPromotion + refundAmount',
    computeFormulaDecimals: 2
  },
  {
    inputName: 'Total Released Amount (฿)',
    outputName: 'totalReleasedAmount'
  },
  {
    outputName: 'settlementAmount',
    computeFormula: 'totalReleasedAmount',
    computeFormulaDecimals: 2
  },
  //
  // Discounts
  {
    inputName: 'Product Discount Rebate from Shopee',
    outputName: 'productDiscountRebateFromShopee'
  },
  {
    inputName: 'Voucher',
    outputName: 'voucher'
  },
  {
    inputName: 'Seller Absorbed Coin Cashback',
    outputName: 'sellerAbsorbedCoinCashback'
  },
  {
    outputName: 'discount',
    computeFormula: 'productDiscountRebateFromShopee + voucher + sellerAbsorbedCoinCashback',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'revenueNet',
    computeFormula: 'revenueGross + discount',
    computeFormulaDecimals: 2
  },
  //
  // Distribution
  {
    inputName: 'Shipping Fee Paid by Buyer',
    outputName: 'shippingFeePaidByBuyer'
  },
  {
    inputName: 'Shipping Rebate From Shopee',
    outputName: 'shippingRebateFromShopee'
  },
  {
    inputName: 'Actual Shipping Fee',
    outputName: 'actualShippingFee'
  },
  {
    inputName: 'Reverse Shipping Fee',
    outputName: 'reverseShippingFee'
  },
  {
    outputName: 'shippingSubtotal',
    computeFormula: 'shippingFeePaidByBuyer + shippingRebateFromShopee + actualShippingFee + reverseShippingFee',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'onlineDistributionFees',
    computeFormula: 'shippingFeePaidByBuyer + shippingRebateFromShopee + actualShippingFee + reverseShippingFee',
    computeFormulaDecimals: 2
  },
  //
  // Marketing fees
  {
    inputName: 'AMS Commission Fee',
    outputName: 'AMSCommissionFee'
  },
  {
    outputName: 'onlineMarketingAds',
    computeFormula: 'AMSCommissionFee',
    computeFormulaDecimals: 2
  },
  //
  // Platform fees
  {
    inputName: 'Commission fee',
    outputName: 'commissionFeeInclSalesTax'
  },
  {
    inputName: 'Service Fee',
    outputName: 'serviceFeeInclSalesTax'
  },
  {
    inputName: 'Transaction Fee',
    outputName: 'transactionFeeInclSalesTax'
  },
  {
    outputName: 'onlinePlatformFees',
    computeFormula: 'commissionFeeInclSalesTax + serviceFeeInclSalesTax + transactionFeeInclSalesTax',
    computeFormulaDecimals: 2
  },

  {
    outputName: 'feesAndCharges',
    computeFormula: 'AMSCommissionFee + commissionFeeInclSalesTax + serviceFeeInclSalesTax + transactionFeeInclSalesTax',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'computedSettlementAmount',
    computeFormula: 'revenueNet + onlineMarketingAds + onlinePlatformFees + onlineDistributionFees',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'mismatchSettlement',
    computeFormula: 'computedSettlementAmount - settlementAmount',
    computeFormulaDecimals: 2
  }

]
