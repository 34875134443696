import * as XLSX from 'xlsx'

/**
 *
 * @param {*} file
 * @returns
 */
export async function readXLSXfile (file) {
  /* get raw data */
  // https://docs.sheetjs.com/docs/demos/local/file/
  const data = await file.arrayBuffer()
  // data is an ArrayBuffer

  const workbook = XLSX.read(data)
  // console.log('workbook', workbook)

  // sheetNames are available in workbook.SheetNames

  return workbook
}
