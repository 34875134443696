
import Pino from 'pino'
// In case version above does not work, use full syntax:
// import { default as Pino } from 'pino'

/*
  https://github.com/pinojs/pino/blob/master/docs/api.md#options
  levels: One of 'fatal', 'error', 'warn', 'info', 'debug', 'trace' or 'silent'.
*/

const LOCAL_LEVEL = 'debug'

/*
 * @typedef {Object} PinoInstance
 * @extends Pino
 * @property {Function} PinoInstance.silence
 */

/*
 *
 * @param {String} label
 * @returns {PinoInstance}
 */

export function launchLogger (label) {
  const maxLevel = process.env.NODE_ENV === 'production' ? 'info' : LOCAL_LEVEL
  // console.log('maxLevel', maxLevel)

  const pinoInstance = Pino({
    browser: { asObject: true },
    name: label,
    level: maxLevel,
    formatters: {
      level: (label) => {
        return {
          level: label
        }
      },
      bindings: (bindings) => {
        // Ignores .pid
        return {
          // pid: bindings.pid,
          // host: bindings.hostname,
          name: bindings.name
        }
      }
    },
    timestamp: Pino.stdTimeFunctions.isoTime
  })

  // @ts-ignore
  pinoInstance.silence = silence(pinoInstance)
  // pinoInstance.silence = function () { console.log('silence...') }

  return pinoInstance
}

function silence (pino) {
  return function () {
    if (pino.silent) {
      // .silent() should silence the logger
      // pino.silent()

      // But does not for some reason... so force the level
      pino.level = Infinity
    } else {
      // In the browser, we silence the logger through the level
      pino.level = 'silent'
    }
  }
}
