import { generateFilePicker } from '../../../helpers/menus/index.js'
import * as XLSX from 'xlsx'
import { parseSheet } from './parse-sheet.js'
import { readFileEntityName } from './read-file-entity-name.js'
import { storeUpload } from '../utils/storage/store-upload.js'
import { showAvailableData } from './show-available-data.js'

const menuComponents = {}

export function createFileDropper () {
  createFileDropperElement()

  menuComponents.filepicker = generateFilePicker({
    containerId: 'fileDropper',
    messages: {
      ready: 'Pick or drop you file (csv)'
    },
    onChange: function (file) {
      onNewFile({ file })
    },
    isBoxed: true
  })
}

function createFileDropperElement () {
  const fileDropperElement = document.createElement('div')
  fileDropperElement.setAttribute('id', 'fileDropper')
  fileDropperElement.innerText = 'file drop'

  const optionSelectorElement = document.getElementById('optionSelector')
  optionSelectorElement.after(fileDropperElement)
}

export async function readFile (file) {
  const reader = new FileReader()

  if (file) {
    reader.readAsText(file)
  }

  return new Promise(function (resolve) {
    function onFileReady () {
      const fileContent = reader.result
      console.log(fileContent)

      resolve(fileContent)
    }

    reader.addEventListener('load', onFileReady, false)
  })
}

async function onNewFile (params) {
  console.log('new file', params)
  const { file } = params
  /* get raw data */
  // https://docs.sheetjs.com/docs/demos/local/file/
  const data = await file.arrayBuffer()
  /* data is an ArrayBuffer */
  const workbook = XLSX.read(data)
  // console.log('workbook', workbook)

  const worksheet = workbook.Sheets[workbook.SheetNames[0]]
  // console.log(XLSX.utils.sheet_to_json(worksheet))

  const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

  const dataRows = parseSheet({
    sheetData
  })

  const entityName = readFileEntityName(sheetData)

  storeUpload(file, entityName, dataRows)

  showAvailableData()

  // combineItems(entityName, dataRows)
}
