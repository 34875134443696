import * as fxConversion from '../../../../helpers/fx/convert/convert.js'

export function computeConvertedAmount (baseCurrencySymbol, amount, context) {
  // console.log('computeConvertedAmount', arguments)
  const { currencySymbol, FXratesDB } = context

  if (currencySymbol === 'XXX') return amount || null

  const targetPair = [baseCurrencySymbol, currencySymbol].join('.')
  const pairRatesDB = FXratesDB[targetPair]

  const conversionResults = fxConversion.convert({
    date: '2023-08-01',
    amount,
    pairRatesDB
  })
  const convertedAmount = conversionResults?.value

  return convertedAmount || null
}
