
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import htmlIndex from './index.html'
import { callApi } from '../../../helpers/fetch.js'
import { showTokensTable } from './show-tokens-table.js'

/**
 *
 * @returns
 */
export async function showPageTokens (orgId) {
  console.log('module show')

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showTokens(orgId)
}

async function showTokens (orgId) {
  const xeroTokens = await loadTokens(orgId)
  console.log('xeroTokens', xeroTokens)

  showTokensTable(xeroTokens)
}

/**
 */
async function loadTokens (orgId) {
  // Avoid undefined: allows to have all orgs
  if (!orgId) orgId = ''

  const apiUrl = '/api/xero/sync/info/:orgId/tokensDetails'
  const data = await callApi(apiUrl, {
    orgId
  })
  return data.orgTokens
}
