import { greatest, least, scaleLinear } from 'd3'
import { colorArrayToRGB } from '../../../../helpers/colors/index.js'

export function computeColorRange (options) {
  const cohorts = options.cohorts

  const values = cohorts.map(function (oneSerie) {
    // Get all the values, except first one (being the cohort size)
    return oneSerie.showValues.slice(1)
  })

  const allVallues = values.flat()
  const range = [least(allVallues), greatest(allVallues)]
  // console.log('allVallues', allVallues, range)
  return range
}

const baseColorValuesRecent = [0, 192, 239] // Blue
// const baseColorValuesRecent = [105, 128, 163] // Blue
const targetRangeRecent = [0.1, 1]
const baseColorValuesOlder = [130, 130, 130] // Grey
const targetRangeOlder = [0.1, 0.3]
const recentFrom = 6

export function cohortColor (cohorts, cohortIndex) {
  // 6 most recent ones in blue
  // Previous ones in grey

  const cohortToLast = cohorts.length - cohortIndex
  const baseColors = (cohortToLast <= recentFrom) ? baseColorValuesRecent : baseColorValuesOlder
  const targetRange = (cohortToLast <= recentFrom) ? targetRangeRecent : targetRangeOlder

  const cohortPct = cohortIndex / cohorts.length
  const colorAlpha = scaleLinear([0, 1], targetRange)(cohortPct)
  const color = colorArrayToRGB([...baseColors, colorAlpha])

  return color
}

export function cohortLineWidth (cohorts, cohortIndex) {
  const defaultBorderWidth = 3 // Default

  const cohortToLast = cohorts.length - cohortIndex

  const borderWidth = (cohortToLast <= recentFrom) ? defaultBorderWidth : 1
  return borderWidth
}
