
/**
 * Receives an array of values
 * Computes a series of values
 * TODO: min, max, median
 * TODO: range: and ability to show multiple values in a cell?
 * @param {Array} values
 * @param {Object} options
 * @param {Number} options.calculationPeriods
 * @param {Object} options.aggregation
 * @param {Number} options.aggregation.includedPeriods -1 for all time
 * @param {String} options.aggregation.method sum, average, first, last
 * @param {Boolean} options.isFromFirstPeriod Defaults to false
 */

import { roundNumber } from '../helpers/numbers.js'

export function computeSerieAggregations (values, options) {
  // console.log('computeSerieAggregations', values, options)
  if (!Array.isArray(values)) return false
  if (!options?.aggregation) return false

  // Should the value be calculated for each period (eg. monthly) or once every X period (eg. quarterly)
  const calculationPeriods = options.calculationPeriods || 1

  // How many periods values are included in each calculation
  const includedPeriods = options.aggregation.includedPeriods || 1

  // Defaults to isFromFirstPeriod = false
  const isFromFirstPeriod = !!options.isFromFirstPeriod || false

  const method = options.aggregation.method || 'sum'

  const computedAggregations = []
  const includedValues = []

  values.forEach(function (oneValue, index) {
    includedValues.push(oneValue)

    // if includedPeriods -1: keep all values
    if (includedPeriods > 0 && includedValues.length > includedPeriods) {
      // Remove the first value of the array
      includedValues.shift()
    }
    // console.log(index, includedValues)

    // Computes the aggregations every calculationPeriods
    const modulo = (index + 1) % calculationPeriods
    // console.log(index, (index + 1), '%', calculationPeriods, modulo)
    if (modulo !== 0) return

    // When a specific number of period is aimed at; The minimum number of values need to be included
    // Except when options.isFromCompletePeriod set to false
    if (!isFromFirstPeriod && (includedPeriods > 0 && includedValues.length < includedPeriods)) {
      computedAggregations.push(null)
      return
    }

    if (method === 'sum') {
      const aggregationValue = sumArrayValues(includedValues)
      computedAggregations.push(roundedValue(aggregationValue))
      return
    }
    if (method === 'average') {
      const aggregationValue = sumArrayValues(includedValues) / (includedValues.length) // includedPeriods
      computedAggregations.push(roundedValue(aggregationValue))
      return
    }
    if (method === 'first') {
      const aggregationValue = includedValues[0]
      computedAggregations.push(roundedValue(aggregationValue))
      return
    }
    if (method === 'last') {
      const aggregationValue = includedValues[includedPeriods - 1]
      computedAggregations.push(roundedValue(aggregationValue))
    }
  })
  // console.log('computedAggregations', computedAggregations)
  return computedAggregations
}

const CALCULATION_DECIMALS = 4
function roundedValue (value) {
  return roundNumber(value, CALCULATION_DECIMALS)
}

function sumArrayValues (array) {
  const startingValue = 0
  const aggregationValue = array.reduce(function (accumulator, value) {
    return accumulator + value
  }, startingValue)

  return aggregationValue
}
