import { computeRecordFormula } from '../compute-record-formula/compute-record-formula.js'
import { getInputValue } from './get-record-input-value.js'

/**
 * inputName: String or Array
 * outputName
 * parseFunction
 *
 *
 * fillData
 * computeFormula
 *
 * @param {*} inputDataRecord
 * @param {*} outputRecord
 * @param {*} fieldsSettings
 * @param {Object} [options]
 * @param {Object} [options.context] -- Allows to pass parameters to the parse function
 */
export function generateRecordWithSettings (inputDataRecord, outputRecord, fieldsSettings, options) {
  // Include all the relevant properties
  fieldsSettings?.forEach(function (propertySetting) {
    // console.log('propertySetting', propertySetting)

    if (propertySetting.propertyName) {
      // Use the same property in and out: just replicate the field
      const inputValue = inputDataRecord[propertySetting.propertyName]
      checkConflictingValue(outputRecord, inputValue, propertySetting)

      outputRecord[propertySetting.outputName || propertySetting.propertyName] = inputValue
    } else if (propertySetting.parseFunction) {
      // Get the value
      const inputValue = getInputValue(inputDataRecord, propertySetting.inputName)
      // console.log('inputValue', inputValue)

      const readyValue = propertySetting.parseFunction(inputValue, options?.context)
      checkConflictingValue(outputRecord, inputValue, propertySetting)

      outputRecord[propertySetting.outputName] = readyValue
    } else if (propertySetting.inputName) {
      // Get the value
      const inputValue = getInputValue(inputDataRecord, propertySetting.inputName)
      // console.log('inputValue', inputValue)
      outputRecord[propertySetting.outputName] = inputValue
    } else {
      if (propertySetting.computeFunction) {
        // Execute the function
        const currentRecord = Object.assign({}, inputDataRecord, outputRecord)
        const computedValue = propertySetting.computeFunction(currentRecord)
        outputRecord[propertySetting.outputName] = computedValue
        //
      } else if (propertySetting.computeFormula) {
        // Execute the formula
        const currentRecord = Object.assign({}, inputDataRecord, outputRecord)

        const computedValue = computeRecordFormula(currentRecord, propertySetting.computeFormula, { decimals: propertySetting.computeFormulaDecimals || 0 })
        outputRecord[propertySetting.outputName] = computedValue
        //
        //
      } else if (propertySetting.fillData) {
        // Store records
        if (propertySetting.fillData === 'records') {
          // Set as Array
          outputRecord[propertySetting.outputName] = outputRecord[propertySetting.outputName] || []

          outputRecord[propertySetting.outputName].push(inputDataRecord)
        }
        //
        //
      }
    }
  })
}

/**
 *
 * @param {*} outputRecord
 * @param {*} inputValue
 * @param {*} propertySetting
 */
function checkConflictingValue (outputRecord, inputValue, propertySetting) {
  // const debugOrderId = '578377009295034756'

  if (!propertySetting.combinationCheckMatch) return
  // outputRecord.conflictChecked = true

  const outputValue = outputRecord[propertySetting.propertyName] || outputRecord[propertySetting.outputName]
  // Analyse if different values
  if (outputValue && inputValue !== outputValue) {
    // console.warn(propertySetting, outputRecord, inputValue, '!==', outputValue)
    outputRecord.hasConflict = true
  }

  // if (outputRecord.orderId === debugOrderId) {
  //   console.log('checkConflictingValue', propertySetting, propertySetting.combinationCheckMatch)
  //   console.log(outputRecord, 'outputValue', outputValue, inputValue)
  // }
}
