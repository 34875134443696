
export function listener (options) {
  const DOMelement = options.containerElement
  if (!DOMelement) return

  DOMelement.addEventListener('click', function (clickEvent) {
    const targetElement = clickEvent.target.closest('.button')
    if (!targetElement) return
    const targetId = targetElement.getAttribute('id')

    // Add new selection
    options.buttonsInstance.selectedId = targetId

    updateButtons({
      buttonsInstance: options.buttonsInstance
    })

    if (options.buttonsInstance.onChange) {
      options.buttonsInstance.onChange()
    }
  })
}

export function updateButtons (options) {
  const DOMelement = options.buttonsInstance.DOMelement
  if (!DOMelement) return

  // Remove active classes
  const buttonElements = DOMelement.querySelectorAll('.button')
  buttonElements.forEach(function (oneButton) {
    deactivateButton(oneButton)
  })

  // Active the proper button
  if (!options.buttonsInstance.selectedId) return
  const targetButton = DOMelement.querySelector('#' + options.buttonsInstance.selectedId)
  activateButton(targetButton)
}

export function findSelectedOption (buttonsInstance) {
  if (!buttonsInstance.selectedId) return false

  const button = buttonsInstance.buttons.find(function (one) {
    return one.id === buttonsInstance.selectedId
  })

  return button
}

function deactivateButton (buttonElement) {
  buttonElement.classList.remove('is-active')

  const buttonIcon = buttonElement.querySelector('.icon')
  buttonIcon.classList.add('is-hidden')
}

function activateButton (buttonElement) {
  buttonElement.classList.add('is-active')

  const buttonIcon = buttonElement.querySelector('.icon')
  buttonIcon.classList.remove('is-hidden')
}
