import { generateRandomString } from '../../helpers/random.js'
import { sleep } from '../../helpers/sleep.js'
import { updateDatasetHighlight } from './highlight-dataset.js'

export async function playChart (chart) {
  const datasets = chart.data.datasets

  // To only play 1 animation at a time, we monitor a playId
  const playId = generateRandomString(8)
  chart.$$fchart.playOngoing = playId

  hideAllDatasets(chart, datasets)
  chart.update()
  await sleep(500)

  const numberDatasets = datasets.length
  // console.log('datasets', datasets, numberDatasets)

  for (let index = 0; index < numberDatasets; index++) {
    if (chart.$$fchart.playOngoing !== playId) return

    showDataset(chart, index)
    updateDatasetHighlight(datasets, index, 0.2)

    // Update with an animation mode? https://www.chartjs.org/docs/latest/developers/api.html#updatemode
    const mode = null
    chart.update(mode)
    await sleep(1000)
  }
  chart.$$fchart.playOngoing = false
}

function hideAllDatasets (chart, datasets) {
  datasets.forEach(function (dataset, index) {
    chart.setDatasetVisibility(index, false)
  })
}

function showDataset (chart, targetDatasetIndex) {
  chart.setDatasetVisibility(targetDatasetIndex, true)
}
