import { generateRandomString } from '../../../../helpers/helpers/random.js'
import { containerListener } from './actions.js'

export function createContainer (options) {
  console.log('createContainer', options)

  if (!options) return

  const cohortContainerId = generateRandomString(8)

  const parentElementClasses = []
  parentElementClasses.push(...options.classes || '')

  if (options.createContainer) {
    const parentContainerElement = document.getElementById(options.parentContainer)

    const cohortChartParentElement = document.createElement('div')
    cohortChartParentElement.classList.add(...parentElementClasses)
    parentContainerElement.append(cohortChartParentElement)

    // Title
    const titleElement = document.createElement('h1')
    titleElement.innerText = options.title || 'Cohort [No title]'
    cohortChartParentElement.append(titleElement)

    // Actions
    if (options.showActionsPlay) {
      const playElement = document.createElement('div')
      playElement.innerText = 'Play Animation'
      playElement.classList.add('button', 'is-primary')
      playElement.addEventListener('click', function (clickEvent) {
        // console.log('clicked')
        containerListener(cohortContainerId)
      })
      cohortChartParentElement.append(playElement)
    }

    // Chart
    const type = options.elementType || 'div'
    console.log('type', type)
    const cohortContainerElement = document.createElement(type)

    cohortContainerElement.setAttribute('id', cohortContainerId)
    cohortChartParentElement.append(cohortContainerElement)
    return cohortContainerId
  }

  // Otherwise find the existing container?
}
