import { columnChart } from '../../../../helpers/charts/index.js'
import { colorArrayToRGB } from '../../../../helpers/colors/index.js'
import { cohortName } from './helpers.js'
import { createContainer } from '../utilities/containers.js'

export function showCohortSizeTrend (cohorts, property, options) {
  console.log('showCohortSizeTrend')

  const containerOptions = Object.assign({}, options)
  containerOptions.classes = containerOptions.classes || []
  containerOptions.classes.push('cohort-chart')
  containerOptions.elementType = 'canvas'
  const cohortContainerId = createContainer(containerOptions)

  // Find the size of the different cohorts
  // console.log(cohorts)
  const cohortSizes = cohorts.map(function (cohort) {
    return {
      cohortId: cohort.key.cohort,
      cohortName: cohortName(cohort.key.cohort, options.cohortPeriods),
      value: cohort.info?.[property][0]
    }
  })

  let previousValue = null
  cohortSizes.forEach(function (cohortSize) {
    cohortSize.change = previousValue ? cohortSize.value / previousValue - 1 : null
    previousValue = cohortSize.value
  })
  console.log('cohortSizes', cohortSizes)

  const labels = cohortSizes.map(function (one) {
    return one.cohortName
  })

  const datasets = []

  const datasetCohortSize = {
    type: 'bar',
    label: 'Cohort Size',
    color: colorArrayToRGB([130, 202, 186]),
    data: cohortSizes.map(function (one) {
      return one.value
    })
  }
  datasets.push(datasetCohortSize)

  const datasetCohortSizeTrend = {
    type: 'line',
    label: 'Growth',
    color: '#6980a3',
    data: cohortSizes.map(function (one) {
      return one.change
    })
  }
  datasets.push(datasetCohortSizeTrend)

  columnChart({
    chartContainerId: cohortContainerId,
    datasets,
    labels
  })
}
