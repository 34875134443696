import { generateButtons } from '../../../../helpers/menus/index.js'
import { showPlatformSettingsTable } from '../settings/settings-table/show-table.js'
import { refreshForm } from './refresh-form.js'
import { showDropFiles } from './show-steps.js'

export const choiceCountry = {
  chosenCountry: null
}

const buttonsContainerId = 'droppersChoiceCountry'

export function chooseCountry () {
  const instance = generateButtons({
    buttonOptions,
    containerId: buttonsContainerId,
    groupLabel: 'Which country?',
    selected: null,
    onChange: function (selectedOption, instance) {
      console.log('selected', selectedOption, instance)
      console.log('choicePlatform.instance', choiceCountry.instance, choiceCountry.instance.selectedOption())

      choiceCountry.chosenCountry = selectedOption

      refreshForm()
      showDropFiles()

      showPlatformSettingsTable({
        country: choiceCountry.chosenCountry.value
      })
    }
  })
  choiceCountry.instance = instance
}

const buttonOptions = [
  {
    label: 'Thailand',
    value: 'TH'
  },
  {
    label: 'Philippines',
    value: 'PH'
  },
  {
    label: 'Malaysia',
    value: 'MY'
  },
  {
    label: 'Singapore',
    value: 'SG'
  },
  {
    label: 'Indonesia',
    value: 'ID'
  }
]
