
import htmlTemplateMenu from './menu.html'
import * as mHtmlParser from '../user/html-parser.js'
import { userData } from '../user/user.js'

const headerMenuElementId = 'layoutHeaderMenu'

export function show (accountData) {
  const menuElement = document.getElementById(headerMenuElementId)
  if (!menuElement) return

  let htmlMenu = htmlTemplateMenu
  htmlMenu = mHtmlParser.applyAccountData(htmlMenu, accountData)
  htmlMenu = mHtmlParser.applyRestrictions(htmlMenu, { accountData })
  htmlMenu = mHtmlParser.applyPreviewClass(htmlMenu, userData())

  if (accountData?.isPublic) {
    htmlMenu = htmlMenu.replaceAll(':publicIcon', '<i class="fa-solid fa-globe right-margin "></i>')
  } else {
    htmlMenu = htmlMenu.replaceAll(':publicIcon', ' ')
  }

  menuElement.innerHTML = htmlMenu

  activateMobileBurgerMenu()
}

// Only load the listeners once
let isMenuListenerLoaded = false

/**
 * From Bulma: https://bulma.io/documentation/components/navbar/#navbar-menu
 */
function activateMobileBurgerMenu () {
  if (isMenuListenerLoaded) return

  // Get all "navbar-burger" elements
  const $navbarBurgers = document.querySelectorAll('.navbar-burger')

  // Add a click event on each of them
  $navbarBurgers.forEach(function (oneBurgerMenu) {
    oneBurgerMenu.addEventListener('click', function () {
      // Get the target from the "data-target" attribute
      const targetElementId = oneBurgerMenu.getAttribute('data-target')
      const targetElement = document.getElementById(targetElementId)

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      oneBurgerMenu.classList.toggle('is-active')
      targetElement.classList.toggle('is-active')
    })
  })
  isMenuListenerLoaded = true
}
