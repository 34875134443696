import * as mAccount from '../../../modules/user/account.js'
import { activateExport } from '../../../helpers/activate-export.js'

let isListenerAdded = false
let _gridInstance

export function enableExport (gridInstance) {
  // Store data for module
  _gridInstance = gridInstance

  if (isListenerAdded) return
  isListenerAdded = true
  activateExport(exportGrid)
}

function exportGrid () {
  const gridInstance = _gridInstance
  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  const exportTime = [
    (new Date()).toJSON().substring(0, 10),
    [(new Date()).getHours(), (new Date()).getMinutes(), (new Date()).getSeconds()].join(':')
  ].join(' @ ')

  // *** The gridInstance is NOT updated with the page view
  const exportSettings = {
    sheetHeader: [
      [null],
      [null, 'Consolidation', accountData.name],
      [null, 'Currency:', gridInstance.context?.currencySymbol],
      [null, 'Exported on:', exportTime]
    ],
    filename: [accountData.name, gridInstance.context?.currencySymbol, exportTime].join(' - ')
  }

  gridInstance.exportCsv(exportSettings)
}
