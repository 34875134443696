// In node, console.log; in the browser window.console.log
const nativeConsoleLog = console.log || window.console.log
const parentObject = console || window.console

// Overall activation of the feature
const ALLOW_DISABLE = true
// const ALLOW_DISABLE = false

// Allowing to keep track of state
let isEnabled = true

/**
 *
 * @param {Boolean|String|null} choice -- reset: back to previous isPersistant=true choice; false: deactivate; true: activate
 * @param {Boolean} [isPersistant] -- Keep track of the choice to enable on choice = 'reset'
 * @returns
 */
export const enableConsole = function (choice, isPersistant) {
  // nativeConsoleLog('enableConsole', choice, isPersistant)
  // parentObject.trace()

  if (!ALLOW_DISABLE) return

  // Allows to call the function without doing any changes
  if (choice === null) return

  if (isPersistant) persistConsole(choice)

  // console.info('enableConsole', choice)
  if (choice === 'reset') {
    resetConsole()
  } else if (choice === false) {
    deactivateConsole()
  } else {
    activateConsole()
  }
}

function resetConsole () {
  if (isEnabled === true) activateConsole()
  if (isEnabled === false) deactivateConsole()
}

function persistConsole (choice) {
  if (choice === true || choice === false) {
    isEnabled = choice
  }
}

function activateConsole () {
  parentObject.log = nativeConsoleLog
}

function deactivateConsole () {
  parentObject.log = function () {}
}

/*

function a1 () {
  enableConsole(true, true)
}

function a2 () {
  enableConsole(false, true)
}

function b1 () {
  enableConsole(true)
    // activate

  enableConsole('reset')
    // back to what it was
}

a1()
// console is enable

b1()
// console runs for the function; and return to enable

a2()
// console is disabled

b1()
// console runs for the funciton; and returns to disabled

*/
