import * as XLSX from 'xlsx'

export function enableExport (params) {
  // console.log('enableExport', params)
  const { gridSettings } = params

  gridSettings.exportCsv = function (additionalParams) {
    _exportCsv(gridSettings, additionalParams)
  }

  // Trigger
  // gridSettings.exportCsv()
}

/**
 *
 * @param {*} gridSettings
 */
export function _exportCsv (gridSettings, additionalParams) {
  console.log('gridSettings', gridSettings, additionalParams)

  const workbook = createWorkbook()
  const worksheet = createSheet(workbook, additionalParams)

  const numberHeaderRows = additionalParams.sheetHeader?.length || 0

  gridSettings._cellsArray.forEach(function (oneCell) {
    const cellPosition = {
      r: oneCell.gridRowStart,
      c: oneCell.gridColStart
    }

    cellPosition.r += numberHeaderRows

    if (oneCell._position.v === 'center') {
      cellPosition.r += gridSettings._infos.headerRows
    }

    if (oneCell._position.h === 'center') {
      cellPosition.c += gridSettings._infos.leftColumns
    }

    const cellValue = oneCell._headerValue || oneCell._value

    // console.log('oneCell', oneCell, cellPosition)
    XLSX.utils.sheet_add_aoa(worksheet, [[cellValue]], { origin: cellPosition })

    // const a1Address = XLSX.utils.encode_cell(cellPosition)
    // The cell is null?? is the function async?
    // const cell = worksheet[a1Address]
    // const cellFormat = '#,###,###'
    // console.log(cellPosition, a1Address, cell)

    // const cell2 = worksheet.B2
    // console.log('cell2', cell2)
    // cell.z = cellFormat
  })

  // Works... but csv have no formatting :p
  // const cell = worksheet.D3
  // console.log('cell', cell)
  // const cellFormat = '#,###,###'
  // cell.z = cellFormat
  // console.log('cell', cell)
  launchDownload(workbook, additionalParams)
}

function createWorkbook () {
  // const fileName = 'export file'

  // const workbook = {
  //   Title: fileName,
  //   Author: 'zenflow.me'
  // }

  const workbook = XLSX.utils.book_new()
  console.log('workbook', workbook)
  return workbook
}

function createSheet (workbook, additionalParams) {
  const sheetName = 'sheet#1'
  // Details should come from outside the module
  const sheetContent = []

  additionalParams.sheetHeader.forEach(function (headerRow) {
    sheetContent.push(headerRow)
  })
  const worksheet = XLSX.utils.aoa_to_sheet(sheetContent)

  // https://docs.sheetjs.com/docs/solutions/processing/#worksheets
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName, true)
  console.log('workbook', workbook)

  return worksheet
}

function launchDownload (workbook, additionalParams) {
  const fileFormat = 'csv'
  const fileName = additionalParams.filename || 'export'
  const writeOptions = {
    compression: true,
    bookType: fileFormat
  }

  XLSX.writeFile(workbook, [fileName, fileFormat].join('.'), writeOptions)
}
