
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import * as list from './list.js'
import htmlIndex from './index.html'
import * as mAccountCreation from './account-create.js'

export async function show () {
  console.log('user/accounts show()')

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  document.title = ['Your zenflow accounts'].join(' ')

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  list.showUserAccounts()

  mAccountCreation.showCreateAccountForm()
}

/**
 * TODO
 * Show public accounts?
 */
