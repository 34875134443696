
/*
  The settings could become part of accounts/users settings
*/

const excludedAccountTypes = ['DEPRECIATN', 'ELIMINATION_DELTA_PNL', 'EARNINGS_PNL', 'FX_IMPACT_PNL']

/**
 *
 * @param {*} accountTypeSettings
 * @returns
 */
export function getAccountTypesToForecast (accountTypeSettings) {
  const accountTypesForForecasting = accountTypeSettings.filter(function (oneAccountType) {
    const isPnlAccount = oneAccountType.statement === 'pnl'
    if (!isPnlAccount) return false

    const isExcluded = excludedAccountTypes.includes(oneAccountType.id)
    if (isExcluded) return false

    return true
  })

  return accountTypesForForecasting
}

/**
 *
 * @param {*} targetAccountId
 * @param {*} accountTypesForForecasting
 * @param {*} accountsDB
 * @returns
 */
export function isAccountToInclude (targetAccountId, indexedAccountTypesForForecasting, indexedAccounts) {
  const targetAccount = indexedAccounts[targetAccountId]
  // accountsDB.find(function (oneAccount) {
  //   return (oneAccount.id === targetAccountId)
  // })
  // console.log('targetAccount', targetAccount)
  if (!targetAccount) return false

  const foundAccountType = indexedAccountTypesForForecasting[targetAccount.type]
  // accountTypesForForecasting.find(function (oneAccountType) {
  //   return (oneAccountType.id === targetAccount.type)
  // })
  // console.log('foundAccountType', foundAccountType)

  return foundAccountType
}
