import { parse } from 'mathjs'
import { roundNumber } from '../../../../../helpers/helpers/numbers.js'

/**
 *
 * @param {*} record
 * @param {*} formula
 * @param {Object} options
 * @param {Number} options.decimals
 */
export function computeRecordFormula (record, formula, options) {
  // console.log('computeRecordFormula', record, formula)
  const computed = executeFormula(record, formula)

  const targetDecimals = options?.decimals || 2
  const roundedValue = roundNumber(computed, targetDecimals)

  return roundedValue
}

function executeFormula (record, formula) {
  const formulaNodes = parse(formula)
  // console.log(formula, 'formulaNodes', formulaNodes)

  const variablesValues = {}

  formulaNodes?.traverse(function (node) {
    if (node.type === 'SymbolNode') {
      // console.log('node.type', node.type, node.name)
      variablesValues[node.name] = record[node.name] || 0
    }
  })

  const compiledFormula = formulaNodes.compile()
  // console.log('compiledFormula', compiledFormula)
  // console.log('variablesValues', variablesValues)

  const result = compiledFormula.evaluate(variablesValues)
  // console.log('result', result)

  return result
}
