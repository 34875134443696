import { generateButtons } from '../../../../helpers/menus/index.js'
import { showPlatformSettingsTable } from '../settings/settings-table/show-table.js'
import { refreshForm } from './refresh-form.js'
import { showDropFiles } from './show-steps.js'

export const choicePlatform = {
  chosenPlatform: null
}

const buttonsContainerId = 'droppersChoicePlatform'

export function choosePlatform () {
  const instance = generateButtons({
    buttonOptions,
    containerId: buttonsContainerId,
    groupLabel: 'Which platform?',
    selected: null,
    onChange: function (selectedOption) { // 2nd param can be the instance
      console.log('selected', selectedOption)
      // console.log('choicePlatform.instance', choicePlatform.instance, choicePlatform.instance.selectedOption())

      choicePlatform.chosenPlatform = selectedOption

      refreshForm()
      showDropFiles()

      showPlatformSettingsTable({
        platform: choicePlatform.chosenPlatform.value
      })
    }
  })
  choicePlatform.instance = instance
}

const buttonOptions = [
  {
    label: 'TikTok',
    value: 'tiktok'
  },
  {
    label: 'Shopee',
    value: 'shopee'
  },
  {
    label: 'Lazada',
    value: 'lazada'
  }
]
