import { callApi } from '../../../helpers/fetch.js'
import { accountData } from '../../../modules/user/account.js'

export async function loadData (context) {
  console.log('cash transactions loadData', context)

  const promises = []

  if (Array.isArray(context.orgId)) {
    context.orgId?.forEach(function (orgId) {
      const promise = loadEntityData(context, orgId)
      promises.push(promise)
    })
  } else {
    const promise = loadEntityData(context, context.orgId)
    promises.push(promise)
  }
  const responses = await Promise.all(promises)
  console.log('responses', responses)

  const responseTransactions = []
  responses.forEach(function (entityResponse) {
    entityResponse.cashTransactions?.forEach(function (cashTransaction) {
      responseTransactions.push(cashTransaction)
    })
  })

  return {
    calculationDecimals: responses[0]?.calculationDecimals,
    cashTransactions: responseTransactions
  }
}

async function loadEntityData (context, orgId) {
  const _accountData = accountData()
  console.log('accountData', _accountData)

  const endpointUrl = '/api/finlogic/cash-journal-transactions/:accountId/:orgId/:dateMin/:dateMax'

  const response = await callApi(endpointUrl, {
    accountId: _accountData?._id,
    orgId,
    dateMin: context?.dates?.min,
    dateMax: context?.dates?.max
  })
  console.log('response', response)
  return response
}
