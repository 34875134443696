import { ascending } from 'd3'
import { accessObjectPropertyValue } from '../objects.js'

// !! JUNE 2023: For some reason, loading launchLogger bugs in this file... so no logger
// import { launchLogger } from '../../logging/winston-logger.js'
// const logger = launchLogger('helpers/data/array-of-objects/value-set')
// logger.transports[0].silent = true
// logger.debug(['loaded'])

/**
 * Extracts and return the values of a target property in an Array of Object
 * Sorts it if requested
 * @param {Array} records
 * @param {String|Array} property String or Array of String
 * @param {Object} [options]
 * @returns
 */
export function valueSet (records, property, options) {
  const values = records.map(function (one) {
    return accessObjectPropertyValue(property, one)
  })
  // console.log(values)

  const setOfValues = new Set(values)
  let arrayOfValues = Array.from(setOfValues)

  if (options?.sort) {
    arrayOfValues.sort(function (a, b) {
      return ascending(a, b)
    })
  }

  if (options?.min) {
    arrayOfValues = arrayOfValues.filter(function (keyValue) {
      return keyValue >= options.min
    })
  }

  if (options?.max) {
    arrayOfValues = arrayOfValues.filter(function (keyValue) {
      return keyValue <= options.max
    })
  }

  return arrayOfValues
}
