import { generateFilePicker } from '../../../../helpers/menus/index.js'

import { moduleData } from '../data.js'
import { getSettingsToUse } from '../settings/settings-to-use.js'
import { runDataAnalysis } from './basic-data.js'
import { splitBundlesToProducts } from '../compute-bundle-products/split-bundles-to-products.js'
import { setElementHtml } from '../../../../helpers/dom.js'
import { showDropFeedback } from '../utilities/show-drop-feedback.js'
import { fillString } from '../../../../../helpers/templating/string-template.js'
import { readWithEnforcedSettings } from './read-with-enforced-settings.js'
import { mergePropertiesOnDatasets } from '../merge-properties/merge-properties.js'
import { showActions } from '../ui/show-steps.js'
import { showOrdersSummary } from './orders-summary/show-orders-summary.js'
import { showSettlementSummary } from './settlements-summary/show-settlements-summary.js'

export function activateDropOrders () {
  generateFilePicker({
    containerId: 'containerDropOrders',
    messages: {
      ready: 'Drop your file'
    },
    onChange: async function (file) {
      await receiveOrderFile({ file })
      showActions()
    },
    isBoxed: true
  })
}

async function receiveOrderFile (params) {
  console.log('receiveOrderFile')
  const { file } = params

  // Read the file
  const droppedFileResult = await readWithEnforcedSettings(file, { settingsType: 'orders' })
  const ordersLines = droppedFileResult.data

  const fileInfo = {
    type: 'orders',
    name: file.name,
    remarks: fillString(':lines lines', { lines: ordersLines.length })
  }
  // droppedFileResult?.fieldsReview?.numberErrors

  // Additional manipulation
  const moduleSettings = getSettingsToUse()
  const platformSettings = moduleSettings?.platformSettings

  const ordersLinesBundlesSplit = splitBundlesToProducts(ordersLines, moduleData.productsDataset?.productsMap, platformSettings?.orders?.fieldsMapping)
  console.log('ordersLinesBundlesSplit', ordersLinesBundlesSplit)

  // Combine with previously dropped dataset(s)
  ordersLinesBundlesSplit?.forEach(function (line) {
    moduleData.ordersLines.push(line)
  })
  console.log('moduleData.ordersLines', moduleData.ordersLines)

  mergePropertiesOnDatasets([moduleData.ordersLines, moduleData.settlements])
  console.log('moduleData', moduleData)

  // Try with lines without combinations
  const tryNoCombination = false
  if (tryNoCombination) {
    await runDataAnalysis(file, moduleSettings?.orders)
  }

  moduleData.files.push(fileInfo)
  setElementHtml('fileOrders', showDropFeedback('orders'))

  showOrdersSummary()
  showSettlementSummary()
}
