import { moduleData } from '../data.js'

export function showDropFeedback (fileType) {
  console.log('showDropFeedback', fileType)
  console.log('moduleData', moduleData)
  const filesInfos = moduleData.files.filter(function (fileInfo) {
    return fileInfo.type === fileType
  })

  const feedback = filesInfos.map(function (fileInfo) {
    const fileFeedback = []
    fileFeedback.push(['<div class="tag">', fileInfo.name, '</div>'].join(''))
    if (fileInfo.remarks) {
      fileFeedback.push(['<div class="tag is-info is-rounded">', fileInfo.remarks, '</div>'].join(''))
    }
    if (fileInfo.error) {
      fileFeedback.push(['<div class="tag is-warning is-rounded">', fileInfo.error, '</div>'].join(''))
    }
    return fileFeedback.join('')
  })

  return feedback?.join('<br>')
}
