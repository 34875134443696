export const tiktokSummaryOutput = {
  sourceData: 'settlements',
  propertiesSettings: [
    {
      propertyName: 'orderId',
      width: 170
    },
    {
      propertyName: 'createdAt',
      width: 90
    },
    {
      propertyName: 'settledAt',
      width: 90
    },
    {
      propertyName: 'subtotalAfterSellerDiscounts',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'subtotalBeforeDiscounts',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'sellerDiscounts',
      type: 'number',
      width: 120
    },
    //
    {
      propertyName: 'refundSubtotalAfterSellerDiscounts',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'refundSubtotalBeforeSellerDiscounts',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'refundOfSellerDiscounts',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'revenueGross',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'discount',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'revenueNet',
      type: 'number',
      width: 120
    },
    //
    {
      propertyName: 'transactionFee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'tikTokShopCommissionFee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'sellerShippingFee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'affiliateCommission',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'affiliatePartnerCommission',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'SFPServiceFee',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'feesTotalCalculated',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'fees_check?',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'onlineMarketingAds',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'onlinePlatformFees',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'onlineDistributionFees',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'settlementAmount',
      type: 'number',
      width: 120
    },
    {
      propertyName: 'settlement_check?',
      type: 'number',
      width: 120
    }
  ]
}
