import { mapGroups } from '../../../../helpers/data/array-of-objects/mapped-array.js'

/**
 * Shopee Data includes every product, so we need to group by Order No.
 * @param {*} initialData
 */
export function prepareShopeeData (initialDataRecords) {
  console.log('prepareShopeeData', initialDataRecords)

  const filteredRecords = filterOrderData(initialDataRecords)
  const groupingProperties = [
    ['Order No.']
  ]
  const rollups = [
    {
      // Every order item includes the total order value
      property: 'Total payment',
      calculation: function (records) {
        return records[0]['Total payment']
      }
    },
    {
      property: 'Username (buyer)',
      calculation: function (records) {
        return records[0]['Username (buyer)']
      }
    },
    {
      property: 'Order Placed Time',
      calculation: function (records) {
        return records[0]['Order Placed Time']
      }
    },
    {
      property: 'Order Status',
      calculation: function (records) {
        return records[0]['Order Status']
      }
    },
    {
      property: 'count',
      calculation: ['count', 'id']
    }
  ]
  const ordersData = mapGroups(filteredRecords, groupingProperties, rollups, { returnArray: true })
  console.log('ordersData', ordersData)
  ordersData.columns = Object.keys(ordersData[0])

  analyseShopeeOrders(ordersData)
  return ordersData
}

function filterOrderData (ordersData) {
  const statusToExclude = ['Batal', 'Pembatalan diajukan']
  const propertyToCheck = 'Order Status'
  return ordersData.filter(function (oneOrderData) {
    return !statusToExclude.includes(oneOrderData[propertyToCheck])
  })
}

/**
Analyse: Order Status?
"Selesai"" Finished"
"Sedang Dikirim" On Ship
Perlu Dikirim: Need to Send

"Batal"" Cancelled
Pembatalan diajukan" Cancellation Submitted
*/
function analyseShopeeOrders (ordersRecords) {
  const groupingProperties = [
    'Order Status'
  ]
  const rollups = [
    {
      // Every order item includes the total order value
      property: 'TotalValue',
      calculation: ['sum', 'Total payment']
    },
    {
      property: 'count',
      calculation: ['count', 'id']
    }
  ]
  const ordersByStatus = mapGroups(ordersRecords, groupingProperties, rollups, { returnArray: true })
  console.log('order by status:', ordersByStatus)
}
