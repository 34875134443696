import { cohortName } from './helpers.js'
import { createContainer } from '../utilities/containers.js'
import { lineChart } from '../../../../helpers/charts/lines.js'
import { cohortColor } from '../utilities/color.js'
import { containers } from './data.js'

/**
 *
 * @param {*} cohorts
 * @param {*} property
 * @param {*} options
 */
export function showChartCohortsContributions (cohorts, property, options) {
  const containerOptions = Object.assign({}, options)
  containerOptions.classes = containerOptions.classes || []
  containerOptions.classes.push('cohort-chart')
  containerOptions.elementType = 'canvas'
  containerOptions.showActionsPlay = true
  const cohortContainerId = createContainer(containerOptions)

  console.log('showChartCohortsContributions', cohorts)
  const cohortContributions = cohorts.map(function (cohort, index) {
    const values = Array.from(cohort.info?.[property])

    // Remove the Period+0
    values.splice(1, 1)

    // Add null values to position relative periods
    const fillValue = 0 // Use 0 to connect the lines
    const fillValues = Array(index).fill(fillValue, 0, index)
    values.splice(0, 0, ...fillValues)
    return {
      cohortId: cohort.key.cohort,
      cohortName: cohortName(cohort.key.cohort, options.cohortPeriods),
      label: cohortName(cohort.key.cohort, options.cohortPeriods),
      color: cohortColor(cohorts, index),
      data: values
    }
  })
  console.log('cohortContributions', cohortContributions)

  const labels = cohortContributions.map(function (one) {
    return one.cohortName
  })

  const chartOptions = {
    chartContainerId: cohortContainerId,
    datasets: cohortContributions,
    labels,
    stack: true,
    yAxisTitle: 'Total of Cohorts'
  }

  const chart = lineChart(chartOptions)
  containers[cohortContainerId] = {
    chart
  }
}
