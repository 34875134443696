import { makeDateSerie } from '../../../../helpers/dates/make-date-serie.js'
import { activateOptionSelector } from '../../../modules/option-selector/index.js'
import * as mAccount from '../../../modules/user/account.js'
import { entities } from '../upload/entities.js'

export function showSelectors (changeAction) {
  console.log('showSelectors')

  const optionSelectorHide = [
    'periodicity',
    'forecasting',
    'valueAggregation',
    'gridLayout',
    'dates',
    // 'currencies',
    'organisations'
  ]

  const optionSelectorShow = [
    // 'dates'
    'currencies'
  ]

  const accountData = mAccount.accountData()
  const dateseries = makeDateSerie(accountData.firstDate)

  // const currencies = accountData?.currencies
  const currencies = entities.map(function (entity) {
    return {
      symbol: entity.baseCurrency
    }
  })
  currencies.unshift({ symbol: 'XXX' })

  const optionSelectorParams = activateOptionSelector({
    containerId: 'optionSelector',
    hide: optionSelectorHide,
    show: optionSelectorShow,
    onChange: changeAction,
    dateseries,
    customSettings: {
      // statementType: 'trial-balances',
      organisations: mapOrganisations(),
      orgOptions: {
        allowConso: false,
        allowMultiple: false,
        defaultIndex: 0
      },
      currencies
    }
  })
  return optionSelectorParams
}

function mapOrganisations () {
  const accountData = mAccount.accountData()
  // console.log('accountData', accountData)

  const organisations = accountData?._accountOrganisations?.map(function (accountOrganisation) {
    return accountOrganisation._xeroOrganisation
  })
  return organisations
}
