
/**
 *
 * all columns
 * [d1, d2]
 * [o1, o2, o3]
 * [c1, c2]
 *
 * [{
 * value: d1,
 * children: [{
 *  value: o1,
 *  children: [{
 *      c1
 *    }]
 *  }]
 * }]
 * @param {Array} arrays of Object [{id}, {id}]
 */
export function arraysToNested (arrays) {
  // console.log('generateCombinedArray', arrays)
  const nestedArray = getChildren(arrays, 0)

  // console.log('nestedArray', nestedArray)
  return nestedArray
}

/**
 * Iterative loop to children
 * @param {*} arrays
 * @param {*} childrenLevel
 * @returns
 */
function getChildren (arrays, childrenLevel) {
  // console.log('getChildren', childrenLevel)
  if (!arrays[childrenLevel]) return

  const children = []
  arrays[childrenLevel].forEach(function (oneChild) {
    // console.log('level', childrenLevel, 'oneChild', oneChild)
    const subChildren = getChildren(arrays, childrenLevel + 1)

    const thisChild = Object.assign({}, oneChild)
    if (subChildren) {
      thisChild.children = subChildren
    }
    // console.log('thisChild', thisChild)
    children.push(thisChild)
  })
  return children
}

/**
 * TODO: allow multi level propertyToGroupBy
 * @param {Object[]} array
 * @param {String} propertyToGroupBy
 */
export function groupBy (array, propertyToGroupBy) {
  const groupedArray = {}

  array.forEach(function (oneObject) {
    const groupingValue = oneObject[propertyToGroupBy]
    groupedArray[groupingValue] = groupedArray[groupingValue] || []
    groupedArray[groupingValue].push(oneObject)
  })
  return groupedArray
}
