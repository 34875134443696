// Dependencies
import * as cssGrid from '../../../../helpers/cssgrid/index.js'

import { forecastCash } from '../../../../helpers/forecasting/forecasting.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import * as mData from '../data.js'
import { forecastingGridColumns } from './forecast-columns.js'
import { forecastingGridRows } from './forecast-rows.js'

let gridInstance

export function showFinancialForecast (context) {
  console.log('showFinancialForecast', context)
  // Forecast beta
  const forecastDataset = forecastCash({
    organisationId: optionSelectorData.organisationId,
    currencySymbol: optionSelectorData.currencySymbol,
    calculationsSettings: {
      monthsAhead: optionSelectorData.forecasting.periods,
      monthsPast: optionSelectorData.forecasting.pastMonths,
      startingPeriod: optionSelectorData.forecasting.startingPeriod
    },
    fs3Data: mData.db.data.fs3Data
  })

  const rowsSettings = forecastingGridRows({
    fs3Data: mData.db.data.fs3Data,
    forecastingDateseries: forecastDataset.forecastingDateseries,
    forecastingLines: forecastDataset.forecastingLines,
    forecastingRunningCash: forecastDataset.forecastingRunningCash
  })
  console.log('rowsSettings', rowsSettings)

  const columnsSettings = forecastingGridColumns({
    forecastingDateseries: forecastDataset.forecastingDateseries
  })

  gridInstance = cssGrid.generateGrid({
    colDefs: columnsSettings,
    rowDefs: rowsSettings,
    containerElementId: 'statementContainer',
    context,
    classes: [],
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })
}
