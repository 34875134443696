import { lazadaSettlementsFieldsMapping } from '../inputs/my-lazada/lazada-settlements-fields-mapping.js'

/**
 * The Lazada settings are actually the same
 */
function makeLazadaOutputSettings () {
  const propertiesSettings = []

  lazadaSettlementsFieldsMapping?.forEach(function (field) {
    const outputSettings = {
      propertyName: field.outputName,
      width: 170
    }

    if (Number.isFinite(field.computeFormulaDecimals)) {
      outputSettings.type = 'number'
    }
    if (field.parseFunction?.name === 'parseNumber') {
      outputSettings.type = 'number'
    }

    propertiesSettings.push(outputSettings)
  })

  return propertiesSettings
}
export const lazadaSummaryOutput = {
  sourceData: 'settlements',
  propertiesSettings: makeLazadaOutputSettings()
}
