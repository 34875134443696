
/**
 *
 * @param {*} fieldSettings
 * @returns
 */
export function isCombinationNeeded (fieldSettings) {
  let hasLineCombination = false
  fieldSettings?.forEach(function (fieldSetting) {
    if (fieldSetting.lineCombinationId) hasLineCombination = true
  })

  return hasLineCombination
}
