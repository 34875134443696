import { setElementHtml } from '../../../../helpers/dom.js'
import { generateFilePicker } from '../../../../helpers/menus/index.js'
import { moduleData } from '../data.js'
import { showDropFeedback } from '../utilities/show-drop-feedback.js'
import { makeProductsDataset } from './make-products-dataset.js'
import { receiveBundles } from './receive-bundles.js'
import { receiveProducts } from './receive-products.js'

const containerDropProductsId = 'containerDropProducts'
const containerDropBundlesId = 'containerDropBundles'
const parsedData = {
  products: null,
  bundles: null
}

export function activateDropProducts () {
  activateProductsDropper()
  activateBundlesDropper()
}

function activateProductsDropper () {
  generateFilePicker({
    containerId: containerDropProductsId,
    messages: {
      ready: 'Pick or drop your file (csv)'
    },
    onChange: async function (file) {
      const products = await receiveProducts({ file })
      parsedData.products = products

      const productsDataset = makeProductsDataset(parsedData.bundles, parsedData.products)
      moduleData.productsDataset = productsDataset

      setElementHtml('fileProducts', showDropFeedback('products'))
    },
    isBoxed: true
  })
}

function activateBundlesDropper () {
  generateFilePicker({
    containerId: containerDropBundlesId,
    messages: {
      ready: 'Pick or drop your file (csv)'
    },
    onChange: async function (file) {
      const bundles = await receiveBundles({ file })
      parsedData.bundles = bundles

      const productsDataset = makeProductsDataset(parsedData.bundles, parsedData.products)
      moduleData.productsDataset = productsDataset

      setElementHtml('fileBundles', showDropFeedback('bundles'))
    },
    isBoxed: true
  })
}
