import { enableConsole } from '../logging/console.js'
import { applyBackgroundColor } from './styling/background-color.js'

export function createCell (params) {
  const { cellParams } = params
  enableConsole(null)
  // console.log('*** createCell', params)

  const cellElement = document.createElement('div')
  cellParams._cellElement = cellElement

  // Expose function to show the cell content: allowing the refresh it
  cellParams.showCell = function (extraParams) {
    _showCell(cellParams, extraParams)
  }
  cellParams.showCell()

  // Cell position (array index start at 0, so we need the +1)
  cellElement.style['grid-row-start'] = cellParams.gridRowStart
  cellElement.style['grid-row-end'] = cellParams.gridRowEnd
  cellElement.style['grid-column-start'] = cellParams.gridColStart
  cellElement.style['grid-column-end'] = cellParams.gridColEnd

  // put the cellId on the cell DOM to allow later referencing
  cellElement.setAttribute('_cellId', cellParams._cellId)

  // Apply styling
  applyBackgroundColor(cellElement, cellParams)

  return cellElement
}

/**
 * _isLoading can be used to disable click behaviour
 * @param {*} cellParams
 * @param {*} extraParams
 * @returns
 */
function _showCell (cellParams, extraParams) {
  // console.log('_showCell', cellParams, extraParams)
  const cellElement = cellParams._cellElement

  if (extraParams?.showLoading) {
    cellElement.innerText = '...loading...'
    cellParams._isLoading = true
    return
  }
  cellParams._isLoading = false

  // Header Cell Value
  getHeaderValue(cellParams)

  // Cell Value
  getValue(cellParams)

  // Apply to the cell
  // cellElement.innerText = cellParams._headerValue || cellParams._valueFormatted
  cellElement.innerHTML = cellParams._headerValue || cellParams._valueFormatted

  // Calculate cell content length
  cellParams._characters = evaluateCellContent(cellParams)

  // Styling
  cellElement.classList.add('cell')
  if (Array.isArray(cellParams.styles)) {
    cellElement.classList.add(...cellParams.styles)
  }

  // Additional styles
  if (typeof cellParams.columnDef?.valueStyling === 'function') {
    const additionalStyles = cellParams.columnDef.valueStyling(cellParams)
    cellElement.classList.add(...additionalStyles)
  }

  cellParams._renderingCount = cellParams._renderingCount || 0
  cellParams._renderingCount++
}

function evaluateCellContent (cellParams) {
  const contentLength = (cellParams._valueFormatted || '').toString().length
  return contentLength
}

/**
 * Value for header cells
 * Using the headerValueGetter() function or default to the .value or .id
 * @param {*} cellParams
 * @returns
 */
function getHeaderValue (cellParams) {
  // console.log('getHeaderValue', cellParams)
  // No need to get the header Value when not on header
  if (cellParams.rowDef) return

  let headerCellValue
  if (typeof cellParams.columnDef.headerValueGetter === 'function') {
    // console.log('call headerValueGetter(cellParams)')
    headerCellValue = cellParams.columnDef.headerValueGetter(cellParams)
  } else {
    headerCellValue = cellParams.columnDef.value || cellParams.columnDef.id || ''
  }
  // Store the value in the cellParams
  cellParams._headerValue = headerCellValue
}

/**
 * value for rows which are not header
 * @param {*} cellParams
 */
function getValue (cellParams) {
  if (!cellParams.rowDef) return

  let cellValue
  if (cellParams.rowDef.isBreak) {
    // Default for .isBreak rows
    cellValue = ''
  } else if (typeof cellParams.columnDef?.valueGetter === 'function') {
    cellValue = cellParams.columnDef.valueGetter(cellParams)
  } else {
    cellValue = cellParams.value || cellParams.id || ''
  }
  // Store the value in the cellParams
  cellParams._value = cellValue

  // Formatting
  let cellValueFormatted
  if (typeof cellParams.columnDef?.valueFormatter === 'function') {
    cellValueFormatted = cellParams.columnDef.valueFormatter(cellParams)
  } else {
    cellValueFormatted = cellParams._value
  }
  // Store the value in the cellParams
  cellParams._valueFormatted = cellValueFormatted
}
