
/**
 * rollup:
 *  sum: for numbers,
 *  count: count records
 *  first: for text and date values for example
 *
 *
 * Common properties expected
 *
 * !! Date rollups might be a miss: rollup as Set()?
 */
export const rollupProperties = [
  {
    propertyName: 'numberRecords',
    rollup: 'count'
  },
  {
    propertyName: 'records',
    rollup: 'records'
  },
  {
    propertyName: 'isDirectProduct',
    rollup: 'set'
  },
  {
    propertyName: 'isUnknownProduct',
    rollup: 'set'
  },
  {
    propertyName: 'isFromBundle',
    rollup: 'set'
  },
  {
    propertyName: 'isBundle',
    rollup: 'set'
  },
  {
    propertyName: 'orderStatus',
    rollup: 'set'
  },
  {
    propertyName: 'createdAt',
    rollup: 'set'
  },
  {
    propertyName: 'paidAt',
    rollup: 'set'
  },
  {
    propertyName: 'shippedAt',
    rollup: 'set'
  },
  {
    propertyName: 'deliveredAt',
    rollup: 'set'
  },
  {
    propertyName: 'cancelledAt',
    rollup: 'set'
  },
  {
    propertyName: 'settledAt',
    rollup: 'set'
  },
  {
    propertyName: 'quantity',
    rollup: 'sum'
  },
  {
    propertyName: 'revenueGross',
    rollup: 'sum'
  },
  {
    propertyName: 'discount',
    rollup: 'sum'
  },
  {
    propertyName: 'revenueNet',
    rollup: 'sum'
  }
]
