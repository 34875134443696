/**
 * Found in https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
 * Otherwise Lodash _.get() should work well https://lodash.com/docs/4.17.15#get
 * @param {String|Array} path - either [property1, property2, ...] or 'property1.property2'
 * @param {Object} object
 * @param {String} separator - character for which to split the path into array; defaults to '.'
 * @returns
 */
export function accessObjectPropertyValue (path, object = {}, separator = '.') {
  if (!path) {
    console.warn('no path:', path)
    return
  }
  const properties = Array.isArray(path) ? path : path.split(separator)
  const propertyValue = properties.reduce(function (prev, curr) {
    return prev?.[curr]
  }, object)
  return propertyValue
}

/**
 * Returns the last child within children[] array
 * {
 * children: [{}]
 * }
 *
 * options: onlyLastOfLast
 * @param {Object} object
 * @param {Object} options
 * @returns
 */
export function findLastChildren (object, options = {}) {
  const lastChildren = []

  if (object.children) {
    let hasSubChild = false

    object.children.forEach(function (oneChild, index) {
      if (options.onlyLastOfLast && index < object.children.length - 1) return

      if (oneChild.children) {
        hasSubChild = true

        const found = findLastChildren(oneChild)
        lastChildren.push(...found)
      }
    })

    if (!hasSubChild) {
      const lastChild = object.children[object.children.length - 1]
      lastChildren.push(lastChild)
    }
  }

  return lastChildren
}
