import { parseDateString } from '../../../../utilities/parse-date-string.js'
import { parseNumber } from '../../../utilities/parse-number.js'

/**

*/
export const tiktokSettlementsFieldsMapping = [
  {
    inputName: 'Order/adjustment ID',
    outputName: 'orderId'
  },
  {
    outputName: 'recordType',
    parseFunction: function () {
      return 'settlementLine'
    }
  },
  {
    outputName: 'orderPlatform',
    parseFunction: function (record, context) {
      return context?.platform || undefined
    }
  },
  {
    outputName: 'orderCountry',
    parseFunction: function (record, context) {
      return context?.country || undefined
    }
  },
  {
    inputName: 'Type',
    outputName: 'lineType'
  },
  {
    inputName: 'Order created time',
    outputName: 'createdAt',
    parseFunction: parseDateString
  },
  {
    inputName: ['Order settled time', 'Time(Timezone=UTC)'],
    outputName: 'settledAt',
    parseFunction: parseDateString
  },
  {
    inputName: 'Currency',
    outputName: 'currency'
  },
  {
    inputName: 'Total settlement amount',
    outputName: 'totalSettlementAmount_input',
    parseFunction: parseNumber
  },
  {
    inputName: 'Total revenue',
    outputName: 'totalRevenue_input',
    parseFunction: parseNumber
  },
  {
    inputName: 'Subtotal after seller discounts',
    outputName: 'subtotalAfterSellerDiscounts',
    parseFunction: parseNumber
  },
  {
    inputName: 'Subtotal before discounts',
    outputName: 'subtotalBeforeDiscounts',
    parseFunction: parseNumber
  },
  {
    inputName: 'Refund subtotal before seller discounts',
    outputName: 'refundSubtotalBeforeSellerDiscounts',
    parseFunction: parseNumber
  },
  {
    // TODO: include refunds?
    outputName: 'revenueGross',
    computeFormula: 'subtotalBeforeDiscounts + refundSubtotalBeforeSellerDiscounts',
    computeFormulaDecimals: 2
  },
  {
    inputName: 'Seller discounts',
    outputName: 'sellerDiscounts',
    parseFunction: parseNumber
  },
  {
    inputName: 'Refund of seller discounts',
    outputName: 'refundOfSellerDiscounts',
    parseFunction: parseNumber
  },
  {
    outputName: 'discount',
    computeFormula: 'sellerDiscounts + refundOfSellerDiscounts',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'revenueNet',
    computeFormula: 'revenueGross + discount', // Discount is negative
    computeFormulaDecimals: 2
  },
  {
    // This should be 0
    outputName: 'revenue_check',
    computeFormula: 'revenueNet - totalRevenue_input',
    computeFormulaDecimals: 2
  },
  {
    inputName: 'Total fees',
    outputName: 'totalFees_input',
    parseFunction: parseNumber
  },
  {
    inputName: 'Refund subtotal after seller discounts',
    outputName: 'refundSubtotalAfterSellerDiscounts',
    parseFunction: parseNumber
  },
  {
    inputName: 'Transaction fee',
    outputName: 'transactionFee',
    parseFunction: parseNumber
  },
  {
    inputName: 'TikTok Shop commission fee',
    outputName: 'tikTokShopCommissionFee',
    parseFunction: parseNumber
  },
  {
    inputName: 'Seller shipping fee',
    outputName: 'sellerShippingFee',
    parseFunction: parseNumber
  },
  {
    inputName: 'SFP service fee',
    outputName: 'SFPServiceFee',
    parseFunction: parseNumber
  },
  {
    inputName: 'Logistics reimbursement',
    outputName: 'logisticsReimbursement',
    parseFunction: parseNumber
  },
  {
    inputName: 'Satisfaction compensation',
    outputName: 'satisfactionCompensation',
    parseFunction: parseNumber
  },
  {
    inputName: 'Affiliate commission',
    outputName: 'affiliateCommission',
    parseFunction: parseNumber
  },
  {
    inputName: 'Affiliate partner commission',
    outputName: 'affiliatePartnerCommission',
    parseFunction: parseNumber
  },

  {
    outputName: 'onlinePlatformFees',
    computeFormula: 'transactionFee + tikTokShopCommissionFee',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'onlineDistributionFees',
    computeFormula: 'sellerShippingFee + SFPServiceFee + logisticsReimbursement + satisfactionCompensation',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'onlineMarketingAds',
    computeFormula: 'affiliateCommission + affiliatePartnerCommission',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'feesTotalCalculated',
    computeFormula: 'onlinePlatformFees + onlineDistributionFees + onlineMarketingAds',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'settlementAmount',
    computeFormula: 'revenueNet + feesTotalCalculated',
    computeFormulaDecimals: 2
  },
  {
    // Should be 0
    outputName: 'fees_check',
    computeFormula: 'feesTotalCalculated - totalFees_input',
    computeFormulaDecimals: 2
  },
  {
    // Should be 0
    outputName: 'settlement_check',
    computeFormula: 'totalSettlementAmount_input - settlementAmount',
    computeFormulaDecimals: 2
  }
]
