import { accessObjectPropertyValue } from '../../../helpers/data/objects.js'

export function generateRowDefs (sortedGroups) {
  const rowDefs = []
  console.log('generateRowDefs', sortedGroups)

  const defaultContact = 'Unknown'

  for (const oneEntry of sortedGroups.entries()) {
    const key = oneEntry[0]
    const values = oneEntry[1]

    const contactId = key
    const contactName = accessObjectPropertyValue('_id.contact', values[0]) || defaultContact

    const rowDef = {
      id: contactId,
      isContactRow: true,
      value: contactName,
      // styles: oneLayoutRow.styles,
      params: {
        contactName,
        records: values
      }
    }
    rowDefs.push(rowDef)
  }

  const rowDef = {
    id: 'total',
    value: 'Total',
    styles: ['sectionTotal'],
    params: {
      sortedGroups
    }
  }
  rowDefs.push(rowDef)

  return rowDefs
}
