
import { fillString } from '../../../../../../helpers/templating/string-template.js'
import htmlFieldInfo from './field-info.html'

const containerId = 'commerce'
/**
 *
 * @param {*} fieldsMap - the input dataset fields
 * @param {*} fieldsMapping - the settings used
 */
export function showDatasetInfo (fieldsMap, fieldsMapping) {
  const containerElement = document.getElementById(containerId)
  if (!containerElement) return null

  // Reset
  containerElement.innerText = ''

  const fieldsInfo = new Map()

  // Each fieldsMap: ie from the file itself
  fieldsMap.forEach(function (field) {
    const correspondingFieldMapping = fieldsMappingCorresponding(fieldsMapping, field.inputName)
    if (correspondingFieldMapping) {
      fieldsInfo.set(correspondingFieldMapping.outputName, {
        field,
        correspondingFieldMapping
      })
    }

    let elementHtml = htmlFieldInfo
    elementHtml = fillString(elementHtml, {
      inputValue: field.inputName,
      outputName: correspondingFieldMapping?.outputName || '__not mapped__'
    })
    containerElement.insertAdjacentHTML('beforeend', elementHtml)
  })
  // [mapped: outputName] [input Field]
  // [mapped: outputName] [computeFormula]

  let elementHtml = htmlFieldInfo
  elementHtml = fillString(elementHtml, {
    inputValue: '--- Computed Fields below ---',
    outputName: '--- Computed Fields below ---'
  })
  containerElement.insertAdjacentHTML('beforeend', elementHtml)

  fieldsMapping?.forEach(function (fieldMapping) {
    const alreadyShown = fieldsInfo.get(fieldMapping.outputName)
    if (alreadyShown) return

    // console.log('fieldsMapping field', fieldMapping)
    let elementHtml = htmlFieldInfo
    elementHtml = fillString(elementHtml, {
      inputValue: showMapping(fieldMapping),
      outputName: fieldMapping.outputName
    })
    containerElement.insertAdjacentHTML('beforeend', elementHtml)
  })
}

function showMapping (fieldMapping) {
  if (fieldMapping.computeFormula) {
    return '= ' + fieldMapping.computeFormula
  }
  if (fieldMapping.inputName) {
    return fieldMapping.inputName
  }
  if (fieldMapping.lineCombination) {
    return 'combine: ' + fieldMapping.lineCombination
  }
  return '--system field--'
}

function fieldsMappingCorresponding (fieldsMapping, targetField) {
  // console.log('fieldsMappingCorresponding', fieldsMapping, targetField)
  const correspondingFieldMapping = fieldsMapping?.find(function (fieldMapping) {
    if (fieldMapping.inputName === targetField) return true
    if (fieldMapping.inputName?.includes(targetField)) return true
    if (fieldMapping.inputName?.includes(targetField.trim())) return true

    return false
  })
  return correspondingFieldMapping
}
