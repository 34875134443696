
// App config
import * as configVar from '../../../config/config.js'

import * as cssGrid from '../../../../helpers/cssgrid/index.js'

import { optionSelectorData } from '../../../modules/option-selector/data.js'
import * as mData from '../data.js'
import * as mAccount from '../../../modules/user/account.js'

import * as mFinancialsHelpers from '../../../helpers/helpers.js'

import { enableExport } from './export.js'
import { outputAccountDetails, trackingCategoryName, trackingCategoryOptionName } from './helpers.js'
import { callApi } from '../../../helpers/fetch.js'
import { enforceRestrictedPage } from '../../../modules/user/user.js'

import { hideElement, setElementHtml, showElement } from '../../../helpers/dom.js'
import { createContainer, setPageTitle } from '../helpers.js'
import { showOptionSelector } from './menu.js'

let gridInstance

export async function showTrialBalances (params, context) {
  console.log('showTrialBalances', context)
  console.log('mData', mData)
  const { orgId, statementType } = params
  mData.db.options.statementType = statementType

  if (enforceRestrictedPage({ allowPublic: true })) return

  const contentElement = createContainer()

  const DOMelement = setElementHtml(configVar.contentElementId, '')
  if (!DOMelement) return

  DOMelement.classList.add('position-absolute', 'top-to-bottom')
  DOMelement.append(contentElement)

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  setPageTitle(accountData)

  showOptionSelector(refreshTrialBalanceTable)

  await prep(orgId)
  hideElement('statementLoadingMessage')

  // Option Selector?
  // Base data?
}

const context = {
  data: {}
}

async function prep (orgId) {
  context.showOrgId = orgId

  if (optionSelectorData.organisationId === 'conso') {
    optionSelectorData.organisationId = mData.db.data.organisations[0].id
    context.showOrgId = optionSelectorData.organisationId
  }
  if (context.showOrgId === 'all') {
    const accountData = mAccount.accountData()
    context.showOrgId = accountData?._accountOrganisations?.[0]._xeroOrganisation?._id
  }

  await refreshTrialBalanceTable()
}

async function refreshTrialBalanceTable () {
  showElement('statementLoadingMessage')

  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  const loadedTB = await loadTrialBalances(context)
  context.data.loadedTB = loadedTB

  gridInstance = cssGrid.generateGrid({
    colDefs: trialBalancesColumns(context),
    rowDefs: trialBalancesRows(context.data.loadedTB),
    containerElementId: 'statementContainer',
    context,
    classes: [],
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  enableExport(context.data.loadedTB)
  hideElement('statementLoadingMessage')
}

function trialBalancesColumns (context) {
  const columnDefs = [{
    id: 'Month',
    width: 90,
    valueGetter: function (cellParams) {
      return cellParams.rowDef[1].key.month
    }
  }, {
    id: 'Code',
    width: 70,
    valueGetter: function (cellParams) {
      const targetAccountXeroId = cellParams.rowDef[1].key.accountXeroId

      return outputAccountDetails(cellParams.gridSettings?.context?.data?.loadedTB, targetAccountXeroId, 'code')
    }
  }, {
    id: 'Account',
    width: 200,
    valueGetter: function (cellParams) {
      const targetAccountXeroId = cellParams.rowDef[1].key.accountXeroId

      return outputAccountDetails(cellParams.gridSettings?.context?.data?.loadedTB, targetAccountXeroId, 'name')
    }
  }, {
    id: 'Tracking 1',
    width: 200,
    headerValueGetter: function (cellParams) {
      const trackingCategories = cellParams.gridSettings?.context?.data?.loadedTB?.trackingCategories
      return trackingCategoryName(trackingCategories, 1)
    },
    valueGetter: function (cellParams) {
      const trackingCategories = cellParams.gridSettings?.context?.data?.loadedTB?.trackingCategories
      const trackingOptionId = cellParams.rowDef[1].key.tracking_1
      return trackingCategoryOptionName(trackingCategories, 1, trackingOptionId)
    }
  }, {
    id: 'Tracking 2',
    width: 200,
    headerValueGetter: function (cellParams) {
      const trackingCategories = cellParams.gridSettings?.context?.data?.loadedTB?.trackingCategories
      return trackingCategoryName(trackingCategories, 2)
    },
    valueGetter: function (cellParams) {
      const trackingCategories = cellParams.gridSettings?.context?.data?.loadedTB?.trackingCategories
      const trackingOptionId = cellParams.rowDef[1].key.tracking_2

      return trackingCategoryOptionName(trackingCategories, 2, trackingOptionId)
    }
  }, {
    id: 'Value',
    width: 120,
    styles: ['numberColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef[1].sum
    },
    valueFormatter: function (cellParams) {
      return mFinancialsHelpers.contentValueFormatter({
        cellParams,
        decimals: optionSelectorData.decimals
      })
    },
    valueStyling: function (cellParams) {
      return mFinancialsHelpers.contentValueStyling({ cellParams })
    }
  }, {
    id: 'Transactions',
    width: 120,
    styles: ['numberColumns'],
    valueGetter: function (cellParams) {
      return cellParams.rowDef[1].values.length
    },
    valueFormatter: function (cellParams) {
      return mFinancialsHelpers.contentValueFormatter({
        cellParams,
        decimals: optionSelectorData.decimals
      })
    },
    valueStyling: function (cellParams) {
      return mFinancialsHelpers.contentValueStyling({ cellParams })
    }
  }
  ]

  // context.data.loadedTB.trackingCategoriesIds.forEach(function (oneTrackingCategoryId, index) {
  //   columnDefs.push({
  //     id: generateTrackingName({
  //       trackingCatNumber: index,
  //       trackingCategories: context.data.loadedTB.trackingCategories,
  //       trackingCategoriesIds: context.data.loadedTB.trackingCategoriesIds
  //     }),
  //     width: 200,
  //     valueGetter: function (cellParams) {
  //       return generateTrackingValue({
  //         trackingCatNumber: index,
  //         trackingOptionId: cellParams.rowDef['tracking' + index],
  //         trackingCategories: context.data.loadedTB.trackingCategories,
  //         trackingCategoriesIds: context.data.loadedTB.trackingCategoriesIds
  //       })
  //     }
  //   })
  // })

  return columnDefs
}

function trialBalancesRows (loadedTB) {
  return loadedTB.groupedTB.filter(function (one) {
    // Why this case?
    // if (one[1].values.length === 1) return false

    return true
  })
}

async function loadTrialBalances (context) {
  console.log('loadTrialBalances', context, mData)

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)
  console.log('optionSelectorData', optionSelectorData)

  const accountId = accountData._id
  const orgId = context.showOrgId

  const apiUrl = '/api/xero/a/:accountId/o/:orgId/trial-balances/:startingDate/:endingDate'
  const data = await callApi(apiUrl, {
    accountId,
    orgId,
    startingDate: optionSelectorData.dates.min || '0',
    endingDate: optionSelectorData.dates.max || '0'
  })
  return data
}
