import * as fsDebug from './executor-debug.js'

// Dependencies
import * as cssGrid from '../../../helpers/cssgrid/index.js'

// Module
import * as mData from './data.js'
import * as fsSettings from './settings.js'
import * as fs2Columns from './statements-v2-columns.js'
import * as fs2RowsLayout from './statements-v2-rows-layout.js'
import { restrictDateseries } from '../../../helpers/helpers/dateseries.js'

let gridInstance

/**
 *
 * @param {Object} params
 * @param {Object} params.accountsAggregatedData
 * @param {String} params.containerElementId
 * @returns
 */
export function showFinancials (params) {
  console.log('v2 showFinancials', params)
  // For Columns
  const { context, dateseries, aggregationDB, columnsLayout, optionPeriods } = params

  // For others
  const { containerElementId, classes } = params

  if (!containerElementId) {
    console.warn('no containerElementId')
    return
  }

  const restrictedDateseries = restrictDateseries({
    dateseries,
    optionPeriods
  })

  const valueType = fsSettings.valueType()
  console.log('valueType', valueType)
  const layoutRows = fsSettings.layoutRows()

  fsDebug.debug(context)

  const organisations = mData.db.data.fs3Data.organisations

  context.statementType = mData.db.options.statementType

  gridInstance = cssGrid.generateGrid({
    colDefs: fs2Columns.generateColumnDefs({
      dateseriesView: restrictedDateseries,
      organisations,
      valueType,
      columnsLayout
    }),
    rowDefs: fs2RowsLayout.generateRowDefs({
      aggregationDB,
      layoutRows
    }),
    containerElementId,
    context,
    classes,
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  return gridInstance
}
