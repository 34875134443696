
/**
 *
 * @param {*} colorArray
 * @returns {String} rgb or rgba color string
 */
export function colorArrayToRGB (colorArray) {
  if (colorArray.length === 3) {
    return ['rgb(', colorArray.join(','), ')'].join('')
  }

  if (colorArray.length === 4) {
    return ['rgba(', colorArray.slice(0, 3).join(','), ',', colorArray[3], ')'].join('')
  }

  // Not correct value?
  return 'red'
}

export function parseRGB (string) {
  const regex = /([0-9.]+)/g
  const matches = string.match(regex)

  if (!matches) return null

  const rgbArray = matches.map(function (element) {
    return +element
  })
  return rgbArray
}
