import { indexArray } from '../data/array-index.js'
import { computeSerieAggregations } from '../values/values.js'
import { getAccountTypesToForecast, isAccountToInclude } from './forecasting-lines.js'
import { generateForecastingDateseries } from './helpers.js'
import { showDebugLogs } from './output.js'

/**
 * Receives
 * @param {*} params
 */
export function forecastCash (params) {
  console.log('forecastCash', params)
  const { organisationId, currencySymbol, fs3Data } = params
  const dateseries = fs3Data.dateseries

  // When to start the forecast
  let startingPeriodIndex

  if (params.calculationsSettings?.startingPeriod) {
    startingPeriodIndex = dateseries.indexOf(params.calculationsSettings?.startingPeriod)
  }

  if (!startingPeriodIndex) {
    // Starting month: default current month
    startingPeriodIndex = dateseries.length - 1 // Last period (ie current month)
  }

  // Forecasting period:
  const calculationsSettings = {
    monthsAhead: params.calculationsSettings?.monthsAhead || 6,
    // Method to compute past numbers
    monthsPast: params.calculationsSettings?.monthsPast || 3,
    methodPast: 'average'
  }
  console.log('calculationsSettings', calculationsSettings)

  const forecastingDateseries = generateForecastingDateseries(dateseries, calculationsSettings, startingPeriodIndex)

  const startingCash = computeStartingCash(fs3Data, organisationId, currencySymbol, startingPeriodIndex)

  const forecastingLines = computeForecastingLines({
    calculationsSettings,
    startingPeriodIndex,
    fs3Data,
    organisationId,
    currencySymbol
  })

  const forecastingRunningCash = computeRunningCash(fs3Data, forecastingDateseries, startingCash, forecastingLines)

  showDebugLogs(fs3Data, forecastingDateseries, startingCash, forecastingLines)

  return {
    fs3Data,
    forecastingDateseries,
    startingCash,
    forecastingRunningCash,
    forecastingLines
  }
}

function computeRunningCash (fs3Data, forecastingDateseries, startingCash, forecastingLines) {
  let runningCash = startingCash

  const runningCashSeries = [startingCash]

  forecastingDateseries.forEach(function (oneForecastingDate, index) {
    let periodChange = 0

    forecastingLines.forEach(function (oneForecastingLine) {
      const forecastingValue = oneForecastingLine.forecastingValues[index]
      if (!forecastingValue) return

      periodChange += forecastingValue
    })

    // Debits are positive, so we use -1
    runningCash += -1 * periodChange
    runningCashSeries.push(runningCash)
  })

  return runningCashSeries
}

/**
 * TODO: are the overdrafts included?
 * @param {*} fs3Data
 * @param {*} organisationId
 * @param {*} currencySymbol
 * @param {*} startingPeriodIndex
 * @returns
 */
function computeStartingCash (fs3Data, organisationId, currencySymbol, startingPeriodIndex) {
  console.log('computeStartingCash', fs3Data)
  const layoutsDB = fs3Data.layouts

  const bankLayouts = layoutsDB.filter(function (oneLayout) {
    const isOrg = oneLayout.org === organisationId
    if (!isOrg) return false

    const isTargetCurrency = oneLayout.currencySymbol === currencySymbol
    if (!isTargetCurrency) return false

    const isBankLayout = oneLayout.layoutId === 'BANK'
    if (!isBankLayout) return false

    return true
  })

  console.log('bankLayouts', bankLayouts)
  let startingCash = 0

  bankLayouts.forEach(function (oneBankLayout) {
    const periodBankBalance = oneBankLayout.data.cumuls[startingPeriodIndex - 1]
    startingCash += periodBankBalance
  })

  //
  return startingCash
}

/**
 * Find all applicable P&L lines with their values
 */
function computeForecastingLines (params) {
  const { calculationsSettings, startingPeriodIndex, fs3Data, organisationId, currencySymbol } = params

  const forecastingLines = []

  const accountTypeSettings = fs3Data.accountTypeSettings
  const aggregationDB = fs3Data.aggregates
  const accountsDB = fs3Data.accounts

  const accountTypesForForecasting = getAccountTypesToForecast(accountTypeSettings)

  const indexedAccounts = indexArray(accountsDB, 'id')
  const indexedAccountTypesForForecasting = indexArray(accountTypesForForecasting, 'id')

  aggregationDB.forEach(function (oneAggregation) {
    const isOrg = oneAggregation.org === organisationId
    if (!isOrg) return

    const isTargetCurrency = oneAggregation.currencySymbol === currencySymbol
    if (!isTargetCurrency) return

    const thisAccountTypeSettings = isAccountToInclude(oneAggregation.account, indexedAccountTypesForForecasting, indexedAccounts)
    if (!thisAccountTypeSettings) return

    const options = {
      aggregation: {
        includedPeriods: calculationsSettings.monthsPast,
        method: calculationsSettings.methodPast
      }
    }

    const seriesAggregation = computeSerieAggregations(oneAggregation.data.periods, options)

    // Use the previous period value
    const forecastingValue = seriesAggregation[startingPeriodIndex - 1]

    const forecastingValues = []
    while (forecastingValues.length < calculationsSettings.monthsAhead) {
      forecastingValues.push(forecastingValue)
    }

    forecastingLines.push({
      accountType: thisAccountTypeSettings,
      aggregation: oneAggregation,
      forecastingBase: seriesAggregation,
      forecastingValue,
      forecastingValues
    })
  })

  return forecastingLines
}
