import { cohortSettings } from '../data.js'

const expectedHeaders = [
  {
    properties: {
      actionTimestamp: 'Order Placed Time', //  'Order Completed Time',
      value: 'Total payment',
      userId: 'Username (buyer)'
    },
    source: 'Shopee'
  },
  {
    properties: {
      actionTimestamp: 'Waktu Pesanan Dibuat', // Waktu Pesanan Selesai
      value: 'Total Pembayaran',
      userId: 'Username (Pembeli)'
    },
    source: 'Shopee Indonesia'
  },
  {
    properties: {
      actionTimestamp: 'วันที่ทำการสั่งซื้อ', // เวลาที่ทำการสั่งซื้อสำเร็จ
      value: 'จำนวนเงินทั้งหมด',
      userId: 'ชื่อผู้ใช้ (ผู้ซื้อ)'
    },
    source: 'Shopee Thailand'
  },
  {
    properties: {
      actionTimestamp: 'Order Creation Date', // Order Complete Time
      value: 'Grand Total',
      userId: 'Username (Buyer)'
    },
    source: 'Shopee Malaysia'
  },
  {
    properties: {
      actionTimestamp: 'Date',
      // actionTimestamp: 'Payment Month',
      value: 'Payment Value',
      userId: 'Customer ID'
    },
    source: 'Future Flow Demo'
  }
]

export function storeProperties (sourceSettings) {
  const { fields } = cohortSettings

  fields.userId = sourceSettings.properties.userId
  fields.actionTimestamp = sourceSettings.properties.actionTimestamp
  fields.actionValue = sourceSettings.properties.value
}

export function detectDataSource (columns) {
  let foundSource = false
  let index = 0

  while (!foundSource && index < expectedHeaders.length) {
    const potentialSource = expectedHeaders[index]
    const isSource = testSource(potentialSource, columns)
    if (isSource) {
      foundSource = potentialSource
    }
    index++
  }
  if (foundSource) {
    storeProperties(foundSource)
    return foundSource
  } else {
    useDefaulFields(columns)
    return false
  }
}

function testSource (sourceSettings, columns) {
  let includesAllNeededColumns = true

  Object.keys(sourceSettings.properties).forEach(function (oneKey) {
    const neededColumn = sourceSettings.properties[oneKey]
    if (!columns.includes(neededColumn)) {
      includesAllNeededColumns = false
    }
  })

  return includesAllNeededColumns
}

function useDefaulFields (columns) {
  const { fields } = cohortSettings

  fields.userId = columns[0]
  fields.actionTimestamp = columns[1]
  fields.actionValue = columns[2]
}

// Shopee 2023
// [
//   '',
//   'id',
//   'Order No.',
//   'Order Status',
//   'Order Placed Time',
//   'Product name',
//   'SKU reference number',
//   'Variation name',
//   'Initial price',
//   'Price after discount',
//   'Quantity',
//   'Total product price',
//   'Total discount',
//   'Discount from seller',
//   'Discount from Shopee',
//   'No. of products in order',
//   'Vouchers borne by Seller',
//   'Vouchers borne by Shopee',
//   'Discount package',
//   'Discount package (discount from shopee)',
//   'Discount package (discount from seller)',
//   'Shopee Coin deduction',
//   'Credit card discount',
//   'Shipping costs are paid by the buyer',
//   'Estimated discount on shipping costs',
//   'Total payment',
//   'Estimated shipping costs',
//   'Username (buyer)',
//   'Shipping address',
//   'County / Town',
//   'Province',
//   'Order Completed Time'
// ]

// Shopee Indonesia
// [
//   'id',
//   'No. Pesanan',
//   'Status Pesanan',
//   'Waktu Pesanan Dibuat',
//   'Nama Produk',
//   'Nomor Referensi SKU',
//   'Nama Variasi',
//   'Harga Awal',
//   'Harga Setelah Diskon',
//   'Jumlah',
//   'Total Harga Produk',
//   'Total Diskon',
//   'Diskon Dari Penjual',
//   'Diskon Dari Shopee',
//   'Jumlah Produk di Pesan',
//   'Voucher Ditanggung Penjual',
//   'Voucher Ditanggung Shopee',
//   'Paket Diskon',
//   'Paket Diskon (Diskon dari Shopee)',
//   'Paket Diskon (Diskon dari Penjual)',
//   'Potongan Koin Shopee',
//   'Diskon Kartu Kredit',
//   'Ongkos Kirim Dibayar oleh Pembeli',
//   'Estimasi Potongan Biaya Pengiriman',
//   'Total Pembayaran',
//   'Perkiraan Ongkos Kirim',
//   'Username (Pembeli)',
//   'Alamat Pengiriman',
//   'Kota/Kabupaten',
//   'Provinsi',
//   'Waktu Pesanan Selesai'
// ]
