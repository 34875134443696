import * as XLSX from 'xlsx'

/**
* The Range might be incorrect at first
* https://docs.sheetjs.com/docs/miscellany/errors/ provide recalculation function to apply on the worksheet
* @param {*} worksheet
*/
export function updateSheetRange (worksheet) {
  if (!worksheet) return
  const range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } }
  Object.keys(worksheet).filter(function (x) { return x.charAt(0) !== '!' }).map(XLSX.utils.decode_cell).forEach(function (x) {
    range.s.c = Math.min(range.s.c, x.c); range.s.r = Math.min(range.s.r, x.r)
    range.e.c = Math.max(range.e.c, x.c); range.e.r = Math.max(range.e.r, x.r)
  })
  worksheet['!ref'] = XLSX.utils.encode_range(range)
}
