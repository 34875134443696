let _intercomAppId

export function recordPage () {
  if (!window.Intercom) return
  window.Intercom('update')
}

export function identifyUser (userData) {
  if (!window.Intercom) return
  // window.Intercom('update', {
  //   name: userData.name,
  //   email: userData.email
  // })

  // The update does not work... so using boot again
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: _intercomAppId,
    name: userData.name,
    email: userData.email
    // created_at: '<%= current_user.created_at.to_i %>' // Signup date as a Unix timestamp
  })
}

export function bootIntercom () {
  if (!window.Intercom) return
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: _intercomAppId
  })
}

export function loadIntercom (intercomAppId) {
  // console.log('loadIntercom', intercomAppId)
  _intercomAppId = intercomAppId

  const w = window
  const ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    const d = document
    const i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    const l = function () {
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/:intercomAppId'.replaceAll(':intercomAppId', _intercomAppId)
      const x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
}
