// Router using https://visionmedia.github.io/page.js/
// Last Updated 2020: React, VueJS and Angular probably took over.
// Flo still likes the simplicity of this front end router
//

// Admin
import * as pAdminOrgInfo from './pages/admin/org-info/index.js'
import * as pAdminOrgInfos from './pages/admin/org-info/infos.js'
import * as pAdminOrgInfoTrialBalances from './pages/admin/org-info/trial-balances.js'
import { showPageListOrgs } from './pages/admin/orgs/index.js'
import { loadPageStarter } from './config/app-starter.js'
import { showPageListAccounts } from './pages/admin/accounts/index.js'
import { showPageSyncs } from './pages/admin/xero-syncs/index.js'
import { showPageTokens } from './pages/admin/tokens/index.js'
import page from 'page'

//
page('/admin', async function (context) {
  await loadPageStarter()
  console.log('admin')
})

//
page('/admin/accounts', async function (context) {
  await loadPageStarter()

  showPageListAccounts()
})

//
page('/admin/orgs', async function (context) {
  await loadPageStarter()

  showPageListOrgs()
})

// Listing of Syncs: all org or specific one
page('/admin/syncs/:orgId?', async function (context) {
  await loadPageStarter()

  const { orgId } = context.params

  showPageSyncs(orgId)
})

page('/admin/tokens/:orgId?', async function (context) {
  await loadPageStarter()

  const { orgId } = context.params

  showPageTokens(orgId)
})

page('/admin/org-info/:orgId', async function (context) {
  const { orgId } = context.params
  await loadPageStarter()

  pAdminOrgInfo.show(orgId)
})

page('/admin/org-info/:orgId/trial-balances', async function (context) {
  const { orgId } = context.params
  await loadPageStarter()

  pAdminOrgInfoTrialBalances.show(orgId)
})

page('/admin/org-info/:orgId/infos', async function (context) {
  const { orgId } = context.params
  await loadPageStarter()

  pAdminOrgInfos.showOrgInfos(orgId)
})

// Init the router
page()
