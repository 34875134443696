
export function copyValue (cellElement) {
  console.log('try copyValue', cellElement, cellElement.innerText)
  navigator.clipboard.writeText(cellElement.innerText).then(
    () => {
      /* clipboard successfully set */
      console.log('copied', cellElement.innerText)
    },
    (e) => {
      /* clipboard write failed */
      console.warn('copied failed', e)
    }
  )

  // .focus() does not seem to work
  cellElement.focus()
}
