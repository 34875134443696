// App config
import * as configVar from '../../../config/config.js'

import * as mUser from '../../../modules/user/user.js'

import * as cssGrid from '../../../../helpers/cssgrid/index.js'

import htmlIndexTemplate from '../schedules.html'
import { hideElement, setElementHtml } from '../../../helpers/dom.js'
import { activateOptionSelector } from '../../../modules/option-selector/index.js'
import { loadScheduleDataTransactions } from './load.js'
import { generateTransactionsRowDefs } from './grid-rows.js'
import { generateTransactionsColumnDefs } from './grid-columns.js'

let gridInstance
const schedulesTransactionsDB = {}

export async function showTransactions (params) {
  console.log('showTransactions', params)

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  // Ensure the page content element is available
  const DOMelement = setElementHtml(configVar.contentElementId, htmlIndexTemplate)
  if (!DOMelement) return

  schedulesTransactionsDB.schedulesData = await loadScheduleDataTransactions(params)
  schedulesTransactionsDB.settings = computeDateseries(schedulesTransactionsDB.schedulesData)

  executeShowTransactions()
  setOptionSelector()
  hideElement('pageLoadingMessage')
}

function setOptionSelector () {
  const optionSelectorHide = []
  optionSelectorHide.push('dates')
  optionSelectorHide.push('periodicity')
  optionSelectorHide.push('valueAggregation')
  optionSelectorHide.push('organisations')
  optionSelectorHide.push('gridLayout')
  optionSelectorHide.push('currencies')
  optionSelectorHide.push('forecasting')

  activateOptionSelector({
    containerId: 'optionSelector',
    hide: optionSelectorHide,
    onChange: executeShowTransactions,
    dateseries: schedulesTransactionsDB.settings.dateseriesView
  })
}

function executeShowTransactions () {
  showTransactionsGrid({ schedulesTransactionsDB })
}

function showTransactionsGrid (params) {
  console.log('showTransactionsGrid', params)

  const containerElementId = 'scheduleGrid'

  const transactionsData = params.schedulesTransactionsDB.schedulesData

  gridInstance = cssGrid.generateGrid({
    colDefs: generateTransactionsColumnDefs(),
    rowDefs: generateTransactionsRowDefs(transactionsData),
    containerElementId,
    // context,
    // classes,
    startingScrollSettings: (gridInstance) ? gridInstance._layoutElements.scrollingPositions : false
  })

  return gridInstance
}

function computeDateseries (schedulesData) {
  console.log('schedulesData', schedulesData)

  return {
    dateseriesView: []
  }
}
