
// Module
import { fillString } from '../../../../helpers/templating/string-template.js'
import { hideElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../data.js'
import { executeOnChange } from '../onchange.js'

import htmlTemplateValueAggregationButton from './options-value-aggregations-button.html'
import htmlTemplateValueAggregationDropdown from './options-value-aggregations-dropdown.html'

export function activate (params) {
  if (params?.hide?.includes('valueAggregation')) {
    hideElement('optionSelectorValueType')
    return
  }

  showValueAggregationSelector(params)
  listeners(params)
}

function listeners (params) {
  //
  const valueAggregationElement = document.getElementById('valueAggregationButtons')
  valueAggregationElement.addEventListener('click', function (clickEvent) {
    console.log('aggregation method included click')
    const target = clickEvent.target.closest('.oneChoice')
    if (!target) return
    console.log('target', target)

    const targetMethod = target.getAttribute('d-aggregationMethod')
    const targetPeriodsIncluded = target.getAttribute('d-periodIncluded')
    console.log(targetMethod, targetPeriodsIncluded)

    optionSelectorData.viewAggregations.method = targetMethod
    optionSelectorData.viewAggregations.includedPeriods = +targetPeriodsIncluded

    target.classList.add('is-active')
    executeOnChange(params)
  })
}

function showValueAggregationSelector (params) {
  const buttonsContainerElement = document.getElementById('valueAggregationButtons')
  buttonsContainerElement.innerText = ''

  const buttonsHtml = []

  methods.forEach(function (oneMethod) {
    const isMatchStatementType = (oneMethod.for.statement === params.customSettings?.statementType) || oneMethod.for.statement.includes(params.customSettings?.statementType)
    const isForcedMethod = (params.aggregationMethods && params.aggregationMethods.includes(oneMethod.method))
    if (!(isMatchStatementType || isForcedMethod)) return

    function basicsReplacement (htmlButton, oneMethod) {
      return fillString(htmlButton, {
        aggregationMethod: oneMethod.method,
        methodLabel: oneMethod.label
      })
    }

    let htmlButton

    if (Array.isArray(oneMethod.includedPeriods)) {
      console.log('array ', oneMethod.includedPeriods)
      htmlButton = basicsReplacement(htmlTemplateValueAggregationDropdown, oneMethod)

      const dropdownContentHtml = []

      oneMethod.includedPeriods.forEach(function (oneButton) {
        let thisButtonHtml = '<div class="dropdown-item oneChoice clickable" d-periodIncluded=":periodIncluded" d-aggregationMethod=":aggregationMethod">:label</div>'
        thisButtonHtml = fillString(thisButtonHtml, {
          periodIncluded: oneButton.value,
          aggregationMethod: oneMethod.method,
          label: oneButton.label
        })
        dropdownContentHtml.push(thisButtonHtml)
      })
      htmlButton = htmlButton.replaceAll(':dropdownContent', dropdownContentHtml.join(''))
    } else {
      htmlButton = basicsReplacement(htmlTemplateValueAggregationButton, oneMethod)
      htmlButton = htmlButton.replaceAll(':periodIncluded', oneMethod.includedPeriods)
    }

    buttonsHtml.push(htmlButton)
  })

  buttonsContainerElement.innerHTML = buttonsHtml.join('')
}

export const methods = [{
  for: {
    calculationPeriods: 'any', // ie. when is the method applicable
    statement: 'bs'
  },
  label: 'Balance',
  method: 'balance',
  includedPeriods: 1
},
{
  for: {
    calculationPeriods: 1, // ie. when is the method applicable
    statement: 'pnl'
  },
  label: 'Period Value',
  method: 'period',
  includedPeriods: 1
}, {
  for: {
    calculationPeriods: 'any', // ie. when is the method applicable
    statement: 'pnl'
  },
  label: 'Total',
  method: 'sum',
  includedPeriods: [{
    label: 'Trailing 3 months',
    value: 3
  }, {
    label: 'Trailing 6 months',
    value: 6
  }, {
    label: 'Trailing 12 months',
    value: 12
  }, {
    label: 'All time',
    value: -1
  }]
}, {
  for: {
    calculationPeriods: 'any', // ie. when is the method applicable
    statement: ['pnl', 'bs']
  },
  label: 'Average',
  method: 'average',
  includedPeriods: [{
    label: 'Trailing 3 months',
    value: 3
  }, {
    label: 'Trailing 6 months',
    value: 6
  }, {
    label: 'Trailing 12 months',
    value: 12
  }]
}]
