import { indexArray } from '../../../../helpers/data/array-index.js'

export function forecastingGridRows (params) {
  const rowsSettings = []

  rowsSettings.push(startingCashRow(params))
  rowsSettings.push(breakRow())

  rowsSettings.push(...contentRows(params))

  rowsSettings.push(breakRow())
  rowsSettings.push(endingCashRow(params))

  return rowsSettings
}

function contentRows (params) {
  console.log('contentRows', params)
  const contentRows = []

  const { fs3Data, forecastingLines } = params

  const accountsDB = fs3Data.accounts
  const indexedAccounts = indexArray(accountsDB, 'id')

  forecastingLines.forEach(function (oneForecastingLine) {
    const targetAccount = indexedAccounts[oneForecastingLine.aggregation.account]

    const rowSettings = {
      id: oneForecastingLine.aggregation.account,
      value: [targetAccount.type, targetAccount.name].join(' '),
      data: oneForecastingLine
      // styles: ['sectionSubTotal']
    }

    contentRows.push(rowSettings)
  })

  return contentRows
}

function startingCashRow (params) {
  const rowSettings = {
    id: 'startingCash',
    value: 'Starting Cash',
    styles: ['sectionSubTotal'],
    params: {
      forecastingRunningCash: params.forecastingRunningCash
    }
  }

  return rowSettings
}

function endingCashRow (params) {
  const rowSettings = {
    id: 'endingCash',
    value: 'Ending Cash',
    styles: ['sectionTotal'],
    params: {
      forecastingRunningCash: params.forecastingRunningCash
    }
  }

  return rowSettings
}

function breakRow () {
  const rowSettings = {
    height: 10, // TODO: not yet implemented
    // id: 'row_' + index,
    value: ' ',
    isBreak: true
  }
  return rowSettings
}
