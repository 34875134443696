
// App config
import * as configVar from '../../../config/config.js'

import * as mAccount from '../../../modules/user/account.js'

import { enforceRestrictedPage } from '../../../modules/user/user.js'

import { hideElement, setElementHtml } from '../../../helpers/dom.js'
import { createContainer, setPageTitle } from '../../financials/helpers.js'
import { listItems } from './list-items.js'

import htmlTemplateGrouping from './grouping.html'
import './style.less'
import { enableDragging } from './enable-dragging.js'

export async function showPageItemsGrouping (params) {
  console.log('showPageItemsGrouping')

  if (enforceRestrictedPage({ allowPublic: true })) return

  const DOMelement = setElementHtml(configVar.contentElementId, '')
  if (!DOMelement) return

  DOMelement.classList.remove('position-absolute', 'top-to-bottom')

  const contentElement = createContainer('Create P&L and Balance Sheet layouts')
  DOMelement.append(contentElement)

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  setPageTitle(accountData, 'Group Items')

  const containerElement = document.getElementById('statementContainer')
  containerElement.innerHTML = htmlTemplateGrouping

  listItems()
  enableDragging()

  hideElement('statementLoadingMessage')
}
