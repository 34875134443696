import sift from 'sift'

export function filterDB (records, options) {
  console.log('filterDB', records, options)

  const { periodStart, periodEnd, propertyToFilter } = options

  const _propertyToFilter = propertyToFilter || 'settledAt'

  const filter = {}
  if (_propertyToFilter === 'paid.notSettled') {
    filter.$and = [
      {
        paidAt: { $gte: periodStart }
      },
      {
        paidAt: { $lte: periodEnd }
      },
      {
        $or: [
          {
            settledAt: {
              $exists: false
            }
          }, {
            settledAt: null
          }
        ]
      }
    ]
  } else if (_propertyToFilter === 'settled.or.paid') {
    filter.$and = [
      {
        $or: [
          {
            $and: [
              {
                paidAt: { $gte: periodStart }
              },
              {
                paidAt: { $lte: periodEnd }
              }
            ]
          },
          {
            $and: [
              {
                settledAt: { $gte: periodStart }
              },
              {
                settledAt: { $lte: periodEnd }
              }
            ]
          }
        ]
      }
    ]
  } else {
    filter.$and = [
      {
        [_propertyToFilter]: { $gte: periodStart }
      },
      {
        [_propertyToFilter]: { $lte: periodEnd }
      }
    ]
  }

  console.log('filter', filter)

  const filteredRecords = records?.filter(sift(filter))

  return filteredRecords
}
