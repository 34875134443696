import * as XLSX from 'xlsx'
import { outputAccountDetails, trackingCategoryName, trackingCategoryOptionName } from './helpers.js'
import { activateExport } from '../../../helpers/activate-export.js'

let isListenerAdded = false

export function enableExport (loadedTB) {
  if (isListenerAdded) return
  isListenerAdded = true
  activateExport(downloadTrialBalanceCsv, loadedTB)
}

function downloadTrialBalanceCsv (loadedTB) {
  const { groupedTB, trackingCategories } = loadedTB

  const rows = groupedTB.map(function (oneRow) {
    console.log('oneRow', oneRow)
    const rowValue = oneRow[1]

    const trackingCategoriesNames = [
      trackingCategoryName(trackingCategories, 1),
      trackingCategoryName(trackingCategories, 2)
    ]
    const targetAccountXeroId = rowValue.key.accountXeroId
    return {
      Month: rowValue.key.month,
      Value: rowValue.sum,
      'Account Code': outputAccountDetails(loadedTB, targetAccountXeroId, 'code'),
      Account: outputAccountDetails(loadedTB, targetAccountXeroId, 'name'),
      // 'Total Before': oneRow.totalBefore,
      // 'Total After': oneRow.totalAfter,
      [trackingCategoriesNames[0]]: trackingCategoryOptionName(trackingCategories, 1, rowValue.key.tracking_1),
      [trackingCategoriesNames[1]]: trackingCategoryOptionName(trackingCategories, 2, rowValue.key.tracking_2)
    }
  })
  console.log('rows', rows)

  const worksheet = XLSX.utils.json_to_sheet(rows)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Trial balances')

  const writeOptions = {
    compression: true,
    bookType: 'csv'
  }

  XLSX.writeFile(workbook, 'Trial Balances.csv', writeOptions)
}
