import * as d3 from 'd3'
import { optionSelectorData } from '../../modules/option-selector/data.js'
import { generateDateseries } from '../../../helpers/helpers/dateseries.js'

export const propertyForMethod = {
  period: 'amount',
  balance: 'cumulAmount'
}

function grouping (record) {
  return record._id.contactId
}

export function computeGroups (records) {
  const groups = d3.group(records, grouping) // Map

  return groups
}

/**
 *
 * @param {*} records
 * @returns
 */
export function computeSortedGroups (records, groups, restrictedDateseries) {
  console.log('computeSortedGroups', optionSelectorData.viewAggregations.method)
  function sortingAggregation (records) {
    if (optionSelectorData.decimals === 2) {
      return records[0]._id.contact
    }
    const groupAggregationValue = -d3.sum(records, function (record) {
      // Filter the dates
      if (!restrictedDateseries.includes(record._id.date)) return 0

      return record[propertyForMethod[optionSelectorData.viewAggregations.method]]
      // return record.amount
    })
    return groupAggregationValue
  }
  function rollupAggregation (records) {
    const groupAggregationValue = d3.count(records, function (record) {
      // Filter the dates
      if (!restrictedDateseries.includes(record._id.date)) return undefined

      return record[propertyForMethod[optionSelectorData.viewAggregations.method]]
      // return record.amount
    })
    return groupAggregationValue
  }

  const sortedKeys = d3.groupSort(records, sortingAggregation, grouping) // Array of keys

  // Rollups to evaluate 0-values
  const rolledupValues = d3.rollup(records, rollupAggregation, grouping) // Array of keys

  const sortedGroups = new Map()
  sortedKeys.forEach(function (oneKey) {
    // avoid 0-values
    const rolledupValue = rolledupValues.get(oneKey)
    if (!rolledupValue) return

    const group = groups.get(oneKey)
    // sortedGroups.push(group)
    sortedGroups.set(oneKey, group)
  })

  return sortedGroups
}

/**
 * We need a min and max
 * @param {*} sortedGroups
 */
export function densifyRecordGroups (sortedGroups, options) {
  // console.log('sortedGroups', sortedGroups, options)

  // function computeNextDate (dateYYYYMM) {
  //   const date = new Date(dateYYYYMM)
  //   const nextDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1))
  //   console.log(date, nextDate)
  //   return nextDate.toJSON().substring(0, 7)
  // }
  // generateDateseries()

  sortedGroups.forEach(function (groupOfRecords, key) {
    // console.log('groupOfRecords', groupOfRecords)
    const densified = densify(groupOfRecords, {
      min: options.min || groupOfRecords[0]._id.date,
      max: options.max || groupOfRecords[groupOfRecords.length - 1]._id.date
    })

    sortedGroups.set(key, densified)
  })
}

function densify (array, params) {
  const dateseries = generateDateseries(params)
  // console.log('densify', dateseries, params)

  let arrayIndex = 0

  const densified = []

  dateseries.forEach(function (oneDate) {
    const recordAtIndex = array[arrayIndex]
    // console.log(oneDate, arrayIndex, recordAtIndex)
    if (recordAtIndex?._id?.date === oneDate) {
      densified.push(recordAtIndex)
      arrayIndex++
    } else {
      // Previous record, or first (if the densifying dateseries starts earlier than the first record)
      const recordAtPreviousIndex = array[arrayIndex - 1] || array[0]
      const copiedRecord = Object.assign({}, recordAtPreviousIndex)
      copiedRecord._id = Object.assign({}, copiedRecord._id)
      copiedRecord._id.date = oneDate

      // Update the values of the period
      copiedRecord.amount = 0
      copiedRecord.count = 0

      if (arrayIndex === 0) {
        copiedRecord.cumulEntries = 0
        copiedRecord.cumulAmount = 0
      }

      densified.push(copiedRecord)
    }
  })
  // console.log('densified', densified)
  return densified
}
