// import { combinedFieldsSettings } from '../../combined-fields-settings.js'
import { tiktokOrdersFieldsMapping } from './tiktok-orders-fields-mapping.js'
import { tiktokSettlementsFieldsMapping } from './tiktok-settlements-fields-mapping.js'

export const IDtiktokSettings = {
  orders: {
    dataFile: {
      type: 'csv'
    },
    fileDropZoneText: 'Pick or drop your file (.csv)',
    fieldsMapping: tiktokOrdersFieldsMapping
  },
  settlements: {
    dataFile: {
      sheetName: 'Order details',
      // sheetName: 'Settlements after 2022.6.6', // This sheet was only shown in Jan 2024
      downloadTip: 'eg from https://seller-id.tokopedia.com/finance/settled'
    },
    fileDropZoneText: 'Pick or drop your file (.xlsx)',
    fieldsMapping: tiktokSettlementsFieldsMapping
  }
}
